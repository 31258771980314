import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import { AllOrdersToolbar } from './AllOrdersToolbar';
import { AllOrdersTable } from './AllOrdersTable';
import { OrderFiltersInput } from '../../../../../__generated__/types';
import { useGetAllOrdersDelivery } from '../../../../../graphql/queries/hook/useGetAllOrdersDelivery';
import { useFiltersSearchParamsForOrders } from '../../../../../hooks/useFiltersSearchParamsForOrders';
import { addDays, format } from 'date-fns';

export const AllOrdersTab = () => {
  const { selStatus, selDateTo, selDateFrom } = useFiltersSearchParamsForOrders({
    dateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
    dateTo: format(new Date(), 'yyyy-MM-dd'),
  });
  const { allOrdersDelivery, page, totalPage, changePage, loading } = useGetAllOrdersDelivery({
    filters: getFiltersOrders(),
  });

  const [resetFilterOrders, setResetFilterOrders] = useState(new Date().getTime().toString());

  const handlerReset = () => {
    setResetFilterOrders(new Date().getTime().toString());
  };

  function getFiltersOrders(): OrderFiltersInput | undefined {
    const filters: OrderFiltersInput | undefined = {};

    if (selStatus) {
      filters['status'] = {
        eq: selStatus,
      };
    }

    if (selDateTo && selDateFrom) {
      filters['date'] = {
        between: [selDateFrom, selDateTo],
      };
    }
    return Object.keys(filters).length > 0 ? filters : undefined;
  }

  return (
    <Box width="100%">
      <AllOrdersToolbar key={resetFilterOrders} setResetFilterOrders={handlerReset} />
      <AllOrdersTable
        allOrdersDelivery={allOrdersDelivery}
        totalPage={totalPage}
        changePage={changePage}
        currentPage={page}
        loading={loading}
      />
    </Box>
  );
};
