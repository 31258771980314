import React, { FC, useEffect, useState } from 'react';
import { Stack, Button, Typography } from '@mui/material';
import { Enum_Order_Status } from '../../../../../__generated__/types';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useDefaultRangeCalendar } from '../../../../../hooks';
import { useFiltersSearchParamsForOrders } from '../../../../../hooks/useFiltersSearchParamsForOrders';
import { RangeCalendar } from '../../../../../legos/rangeCalendar/RangeCalendar';
import { useLocalization } from '../../../../../localization';
import { ItemSelectFilterType, SelectFilter } from '../../../../OrdersList/components/SelectFilter';
import { addDays, endOfToday, format, startOfDay, subDays } from 'date-fns';

interface Props {
  setResetFilterOrders(): void;
}

const STATUSES_FILTERS: ItemSelectFilterType[] = [
  ...[Enum_Order_Status.PickedUp, Enum_Order_Status.Delivery].map(value => ({
    label: value,
    value,
  })),
];

export const AllOrdersToolbar: FC<Props> = () => {
  const { translateLang } = useLocalization();
  const { reportRangeDates } = useDefaultRangeCalendar();
  const { changeStatus, changeDate, resetFilters, selStatus, selDateTo, selDateFrom } =
    useFiltersSearchParamsForOrders({
      dateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
      dateTo: format(new Date(), 'yyyy-MM-dd'),
    });
  const [selectDates, setSelectedDates] = useState(getDatesFilters);

  const handlerChangesDates = (val: Date[]) => {
    setSelectedDates(val);
    changeDate(format(val[0], 'yyyy-MM-dd'), format(val[1] ?? val[0], 'yyyy-MM-dd'));
  };

  const resetFiltersAll = () => {
    resetFilters();
    setSelectedDates([startOfDay(subDays(new Date(), 30)), new Date()]);
  };

  function getDatesFilters(): Date[] {
    if (selDateFrom && selDateTo) {
      return [new Date(selDateFrom), new Date(selDateTo)];
    }
    return [startOfDay(new Date()), endOfToday()];
  }

  useEffect(() => {
    resetFilters();
  }, []);

  return (
    <>
      <Stack
        bgcolor="white"
        pl={2}
        paddingY={1}
        flexDirection={{ xs: 'column', md: 'row' }}
        alignItems="center"
        justifyContent="right"
        spacing={2}
      >
        <Stack
          pr={3}
          py="17px"
          spacing={2}
          bgcolor="white"
          flexDirection="row"
          justifyContent="right"
        >
          <Stack direction="row" gap={2} alignItems="center">
            <SelectFilter
              labelFilter={translateLang('status')}
              items={STATUSES_FILTERS}
              selectItem={selStatus}
              changeUrlParams={changeStatus}
            />
            <RangeCalendar
              selectedDates={selectDates}
              defaultRangeDates={reportRangeDates}
              setSelectedDates={handlerChangesDates}
            />
            <Button
              color="primary"
              variant="outlined"
              onClick={resetFiltersAll}
              style={{
                width: '100%',
                textTransform: 'none',
              }}
            >
              <TranslatedField
                noWrap
                isTranslate
                fontSize={16}
                color="black"
                originText="resetFilters"
              />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
