import React, { FC, useState } from 'react';
import { Stack } from '@mui/system';
import { CustomModal } from '../../../../../../components';
import { ScanComponent } from '../../../ScanComponent';
import { PreDeliveryTableComponent } from './RpeDeliveryTableComponent';
import {
  PickedOrderItemMutation,
  usePickedOrderItemMutation,
} from '../../../../../../graphql/mutations/__generated__/pickedOrderItem';
import { Enum_Mode_Delivery_State, Enum_Move_To } from '../../../../../../__generated__/types';
import { handlerError } from '../../../../../../helpers/functions';
import { useGetPreDeliveryOrderItems } from '../../hooks/useGetPreDeliveryOrderItems';
import { usePreDeliveryStateQuery } from '../../../../../../graphql/cacheQuery/__generated__/preDeliveryState';
import { NonSearched } from '../../../NonSearched';
import { useUpdateOrderMutation } from '../../../../../../graphql/mutations/__generated__/updateOrder';
import { useGetMe } from '../../../../../../graphql/queries/hook/useGetMe';
import { useGetOrderById } from '../../../../../../graphql/queries/hook/useGetOrderById';
import { useLocalization } from '../../../../../../localization';
import { SelectItemProductInfo } from '../../../../types';
import { AddQuantity } from '../../../AddQuantity';
import { OrderItemType } from '../../../../../../helpers/types';
import { FetchResult } from '@apollo/client';

type MODE = 'table' | 'searched' | 'nonSearched' | 'addQuantity';
interface Props {
  open: boolean;
  handleClose: () => void;
}

export const PreDeliveryModal: FC<Props> = ({ open, handleClose }) => {
  const { id: courierID } = useGetMe();
  const { updateSelectLanguage } = useLocalization();
  const { data: dataPreDeliveryState } = usePreDeliveryStateQuery();
  const { courier } = useGetOrderById(dataPreDeliveryState?.preDeliveryState.idOrder || '');
  const [runPickedOrderItem] = usePickedOrderItemMutation();
  const [runUpdateOrder] = useUpdateOrderMutation();
  const [selectMode, setSelectMode] = useState<MODE>('table');
  const [selectProductInfo, setSelectProductInfo] = useState<SelectItemProductInfo | null>(null);
  const {
    getHeadersTable,
    getRowsTable,
    loading,
    refetchOrderItems,
    isComplectedToDelivery,
    getOrderItemByBarcode,
  } = useGetPreDeliveryOrderItems(setSelectProductInfo);
  const [orderItem, setOrderItem] = useState<OrderItemType>();

  const handleRunPickOrderItem = async (eanNumber: string, quantity?: number) => {
    const moveTo =
      dataPreDeliveryState?.preDeliveryState.callParent === Enum_Mode_Delivery_State.Warehouse
        ? Enum_Move_To.PickUp
        : Enum_Move_To.Delivery;
    try {
      const response: FetchResult<PickedOrderItemMutation> = await runPickedOrderItem({
        variables: {
          input: {
            moveTo,
            orderId: dataPreDeliveryState?.preDeliveryState.idOrder || '',
            quantity: quantity || 1,
            stockBarcode: eanNumber,
          },
        },
      });
      if (response?.data?.moveOrderItemTo?.success) {
        refetchOrderItems && refetchOrderItems();
        return response.data.moveOrderItemTo.success;
      }
    } catch (err: unknown) {
      handlerError(err);
    }
  };

  const handlePickedOrderItem = async (eanNumber: string) => {
    try {
      const res = await handleRunPickOrderItem(eanNumber);
      if (res) {
        const curOrderItem = getOrderItemByBarcode(eanNumber);
        if (
          curOrderItem?.quantity &&
          (curOrderItem?.pickedUpQuantity || curOrderItem?.pickedUpQuantity === 0) &&
          +curOrderItem?.quantity > +curOrderItem?.pickedUpQuantity + 1
        ) {
          setOrderItem(curOrderItem);
          setSelectMode('addQuantity');
        } else {
          setSelectMode('table');
        }
        const isDeliveryParent =
          dataPreDeliveryState?.preDeliveryState.callParent === Enum_Mode_Delivery_State.Delivery;
        if (!courier && isDeliveryParent) {
          await runUpdateOrder({
            variables: {
              id: dataPreDeliveryState?.preDeliveryState.idOrder || '',
              data: {
                courier: courierID,
              },
              locale: updateSelectLanguage as string,
            },
          });
        }
      } else {
        setSelectMode('nonSearched');
      }
    } catch (err: unknown) {
      handlerError(err);
      setSelectMode('nonSearched');
    }
  };
  return (
    <CustomModal
      title=""
      handleComeBack={selectMode !== 'table' ? () => setSelectMode('table') : undefined}
      handleClose={handleClose}
      open={open}
      bgcolor="#F1F3F8"
      width="1100px"
      height="700px"
      display="flex"
      flexDirection="column"
    >
      <Stack textAlign="center" width="100%" height="100%" overflow="hidden">
        {selectMode === 'searched' ? (
          <ScanComponent
            isOpen={selectMode === 'searched'}
            onChange={handlePickedOrderItem}
            loading={loading}
          />
        ) : null}
        {selectMode === 'addQuantity' ? (
          <AddQuantity
            isOpen={selectMode === 'addQuantity'}
            handleAddQuantity={handleRunPickOrderItem}
            handleClose={() => setSelectMode('table')}
            orderItem={orderItem}
          />
        ) : null}
        {selectMode === 'table' ? (
          <PreDeliveryTableComponent
            isComplectedToDelivery={isComplectedToDelivery}
            getHeadersTable={getHeadersTable}
            getRowsTable={getRowsTable}
            isOpen={selectMode === 'table'}
            onChange={() => setSelectMode('searched')}
            loading={loading}
            selectProduct={selectProductInfo}
          />
        ) : null}
        {selectMode === 'nonSearched' ? (
          <NonSearched
            isOpen={selectMode === 'nonSearched'}
            handleClose={() => {
              /**/
            }}
            handleClickButton={handlePickedOrderItem}
          />
        ) : null}
      </Stack>
    </CustomModal>
  );
};
