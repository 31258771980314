import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Grid, Stack, Typography } from '@mui/material';

import { theme } from '../../../../helpers';
import { PatchesName } from '../../../../types/types';
import { useGetSuppliersQuery } from '../../../../graphql/queries/__generated__/getSuppliers';
import { useGetSupplierBalanceLazyQuery } from '../../../../graphql/queries/__generated__/getSupplierBalance';

interface SuppliersType {
  supplierBalance?: number | null;
  remainingPayment?: number | null;
  supplierName: string | null | undefined;
  id?: string | null;
}

export const SuppliersTab = () => {
  const navigate = useNavigate();

  const { data: suppliersData } = useGetSuppliersQuery({
    variables: {
      filters: { or: [{ blocked: { eq: false } }, { blocked: { eq: null } }] },
    },
  });
  const [runGetSupplierBalance] = useGetSupplierBalanceLazyQuery();

  const [loading, setLoading] = useState(true);
  const [suppliers, setSuppliers] = useState<SuppliersType[]>([]);

  useEffect(() => {
    const fetchSuppliersData = async () => {
      if (suppliersData?.suppliers?.data) {
        const supplierPromises = suppliersData.suppliers.data.map(async item => {
          const balanceData = await runGetSupplierBalance({
            variables: {
              input: { supplierId: item.id as string },
            },
          });

          return {
            supplierBalance: balanceData.data?.supplierBalance?.balance,
            remainingPayment: balanceData.data?.supplierBalance?.remainingPayment,
            supplierName: item.attributes?.name,
            id: item.id,
          };
        });

        const result = await Promise.all(supplierPromises);
        setSuppliers(result);
        setLoading(false);
      }
    };

    fetchSuppliersData();
  }, [suppliersData, runGetSupplierBalance]);

  const handleGoToSupplierPage = (supplierId: string) => {
    navigate(`/${PatchesName.Accounting}/providers/${supplierId}`);
  };

  return (
    <Grid container width={!loading ? '100%' : 'auto'} gap={2.5}>
      {!loading ? (
        <>
          {suppliers?.map(item => (
            <Grid
              item
              container
              xs={6}
              md={4}
              lg={3}
              key={item.id}
              flexDirection="row"
              flexWrap="nowrap"
              alignItems="center"
              justifyContent="center"
              gap={1.25}
              p={2.5}
              bgcolor={theme.palette.common.white}
              borderRadius="10px"
              sx={{ cursor: 'pointer' }}
              onClick={() => handleGoToSupplierPage(item.id ?? '')}
            >
              <Typography fontSize={32} color={theme.palette.primary.main}>
                {item.supplierName}
              </Typography>
              <Typography
                fontSize={20}
                marginTop="5px"
                fontWeight={600}
                whiteSpace="nowrap"
                color={
                  item.supplierBalance && item.supplierBalance <= 0
                    ? theme.palette.common.red
                    : theme.palette.common.appleGreen
                }
              >
                {`${item.supplierBalance || 0} €`}
              </Typography>
            </Grid>
          ))}
        </>
      ) : (
        <Stack justifyContent="center" alignItems="center" mt={10}>
          <CircularProgress size={40} />
        </Stack>
      )}
    </Grid>
  );
};
