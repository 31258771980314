import React, { ReactNode } from 'react';
import { useLocalization } from '../../../../../localization';
import { HeaderCell } from '../../../../../components/TableProductActive/TableProductActive';
import { AllOrdersDeliveryRowItem } from '../components/tablesComponents/AllOrdersDeliveryRowItem';
import { AllOrdersDeliveryType } from '../../../../../graphql/queries/hook/useGetAllOrdersDelivery';

interface HookProps {
  allOrdersDelivery?: AllOrdersDeliveryType[];
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetAllOrdersDeliveryTable = ({ allOrdersDelivery }: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('ID'),
        containerProps: { width: '56px', color: '#F1F3F8', align: 'center' },
      },
      {
        title: translateLang('numberOrder'),
        containerProps: { width: '56px', color: '#F1F3F8', align: 'center' },
      },
      {
        title: translateLang('customer'),
        containerProps: { width: '380px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('phone'),
        containerProps: { width: '190px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('courier'),
        containerProps: { width: '180px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('address'),
        containerProps: { width: '820px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('deliveryTime'),
        containerProps: { width: '360px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('status'),
        containerProps: { width: '100px', color: '#f1f3f8', align: 'left' },
      },
      {
        title: translateLang(''),
        containerProps: { width: '80px', color: '#f1f3f8', align: 'left' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (allOrdersDelivery && allOrdersDelivery?.length) {
      return allOrdersDelivery?.map((item, index) => {
        return (
          <AllOrdersDeliveryRowItem
            key={index}
            courier={item.courier}
            courierId={item.courierId}
            customerPhone={item.customerPhone}
            orderId={item.orderId}
            deliveryAddress={item.deliveryAddress}
            customerInfo={item.customerInfo}
            deliveryTime={item.deliveryTime}
            status={item.status}
            orderNumber={item.orderNumber}
            customerId={item.customerId}
          />
        );
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
