import React, { FC, useState } from 'react';
import { Stack } from '@mui/system';
import {
  Enum_Customerreturn_Status,
  Enum_Method_To_Return,
  Enum_Receive_For,
  Enum_Supplierreturn_Status,
  StockReturnedFromCustomerEntity,
} from '../../../../../__generated__/types';
import { CustomModal } from '../../../../../components';
import { handlerError } from '../../../../../helpers/functions';
import { NonSearched } from '../../NonSearched';
import { ScanComponent } from '../../ScanComponent';
import { useMakereceiveOrderItemFromDeliveryMutation } from '../../../../../graphql/mutations/__generated__/makeReceiveOrderItemFromDelivery';
import { SearchedContentForReturn } from './SearchedContentForReturn';
import { SelectReturnType } from './SelectReturnType';
import { normalizeReturnedItem } from '../../../../../graphql/queries/hook/useGetOrderItemsReturnedFromCustomers';
import { useGetStockReturnedFromCustomerByIdLazyQuery } from '../../../../../graphql/queries/__generated__/getStockReturnedFromCustomerById';
import { useGetCustomerReturnsLazyQuery } from '../../../../../graphql/queries/__generated__/getCustomerReturns';
import { endOfToday, startOfToday, subDays } from 'date-fns';
import { useCreateCustomerReturnMutation } from '../../../../../graphql/mutations/__generated__/createCustomerReturn';
import { useCreateSupplierReturnMutation } from '../../../../../graphql/mutations/__generated__/createSupplierReturn';
import { useGetSupplierReturnsLazyQuery } from '../../../../../graphql/queries/__generated__/getSupplierReturns';
import { useGetStockReturnedFromCustomersLazyQuery } from '../../../../../graphql/queries/__generated__/getStockReturnedFromCustomers';

interface Props {
  id: string;
  open: boolean;
  handleClose: () => void;
}
type MODE = 'return' | 'returnType' | 'searched' | 'nonSearched';

export const ReturnItemModal: FC<Props> = ({ open, id, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [runReceive] = useMakereceiveOrderItemFromDeliveryMutation();
  const [selectMode, setSelectMode] = useState<MODE>('searched');
  const [currentEanNumber, setCurrentEanNumber] = useState('');
  const [runGetReturnedItem, { data }] = useGetStockReturnedFromCustomerByIdLazyQuery();
  const [runGetCustomerReturns] = useGetCustomerReturnsLazyQuery();
  const [runGetSupplierReturns] = useGetSupplierReturnsLazyQuery();
  const [createCustomerReturnMutation] = useCreateCustomerReturnMutation();
  const [createSupplierReturnMutation] = useCreateSupplierReturnMutation();

  const [runGetReturnedItemsList] = useGetStockReturnedFromCustomersLazyQuery();

  const normalizedReturnItem = data?.stockReturnedFromCustomer
    ? normalizeReturnedItem(
        data?.stockReturnedFromCustomer?.data as StockReturnedFromCustomerEntity
      )
    : null;

  const handleSearchProduct = async (eanNumber: string) => {
    const returnedItem = await runGetReturnedItem({ variables: { id: id } });

    if (
      returnedItem?.data?.stockReturnedFromCustomer?.data?.attributes?.order_item?.data?.attributes
        ?.barcode === eanNumber
    ) {
      setSelectMode('returnType');
      setCurrentEanNumber(eanNumber);
    } else {
      setSelectMode('nonSearched');
    }
  };

  const handlePickedOrderItem = async (
    methodToReturn = Enum_Method_To_Return.RemoveFromOrder,
    receiveFor = Enum_Receive_For.Stock,
    customerId?: string,
    providerId?: string
  ) => {
    setLoading(true);
    try {
      let customerReturnId = '';
      let supplierReturnId = '';
      // get customerReturnId
      if (methodToReturn === Enum_Method_To_Return.AddToCustomerReturn && customerId) {
        const customerReturn = await runGetCustomerReturns({
          variables: {
            filters: {
              customer: { id: { eq: customerId } },
              status: { eq: Enum_Customerreturn_Status.Created },
              createdAt: { between: [subDays(startOfToday(), 1), endOfToday()] },
            },
            sort: ['createdAt:desc'],
          },
          fetchPolicy: 'network-only',
        });
        if (customerReturn?.data?.customerReturns?.data?.[0]?.id) {
          customerReturnId = customerReturn?.data?.customerReturns?.data?.[0]?.id;
        } else {
          const newCustomerReturn = await createCustomerReturnMutation({
            variables: {
              data: {
                customer: customerId,
              },
            },
          });
          if (newCustomerReturn?.data?.createCustomerReturn?.data?.id) {
            customerReturnId = newCustomerReturn?.data?.createCustomerReturn?.data?.id;
          }
        }
      }

      // get supplierReturnId
      if (receiveFor === Enum_Receive_For.Supplier && providerId) {
        const supplierReturn = await runGetSupplierReturns({
          variables: {
            filters: {
              supplier: { id: { eq: providerId } },
              createdAt: { between: [subDays(startOfToday(), 1), endOfToday()] },
              or: [
                { status: { eq: Enum_Supplierreturn_Status.Created } },
                { status: { eq: null } },
              ],
            },
            sort: ['createdAt:desc'],
          },
          fetchPolicy: 'network-only',
        });
        if (supplierReturn?.data?.supplierReturns?.data?.[0]?.id) {
          supplierReturnId = supplierReturn?.data?.supplierReturns?.data?.[0]?.id;
        } else {
          const newSupplierReturn = await createSupplierReturnMutation({
            variables: {
              data: {
                supplier: providerId,
              },
            },
          });
          if (newSupplierReturn?.data?.createSupplierReturn?.data?.id) {
            supplierReturnId = newSupplierReturn?.data?.createSupplierReturn?.data?.id;
          }
        }
      }

      const response = await runReceive({
        variables: {
          input: {
            methodToReturn,
            ...(methodToReturn === Enum_Method_To_Return.AddToCustomerReturn && customerReturnId
              ? { customerReturnId }
              : {}),
            receiveFor,
            ...(receiveFor === Enum_Receive_For.Supplier && supplierReturnId
              ? { supplierReturnId }
              : {}),
            barcode: currentEanNumber,
            stockReturnedFromCustomerId: id,
          },
        },
      });
      if (response && response.data?.receiveOrderItemFromDelivery) {
        await runGetReturnedItemsList();
        setSelectMode('return');
      }
    } catch (err: unknown) {
      handlerError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomModal
      title=""
      handleComeBack={selectMode !== 'return' ? () => setSelectMode('searched') : undefined}
      handleClose={handleClose}
      open={open}
      bgcolor="#F1F3F8"
      width="1000px"
      height="700px"
      display="flex"
      flexDirection="column"
    >
      <Stack textAlign="center" width="100%" height="100%" overflow="hidden">
        {selectMode === 'searched' ? (
          <ScanComponent
            isOpen={selectMode === 'searched'}
            onChange={handleSearchProduct}
            loading={false}
          />
        ) : null}

        {selectMode === 'returnType' && (
          <SelectReturnType
            isOpen={selectMode === 'returnType'}
            barCode={currentEanNumber}
            returnedItem={normalizedReturnItem}
            handleClose={handleClose}
            handleClickButton={handlePickedOrderItem}
            loading={loading}
          />
        )}

        {selectMode === 'return' && (
          <SearchedContentForReturn
            isOpen={selectMode === 'return'}
            barCode={currentEanNumber}
            handleClose={handleClose}
            returnedItem={normalizedReturnItem}
          />
        )}

        {selectMode === 'nonSearched' ? (
          <NonSearched
            isOpen={selectMode === 'nonSearched'}
            handleClose={handleClose}
            handleClickButton={handleSearchProduct}
          />
        ) : null}
      </Stack>
    </CustomModal>
  );
};
