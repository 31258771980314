import { CustomerContactInfoFragment } from '../../fragments/__generated__/customerContactInfo';
import {
  GetOrderByIdQuery,
  useGetOrderByIdLazyQuery,
  useGetOrderByIdQuery,
} from '../__generated__/getOrderById';
import { CarEntityResponse } from '../../../__generated__/types';
import { VehiclesType } from '../../../helpers/types';
import { getVehicle } from '../../../helpers/functions';
import { useEffect } from 'react';
import { useLocalization } from '../../../localization';

type InfoType = NonNullable<NonNullable<CustomerContactInfoFragment['data']>['attributes']>;
type PhoneNumbersType = NonNullable<InfoType>['phoneNumbers'];
type AddressType = NonNullable<InfoType>['deliveryAddress'];

export interface OrderType {
  managers?: string[];
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  userName?: string;
  typeClient?: string;
  typePriceClient?: string;
  total?: number | null | undefined;
  phoneNumbers?: PhoneNumbersType;
  deliveryArea?: number | null;
  deliveryAddress?: AddressType;
  deliveryDate?: string | null | undefined;
  orderItems?: string[];
  comment?: string | null | undefined;
  vehicles?: Array<VehiclesType>;
  quantity?: number | null | undefined;
  loading?: boolean;
  customerId: string | null | undefined;
  selectCar?: CarEntityResponse | null;
  orderNumber?: string | null;
  courier?: {
    id?: string | null;
    firsName?: string | null;
    lastName?: string | null;
    userName?: string | null;
  } | null;
  status?: string | null;
}

export const useGetOrderById = (orderId: string): OrderType => {
  const { updateSelectLanguage } = useLocalization();
  const { data, loading } = useGetOrderByIdQuery({
    variables: { id: orderId, locale: updateSelectLanguage as string },
  });
  const order = getDataOrder(data);
  order.loading = loading;
  return order;
};
export const useGetOrderByIdLazy = (orderId: string): OrderType => {
  const { updateSelectLanguage } = useLocalization();
  const [runGetOrderById, { data, loading }] = useGetOrderByIdLazyQuery();

  useEffect(() => {
    if (orderId) {
      runGetOrderById({
        variables: { id: orderId, locale: updateSelectLanguage as string },
      }).then();
    }
  }, [orderId]);
  const order = getDataOrder(data);
  order.loading = loading;
  return order;
};

function getDataOrder(data?: GetOrderByIdQuery): OrderType {
  let courier = null;
  if (data?.order?.data?.attributes?.courier?.data) {
    courier = {
      id: data?.order?.data?.attributes?.courier.data?.id,
      firsName:
        data?.order?.data?.attributes?.courier.data?.attributes?.customer_contact_info?.data
          ?.attributes?.firstName,
      lastName:
        data?.order?.data?.attributes?.courier.data?.attributes?.customer_contact_info?.data
          ?.attributes?.lastName,
      userName: data?.order?.data?.attributes?.courier.data?.attributes?.username,
    };
  }
  let orderNumber = data?.order?.data?.attributes?.orderNumber || '';
  if (data?.order?.data?.attributes?.orderNumberAdd) {
    orderNumber += `-${data?.order.data.attributes.orderNumberAdd}`;
  }
  return {
    managers: data?.order?.data?.attributes?.managers?.data.map(item => item?.id || ''),
    courier,
    customerId: data?.order?.data?.attributes?.customer?.data?.id,
    id: data?.order?.data?.id || '',
    status: data?.order?.data?.attributes?.status,
    orderNumber,
    total: data?.order?.data?.attributes?.total,
    firstName:
      data?.order?.data?.attributes?.customer?.data?.attributes?.customer_contact_info?.data
        ?.attributes?.firstName,
    lastName:
      data?.order?.data?.attributes?.customer?.data?.attributes?.customer_contact_info?.data
        ?.attributes?.lastName,
    userName: data?.order?.data?.attributes?.customer?.data?.attributes?.username,
    comment: data?.order?.data?.attributes?.comment,
    phoneNumbers:
      data?.order?.data?.attributes?.customer?.data?.attributes?.customer_contact_info?.data
        ?.attributes?.phoneNumbers,
    deliveryAddress:
      data?.order?.data?.attributes?.customer?.data?.attributes?.customer_contact_info?.data
        ?.attributes?.deliveryAddress,
    deliveryDate: data?.order?.data?.attributes?.deliveryDate,
    typeClient:
      data?.order?.data?.attributes?.customer?.data?.attributes?.role?.data?.attributes?.name || '',
    typePriceClient:
      data?.order?.data?.attributes?.customer?.data?.attributes?.role?.data?.attributes?.name || '',
    vehicles: getVehicle(data),
    selectCar: data?.order?.data?.attributes?.car || null,
    orderItems: data?.order?.data?.attributes?.order_items?.data.map(item => item.id || ''),
  };
}
