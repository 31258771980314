import { Box, Divider, Grid, Stack, useMediaQuery } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';

import { useGetClientById } from '../../../graphql/queries/hook/useGetClientById';
import { AddressField } from './AddressField';
import { AddressModal, CredentialsInformationsModal, PhoneModal } from './Modals';
import { PhonesField } from './PhonesField';
import { CredentialsInformations } from './СredentialsInformations';
import { UserInformaton } from './UserInformaton';
import { theme } from '../../../helpers';
import { setCurrentClientCred } from '../../../graphql/store/functionsWitingCache';
import { useGetMe } from '../../../graphql/queries/hook/useGetMe';

interface ContactInformationTabProp {
  id: string;
}

export const ContactInformationTab: FC<ContactInformationTabProp> = ({ id }) => {
  const { isAdmin, isSuperAdmin, isAccounter } = useGetMe();
  const {
    typeClient,
    typePriceClient,
    phoneNumbers,
    deliveryAddress,
    lastName,
    firstName,
    email,
    userName,
    primaryPhoneNumber,
    VATId,
    companyName,
  } = useGetClientById(id);

  const [openPhoneModal, setOpenPhoneModal] = useState<{ id?: string; isOpen: boolean }>({
    isOpen: false,
  });
  const [openAddressModal, setOpenAddressModal] = useState<{ id?: string; isOpen: boolean }>({
    isOpen: false,
  });
  const [openUserCredentianlsModal, setOpenUserCredentianlsModal] = useState<{
    id?: string;
    isOpen: boolean;
  }>({
    isOpen: false,
  });

  const openPhoneModals = (value?: string) => {
    setOpenPhoneModal({ id: value, isOpen: true });
  };

  const openAddressModals = (value?: string) => {
    setOpenAddressModal({ id: value, isOpen: true });
  };
  const openUserCredentianlsModals = (value?: string) => {
    setOpenUserCredentianlsModal({ id: value, isOpen: true });
  };
  useEffect(() => {
    if (lastName && firstName) {
      setCurrentClientCred({
        dataWrite: {
          client: {
            firstName,
            lastName,
          },
        },
      });
    } else {
      setCurrentClientCred({
        dataWrite: {
          client: {
            firstName: '',
            lastName: '',
          },
        },
      });
    }
  }, [lastName, firstName]);
  return (
    <Box
      sx={{
        backgroundColor: '#FFFFFF',
        m: '10px',
        borderRadius: '10px',
        padding: '40px',
      }}
    >
      <Grid container sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
        <Grid item xs={12} md={7} pr="15px" justifyContent="flex-start" alignItems="flex-start">
          <Stack spacing={2}>
            <CredentialsInformations
              id={id}
              firstName={firstName}
              lastName={lastName}
              userName={userName}
              companyName={companyName}
              VATId={VATId}
              isSuperAdminOrAccounter={isAdmin || isSuperAdmin || isAccounter}
              clientType={typeClient}
              onClickInput={val => openUserCredentianlsModals(val)}
            />
            <PhonesField
              id={id}
              phoneNumbers={phoneNumbers}
              onClickInput={val => openPhoneModals(val)}
            />

            <AddressField
              userId={id}
              deliveryAddress={deliveryAddress}
              onClickInput={val => openAddressModals(val)}
            />
          </Stack>
        </Grid>
        <Divider
          sx={{ margin: '15px' }}
          orientation={useMediaQuery(theme.breakpoints.down('md')) ? 'horizontal' : 'vertical'}
          flexItem
        />
        <Grid item xs={12} md={3} pl="15px" justifyContent="flex-start" alignItems="flex-start">
          <UserInformaton
            id={id}
            typeClient={typePriceClient}
            email={email}
            primaryPhoneNumber={primaryPhoneNumber}
          />
        </Grid>
      </Grid>

      {openPhoneModal.isOpen && (
        <PhoneModal
          clientId={id}
          id={openPhoneModal.id}
          openModal={true}
          closeModal={() => setOpenPhoneModal({ id: '', isOpen: false })}
        />
      )}
      {openAddressModal.isOpen && (
        <AddressModal
          clientId={id}
          id={openAddressModal.id}
          openModal={true}
          closeModal={() => setOpenAddressModal({ id: '', isOpen: false })}
        />
      )}
      {/*{openAddressArreaModal.isOpen && (
        <AddressAreaModal
          clientId={id}
          id={openAddressModal.id}
          openModal={true}
          closeModal={() => setOpenAddressArreaModal({ id: '', isOpen: false })}
        />
      )}*/}
      {openUserCredentianlsModal.isOpen && (
        <CredentialsInformationsModal
          clientId={id}
          openModal={true}
          closeModal={() => setOpenUserCredentianlsModal({ id: '', isOpen: false })}
        />
      )}
    </Box>
  );
};
