import { Enum_Banner_Size } from '../../../__generated__/types';

export const WIDTH_UPLOAD_IMAGE = 1212;
export const HEIGHT_UPLOAD_IMAGE = 300;
export const WIDTH_UPLOAD_IMAGE_MOBILE = 360;
export const HEIGHT_UPLOAD_IMAGE_MOBILE = 200;
const OFFSET = 20;

export function validateImg(
  fileImg: File,
  variantBanner: Enum_Banner_Size
): Promise<{ isValid: boolean; img: HTMLImageElement }> {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(fileImg);
    reader.addEventListener('load', evt => {
      const img = new Image();
      if (evt && evt?.target?.result && typeof evt.target.result === 'string') {
        img.src = evt.target.result;

        img.addEventListener('load', () => {
          const height = img.height;
          const width = img.width;

          const aspect = width / height;
          let ratios;
          if (variantBanner === Enum_Banner_Size.Desktop) {
            ratios = [...getRatios(WIDTH_UPLOAD_IMAGE, HEIGHT_UPLOAD_IMAGE, OFFSET)];
          } else {
            ratios = [...getRatios(WIDTH_UPLOAD_IMAGE_MOBILE, HEIGHT_UPLOAD_IMAGE_MOBILE, OFFSET)];
          }
          const minAspect = ratios[0];
          const maxAspect = ratios[ratios.length - 1];
          if (aspect >= minAspect && aspect <= maxAspect && fileImg.size < 1e6) {
            resolve({ isValid: true, img });
          } else {
            resolve({ isValid: false, img });
          }
        });
      }
    });
  });

  function getRatios(width: number, height: number, offset: number): [number, number] {
    const ratios = [];
    for (let i = width - offset; i <= width + offset; i += 10) {
      for (let j = height - offset; j <= height + offset; j += 10) {
        ratios.push(i / j);
      }
    }
    ratios.sort();
    // console.log(ratios[0], ratios.at(-1));
    return [ratios[0], ratios.at(-1) || 2];
  }
}
