import React from 'react';
import { StepLabel, Typography } from '@mui/material';

import { TimePointButtonIcon } from './TimePointButtonIcon';
import { TimePointProps, TimePointStatusName } from './types';

export const TimePoint: React.FC<TimePointProps> = ({
  statuses,
  topLabel,
  bottomLabel,
  disabled,
  onClick,
}) => (
  <>
    <Typography
      variant="body2"
      textAlign="center"
      mb={3}
      className={`TimePointStatus-${
        statuses.isSkipped
          ? TimePointStatusName.Skipped
          : statuses.isActive
          ? TimePointStatusName.Active
          : 'empty'
      }`}
    >
      {topLabel}
    </Typography>
    <StepLabel
      StepIconComponent={() => (
        <TimePointButtonIcon statuses={statuses} disabled={disabled} onClick={onClick} />
      )}
    >
      {bottomLabel}
    </StepLabel>
  </>
);
