import React, { useEffect, useRef, useState } from 'react';
import jsPDF from 'jspdf';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import html2canvas from 'html2canvas';
import { ArrowBack } from '@mui/icons-material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material';

import { Input } from '../../../../legos';
import { theme } from '../../../../helpers';
import { InvoicePrint } from './InvoicePrint';
import { useValidation } from './hooks/useValidation';
import { useLocalization } from '../../../../localization';
import { CalendarInvoicePrint } from './CalendarInvoicePrint';
import { IncomingPrintFields, UserAdressProps } from './types';
import { useGetClientById } from '../../../../graphql/queries/hook/useGetClientById';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';
import { useGetCustomerBillLazyQuery } from '../../../../graphql/queries/__generated__/getCustomerBill';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useCreateCustomerBillMutation } from '../../../../graphql/mutations/__generated__/createCustomerBill';
import { useUpdateCustomerBillMutation } from '../../../../graphql/mutations/__generated__/updateCustomerBill';
import { useUpdateCustomerContactInfoMutation } from '../../../../graphql/mutations/__generated__/updateCustomerContactInfo';
import { GetOrdersAccountingDocument } from '../../../../graphql/queries/__generated__/getOrdersAccounting';

export const InvoicePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { addNotification } = useHandlerNotificationApp();
  const { translateLang, updateSelectLanguage } = useLocalization();

  const { idOrder, idUser = '' } = useParams();
  const idsOrder = idOrder?.split(',');
  const { validationSchema } = useValidation();

  const { contactInfoId } = useGetClientById(idUser);

  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState<Date | null>(new Date());
  const [invoicePaid, setInvoicePaid] = useState(false);
  const [invoicePaidComment, setInvoicePaidComment] = useState('');
  const [paymentMethod, setPaymentMethod] = useState('cashPayment');

  const divInvoiceRef = useRef<HTMLDivElement>(null);

  const [runUpdateCustomer] = useUpdateCustomerContactInfoMutation();
  const [runGetCustomerBill, { data: customerBillData, refetch }] = useGetCustomerBillLazyQuery();

  const customerBill = customerBillData?.customerBill?.data?.attributes;
  const userContactInfo =
    customerBill?.customer?.data?.attributes?.customer_contact_info?.data?.attributes;

  const [userData, setUserData] = useState<UserAdressProps>({
    companyName: null,
    streetHouse: null,
    cityIndex: null,
    VATId: null,
  });

  const [createCustomerBillMutation] = useCreateCustomerBillMutation({
    variables: {
      data: {
        orders: idsOrder,
      },
    },
    refetchQueries: [
      {
        query: GetOrdersAccountingDocument,
        variables: {
          locale: updateSelectLanguage as string,
          filters: location.state.filters,
          sort: location.state.sort,
        },
      },
    ],
  });
  const [updateCustomerBillMutation] = useUpdateCustomerBillMutation();

  useEffect(() => {
    setUserData({
      companyName:
        userContactInfo?.companyName ??
        `${userContactInfo?.firstName ?? ''} ${userContactInfo?.lastName ?? ''}`,
      streetHouse: `${userContactInfo?.deliveryAddress?.[0]?.street ?? ''} ${
        userContactInfo?.deliveryAddress?.[0]?.number ?? ''
      }`,
      cityIndex: `${userContactInfo?.deliveryAddress?.[0]?.city ?? ''} ${
        userContactInfo?.deliveryAddress?.[0]?.zipCode ?? ''
      }`,
      VATId: userContactInfo?.VATId ?? '',
    });
  }, [userContactInfo]);

  const initialValues = {
    [IncomingPrintFields.CompanyName]: userData?.companyName ?? '',
    [IncomingPrintFields.StreetHouse]: userData?.streetHouse ?? '',
    [IncomingPrintFields.CityIndex]: userData?.cityIndex ?? '',
    [IncomingPrintFields.VATId]: userData?.VATId ?? '',
  };

  const { values, errors, touched, handleChange, handleSubmit, dirty } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: ({ VATId, companyName, streetHouse, cityIndex }) => {
      runUpdateCustomer({
        variables: {
          id: contactInfoId ?? '',
          data: {
            VATId,
            companyName,
          },
        },
      })
        .then(() => {
          setUserData({
            companyName,
            streetHouse,
            cityIndex,
            VATId,
          });
          addNotification({
            messageError: translateLang('updatedSuccessfully'),
            typeMessage: 'success',
          });
        })
        .catch(err => {
          addNotification({
            messageError: err?.message,
            typeMessage: 'error',
          });
        });
    },
  });

  useEffect(() => {
    createCustomerBillMutation({
      variables: {
        data: {
          orders: idsOrder,
        },
      },
    })
      .then(async data => {
        await runGetCustomerBill({
          variables: {
            id: data.data?.createCustomerBill?.data?.id,
            locale: updateSelectLanguage as string,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (customerBill?.billDate) {
      setDate(new Date(customerBill?.billDate));
    }
  }, [customerBill?.billDate]);

  const handleDateChange = (newValue: Date | null) => {
    updateCustomerBillMutation({
      variables: {
        id: customerBillData?.customerBill?.data?.id as string,
        data: {
          billDate: format(newValue ?? new Date(), 'yyyy-MM-dd'),
          orders: idsOrder,
        },
      },
    })
      .then(() => {
        setDate(newValue);
        refetch();
        addNotification({
          messageError: translateLang('invoiceDateUpdated'),
          typeMessage: 'success',
        });
      })
      .catch(err => {
        addNotification({ messageError: err.message, typeMessage: 'error' });
      });
  };

  const runInvoicePrint = () => {
    const ifram = document.createElement('iframe');
    ifram.style.display = 'none';
    document.body.appendChild(ifram);
    const pri = ifram.contentWindow;

    if (pri) {
      pri.document.open();
      if (divInvoiceRef.current) {
        const customInnerHTML = divInvoiceRef.current.innerHTML
          .replace(
            '<input',
            `<input style='border: none; font-size: 12px; font-weight: 700; margin-left: 16px'`
          )
          .replace('class="MuiInputAdornment', `style='display:none' class="MuiInputAdornment`);
        pri.document.write(customInnerHTML);
      }
      pri.document.close();
      pri.focus();
      pri.print();
    }
    setTimeout(() => {
      document.body.removeChild(ifram);
    }, 500);
  };

  const downloadInvoicePDF = async () => {
    const lineElement = divInvoiceRef.current?.querySelector('.red-line') as HTMLElement;

    if (divInvoiceRef.current) {
      if (lineElement) {
        lineElement.style.zIndex = '-1';
      }
      const element = divInvoiceRef.current;
      const canvas = await html2canvas(element, {
        scale: 3,
        useCORS: true,
      });
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF({
        orientation: 'portrait',
        unit: 'mm',
        format: 'a4',
      });
      if (lineElement) {
        lineElement.style.zIndex = '1';
      }
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`invoice_${billNumber}.pdf`);
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const toggleInvoicePaid = () => {
    setInvoicePaid(!invoicePaid);
  };

  const billNumber = `${new Date(customerBill?.billDate).getFullYear()}/${
    customerBill?.billNumber
  }`;

  const ordersInvoice = customerBill?.orders?.data.map(item => ({
    orderNumber: item.attributes?.orderNumber,
    data: item.attributes?.order_items?.data.map(orderItem => ({
      title:
        orderItem.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0].attributes
          ?.title,
      count:
        (orderItem.attributes?.quantity?.orderedQuantity || 0) -
        (orderItem.attributes?.quantity?.returnedQuantity || 0),
      price: orderItem.attributes?.customer_price,
    })),
  }));

  const total = customerBill?.total || 0;

  const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPaymentMethod((event.target as HTMLInputElement).value);
  };

  return (
    <Stack mb={4}>
      <Box mb={2.5}>
        <IconButton onClick={handleGoBack}>
          <ArrowBack sx={{ color: theme.palette.common.black }} />
        </IconButton>
      </Box>
      {!loading ? (
        <Stack
          maxWidth={1300}
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          gap={5}
        >
          <InvoicePrint
            ref={divInvoiceRef}
            total={total}
            invoicePaid={invoicePaid}
            invoicePaidComment={invoicePaidComment}
            userData={userData}
            billNumber={billNumber}
            ordersInvoice={ordersInvoice}
            date={date}
          />

          <Stack justifyContent="space-between">
            <Stack flexDirection="column" gap={2}>
              <TranslatedField
                originText={'documentSettings'}
                fontSize={28}
                fontWeight={600}
                isTranslate
                noWrap
                overflow="initial"
              />
              <CalendarInvoicePrint
                disableFuture
                value={date}
                isLabel={false}
                setValue={handleDateChange}
                inputStyle={{
                  bgcolor: theme.palette.common.white,
                  height: 40,
                  width: 132,
                  p: 0,
                  '& .MuiInputBase-root': {
                    fontFamily: 'Mulish,sans-serif',
                    fontSize: '12px',
                    fontWeight: 700,
                    lineHeight: 1.5,
                    p: '9px 15px',
                  },
                }}
              />
              <Stack flexDirection="row" gap={1.25} alignItems="center">
                <Checkbox checked={invoicePaid} onChange={toggleInvoicePaid} />

                <TranslatedField
                  originText={'invoicePaid'}
                  fontSize={20}
                  fontWeight={500}
                  isTranslate
                  noWrap
                  overflow="initial"
                />
              </Stack>
              {invoicePaid ? (
                <Stack flexDirection="column" gap={2}>
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">
                      {translateLang('paymentMethod')}
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                      value={paymentMethod}
                      onChange={handlePaymentMethodChange}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <FormControlLabel
                        value="cashPayment"
                        control={<Radio />}
                        label={translateLang('cashPayment')}
                      />
                      <FormControlLabel
                        value="bankTransfer"
                        control={<Radio />}
                        label={translateLang('bankTransfer')}
                      />
                    </RadioGroup>
                  </FormControl>
                  <TextField
                    multiline
                    rows={4}
                    value={invoicePaidComment}
                    label={translateLang('comment')}
                    onChange={e => setInvoicePaidComment(e.target.value)}
                  ></TextField>
                </Stack>
              ) : null}

              <Stack
                component="form"
                maxWidth={300}
                mt={3}
                flexGrow={1}
                spacing={2}
                onSubmit={handleSubmit}
              >
                <Stack height={75}>
                  <Input
                    label={translateLang('companyName')}
                    name={IncomingPrintFields.CompanyName}
                    value={values[IncomingPrintFields.CompanyName]}
                    onChange={handleChange}
                    error={
                      touched[IncomingPrintFields.CompanyName] &&
                      !!errors[IncomingPrintFields.CompanyName]
                    }
                    helperText={
                      touched[IncomingPrintFields.CompanyName] &&
                      errors[IncomingPrintFields.CompanyName]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                </Stack>
                <Stack height={75}>
                  <Input
                    label={`${translateLang('street')}, ${translateLang('house')}`}
                    name={IncomingPrintFields.StreetHouse}
                    value={values[IncomingPrintFields.StreetHouse]}
                    onChange={handleChange}
                    error={
                      touched[IncomingPrintFields.StreetHouse] &&
                      !!errors[IncomingPrintFields.StreetHouse]
                    }
                    helperText={
                      touched[IncomingPrintFields.StreetHouse] &&
                      errors[IncomingPrintFields.StreetHouse]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                </Stack>
                <Stack height={75}>
                  <Input
                    label={`${translateLang('city')}, ${translateLang('zip')}`}
                    name={IncomingPrintFields.CityIndex}
                    value={values[IncomingPrintFields.CityIndex]}
                    onChange={handleChange}
                    error={
                      !!(
                        touched[IncomingPrintFields.CityIndex] &&
                        !!errors[IncomingPrintFields.CityIndex]
                      )
                    }
                    helperText={
                      touched[IncomingPrintFields.CityIndex] &&
                      errors[IncomingPrintFields.CityIndex]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                </Stack>
                <Stack height={75}>
                  <Input
                    label={translateLang('VATId')}
                    name={IncomingPrintFields.VATId}
                    value={values[IncomingPrintFields.VATId]}
                    onChange={handleChange}
                    error={
                      touched[IncomingPrintFields.VATId] && !!errors[IncomingPrintFields.VATId]
                    }
                    helperText={
                      touched[IncomingPrintFields.VATId] && errors[IncomingPrintFields.VATId]
                    }
                    bgcolor={theme.palette.common.white}
                    inputProps={{
                      style: {
                        height: 16,
                      },
                    }}
                  />
                </Stack>

                <Button
                  disabled={!dirty}
                  color="secondary"
                  variant="contained"
                  size="large"
                  type="submit"
                >
                  <TranslatedField originText="saveChanges" isTranslate color="white" />
                </Button>
              </Stack>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  height: 50,
                  minWidth: 213,
                  p: 2,
                  mt: 4,
                  textTransform: 'none',
                  backgroundColor: '#5269A3',
                  borderRadius: '5px',
                }}
                onClick={downloadInvoicePDF}
              >
                <TranslatedField
                  originText={'download'}
                  fontSize={16}
                  isTranslate
                  noWrap
                  overflow="initial"
                />
              </Button>
            </Stack>
            <Stack flexDirection="row" gap={2} mt={2}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  height: 50,
                  minWidth: 213,
                  p: 2,
                  textTransform: 'none',
                  backgroundColor: '#5269A3',
                  borderRadius: '5px',
                }}
                onClick={runInvoicePrint}
              >
                <TranslatedField
                  originText={'print'}
                  fontSize={16}
                  isTranslate
                  noWrap
                  overflow="initial"
                />
              </Button>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  height: 50,
                  minWidth: 213,
                  p: 2,
                  textTransform: 'none',
                  backgroundColor: '#5269A3',
                  borderRadius: '5px',
                }}
              >
                <TranslatedField
                  originText={'send'}
                  fontSize={16}
                  isTranslate
                  noWrap
                  overflow="initial"
                />
              </Button>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Stack justifyContent="center" alignItems="center" mt={10}>
          <CircularProgress size={40} />
        </Stack>
      )}
    </Stack>
  );
};
