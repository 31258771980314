import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OrderDetailBox } from './components/OrderDetailBox';
import { ClientInfo } from './components/ClientInfo';
import { TimeLine } from '../../components';
import { PatchesName, UrlParamsObjectType } from '../../types/types';
import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { useLocalization } from '../../localization';
import { useGetOrderById } from '../../graphql/queries/hook/useGetOrderById';
import { setCurrentClientCred } from '../../graphql/store/functionsWitingCache';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import { InfoOutlined, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { ButtonsWrapper } from './components/ButtonsWrapper';
import { Enum_Order_Status } from '../../__generated__/types';
import { SupplierSupportAutoOrderingModal } from './components/SupplierSupportAutoOrderingModal';
import { useGetMe } from '../../graphql/queries/hook/useGetMe';

export const OrderInfo = () => {
  const navigate = useNavigate();

  const [showSupplierSupportAutoOrderingModal, setShowSupplierSupportAutoOrderingModal] =
    useState<boolean>(false);

  const { translateLang } = useLocalization();
  const { isAdmin, isSuperAdmin } = useGetMe();
  const [notEditOrder, setNotEditOrder] = useState(false);
  const [isCollapseRadio, setIsCollapseRadio] = useState(false);
  const { idUser = '', idOrder = '' } = useParams<UrlParamsObjectType>();
  const { firstName, lastName, orderNumber, status } = useGetOrderById(idOrder);

  const handleCloseSupplierSupportAutoOrderingModal = () =>
    setShowSupplierSupportAutoOrderingModal(false);

  const handlerButtonWrapperEditOrder = () => {
    navigate(`/${PatchesName.Orders}/${PatchesName.CreateOrder}/${idUser}/${idOrder}`);
  };
  const handlerButtonWrapperAutoOrder = async () => {
    setShowSupplierSupportAutoOrderingModal(true);
  };

  useEffect(() => {
    if (lastName && firstName) {
      setCurrentClientCred({
        dataWrite: {
          client: {
            firstName,
            lastName,
          },
        },
      });
    } else {
      setCurrentClientCred({
        dataWrite: {
          client: {
            firstName: '',
            lastName: '',
          },
        },
      });
    }
  }, [lastName, firstName]);

  return (
    <WrapperPages
      titleHeader={`${translateLang('order')} #${orderNumber}`}
      customChildrenButton={
        <ButtonsWrapper
          disableButtonOrder={notEditOrder}
          disableButtonAuto={
            status !== Enum_Order_Status.Coordination || !(isAdmin || isSuperAdmin)
          }
          handlerButtonEditOrder={handlerButtonWrapperEditOrder}
          handlerButtonAutoOrder={handlerButtonWrapperAutoOrder}
        />
      }
    >
      <TimeLine orderId={idOrder} setNotEditOrder={setNotEditOrder} />

      <Box width="100%">
        <Box
          boxSizing="border-box"
          width="100%"
          py={2}
          px={4}
          borderRadius="10px"
          bgcolor="white"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" alignItems="center">
            <InfoOutlined />
            <Typography ml={1} fontSize="24px">
              {translateLang('customerVehicleInformation')}
            </Typography>
          </Box>

          <IconButton
            sx={{ color: '#000' }}
            onClick={() => {
              setIsCollapseRadio(!isCollapseRadio);
            }}
          >
            {!isCollapseRadio ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
          </IconButton>
        </Box>
        <Collapse in={isCollapseRadio}>
          <ClientInfo orderId={idOrder} userId={idUser} />
        </Collapse>
      </Box>
      <OrderDetailBox orderId={idOrder} />
      {showSupplierSupportAutoOrderingModal && (
        <SupplierSupportAutoOrderingModal
          isModalOpen
          handleClose={handleCloseSupplierSupportAutoOrderingModal}
          orderId={idOrder}
        />
      )}
    </WrapperPages>
  );
};
