export enum IncomingPrintFields {
  CompanyName = 'companyName',
  StreetHouse = 'streetHouse',
  CityIndex = 'cityIndex',
  VATId = 'VATId',
}

export interface UserAdressProps {
  companyName: string | null;
  streetHouse: string | null;
  cityIndex: string | null;
  VATId: string | null;
}
