import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Stack, Stepper } from '@mui/material';
import { useLocalization } from '../../localization';

import { TimePoint } from './TimePoint';
import { CustomModal } from '../CustomModal';
import { CustomizedStep } from './CustomizedStep';
// import { EditingHistoryForm } from './EditingHistoryForm';
import { EditingHistoryTable } from './EditingHistoryTable';
import { orderStatuses } from './helpers';
import { toUpperCaseFirst } from '../../helpers';
// import { EditingHistoryPayload } from './types';

import {
  ComponentOrderStatusEditingHistory,
  Enum_Componentorderstatuseditinghistory_Status,
  Enum_Order_Status,
  InputMaybe,
} from '../../__generated__/types';
import { useGetOrderByIdLazyQuery } from '../../graphql/queries/__generated__/getOrderById';
import { useUpdateOrderMutation } from '../../graphql/mutations/__generated__/updateOrder';
import { useGetMe } from '../../graphql/queries/hook/useGetMe';
import { useGetManagers } from '../../pages/CheckoutPage/hooks/useGetManagers';
import { handlerError } from '../../helpers/functions';

interface Props {
  orderId: string;
  setNotEditOrder: Dispatch<SetStateAction<boolean>>;
}
export const TimeLine: FC<Props> = ({ orderId, setNotEditOrder }) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const { id } = useGetMe();
  const { managers } = useGetManagers(orderId);

  // const [isAddEditingHistoryModalOpen, setIsAddEditingHistoryModalOpen] = useState(false);
  const [isEditingHistoryModalOpen, setIsEditingHistoryModalOpen] = useState(false);

  const [orderHistory, setOrderHistory] = useState<ComponentOrderStatusEditingHistory[]>([]);
  const [activeStep, setActiveStep] = useState(1);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [status, setStatus] = useState<
    Enum_Order_Status | Enum_Componentorderstatuseditinghistory_Status
  >(Enum_Order_Status.Created);

  const [getOrderById, { data: dataOrderByID }] = useGetOrderByIdLazyQuery();
  const [runUpdateOrder] = useUpdateOrderMutation();

  const editingStatuesIndex = orderStatuses.findIndex(
    status => status === Enum_Componentorderstatuseditinghistory_Status.Editing
  );

  /*const handleAddEditingHistory = () => {
    setIsAddEditingHistoryModalOpen(isOpen => !isOpen);
  };*/

  const handleViewEditingHistory = () => {
    setIsEditingHistoryModalOpen(isOpen => !isOpen);
  };

  const updateOrder = (
    status: Enum_Order_Status | Enum_Componentorderstatuseditinghistory_Status,
    status_note: InputMaybe<string> = ''
  ) =>
    runUpdateOrder({
      variables: {
        id: orderId,
        locale: updateSelectLanguage as string,
        data: {
          managers,
          status: status as Enum_Order_Status,
          status_editing_history: [
            ...orderHistory.map(item => ({
              id: item.id,
              manager: item.manager?.data?.id,
              date: item.date,
              status_note: item.status_note,
              status: item.status,
            })),
            {
              manager: id,
              date: new Date(),
              status_note,
              status: status as Enum_Componentorderstatuseditinghistory_Status,
            },
          ],
        },
      },
    });

  const updateOrderEditingHistory = (_status: Enum_Componentorderstatuseditinghistory_Status) => {
    const statusNote = `${translateLang('transitionToStatus')} - ${toUpperCaseFirst(
      translateLang(status)
    )}`;

    updateOrder(_status, statusNote)
      .then(() => {
        if (_status === Enum_Componentorderstatuseditinghistory_Status.Editing) {
          setIsEditingMode(true);
        }

        setActiveStep(currentStep + 1);
      })
      .catch((err: unknown) => {
        handlerError(err);
      });
  };

  const handleTimePointClick = (
    currentStep: number,
    status: Enum_Componentorderstatuseditinghistory_Status
  ) => {
    if (status === Enum_Componentorderstatuseditinghistory_Status.InStock) {
      return;
    }
    setCurrentStep(currentStep);
    setStatus(status);

    if (
      editingStatuesIndex === activeStep - 1 &&
      status === Enum_Componentorderstatuseditinghistory_Status.Coordination
    ) {
      updateOrderEditingHistory(status);
      return;
    }

    if (status === Enum_Componentorderstatuseditinghistory_Status.Editing) {
      updateOrderEditingHistory(status);
      return;
    }

    updateOrder(status, translateLang(status))
      .then(() => {
        setActiveStep(++currentStep);
      })
      .catch((err: unknown) => {
        handlerError(err);
      });
  };

  useEffect(() => {
    getOrderById({ variables: { id: orderId, locale: updateSelectLanguage as string } }).then(
      ({ data }) => {
        const statusEditingHistory = data?.order?.data?.attributes?.status_editing_history;

        setOrderHistory((statusEditingHistory as ComponentOrderStatusEditingHistory[]) ?? []);
      }
    );
    setNotEditOrder(!(activeStep === 1 || activeStep === 3));
  }, [activeStep]);
  useEffect(() => {
    const orderStatus =
      dataOrderByID?.order?.data?.attributes?.status ||
      Enum_Componentorderstatuseditinghistory_Status.Created;
    const activeStatusIndex = orderStatuses.findIndex(
      // status => status === orderHistory?.at(-1)?.status
      status => status === orderStatus
    );
    const isOrderEditing = orderHistory?.some(
      item => item?.status === Enum_Componentorderstatuseditinghistory_Status.Editing
    );

    // console.log('activeStatusIndex', dataOrderByID);
    setActiveStep(activeStatusIndex + 1);
    setIsEditingMode(isOrderEditing ?? false);
  }, [orderHistory, dataOrderByID]);

  return (
    <Stack mt={3} mb={7}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {orderStatuses.map((status, i) => {
          // console.log('activeStep', activeStep);
          // console.log('editingStatuesIndex', editingStatuesIndex);
          const isCoordinationStep = !(activeStep === editingStatuesIndex && i === activeStep);
          const isEditingStep =
            !(activeStep === editingStatuesIndex + 1 && i === activeStep - 2) ||
            (activeStep === editingStatuesIndex + 1 && i === activeStep);
          const isNextStep = i !== activeStep || activeStep === editingStatuesIndex + 1;
          const isBlockedManual = i > 3;

          const foundEntries = orderHistory?.filter(item => item?.status === status);
          const date = foundEntries?.length && new Date(foundEntries?.at(-1)?.date as string);

          return (
            <CustomizedStep
              key={status}
              active={
                activeStep === editingStatuesIndex
                  ? i === activeStep || i === activeStep + 1
                  : activeStep === editingStatuesIndex + 1
                  ? i === editingStatuesIndex
                  : i === activeStep
              }
            >
              <TimePoint
                statuses={{
                  isActive: i === activeStep - 1,
                  isCompleted:
                    i < activeStep - (activeStep === editingStatuesIndex + 1 ? 2 : 1) ||
                    (isEditingMode && activeStep === editingStatuesIndex && i === activeStep),
                  isSkipped:
                    !isEditingMode && activeStep > editingStatuesIndex && i === editingStatuesIndex,
                }}
                topLabel={translateLang(status)}
                bottomLabel={
                  isEditingMode && i === editingStatuesIndex ? (
                    <span
                      // className="TimePointStatus-edit-history"
                      onClick={handleViewEditingHistory}
                    >
                      {translateLang('viewEditHistory')}
                    </span>
                  ) : date ? (
                    `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
                  ) : (
                    ''
                  )
                }
                disabled={(isCoordinationStep && isEditingStep && isNextStep) || isBlockedManual}
                onClick={() => handleTimePointClick(i, status)}
              />
            </CustomizedStep>
          );
        })}
      </Stepper>
      {/*<CustomModal
        title={translateLang('addDescription')}
        open={isAddEditingHistoryModalOpen}
        width={500}
        handleClose={handleAddEditingHistory}
      >
        <EditingHistoryForm updateOrderEditingHistory={updateOrderEditingHistory} />
      </CustomModal>*/}
      <CustomModal
        title={translateLang('editingHistory')}
        open={isEditingHistoryModalOpen}
        width={900}
        handleClose={handleViewEditingHistory}
      >
        <EditingHistoryTable
          data={orderHistory.filter(
            item =>
              item?.status === Enum_Componentorderstatuseditinghistory_Status.Coordination ||
              item?.status === Enum_Componentorderstatuseditinghistory_Status.Editing
          )}
        />
      </CustomModal>
    </Stack>
  );
};
