import React, { FC } from 'react';
import { Checkbox } from '@mui/material';

import { ItemStockType } from './hooks/useGetStocks';
import { TableProductActive } from '../../../../../../../../components/TableProductActive/TableProductActive';

interface Props {
  item: ItemStockType;
  handleCheckedItem: (id: string) => void;
}

export const TableStocksRow: FC<Props> = ({ item, handleCheckedItem }) => (
  <>
    <TableProductActive.RowTable>
      <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none' }}>
        <Checkbox checked={item.checked} onClick={() => handleCheckedItem(item.productStockId)} />
      </TableProductActive.CellTable>
      <TableProductActive.CellTable>{item.productCode}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{item.productName}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{item.brandName}</TableProductActive.CellTable>
      <TableProductActive.CellTable>{item.incomingQuantity} </TableProductActive.CellTable>
      <TableProductActive.CellTable>{item.supplierPrice} </TableProductActive.CellTable>
    </TableProductActive.RowTable>
  </>
);
