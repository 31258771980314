import { useGetSpecialProposalsQuery } from '../__generated__/getSpecialProposals';
import { useLocalization } from '../../../localization';

type CategorySpecialProd = {
  id: string;
  title: string;
  systemName: string;
};
export interface ItemSpecialProd {
  idProduct: string;
  title: string;
  vendorCode: string;
  imgUrl: string;
  manufacturer: string;
}

interface ReturnHook {
  specialProducts: Array<ItemSpecialProd>;
  idCategory: string;
  categories: Array<CategorySpecialProd>;
}
export const useGetSpecialProposals = (selectCategory: number): ReturnHook => {
  const { updateSelectLanguage } = useLocalization();
  const { data: dataProposals } = useGetSpecialProposalsQuery({
    variables: {
      sort: ['id'],
      locale: updateSelectLanguage,
    },
  });

  if (dataProposals?.specialProposals?.data.length) {
    // console.log(dataProposals);
    const categories: Array<CategorySpecialProd> = dataProposals?.specialProposals.data.map(
      item => {
        return {
          id: item.id || '',
          systemName: item.attributes?.system_name || '',
          title: item.attributes?.name || '',
        };
      }
    );

    const prod = dataProposals?.specialProposals.data[selectCategory];
    if (prod?.attributes?.generalSpares?.data?.length) {
      const specialProducts = prod?.attributes?.generalSpares?.data.map(item => {
        const data = item?.attributes?.images;
        // const img = JSON.parse(data) as Array<{ [key: string]: string }>;
        const url = data && data.length ? data[0].imageURL200 : '';
        return {
          idProduct: item?.id || '',
          title: item?.attributes?.title || '',
          vendorCode: item?.attributes?.tecdoc_articleNumber || '',
          manufacturer: item?.attributes?.brandName || '',
          imgUrl: url,
        };
      });

      return {
        idCategory: dataProposals?.specialProposals.data[0].id || '5',
        specialProducts,
        categories,
      };
    }
    return {
      idCategory: dataProposals?.specialProposals.data[0].id || '5',
      specialProducts: [],
      categories,
    };
  }

  return {
    idCategory: '',
    specialProducts: [],
    categories: [],
  };
};
