import React, { FC, useState } from 'react';
import {
  Stack,
  Box,
  Divider,
  Button,
  Typography,
  Pagination,
  CircularProgress,
} from '@mui/material';
import { useLocalization } from '../../../../../localization';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { DetailBlockInfo } from './DetailBlockInfo';
import {
  AllOrdersDeliveryType,
  useGetAllOrdersDelivery,
} from '../../../../../graphql/queries/hook/useGetAllOrdersDelivery';
import { Enum_Order_Status } from '../../../../../__generated__/types';
import { format } from 'date-fns';
import { DeliveryDateChangeModal } from '../../AllOrders/components/DeliveryDateChangeModal';
import { NoRowsImage } from '../../../../../components/NoRowsImage/NoRowsImage';

interface Props {
  isMobile: boolean;
  allOrdersDelivery?: Array<AllOrdersDeliveryType>;
}

export const InProgressOrderDetailBlockInfoDesktop: FC<Props> = ({ isMobile }) => {
  const { translateLang } = useLocalization();
  const [modal, setModal] = useState(false);
  const { allOrdersDelivery, totalPage, changePage, page, loading } = useGetAllOrdersDelivery({
    filters: {
      status: {
        containsi: Enum_Order_Status.Delivery,
      },
    },
  });
  return (
    <Stack width="100%">
      {allOrdersDelivery.length ? (
        <Stack alignItems="center">
          <Stack width="100%" flexDirection="row" justifyContent="left">
            <TranslatedField originText={translateLang('current')} />
            <Typography
              sx={{ color: allOrdersDelivery.length > 0 ? '#97A408' : 'black', ml: '10px' }}
            >
              {allOrdersDelivery.length || ''}
            </Typography>
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            width={isMobile ? '90%' : '100%'}
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
            mb="50px"
          >
            {allOrdersDelivery.map(item => (
              <Box
                margin="10px"
                key={item.orderNumber}
                width={{ xs: '80%', lg: '40%' }}
                sx={{
                  borderRadius: '10px',
                  padding: '25px',
                  backgroundColor: '#ffff',
                  height: '150px',
                }}
              >
                <Stack direction="row">
                  <Box width="60%">
                    <DetailBlockInfo
                      customerInfo={item.customerInfo}
                      customerPhone={item.customerPhone}
                      deliveryAddress={item.deliveryAddress}
                      orderNumber={item.orderNumber}
                      deliveryTime={item.deliveryTime}
                      status={item.status}
                      orderId={item.orderId}
                      courierId={item.courierId}
                      courier={item.courier}
                      customerId={item.customerId}
                    />
                  </Box>

                  <Divider
                    sx={{ margin: '10px' }}
                    orientation={isMobile ? 'horizontal' : 'vertical'}
                    flexItem
                  />
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setModal(true)}
                    sx={{
                      textTransform: 'none',
                      height: '50px',
                      borderRadius: '10px',
                      placeSelf: 'center',
                      width: { md: '40%' },
                    }}
                  >
                    <TranslatedField
                      noWrap
                      isTranslate
                      fontSize={16}
                      color="black"
                      originText="editDeliveryTime"
                    />
                  </Button>
                </Stack>

                {modal && (
                  <DeliveryDateChangeModal
                    isMobile={isMobile}
                    orderId={item.orderId}
                    isOpen={modal}
                    date={format(new Date(item.deliveryTime as string), 'yyyy-MM-dd HH:mm') || ''}
                    closeModal={() => setModal(false)}
                  />
                )}
              </Box>
            ))}
          </Stack>

          <Pagination
            sx={{
              '& .MuiPaginationItem-root': {
                '&.Mui-selected': {
                  background: '#5269A3',
                  color: 'white',
                  borderRadius: '8px',
                },
              },
            }}
            count={totalPage}
            shape="rounded"
            page={page}
            onChange={changePage ? (event, value) => changePage(value - 1) : undefined}
          />
        </Stack>
      ) : (
        <NoRowsImage text={translateLang('nothingFound')} />
      )}
    </Stack>
  );
};
