import React, { FC } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Pagination,
  Stack,
  Typography,
} from '@mui/material';
import { useLocalization } from '../../../../../localization';
import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { DetailBlockInfo } from './DetailBlockInfo';
import {
  AllOrdersDeliveryType,
  useGetAllOrdersDelivery,
} from '../../../../../graphql/queries/hook/useGetAllOrdersDelivery';
import { useGetIsMobileQuery } from '../../../../../graphql/cacheQuery/__generated__/getIsMobile';
import { Enum_Order_Status } from '../../../../../__generated__/types';
import { NoRowsImage } from '../../../../../components/NoRowsImage/NoRowsImage';

interface Props {
  allOrdersDelivery?: Array<AllOrdersDeliveryType>;
}

export const ComletedOrderDetailBlockInfoDesktop: FC<Props> = () => {
  const { translateLang } = useLocalization();
  const { data } = useGetIsMobileQuery();
  const isMobile = data?.isMobile ?? false;

  const { allOrdersDelivery, totalPage, changePage, page, loading } = useGetAllOrdersDelivery({
    filters: {
      status: {
        containsi: Enum_Order_Status.Completed,
      },
    },
  });

  return (
    <Stack alignItems="center">
      <Stack width="100%" flexDirection="row" justifyContent="left">
        <TranslatedField originText={translateLang('executed')} />
        <Typography sx={{ color: allOrdersDelivery.length > 0 ? '#97A408' : 'black', ml: '10px' }}>
          {allOrdersDelivery.length || ''}
        </Typography>
      </Stack>
      {loading ? <CircularProgress size={30} color="secondary" /> : null}
      {allOrdersDelivery.length ? (
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          width={isMobile ? '90%' : '100%'}
          flexWrap="wrap"
          justifyContent="center"
          alignItems="center"
        >
          {allOrdersDelivery.map(item => (
            <Box
              margin="10px"
              key={item.orderNumber}
              width={{ xs: '80%', lg: '40%' }}
              sx={{
                borderRadius: '10px',
                padding: '25px',
                backgroundColor: '#ffff',
                border: '1px solid #97A408',
                height: '150px',
              }}
            >
              <Stack direction="row">
                <Box width="60%">
                  <DetailBlockInfo
                    customerInfo={item.customerInfo}
                    customerPhone={item.customerPhone}
                    deliveryAddress={item.deliveryAddress}
                    orderNumber={item.orderNumber}
                    deliveryTime={item.deliveryTime}
                    status={item.status}
                    orderId={item.orderId}
                    courierId={item.courierId}
                    courier={item.courier}
                    customerId={item.customerId}
                  />
                </Box>
                {/* ToDo: button click function implement  */}
                {/* <Divider
                  sx={{ margin: '10px' }}
                  orientation={isMobile ? 'horizontal' : 'vertical'}
                  flexItem
                />
                <Button
                  disabled
                  color="primary"
                  variant="outlined"
                  sx={{
                    textTransform: 'none',
                    height: '50px',
                    borderRadius: '10px',
                    placeSelf: 'center',
                    width: { md: '40%' },
                  }}
                >
                  <TranslatedField
                    noWrap
                    isTranslate
                    fontSize={16}
                    color="black"
                    originText="makeReturn"
                  />
                </Button> */}
              </Stack>
            </Box>
          ))}
        </Stack>
      ) : (
        <NoRowsImage text={translateLang('nothingFound')} />
      )}

      <Pagination
        sx={{
          '& .MuiPaginationItem-root': {
            '&.Mui-selected': {
              background: '#5269A3',
              color: 'white',
              borderRadius: '8px',
            },
          },
        }}
        count={totalPage}
        shape="rounded"
        page={page}
        onChange={changePage ? (event, value) => changePage(value - 1) : undefined}
      />
    </Stack>
  );
};
