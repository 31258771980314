import React, { FC, useState } from 'react';
import * as yup from 'yup';
import { Box, Button, CircularProgress, Divider, Stack, Typography, Zoom } from '@mui/material';
import { useLocalization } from '../../../localization';
import { Input } from '../../../legos';
import { useFormik } from 'formik';
import { ModalTypography } from './PrintModal/ModalTypography';
import { OrderItemType } from '../../../helpers/types';
import { BarCodeImage } from './BarCodeImage';

interface Props {
  isOpen: boolean;
  orderItem?: OrderItemType;
  handleAddQuantity(eanNumber: string, quantity: number): void;
  handleClose(): void;
}

enum AddQuantityFields {
  quantity = 'quantity',
}

export const AddQuantity: FC<Props> = ({ isOpen, orderItem, handleAddQuantity, handleClose }) => {
  const { translateLang } = useLocalization();
  const [loading, setLoading] = useState(false);

  const maxQuantity =
    orderItem?.quantity && (orderItem?.pickedUpQuantity || orderItem?.pickedUpQuantity === 0)
      ? orderItem?.quantity - orderItem?.pickedUpQuantity - 1
      : 0;

  const initialValues = {
    [AddQuantityFields.quantity]: 1,
  };
  const validationSchema = yup.object({
    [AddQuantityFields.quantity]: yup
      .number()
      .positive(translateLang('positiv_number'))
      .max(maxQuantity, `${translateLang('maximal')} ${maxQuantity}`),
  });
  const { values, errors, handleChange, handleSubmit, isValid } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      setLoading(true);
      if (orderItem?.barcode && values[AddQuantityFields.quantity]) {
        handleAddQuantity(orderItem?.barcode, values[AddQuantityFields.quantity]);
        handleClose();
      }
      setLoading(false);
    },
  });

  return (
    <Zoom in={isOpen} mountOnEnter unmountOnExit>
      <Box paddingX="100px">
        <Typography sx={{ fontWeight: 400, fontSize: '36px', lineHeight: '45px', mb: '20px' }}>
          {translateLang('addQuantity')}
        </Typography>

        <Stack flexDirection="row" height="100%" justifyContent="space-around">
          <BarCodeImage barcode={orderItem?.barcode || ''} />
          <Divider orientation="vertical" />
          <Stack textAlign="left" spacing={2}>
            <ModalTypography label={`${translateLang('product')}`} value={orderItem?.productName} />
            <ModalTypography label={`${translateLang('article')}`} value={orderItem?.article} />
            <ModalTypography label={`${translateLang('provider')}`} value={orderItem?.provider} />
            <ModalTypography label={`${translateLang('order')}`} value={'#' + orderItem?.orderId} />
            <ModalTypography
              label={`${translateLang('incomingQuantity')}`}
              value={`${orderItem?.quantity || 0}`}
            />
            <ModalTypography
              label={`${translateLang('scannedQuantity')}`}
              value={`${
                orderItem?.pickedUpQuantity || orderItem?.pickedUpQuantity === 0
                  ? orderItem?.pickedUpQuantity + 1
                  : 0
              }`}
            />
            <Box mb={4} pt="50px">
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: '18px',
                  lineHeight: '25.1px',
                  marginTop: 2,
                  marginBottom: '10px',
                }}
              >
                {translateLang('addQuantity')}
                {': '}
                {`(${translateLang('maximal')}: ${maxQuantity})`}
              </Typography>
              <Stack component="form" onSubmit={handleSubmit} gap={2}>
                <Input
                  width="100%"
                  type="number"
                  disabled={loading}
                  variant={'outlined'}
                  placeholder={translateLang('quantity')}
                  name={AddQuantityFields.quantity}
                  value={values[AddQuantityFields.quantity]}
                  onChange={handleChange}
                  error={!!errors[AddQuantityFields.quantity]}
                  helperText={errors[AddQuantityFields.quantity]}
                  style={{ maxWidth: 200 }}
                />
                <Button
                  disabled={loading || !isValid}
                  type="submit"
                  variant="contained"
                  fullWidth
                  style={{ height: 40, marginTop: 5, maxWidth: 200 }}
                >
                  {translateLang('addQuantity')}
                  {loading && (
                    <CircularProgress color="secondary" size={20} style={{ marginLeft: 8 }} />
                  )}
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Box>
    </Zoom>
  );
};
