import React, { useCallback, useEffect, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useLocation } from 'react-router-dom';
import { EnumTabsAuthState, LoginFields, OptionLoginType, SuccessType } from './helpers/types';
import { Box, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';

import { useValidation } from '../../hooks';
import { WrapperShadow } from './components/WrapperShadow';
import { TabsAuth } from './components/TabsAuth';
import { useLocalization } from '../../localization';
import { PhoneInput } from './components/PhoneInput';
import { InputAuth } from './components/InputAuth';
import { PasswordInput } from './components/PasswordInput';
import { ButtonOrange } from './components/ButtonOrange';
import { StrokeForNavigate } from './components/StrokeForNavigate';
import { useRegisterByEmailMutation } from '../../graphql/mutations/__generated__/registerByEmail';
import { handlerError } from '../../helpers/functions';
import { useRegisterByPhoneMutation } from '../../graphql/mutations/__generated__/registerByPhone';
import { useHelpersNavigate } from './hooks/helpersNavigate';

interface Props {
  asModal?: boolean;
  closeModal?(): void;
  toggleConfirmForm?(): void;
  handleSetPhone?(val: string): void;
  handleSetPassword?(val: string): void;
}

const initialValues = {
  [LoginFields.Identifier]: '',
  [LoginFields.Password]: '',
  [LoginFields.ConfirmPassword]: '',
};
export const RegisterPage = ({
  asModal,
  closeModal,
  toggleConfirmForm,
  handleSetPhone,
  handleSetPassword,
}: Props) => {
  const { translateLang } = useLocalization();
  const { goToSuccessPage, goToLoginPage, goToEnterCodePage } = useHelpersNavigate();
  const stateLocation = useLocation().state as OptionLoginType;
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { emailValidation, passwordValidation, phoneValidation, confirmPasswordValidation } =
    useValidation();
  const [runRegisterByEmail, { loading: loadByEmail }] = useRegisterByEmailMutation();
  const [runRegisterByPhone, { loading: loadByPhone }] = useRegisterByPhoneMutation();
  const [isPolicy, setIsPolicy] = useState(false);
  const [currentAuthState, setCurrentAuthState] = useState<EnumTabsAuthState>(() => {
    if (stateLocation?.isPhone) {
      return EnumTabsAuthState.Phone;
    }
    return EnumTabsAuthState.Email;
  });

  const validationSchema = yup.object({
    [LoginFields.Identifier]:
      currentAuthState === EnumTabsAuthState.Email ? emailValidation : phoneValidation,
    [LoginFields.Password]: passwordValidation,
    [LoginFields.ConfirmPassword]: confirmPasswordValidation,
  });

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      return;
    }

    const token = await executeRecaptcha('registerByPhone');
    return token;
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const { values, errors, touched, handleChange, setErrors, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async values => {
        try {
          if (currentAuthState === EnumTabsAuthState.Email) {
            const response = await runRegisterByEmail({
              variables: {
                input: {
                  email: values[LoginFields.Identifier],
                  username: values[LoginFields.Identifier],
                  password: values[LoginFields.Password],
                  staff: true,
                },
              },
              refetchQueries: 'active',
            });
            if (response.data?.register.user) {
              if (asModal && closeModal) {
                closeModal();
              } else {
                goToSuccessPage({ state: { isPhone: false, successType: SuccessType.Register } });
              }
            }
          } else {
            const token = await handleReCaptchaVerify();
            const response = await runRegisterByPhone({
              variables: {
                input: {
                  phoneNumber: `+${values[LoginFields.Identifier]}`,
                  password: values[LoginFields.Password],
                  token: token || '',
                },
              },
            });
            if (response.data?.registerByPhoneNumber?.ok) {
              if (asModal && handleSetPhone && handleSetPassword && toggleConfirmForm) {
                handleSetPhone(values[LoginFields.Identifier]);
                handleSetPassword(values[LoginFields.Password]);
                toggleConfirmForm();
              } else {
                goToEnterCodePage({
                  state: {
                    phone: values[LoginFields.Identifier],
                    password: values[LoginFields.Password],
                  },
                });
              }
            }
          }
        } catch (err: any) {
          setErrors({
            [LoginFields.Identifier]: err?.message,
            [LoginFields.Password]: err?.message,
          });
          handlerError(err);
        }
      },
    });
  const handlerChangeState = (state: EnumTabsAuthState) => {
    setCurrentAuthState(state);
  };
  const getLabelCheckBox = () => {
    const str = translateLang('registerPolicy');
    const arr = str.split('@');
    return (
      <Typography>
        {arr.map((item, index) => {
          const isChet = index % 2 === 0;
          return (
            <span
              key={index}
              style={{
                color: isChet ? 'rgba(0,0,0,0.66)' : '#000000',
                fontWeight: isChet ? 'normal' : 'bold',
                fontSize: isChet ? '12px' : '13px',
              }}
            >
              {item}
            </span>
          );
        })}
      </Typography>
    );
  };

  useEffect(() => {
    if (stateLocation && currentAuthState === EnumTabsAuthState.Phone) {
      if (stateLocation?.phone) {
        setFieldValue(LoginFields.Identifier, stateLocation.phone);
      }
      if (stateLocation?.password) {
        setFieldValue(LoginFields.Password, stateLocation.password);
        setFieldValue(LoginFields.ConfirmPassword, stateLocation.password);
      }
    }
  }, [stateLocation]);

  return (
    <WrapperShadow title={asModal ? '' : translateLang('createAccount')}>
      <TabsAuth currentState={currentAuthState} changeState={handlerChangeState} />
      <Stack mt="-10px" component="form" width="100%" onSubmit={handleSubmit}>
        {currentAuthState === EnumTabsAuthState.Phone && (
          <PhoneInput
            phoneValue={values[LoginFields.Identifier]}
            changePhoneValue={val => {
              setFieldValue(LoginFields.Identifier, val.replaceAll(' ', ''));
            }}
            error={touched[LoginFields.Identifier] && !!errors[LoginFields.Identifier]}
            helperText={touched[LoginFields.Identifier] && errors[LoginFields.Identifier]}
          />
        )}
        {currentAuthState === EnumTabsAuthState.Email && (
          <InputAuth
            title={translateLang('email')}
            type="email"
            name={LoginFields.Identifier}
            value={values[LoginFields.Identifier]}
            onChange={handleChange}
            error={touched[LoginFields.Identifier] && !!errors[LoginFields.Identifier]}
            helperText={touched[LoginFields.Identifier] && errors[LoginFields.Identifier]}
          />
        )}
        <PasswordInput
          name={LoginFields.Password}
          value={values[LoginFields.Password]}
          onChange={handleChange}
          error={touched[LoginFields.Password] && !!errors[LoginFields.Password]}
          helperText={touched[LoginFields.Password] && errors[LoginFields.Password]}
        />
        <PasswordInput
          name={LoginFields.ConfirmPassword}
          value={values[LoginFields.ConfirmPassword]}
          onChange={handleChange}
          confirm
          error={touched[LoginFields.ConfirmPassword] && !!errors[LoginFields.ConfirmPassword]}
          helperText={touched[LoginFields.ConfirmPassword] && errors[LoginFields.ConfirmPassword]}
        />
        {!asModal && (
          <FormControlLabel
            control={
              <Checkbox
                sx={{ color: '#9C9C9C', borderRadius: '5px' }}
                checked={isPolicy}
                onChange={() => setIsPolicy(!isPolicy)}
              />
            }
            label={getLabelCheckBox()}
          />
        )}
        <ButtonOrange
          type="submit"
          titleButton={asModal ? translateLang('addClients') : translateLang('signUp')}
          disableButton={!asModal && !isPolicy}
          loading={loadByEmail || loadByPhone}
        />
        {!asModal && (
          <Box width="100%" mt={3}>
            <StrokeForNavigate
              text={translateLang('alreadyHaveAccount')}
              textNavigate={translateLang('log_in')}
              actionNavigate={goToLoginPage}
            />
          </Box>
        )}
      </Stack>
    </WrapperShadow>
  );
};
