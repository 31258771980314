import { styled } from '@mui/material/styles';
import { Box, TableCell, tableCellClasses } from '@mui/material';

export const ContainerCell = styled(Box)(({ justifyContent, borderLeft, height, padding }) => ({
  '&': {
    width: '100%',
    minHeight: height || '40px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: justifyContent || 'center',
    borderLeft: borderLeft || 'solid 0.2px #8D8D8D',
    padding: padding || '10px',
    boxSizing: 'border-box',
  },
}));
export const StyledTableCell = styled(TableCell)(({ theme, color, valign }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: color || '#4d699c',
    color: color ? 'black' : theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    padding: '4px 0',
    verticalAlign: valign || 'middle',
    backgroundColor: 'white',
  },
}));
