/* eslint-disable no-console */
import React, { useMemo, useRef, useState } from 'react';
import { Box, Checkbox } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocalization } from '../../localization';
import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import {
  BoxCell,
  HeaderCell,
  TableCellsValue,
  TableProduct,
} from '../../components/TableProduct/TableProduct';
import { PatchesName } from '../../types/types';
import { useUpdateSpecialProposalMutation } from '../../graphql/mutations/__generated__/updateSpecialProposal';
import { useLocalDataSpecialProductQuery } from '../../graphql/cacheQuery/__generated__/localDataSpecialProduct';
import { SearchField } from '../../components/SearchField';
import { GeneralSparesFiltersInput } from '../../__generated__/types';
import { PAGE_COUNT } from '../../helpers/constants';
import { useGetGeneralSparesQuery } from '../../graphql/queries/__generated__/getGeneralSpares';
import { CustomAvatar } from '../../components';
import logo from '../../assets/logotype.svg';

export const AddTopProducts = () => {
  const { data: dataLocalSpecialProduct } = useLocalDataSpecialProductQuery();
  const [addSpecialProposalMutation] = useUpdateSpecialProposalMutation();
  const navigate = useNavigate();
  const { translateLang, updateSelectLanguage } = useLocalization();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState<GeneralSparesFiltersInput | undefined>();
  const { data: dataCarSpares, loading: loadingSpares } = useGetGeneralSparesQuery({
    variables: {
      pagination: { page: page + 1, pageSize: PAGE_COUNT },
      locale: updateSelectLanguage,
      filters,
    },
  });
  const [selectedIDsProduct, setSelectedIDsProduct] = useState<Array<string>>(
    dataLocalSpecialProduct?.selectedSpecialProduct.productsIDs
      ? [...dataLocalSpecialProduct.selectedSpecialProduct.productsIDs]
      : []
  );
  const staticIDsProducts = useRef(
    dataLocalSpecialProduct?.selectedSpecialProduct.productsIDs
      ? [...dataLocalSpecialProduct.selectedSpecialProduct.productsIDs]
      : []
  );

  const handlerHeaderButton = async () => {
    try {
      await addSpecialProposalMutation({
        variables: {
          id: dataLocalSpecialProduct?.selectedSpecialProduct.idCategory || '5',
          data: {
            products: selectedIDsProduct,
          },
          locale: updateSelectLanguage,
        },
      });
      navigate(`/${PatchesName.TopProducts}`, { replace: true });
    } catch (err: unknown) {
      if (err instanceof ErrorEvent) {
        console.log(err?.message);
      }
    }
  };
  const handlerSearchField = (text: string) => {
    setFilters({
      ...filters,
      searchText: text.trim(),
    });
    setPage(0);
  };
  const isDisableButton = useMemo(() => {
    if (staticIDsProducts.current.length !== selectedIDsProduct.length) {
      return false;
    }
    for (let i = 0; i < staticIDsProducts.current.length; i++) {
      if (!staticIDsProducts.current.includes(selectedIDsProduct[i])) {
        return false;
      }
    }
    return true;
  }, [selectedIDsProduct]);

  const onSelectAllClick = (idProduct?: string) => {
    if (idProduct) {
      if (selectedIDsProduct.includes(idProduct)) {
        const newArrIdProd = selectedIDsProduct.filter(item => item !== idProduct);
        setSelectedIDsProduct(newArrIdProd);
      } else {
        setSelectedIDsProduct([...selectedIDsProduct, idProduct]);
      }
    }
  };
  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      { title: translateLang('photo'), containerProps: { width: '66px' } },
      { title: 'ID', containerProps: { width: '66px' } },
      { title: translateLang('article') },
      { title: translateLang('productName') },
      { title: translateLang('manufacturer') },
      { title: translateLang('action'), containerProps: { width: '40px' } },
    ];
  };
  const getRowsTable = (): Array<TableCellsValue> => {
    if (dataCarSpares && dataCarSpares.generalSpares?.data.length) {
      return dataCarSpares.generalSpares?.data.map(item => {
        const values: Array<BoxCell> = [];
        // const img = JSON.parse(item.attributes?.images) as Array<{ [key: string]: string }>;
        const img = item.attributes?.images;
        const url = img && img.length ? img[0].imageURL200 : '';
        values.push({
          val: (
            <CustomAvatar
              alt="Remy Sharp"
              src={url}
              logo={logo}
              variant="rounded"
              sx={{
                width: '100%',
                height: '100%',
                '& img': {
                  objectFit: 'contain',
                },
              }}
            />
          ),
          containerProps: {
            borderLeft: 'none',
          },
        });
        values.push({ val: item.id });
        values.push({ val: item.attributes?.tecdoc_articleNumber });
        values.push({ val: item.attributes?.title });
        values.push({ val: item.attributes?.brandName });
        values.push({
          val: (
            <Checkbox
              color="primary"
              // indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={selectedIDsProduct.includes(item?.id || '')}
              onChange={() => onSelectAllClick(item?.id || '')}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          ),
          containerProps: { justifyContent: 'center' },
        });
        return { cellsValues: values };
      });
    }
    return [];
  };
  const changePageNumber = (val: number) => {
    setPage(val);
  };
  const totalPages = dataCarSpares?.generalSpares?.meta?.pagination.total
    ? Math.ceil(dataCarSpares?.generalSpares?.meta?.pagination.total / PAGE_COUNT)
    : 0;

  return (
    <WrapperPages
      titleHeader={`${translateLang('addToProduct')} for ${
        dataLocalSpecialProduct?.selectedSpecialProduct.titleTab
      }`}
      isButton
      disableButton={isDisableButton}
      titleButton="Add selected product"
      handlerButton={handlerHeaderButton}
    >
      <Box bgcolor="white" width="100%" flex={1} borderRadius="10px" p={3} boxSizing="border-box">
        <SearchField
          executeWithDelay={handlerSearchField}
          sx={{ width: '90%' }}
          placeholder={translateLang('searchForParts')}
        />
        <Box pt={2} boxSizing="border-box">
          <TableProduct
            totalPages={totalPages}
            itemsRows={getRowsTable()}
            startPage={dataCarSpares?.generalSpares?.meta?.pagination.page || 0}
            itemsHeader={getHeadersTable()}
            changeCurrentPage={changePageNumber}
            loading={loadingSpares}
          />
        </Box>
      </Box>
    </WrapperPages>
  );
};
