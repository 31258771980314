import { OrderFiltersInput, Pagination, PaginationArg } from '../../../__generated__/types';
import { GetOrdersQueryVariables, useGetOrdersQuery } from '../__generated__/getOrders';
import { CustomerContactInfoFragment } from '../../fragments/__generated__/customerContactInfo';
import { PAGE_COUNT } from '../../../helpers/constants';
import { useLocalization } from '../../../localization';
import { addDays, format } from 'date-fns';

type InfoType = NonNullable<NonNullable<CustomerContactInfoFragment['data']>['attributes']>;
type PhoneNumbersType = NonNullable<InfoType>['phoneNumbers'];
export interface Credentials {
  firstName?: string | null;
  lastName?: string | null;
  userName?: string | null;
}
export interface OrdersType {
  id?: string | null;
  customers?: Array<string | undefined>;
  status?: string | null;
  date?: string | null;
  total?: number | null;
  customerCredential?: string | null;
  customerId?: string | null;
  managerCredential?: Array<Credentials>;
  phoneNumbers?: PhoneNumbersType;
  orderNumber?: string | null;
}
export type PaginationType = Omit<Pagination, '__typename'>;

interface ReturnHook {
  pagination?: PaginationType;
  orders: Array<OrdersType> | undefined;
  loadingOrders?: boolean;
  getVariablesOrders(): GetOrdersQueryVariables;
}
interface PropsHook {
  pagination?: PaginationArg;
  filters?: OrderFiltersInput | undefined;
}

export function getVariablesOrderQuery(locale: string): GetOrdersQueryVariables {
  return {
    locale,
    filters: {
      date: {
        between: [format(addDays(new Date(), -30), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
      },
    },
    pagination: { page: 1, pageSize: PAGE_COUNT },
    sort: ['id:desc'],
  };
}

export const useGetOrders = ({ pagination, filters }: PropsHook): ReturnHook => {
  const { updateSelectLanguage } = useLocalization();
  const { data, loading: loadingOrders } = useGetOrdersQuery({
    variables: {
      pagination,
      filters,
      sort: ['id:desc'],
      locale: updateSelectLanguage as string,
    },
  });

  const getVariablesOrders = (): GetOrdersQueryVariables => {
    return {
      pagination,
      filters,
      sort: ['id:desc'],
      locale: updateSelectLanguage as string,
    };
  };

  if (data?.orders?.data) {
    const orders: Array<OrdersType> = data?.orders?.data?.map(order => {
      const lastName =
        order.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
          ?.lastName || '';
      const firstName =
        order.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
          ?.firstName || '';
      const userName = order.attributes?.customer?.data?.attributes?.username || '';
      let customerCredential = `${firstName} ${lastName}`;
      if (!lastName && !firstName) {
        customerCredential = userName;
      }

      let orderNumber = order.attributes?.orderNumber || '';
      if (order.attributes?.orderNumberAdd) {
        orderNumber += `-${order.attributes.orderNumberAdd}`;
      }

      return {
        id: order.id || '',
        orderNumber,
        phoneNumbers:
          order.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
            ?.phoneNumbers,
        customerCredential,
        customerId: order.attributes?.customer?.data?.id || '',
        managerCredential:
          order.attributes?.managers?.data.map(item => {
            return {
              firstName: item.attributes?.customer_contact_info?.data?.attributes?.firstName,
              lastName: item.attributes?.customer_contact_info?.data?.attributes?.lastName,
              userName: item.attributes?.username,
            };
          }) || undefined,

        status: order.attributes?.status,
        date: order.attributes?.date,
        total: order.attributes?.total,
      };
    });
    const pagination: PaginationType = {
      page: data.orders.meta.pagination.page,
      pageCount: data.orders.meta.pagination.pageCount,
      pageSize: data.orders.meta.pagination.pageSize,
      total: data.orders.meta.pagination.total,
    };
    return { orders, pagination, loadingOrders, getVariablesOrders };
  }
  return { orders: [], loadingOrders, getVariablesOrders };
};
