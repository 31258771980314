import React, { Dispatch, FC, Fragment, SetStateAction, useEffect, useRef, useState } from 'react';
import { Box, Divider, Fade, Stack } from '@mui/material';

import { SubCategory } from './SubCategory';
import { CategoryButton } from './CategoryButton';
import { BottomNavigate } from './BottomNavigate';
import { GeneralSparesFiltersInput } from '../../../__generated__/types';

export interface CategoryProps {
  filters: GeneralSparesFiltersInput | undefined;
  handleSetFilters: (filters: GeneralSparesFiltersInput | undefined) => void;
  dataCategories:
    | ({
        __typename?: 'CategoryTree' | undefined;
        id?: string | null | undefined;
        name?: string | null | undefined;
        parentId?: string | null | undefined;
        level?: number | null | undefined;
      } | null)[]
    | undefined;
}

export const CategoryList: FC<CategoryProps> = ({ dataCategories, filters, handleSetFilters }) => {
  const categoryRef = useRef(null);
  const [currentActiveMenu, setCurrentActiveMenu] = useState<{
    id: string;
    name: string | null | undefined;
  }>({
    id: dataCategories?.[0]?.id ? dataCategories?.[0]?.id : '',
    name: dataCategories?.[0]?.name,
  });

  const [checked, setChecked] = useState(false);
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const getSecondCategory = (
    tecdoc_categoryId: string,
    nameSubCategory: string,
    nameCategory: string
  ) => {
    const filter: GeneralSparesFiltersInput = {
      ...filters,
      tecdoc_categoryId,
    };

    handleSetFilters(filter);
    setCategory(nameCategory);
    setSubCategory(nameSubCategory);
  };
  const handlerOpenMenuList = (id: string, name: string) => {
    setCurrentActiveMenu({ id, name });
    setChecked(true);
    const _filters = { ...filters };
    handleSetFilters(_filters);
  };

  const clearFilter = () => {
    setCategory('');
    setSubCategory('');
    setChecked(false);
    setCurrentActiveMenu({
      id: '',
      name: '',
    });
    const _filters = { ...filters };
    delete _filters.tecdoc_categoryId;
    handleSetFilters(_filters);
  };

  useEffect(() => {
    if (
      dataCategories?.[0]?.id &&
      !dataCategories?.some(item => item?.id === currentActiveMenu.id)
    ) {
      setCurrentActiveMenu({
        id: dataCategories?.[0]?.id,
        name: dataCategories?.[0]?.name,
      });
    }
  }, [dataCategories?.[0]?.id, currentActiveMenu.id]);

  return (
    <>
      <Box
        sx={{
          height: '410px',
          width: '100%',
          backgroundColor: 'white',
          borderRadius: '10px',
        }}
        position="relative"
      >
        <Stack
          divider={<Divider variant="middle" orientation="vertical" flexItem />}
          ref={categoryRef}
          direction="row"
          height="56px"
          textAlign="center"
          mt="7px"
          borderRadius="10px"
          sx={{ placeContent: 'space-between' }}
        >
          {dataCategories?.map(item => {
            return (
              <Fragment key={item?.id}>
                <CategoryButton
                  categoryName={item?.name || ''}
                  currentActiveMenu={currentActiveMenu}
                  onSetCategory={handlerOpenMenuList}
                  indexCategory={`${item?.id}`}
                />

                {currentActiveMenu?.id === item?.id && (
                  <Fade in={true} style={{ transitionDelay: checked ? '200ms' : '0ms' }}>
                    <Stack
                      direction="row"
                      padding="10px"
                      flexWrap="wrap"
                      maxHeight="312px"
                      overflow="auto"
                      justifyContent="space-between"
                      marginTop="56px"
                      display="flex"
                      position="absolute"
                      marginBottom="100px"
                      width="80%"
                      sx={{
                        mX: '10px',
                        backgroundColor: 'white',
                        borderBottom: '5px',
                        wordBreak: 'break-all',
                        '&::-webkit-scrollbar': {
                          width: '6px',
                        },
                      }}
                    >
                      <SubCategory
                        idCategory={item?.id}
                        category={category}
                        secondCategory={getSecondCategory}
                      />
                    </Stack>
                  </Fade>
                )}
              </Fragment>
            );
          })}
        </Stack>
      </Box>
      <BottomNavigate
        category={category}
        currentActiveMenu={currentActiveMenu}
        subCategory={subCategory}
        clearFilter={clearFilter}
      />
    </>
  );
};
