import { Button, Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  currentActiveMenu: {
    id: string;
    name: string | null | undefined;
  };
  indexCategory: string;
  categoryName: string;
  onSetCategory(id: string, name: string): void;
}
export const CategoryButton: FC<Props> = ({
  currentActiveMenu,
  indexCategory,
  categoryName,
  onSetCategory,
}) => {
  return (
    <Button
      sx={{
        backgroundColor: currentActiveMenu?.id === indexCategory ? '#F1F3F8' : 'white',
        borderRadius: 0,
        textTransform: 'none',
        fontSize: '12px',
      }}
      onClick={() => onSetCategory(indexCategory, categoryName)}
    >
      <Typography
        color={currentActiveMenu?.id === indexCategory ? 'white.main' : 'secondary.dark'}
        lineHeight="18px"
        fontSize={{ lg: '14px', xs: '12px' }}
      >
        {categoryName || ''}
      </Typography>
    </Button>
  );
};
