import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { addDays, endOfToday, format, startOfDay, startOfMonth, subDays } from 'date-fns';
import { Autocomplete, Box, Button, Stack, TextField, UseAutocompleteProps } from '@mui/material';

import { useLocalization } from '../../../../localization';
import { useDefaultRangeCalendar } from '../../../../hooks';
import { WrapperToolBar } from '../../../../components/WrapperToolBar';
import { OrderItemFiltersInput } from '../../../../__generated__/types';
import { RangeCalendar } from '../../../../legos/rangeCalendar/RangeCalendar';
import { useGetSuppliersQuery } from '../../../../graphql/queries/__generated__/getSuppliers';
import { useFiltersSearchParamsForOrders } from '../../../../hooks/useFiltersSearchParamsForOrders';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';

interface Props {
  setResetFilter(): void;
  result?: string | number;
  setFilterSubscription: Dispatch<SetStateAction<OrderItemFiltersInput>>;
}

export type NoSpareStatusType = {
  label: string;
  value: string;
};

export const ToolBarExpectedTab: FC<Props> = ({
  result,
  setFilterSubscription,
  setResetFilter,
}) => {
  const { translateLang } = useLocalization();
  const { reportRangeDates } = useDefaultRangeCalendar();
  const { changeDate, selDateTo, selDateFrom, resetFilters } = useFiltersSearchParamsForOrders({
    dateFrom: addDays(new Date(), -30).toISOString(),
    dateTo: new Date().toISOString(),
  });

  const { data: dataSuppliers } = useGetSuppliersQuery({
    variables: {
      filters: { or: [{ blocked: { eq: false } }, { blocked: { eq: null } }] },
    },
  });
  const [currentProviders, setCurrentProviders] = useState<NoSpareStatusType | null>(null);
  const [selectDates, setSelectedDates] = useState(getDatesFilters);

  const handlerReset = () => {
    setResetFilter();
    resetFilters();
    setSelectedDates([startOfMonth(new Date()), new Date()]);
  };
  const handlerChangesDates = (val: Date[]) => {
    setSelectedDates(val);
    changeDate(
      val[0]?.toISOString(),
      val[1] ? addDays(val[1], 1)?.toISOString() : addDays(val[0], 1).toISOString()
    );
  };

  const handlerClearFilter = () => {
    setCurrentProviders(null);
    setFilterSubscription(oldFilter => {
      const reset = { ...oldFilter };
      delete reset.spare_archive;
      return reset;
    });
  };
  const handleChangeStatus: UseAutocompleteProps<
    NoSpareStatusType,
    undefined,
    undefined,
    undefined
  >['onChange'] = (evt, option): void => {
    if (option) {
      setCurrentProviders(option as NoSpareStatusType);
      setFilterSubscription(oldFilter => ({
        ...oldFilter,
        spare_archive: {
          supplier: { name: { containsi: option.value } },
        },
      }));
    }
  };

  const getOptionsProviders = (): NoSpareStatusType[] => {
    if (dataSuppliers?.suppliers?.data.length) {
      return dataSuppliers?.suppliers.data.map(item => {
        return {
          label: item?.id || '',
          value: item.attributes?.name || '',
        };
      });
    }
    return [];
  };

  useEffect(() => {
    setFilterSubscription(oldFilter => ({
      ...oldFilter,
      createdAt: {
        between: [
          selDateFrom !== '' ? selDateFrom : startOfDay(subDays(new Date(), 30)).toISOString(),
          selDateTo !== '' ? selDateTo : endOfToday().toISOString(),
        ],
      },
    }));
  }, [selDateFrom, selDateTo, selectDates, setFilterSubscription]);

  function getDatesFilters(): Date[] {
    if (selDateFrom && selDateTo) {
      return [new Date(selDateFrom), new Date(selDateTo)];
    }
    return [startOfDay(subDays(new Date(), 30)), endOfToday()];
  }

  return (
    <WrapperToolBar>
      <Stack width="100%" flexDirection="row" justifyContent="left">
        <TranslatedField
          fontSize="30px"
          originText={`${translateLang('result')}: ${result}` || ''}
        />
      </Stack>
      <Stack spacing={2} bgcolor="white" flexDirection="row" justifyContent="right">
        <Stack direction="row" gap={2} alignItems="center">
          <TranslatedField originText={translateLang('provider')} />
          <Autocomplete
            size="small"
            disablePortal
            id="combo-box-demo"
            sx={{ minWidth: 200 }}
            value={currentProviders}
            options={getOptionsProviders()}
            renderInput={params => <TextField {...params} label={translateLang('provider')} />}
            getOptionLabel={option => {
              return option?.value || '';
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.label}>
                {option.value}
              </Box>
            )}
            onChange={handleChangeStatus}
            onInputChange={(e, value, reason) => {
              if (reason === 'clear') {
                handlerClearFilter();
              }
            }}
          />
          <RangeCalendar
            selectedDates={selectDates}
            defaultRangeDates={reportRangeDates}
            setSelectedDates={handlerChangesDates}
          />
          <Button
            color="primary"
            variant="outlined"
            onClick={handlerReset}
            style={{
              width: '100%',
              textTransform: 'none',
            }}
          >
            <TranslatedField
              noWrap
              isTranslate
              fontSize={16}
              color="black"
              originText="resetFilters"
            />
          </Button>
        </Stack>
      </Stack>
    </WrapperToolBar>
  );
};
