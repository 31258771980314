import React, { DragEvent, FC, useState } from 'react';
import * as yup from 'yup';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { Box, Button, CircularProgress, Typography } from '@mui/material';

import {
  StockForSplit,
  DraggedInvoice,
  IncomingInvoiceDetails,
  IncomingInvoiceForSplit,
} from './types';
import { CustomModal } from '../CustomModal';
import { useLocalization } from '../../localization';
import { InvoiceContainer } from './components/InvoiceContainer';
import { useHandlerNotificationApp } from '../../hooks/useHandlerNotificationApp';
import { IncomingInvoiceEntity, Enum_Incominginvoice_Status } from '../../__generated__/types';
import { useGetIncomingInvoicesStocksQuery } from './query/__generated__/getIncomingInvoicesStocks';
import { GetIncomingInvoiceDocument } from '../../graphql/queries/__generated__/getIncomingInvoice';
import { useCreateIncomingInvoiceMutation } from '../../graphql/mutations/__generated__/createIncomingInvoice';
import { formatDateTime } from '../../utils/dateUtils';

type IncomingInvoiceSplittingProps = {
  isOpen: boolean;
  invoiceData: IncomingInvoiceEntity;
  handleClose: () => void;
  refetchIncomingInvoices: () => void;
};

export const IncomingInvoiceSplittingModal: FC<IncomingInvoiceSplittingProps> = ({
  isOpen,
  invoiceData,
  handleClose,
  refetchIncomingInvoices,
}) => {
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const { updateSelectLanguage } = useLocalization();

  const [draggedInvoice, setDraggedInvoice] = useState<DraggedInvoice | null>(null);

  const [runCreateIncomingInvoice] = useCreateIncomingInvoiceMutation({
    refetchQueries: [
      {
        query: GetIncomingInvoiceDocument,
        variables: {
          id: invoiceData.id,
          locale: updateSelectLanguage,
        },
      },
    ],
  });

  const dragStartHandler = (stock: StockForSplit, invoice: IncomingInvoiceForSplit) => {
    setDraggedInvoice({ stock, invoice });
  };

  const validationSchema = yup.array().of(
    yup.object({
      invoice: yup.object({
        supplierInvoiceTotal: yup
          .number()
          .typeError(translateLang('mustBeNumber'))
          .required(translateLang('emptyInputError'))
          .moreThan(0, translateLang('amountMoreThanZero')),
        supplierInvoiceNumber: yup.string().required(translateLang('emptyInputError')),
        supplierInvoiceDate: yup
          .date()
          .typeError(translateLang('emptyInputError'))
          .required(translateLang('emptyInputError')),
      }),
    })
  );

  const { values, touched, errors, setValues, setFieldValue, handleSubmit } = useFormik<
    IncomingInvoiceDetails[]
  >({
    initialValues: [
      {
        invoice: {
          id: invoiceData.id as string,
          supplierInvoiceDate: invoiceData.attributes?.supplierInvoiceDate,
          supplierInvoiceTotal: invoiceData.attributes?.supplierInvoiceTotal ?? 0,
          supplierInvoiceNumber: invoiceData.attributes?.supplierInvoiceNumber ?? '',
        },
        stocks: [],
      },
      {
        invoice: {
          id: '-1',
          supplierInvoiceTotal: 0,
          supplierInvoiceNumber: '',
          supplierInvoiceDate: null,
        },
        stocks: [],
      },
    ],
    validationSchema,
    onSubmit: async (values: IncomingInvoiceDetails[]) => {
      try {
        for (const invoice of values) {
          if (invoice.invoice.id !== invoiceData.id) {
            await runCreateIncomingInvoice({
              variables: {
                data: {
                  status: Enum_Incominginvoice_Status.Created,
                  stocks: invoice.stocks.map(stock => stock.id) as [],
                  supplier: invoiceData.attributes?.supplier?.data?.id,
                  supplierInvoiceTotal: +invoice.invoice.supplierInvoiceTotal,
                  supplierInvoiceNumber: invoice.invoice.supplierInvoiceNumber,
                  supplierInvoiceDate: format(
                    invoice.invoice.supplierInvoiceDate as Date,
                    'yyyy-MM-dd'
                  ),
                  date: formatDateTime(new Date(), { format: 'yyyy-MM-dd' }),
                },
              },
            });
          }
        }
        refetchIncomingInvoices();
        addNotification({
          typeMessage: 'success',
          messageError: translateLang('invoiceSuccessfullySplit'),
        });
        handleClose();
      } catch (err: any) {
        addNotification({ messageError: err?.message ? err.message : '', typeMessage: 'error' });
      }
    },
  });

  const handleSplitInvoice = async () => {
    handleSubmit();
  };

  const dragEndHandler = () => setDraggedInvoice(null);

  const dragDropHandler = (
    e: DragEvent<HTMLDivElement>,
    droppedInvoice: IncomingInvoiceForSplit
  ) => {
    e.stopPropagation();

    if (draggedInvoice?.invoice?.id && draggedInvoice.invoice?.id !== droppedInvoice.id) {
      const newInvoices = [...values];
      const oldInvoice = newInvoices.find(
        invoice => invoice.invoice.id === draggedInvoice.invoice.id
      );
      const stockIndexInOldInvoice = oldInvoice?.stocks.findIndex(
        stock => stock.id === draggedInvoice.stock.id
      );
      if (stockIndexInOldInvoice !== undefined) {
        oldInvoice?.stocks.splice(stockIndexInOldInvoice, 1);

        const newInvoice = newInvoices.find(invoice => invoice.invoice.id === droppedInvoice.id);
        if (draggedInvoice.stock) {
          newInvoice?.stocks.unshift(draggedInvoice?.stock);
        }
      }
      setDraggedInvoice(null);

      setValues(newInvoices);
    }
  };

  const { loading } = useGetIncomingInvoicesStocksQuery({
    variables: {
      filtersLocale: {
        locale: {
          eq: updateSelectLanguage,
        },
      },
      incomingInvoiceId: invoiceData.id,
      pagination: {
        limit: -1,
      },
      sort: ['id'],
    },
    onCompleted(data) {
      setValues(oldData => {
        const newData = [...oldData];
        newData[0].stocks =
          data.stocks?.data.map(stock => ({
            id: stock.id as string,
            balanceQuantity: stock.attributes?.balanceQuantity ?? 0,
            brandName:
              stock.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0].attributes
                ?.brandName ?? '',
            supplierPrice: stock.attributes?.supplierPrice ?? 0.0,
            title:
              stock.attributes?.car_spare?.data?.attributes?.car_spare_locales?.data[0].attributes
                ?.title ?? '',
            incomingQuantity: stock.attributes?.incomingQuantity ?? 0,
          })) ?? [];
        return newData;
      });
    },
  });

  const handleAddInvoice = () => {
    setValues(oldValue => {
      const lastInvoice = oldValue[oldValue.length - 1];
      const newValue = [
        ...oldValue,
        {
          invoice: {
            id: `${+(lastInvoice.invoice.id ?? 0) - 1}`,
            supplierInvoiceTotal: 0,
            supplierInvoiceNumber: '',
            supplierInvoiceDate: null,
          },
          stocks: [],
        },
      ];

      return newValue;
    });
  };

  return (
    <CustomModal
      width="75vw"
      open={isOpen}
      maxHeight="75vh"
      bgcolor="#F1F3F8"
      handleClose={handleClose}
      title={translateLang('splitIncomingInvoice')}
    >
      {loading ? (
        <CircularProgress />
      ) : (
        <Box display="flex" flexDirection="column" alignItems="space-between">
          <Box
            flex={1}
            pb={0.5}
            pl={0.5}
            width="100%"
            display="flex"
            maxHeight="60vh"
            overflow="scroll"
            flexDirection="row"
            style={{
              overflowY: 'hidden',
            }}
          >
            {values.map((invoice, index) => (
              <InvoiceContainer
                index={index}
                values={values[index]}
                errors={errors[index]}
                touched={touched[index]}
                key={invoice.invoice.id}
                invoiceDetails={invoice}
                setFieldValue={setFieldValue}
                dragEndHandler={dragEndHandler}
                dragDropHandler={dragDropHandler}
                dragStartHandler={dragStartHandler}
                acceptDrag={
                  !!draggedInvoice?.invoice?.id && draggedInvoice.invoice.id !== invoice.invoice.id
                }
              />
            ))}
            <Button
              size="large"
              color="secondary"
              variant="contained"
              onClick={handleAddInvoice}
              sx={{
                boxShadow: 'none',
                minWidth: '100px',
                backgroundColor: 'transparent',
              }}
            >
              <Box display="flex" flexDirection="column">
                <Typography fontSize={80} color="black" sx={{ opacity: 0.6 }}>
                  +
                </Typography>
                <Typography color="black" sx={{ opacity: 0.6 }}>
                  {translateLang('addIncomingInvoice')}
                </Typography>
              </Box>
            </Button>
          </Box>
          <Button
            size="large"
            color="secondary"
            variant="contained"
            onClick={handleSplitInvoice}
            sx={{
              maxWidth: 320,
              margin: '0 auto',
            }}
          >
            {translateLang('doSplitIncomingInvoice')}
          </Button>
        </Box>
      )}
    </CustomModal>
  );
};
