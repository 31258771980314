import * as React from 'react';
import { useLocalization } from '../../../localization';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link, Stack, Breadcrumbs } from '@mui/material';

import { useLocation } from 'react-router-dom';
import { PatchesName } from '../../../types/types';
import { useGetCurrentClientQuery } from '../../../graphql/cacheQuery/__generated__/getClient';

export const BreadCrumps = () => {
  const { translateLang } = useLocalization();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(x => x);
  const { data } = useGetCurrentClientQuery();
  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link underline="hover" color="text.primary" href="/">
          {translateLang('main')}
        </Link>
        {pathnames.map((name, index) => {
          let to = `/${pathnames.slice(0, index + 1).join('/')}`;
          let value = name;
          if (
            pathnames.includes(PatchesName.Clients) &&
            (data?.client.firstName || data?.client.lastName)
          ) {
            value = `${data?.client.firstName || ''} ${data?.client.lastName || ''}`;
          } else if (pathnames.includes(PatchesName.Orders)) {
            if (index === 1 && (data?.client.firstName || data?.client.lastName)) {
              value = `${data?.client.firstName || ''} ${data?.client.lastName || ''} `;
              to = `/${PatchesName.Clients}/${name}`;
            }
          }
          if (pathnames.includes(PatchesName.CreateOrder)) {
            if (index === 2 && (data?.client.firstName || data?.client.lastName)) {
              value = `${data?.client.firstName || ''} ${data?.client.lastName || ''} `;
              to = `/${PatchesName.Clients}/${name}`;
            }
          }

          return (
            <Link underline="hover" color="text.primary" href={to} key={to}>
              {translateLang(value)}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Stack>
  );
};
