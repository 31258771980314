import React, { useMemo } from 'react';
import { format } from 'date-fns';

import { theme } from '../../../../../../helpers';
import { TransactionType } from './hooks/useGetTransactions';
import { useLocalization } from '../../../../../../localization';
import { Enum_Customeraccounttransaction_Documenttype } from '../../../../../../__generated__/types';
import { TableProductActive } from '../../../../../../components/TableProductActive/TableProductActive';
import { useGetOrderByIdLazyQuery } from '../../../../../../graphql/queries/__generated__/getOrderById';
import { CircularProgress } from '@mui/material';

export const ClientTransactionRowItem = ({ transaction }: { transaction: TransactionType }) => {
  const { translateLang, selectLanguages } = useLocalization();
  const [runGetOrderById, { data: orderTotal, loading: orderTotalLoading }] =
    useGetOrderByIdLazyQuery();

  // TODO refactor this block
  if (!orderTotal && !orderTotalLoading && transaction?.documentId) {
    runGetOrderById({
      variables: {
        id: `${transaction?.documentId}`,
        locale: selectLanguages as string,
      },
    });
  }

  const { id, paymentDate, total, paymentDirection, paymentNumber, comment } = transaction;

  const paymentIncoming =
    paymentDirection === Enum_Customeraccounttransaction_Documenttype.PaymentIncomingNonCash ||
    paymentDirection === Enum_Customeraccounttransaction_Documenttype.PaymentIncomingCash;

  const paymentOutgoing =
    paymentDirection === Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingCash ||
    paymentDirection === Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingNonCash;

  const sum =
    paymentIncoming || paymentDirection === Enum_Customeraccounttransaction_Documenttype.Return
      ? `+ ${total} €`
      : paymentOutgoing || paymentDirection === Enum_Customeraccounttransaction_Documenttype.Order
      ? `- ${total} €`
      : `${total} €`;

  const description = useMemo(() => {
    return paymentDirection === Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingCash
      ? translateLang('withdrawCash')
      : paymentDirection === Enum_Customeraccounttransaction_Documenttype.PaymentOutgoingNonCash
      ? translateLang('withdrawBankTransfer')
      : paymentDirection === Enum_Customeraccounttransaction_Documenttype.PaymentIncomingCash
      ? translateLang('fundPACash')
      : paymentDirection === Enum_Customeraccounttransaction_Documenttype.PaymentIncomingNonCash
      ? translateLang('fundPABankTransfer')
      : paymentDirection === Enum_Customeraccounttransaction_Documenttype.Return
      ? translateLang('refunds')
      : (orderTotal?.order?.data?.attributes?.total || 0) > (total || 0)
      ? `${translateLang('partialPaymentOrder')} #${paymentNumber}`
      : `${translateLang('orderPayment')} #${paymentNumber}`;
  }, [orderTotal?.order?.data?.attributes?.total, selectLanguages]);

  return (
    <TableProductActive.RowTable>
      <TableProductActive.CellTable ceilStyle={{ width: '40px', borderLeft: 'none' }}>
        {id}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ width: '200px', borderLeft: 'none' }}>
        {format(new Date(paymentDate), 'yyyy-MM-dd')}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable
        ceilStyle={{ width: '60px', alignItems: 'center', borderLeft: 'none' }}
      >
        {paymentIncoming
          ? translateLang('fund')
          : paymentOutgoing
          ? translateLang('withdraw')
          : paymentDirection === Enum_Customeraccounttransaction_Documenttype.Order
          ? translateLang('order')
          : translateLang('return')}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable
        ceilStyle={{
          width: '60px',
          alignItems: 'center',
          borderLeft: 'none',
          color:
            paymentIncoming ||
            paymentDirection === Enum_Customeraccounttransaction_Documenttype.Return
              ? theme.palette.common.appleGreen
              : paymentOutgoing ||
                paymentDirection === Enum_Customeraccounttransaction_Documenttype.Order
              ? theme.palette.common.red
              : undefined,
        }}
      >
        {sum}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable
        ceilStyle={{ width: '60px', alignItems: 'end', borderLeft: 'none' }}
      >
        {!orderTotalLoading ? description : <CircularProgress size={16} />}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable
        ceilStyle={{ width: '60px', alignItems: 'end', borderLeft: 'none' }}
      >
        {comment}
      </TableProductActive.CellTable>
    </TableProductActive.RowTable>
  );
};
