import React, { FC } from 'react';
import { Box } from '@mui/material';

import { TransactionType } from './hooks/useGetTransactions';
import { useGetClientTransactionTable } from './hooks/useGetClientTransactionTable';
import { TableProductActive } from '../../../../../../components/TableProductActive/TableProductActive';

interface Props {
  totalPage?: number;
  transactions?: TransactionType[];
  currentPage?: number;
  changePage(page: number): void;
  loading?: boolean;
}

export const ClientTransactionTable: FC<Props> = ({
  transactions,
  totalPage,
  currentPage,
  changePage,
  loading,
}) => {
  const { getHeadersTable, getRowsTableActive } = useGetClientTransactionTable({
    transactions,
  });

  return (
    <Box boxSizing="border-box" width="100%">
      <TableProductActive
        startPage={currentPage}
        totalPages={totalPage}
        itemsRows={getRowsTableActive()}
        itemsHeader={getHeadersTable()}
        changeCurrentPage={changePage}
        loading={loading}
      />
    </Box>
  );
};
