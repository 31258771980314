import React, { ReactNode } from 'react';
import { TableCellProps } from '@mui/material';

import { useLocalization } from '../../../../../localization';
import { HeaderCell } from '../../../../../components/TableProductActive/TableProductActive';
import { AllClientsAccountingRowItem } from '../components/tablesComponents/AllClientsAccountingRowItem';
import { ClientType } from '../../../../../graphql/queries/hook/useGetClients';

interface HookProps {
  allClientsAccounting?: ClientType[];
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetAllClientsAccountingTable = ({
  allClientsAccounting,
}: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();
  const getHeadersTable = (): Array<HeaderCell> => {
    const style: TableCellProps = { color: '#F1F3F8', align: 'left' };
    return [
      {
        title: 'ID',
        containerProps: { ...style, align: 'center', width: '20px' },
      },
      {
        title: translateLang('client'),
        containerProps: { ...style, width: '100px' },
      },
      {
        title: translateLang('customerType'),
        containerProps: { ...style, width: '60px' },
      },
      {
        title: translateLang('phoneNumbers'),
        containerProps: { ...style, width: '80px' },
      },
      {
        title: '',
        containerProps: { ...style, align: 'center', width: '20px' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (allClientsAccounting && allClientsAccounting?.length) {
      return allClientsAccounting?.map(item => {
        return <AllClientsAccountingRowItem key={`client-${item.id}`} client={item} />;
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
