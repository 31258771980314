/* eslint-disable no-console */
import { GraphQLError } from 'graphql/error';
import { VehiclesType } from './types';
import { GetClientByIdQuery } from '../graphql/queries/__generated__/getClintById';
import { AUTO_BRO_API, AUTO_BRO_BUNNY_API } from './constants';
import { OrderFiltersInput } from '../__generated__/types';
import { FiltersType } from '../graphql/queries/hook/useGetClients';

export function handlerError(error: unknown): void {
  if (error instanceof ErrorEvent) {
    console.log('%c Error! ', 'background: red; color: #bada55', error?.message);
  } //
  else if (error instanceof GraphQLError) {
    console.log('%c Error! ', 'background: red; color: #bada55', error.message);
  } else {
    console.log('%c Error! ', 'background: red; color: #bada55', error);
  }
}

export function getVehicle(data?: GetClientByIdQuery): VehiclesType[] | undefined {
  return data?.usersPermissionsUser?.data?.attributes?.customer_vehicles?.data.map(item => {
    const car = item.attributes?.car?.data;
    const modificationCar = `${
      car?.attributes?.car_locales?.data[0].attributes?.constructionType || ''
    } ${car?.attributes?.car_locales?.data[0].attributes?.name || ''} (${
      car?.attributes?.car_locales?.data[0].attributes?.fuelType || ''
    } ${((car?.attributes?.cylinderCapacityLiter || 0) / 100).toFixed(2)}L ${
      car?.attributes?.powerHpTo ? `${(+car?.attributes?.powerHpTo / 1.36).toFixed(0)}kW)` : ''
    }`;

    let carModelID = '';
    if (
      item.attributes?.car?.data?.attributes?.car_model?.data?.attributes?.car_model_locales
        ?.data[0].attributes?.name
    ) {
      carModelID =
        item.attributes.car.data.attributes.car_model.data.attributes.car_model_locales.data[0].attributes.name.split(
          ' '
        )[0];
    }

    return {
      id: item?.id || '',
      carID: item.attributes?.car?.data?.id || '',
      tecdoc_carType: item.attributes?.car?.data?.attributes?.tecdoc_carType || '',
      tecdoc_modelId: item.attributes?.car?.data?.attributes?.tecdoc_modelId || '',
      tecdoc_carId: item.attributes?.car?.data?.attributes?.tecdoc_carId || '',
      modificationCar,
      yearOfManufacture: item.attributes?.yearOfManufacture || 0,
      vinCode: item.attributes?.vinCode || '',
      carModel:
        item.attributes?.car?.data?.attributes?.car_model?.data?.attributes?.car_model_locales
          ?.data[0].attributes?.name || '',
      carBodyType:
        item.attributes?.car?.data?.attributes?.car_locales?.data[0].attributes?.constructionType ||
        '',
      carBrand:
        item.attributes?.car?.data?.attributes?.car_brand?.data?.attributes?.car_brand_locales
          ?.data[0].attributes?.name || '',
      carBrandID:
        item.attributes?.car?.data?.attributes?.car_brand?.data?.attributes?.tecdoc_manuId || '',
      carModelID,
      carEngine: '',
      carEngineID: '',
      carBodyTypeID: '',
    };
  });
}

export const getImageUrl = (url: string) => {
  if (url.includes(AUTO_BRO_BUNNY_API)) {
    return url;
  } else {
    return `${AUTO_BRO_API}${url}`;
  }
};

export const getOrdersSearchFilter = (searchText: string) => {
  let cleanedSearchText = searchText.trim().replace(/\s+/g, '');

  if (cleanedSearchText.startsWith('+')) {
    cleanedSearchText = cleanedSearchText.substring(1);
  }

  const filters: OrderFiltersInput | undefined = {};

  filters['or'] = [
    {
      id: { containsi: cleanedSearchText },
    },
    {
      orderNumber: { containsi: cleanedSearchText },
    },
    {
      customer: {
        customer_contact_info: {
          or: [
            { phoneNumbers: { number: { containsi: cleanedSearchText } } },
            { firstName: { containsi: searchText } },
            { lastName: { containsi: searchText } },
            { user: { id: { containsi: cleanedSearchText } } },
          ],
        },
      },
    },
    {
      customer: {
        username: { containsi: searchText },
      },
    },
  ];

  return Object.keys(filters).length > 0 ? filters : undefined;
};

export const getClientsSearchFilter = (searchText: string): FiltersType | undefined => {
  let cleanedSearchText = searchText.trim().replace(/\s+/g, '');

  if (cleanedSearchText.startsWith('+')) {
    cleanedSearchText = cleanedSearchText.substring(1);
  }

  const strings = /[^0-9]/.test(searchText);
  const numbers = /[0-9]/.test(cleanedSearchText);

  if (strings && !numbers) {
    return { searchStrings: searchText };
  } else if (!strings && numbers) {
    return { searchNumbers: cleanedSearchText };
  } else if (strings && numbers) {
    return {
      searchNumbers: cleanedSearchText,
      searchStrings: searchText,
    };
  }

  return undefined;
};

export const getFittingPosition = (
  criteriaArray: {
    criteriaDescription: string;
    formattedValue: string;
  }[]
) => {
  const fitPos =
    criteriaArray?.length &&
    criteriaArray
      .filter(
        criteria =>
          criteria.criteriaDescription === 'Fitting Position' ||
          criteria.criteriaDescription === 'Einbauseite'
      )
      .map(item => item.formattedValue?.charAt(0).toUpperCase() + item.formattedValue?.slice(1))
      .join(', ');
  return fitPos ? fitPos : '';
};

export const getAddress = (address: any) => {
  if (Array.isArray(address)) {
    return address
      .map((i: { zipCode: any; city: any; street: any; number: any }) => {
        const street = i?.street ? i?.street.split(', ')[0] : '';
        return `${i?.zipCode} ${i?.city} ${street} ${i.number ? i?.number : ''}`;
      })
      .join(', ');
  }
  return '';
};
export const getPhone = (phone: any) => {
  if (phone?.length) {
    return phone
      .map((i: { number: any }) => {
        return i.number;
      })
      .join(', ');
  }
  return 'N/A';
};

export const isNumber = (str: string): boolean => {
  return !isNaN(Number(str)) && !isNaN(parseFloat(str));
};

export const formatPhoneNumber = (number: string | null | undefined) => {
  if (!number) return;

  const cleaned = number.replace(/\D/g, '');
  const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{3})(\d*)$/);
  if (match) {
    return `+${match[1]} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
  }
  return number;
};

export const formatCurrencyAmount = (amount: string) => amount.replace('€', '').replace(',', '.');
