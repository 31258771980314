import React, { FC } from 'react';
import Fade from '@mui/material/Fade';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Modal, BoxProps, IconButton, Stack } from '@mui/material';

import { Icon } from '../legos';
import { TranslatedField } from './Layout/components/TranslatedField/TranslatedField';

interface Props extends BoxProps {
  title: string;
  open: boolean;
  bgcolor?: string;
  width?: number | string;
  handleClose: () => void;
  handleComeBack?: () => void;
  children?: JSX.Element[] | JSX.Element;
}

const style = {
  p: 4,
  top: '50%',
  width: 400,
  left: '50%',
  boxShadow: 24,
  borderRadius: 2,
  position: 'absolute' as const,
  transform: 'translate(-50%, -50%)',
};

export const CustomModal: FC<Props> = ({
  open,
  title,
  children,
  width = 400,
  handleClose,
  handleComeBack,
  bgcolor = 'background.paper',
  ...props
}) => {
  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      closeAfterTransition
      sx={{
        mx: 7,
      }}
    >
      <Fade in={open}>
        <Box bgcolor={bgcolor} sx={{ ...style, width }} {...props}>
          <Stack
            flexGrow={1}
            direction="row"
            marginBottom={1}
            alignContent="center"
            justifyContent="space-between"
          >
            {handleComeBack && (
              <IconButton onClick={handleComeBack}>
                <ArrowBackIcon />
              </IconButton>
            )}
            <Box>
              <TranslatedField
                isTranslate
                variant="h5"
                color="black"
                marginTop="3px"
                originText={title}
              />
            </Box>
            <IconButton onClick={() => handleClose()}>
              <Icon icon="close" color="inherit" />
            </IconButton>
          </Stack>
          <Box></Box>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
};
