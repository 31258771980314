import { ComponentClientDeliveryAddress, Enum_Order_Status } from '../__generated__/types';

export enum LocalStorageKey {
  JWT = 'JWT',
  LANG = 'LANG',
  ROLE = 'ROLE',
}

export type LocalStorageKeyNew = 'JWT' | 'LANG' | 'ROLE';
export interface VehiclesType {
  id?: string;
  carID: string;
  tecdoc_modelId: string;
  tecdoc_carId: string;
  tecdoc_carType: string;
  modificationCar: string;
  yearOfManufacture?: number | null;
  carModel?: string;
  carModelID?: string;
  carBodyType?: string;
  carBodyTypeID?: string;
  carBrand?: string;
  carBrandID?: string;
  carEngine?: string;
  carEngineID?: string;
  vinCode?: string;
}
export interface OrderItemType {
  articleCriteria?: any;
  barcode?: string | null;
  eanNumber?: string | null;
  productName?: string | null;
  deliveryDate?: string | null;
  delivers?: string | null;
  provider?: string | null;
  providerPrice?: number | null;
  customerPrice?: number | null;
  createdAt?: string | null;
  article?: string | null;
  discount?: number | null;
  id?: string | null;
  carSpareId?: string | null;
  orderId?: string;
  quantity?: number;
  typeClient?: string;
  orderNumber?: string;
  balanceQuantity?: number;
  outgoingQuantity?: number;
  pickedUpQuantity?: number;
  deliveryQuantity?: number;
  confirmedQuantity?: number;
  statusOrder?: Enum_Order_Status;
  isOrderConfirmed?: boolean | null;
  isOrdered?: boolean | null;
  brandName?: string | null;
  images?: string | Array<{ [key: string]: string }> | null;
  order?: [];
  deliveryAddress?: ComponentClientDeliveryAddress | null;
  firstName?: string;
  lastName?: string;
  customerPhone?: string;
  customerId?: string;
  phoneNumber?: string;
  lockForReturn?: boolean;
  comment?: string;
  autoOrderLastError?: string | null;
}
export interface DataModalType<T> {
  isOpen: boolean;
  selProduct: T | null;
}
