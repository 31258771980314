import React from 'react';
import { styled } from '@mui/material';

import { TimePointButtonIconProps, TimePointStatusColor } from './types';

const TimePointButtonIconRoot = styled('button')<TimePointButtonIconProps>(
  ({ statuses: { isActive, isCompleted, isSkipped }, disabled }) => ({
    width: 28,
    height: 28,
    cursor: disabled ? 'auto' : 'pointer',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '100%',
    background: 'transparent',
    borderColor: isSkipped
      ? TimePointStatusColor.Skipped
      : isActive
      ? TimePointStatusColor.Active
      : TimePointStatusColor.Completed,
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      width: 20,
      height: 20,
      position: 'absolute',
      top: 3,
      left: 3,
      backgroundColor: isSkipped
        ? TimePointStatusColor.Skipped
        : isActive
        ? TimePointStatusColor.Active
        : isCompleted
        ? TimePointStatusColor.Completed
        : '',
      borderRadius: '100%',
    },
  })
);

export const TimePointButtonIcon: React.FC<TimePointButtonIconProps> = ({ disabled, ...rest }) => {
  return (
    <TimePointButtonIconRoot className="TimePointButtonIcon-root" disabled={disabled} {...rest} />
  );
};
