import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Fade,
  IconButton,
  Modal,
  Stack,
  Typography,
} from '@mui/material';

import { StocksTable } from './StocksTable';
import { useGetStocks } from './hooks/useGetStocks';
import { Icon } from '../../../../../../../../legos';
import { theme } from '../../../../../../../../helpers';
import { useLocalization } from '../../../../../../../../localization';
import { useHandlerNotificationApp } from '../../../../../../../../hooks/useHandlerNotificationApp';
import { TranslatedField } from '../../../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useUpdateIncomingInvoiceMutation } from '../../../../../../../../graphql/mutations/__generated__/updateIncomingInvoice';

interface Props {
  invoiceId: string;
  idSupplier: string;
  isModalOpen: boolean;
  handleClose: () => void;
  incomingInvoiceRefetch: () => void;
  incomingInvoiceIds: string[];
}

export const AddProductsModal: FC<Props> = ({
  isModalOpen,
  idSupplier,
  invoiceId,
  incomingInvoiceIds,
  handleClose,
  incomingInvoiceRefetch,
}) => {
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();

  const [updateIncomingInvoiceMutation] = useUpdateIncomingInvoiceMutation();

  const { itemsStock, loading } = useGetStocks({
    filters: {
      supplier: {
        id: { eq: idSupplier },
      },
    },
  });

  const itemsStockFiltered = itemsStock.filter(
    item => !incomingInvoiceIds.includes(item.productStockId) && !item.incomingInvoice
  );

  const [allChecked, setAllChecked] = useState(false);

  const [items, setItems] = useState(itemsStockFiltered);
  const [stocksIds, setStocksIds] = useState<string[] | []>([]);

  useEffect(() => {
    setItems(itemsStockFiltered);
  }, [loading]);

  const toggleChecked = () => {
    const newItems = items?.map(item => ({
      ...item,
      checked: !allChecked,
    }));
    const newStockIds = newItems.filter(item => item.checked).map(item => item.productStockId);
    setAllChecked(!allChecked);
    setItems(newItems);
    setStocksIds(newStockIds);
  };

  const handleCheckedItem = (id: string) => {
    const newItems = items?.map(item => ({
      ...item,
      checked: item.productStockId === id ? !item.checked : item.checked,
    }));
    const newStockIds = newItems.filter(item => item.checked).map(item => item.productStockId);

    setAllChecked(false);
    setItems(newItems);
    setStocksIds(newStockIds);
  };

  const handleUpdateIncomingInvoices = () => {
    updateIncomingInvoiceMutation({
      variables: {
        data: { stocks: [...incomingInvoiceIds, ...stocksIds] },
        id: invoiceId,
      },
    })
      .then(() => {
        addNotification({
          messageError: translateLang('invoiceSuccessfullyUpdated'),
          typeMessage: 'success',
        });
        handleClose();
        incomingInvoiceRefetch();
      })
      .catch(err => {
        addNotification({ messageError: err.message, typeMessage: 'error' });
      });
  };

  return (
    <Modal open={true} onClose={handleClose} closeAfterTransition>
      <Fade in={isModalOpen}>
        <Stack
          bgcolor={theme.palette.common.lightGrey}
          sx={{
            p: 4,
            top: '50%',
            width: 1000,
            left: '50%',
            boxShadow: 24,
            borderRadius: 2,
            position: 'absolute' as const,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Stack alignItems="center">
            <Stack
              width="100%"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography fontSize={30} fontWeight={400} color={theme.palette.common.darkBlue}>
                {translateLang('addProducts')}
              </Typography>

              <IconButton onClick={handleClose} sx={{ p: 0 }}>
                <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
              </IconButton>
            </Stack>

            {!loading ? (
              <StocksTable
                items={items}
                allChecked={allChecked}
                toggleChecked={toggleChecked}
                handleCheckedItem={handleCheckedItem}
              />
            ) : (
              <Stack width="100%" alignItems="center" mt={10}>
                <CircularProgress size={40} />
              </Stack>
            )}

            <Button
              disabled={stocksIds.length <= 0}
              variant="contained"
              fullWidth
              sx={{
                height: 50,
                maxWidth: 320,
                p: 2,
                mt: 3,
                textTransform: 'none',
                backgroundColor: '#5269A3',
                borderRadius: '19px',
                '&:disabled': {
                  color: theme.palette.common.white,
                },
              }}
              onClick={handleUpdateIncomingInvoices}
            >
              <TranslatedField originText={'addProducts'} fontSize={16} isTranslate noWrap />
            </Button>
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
};
