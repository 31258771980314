import React, { FC, useState } from 'react';
import { Stack } from '@mui/system';

import { CustomModal } from '../../../../components';
import { useGetSearchProductLazyQuery } from '../../../../graphql/queries/__generated__/getSearchProducts';
import { SearchedContent } from './SearchedContent';
import { handlerError } from '../../../../helpers/functions';
import { useAddStockFromOrderItemMutation } from '../../../../graphql/mutations/__generated__/addStockFromOrderItem';
import { GetOrderItemsDocument } from '../../../../graphql/queries/__generated__/getOrderItems';
import { getVarOrderItems } from '../../../../graphql/queries/hook/useGetOrderItems';
import { SelectProviderComponent } from './SelectProviderComponent';
import { AddStockInput } from '../../../../__generated__/types';
import { SEARCH } from '../../types';
import { ExtendedSearchProductsResult, SearchedContentNotOrder } from './SearchedContentNotOrder';
import { useAddStockItemMutation } from '../../../../graphql/mutations/__generated__/addStockItem';
import { useLocalization } from '../../../../localization';
import { ScanComponent } from '../ScanComponent';
import { NonSearched } from '../NonSearched';
import { AssignBarCodeComponent } from '../AssignBarCodeComponent';

interface Props {
  open: boolean;
  handleClose: () => void;
}
type MODE =
  | 'inProcess'
  | 'selectProvider'
  | 'searched'
  | 'nonSearched'
  | 'searchedNotOrder'
  | 'assignBarcode';

export const ScanModal: FC<Props> = ({ open, handleClose }) => {
  const { updateSelectLanguage } = useLocalization();
  const [codeSearch, setCodeSearch] = useState<MODE>('inProcess');
  const [addStockFromOrderItemMutation, { data: dataItemOrder, loading: loadAddStock }] =
    useAddStockFromOrderItemMutation();
  const [addStockItemMutation, { data: dataItemStock }] = useAddStockItemMutation();
  const [runGetSearchProduct, { data: dataSearchProduct, loading: loadGetProduct }] =
    useGetSearchProductLazyQuery();
  const [currentEanNumber, setCurrentEanNumber] = useState('');
  const [modeAddStock, setModeAddStock] = useState<SEARCH>('withOrder');

  const changeMode = async (eanNumber: string) => {
    // console.log(eanNumber);
    try {
      const response = await runGetSearchProduct({
        variables: { input: { eanNumber, locale: updateSelectLanguage } },
        fetchPolicy: 'network-only',
      });
      if (response.data?.searchProducts?.length && response.data.searchProducts.length === 1) {
        const item = response.data.searchProducts[0];
        if (item?.orderId) {
          await runAddStockOrderItem(item?.orderId, item?.orderItemId);
        } else {
          setCurrentEanNumber(eanNumber);
          setCodeSearch('searchedNotOrder');
        }
      } //
      else if (response.data?.searchProducts?.length && response.data.searchProducts.length > 1) {
        if (response.data.searchProducts[0]?.orderId) {
          setCodeSearch('selectProvider');
        } else {
          setCurrentEanNumber(eanNumber);
          setCodeSearch('searchedNotOrder');
        }
      } //
      else if (!response.data?.searchProducts?.length) {
        setCurrentEanNumber(eanNumber);
        setCodeSearch('assignBarcode');
      }
    } catch (err: unknown) {
      handlerError(err);
      handleClose();
    }
  };
  const addStockOrderItems = async (index: number) => {
    if (dataSearchProduct?.searchProducts?.length) {
      const item = dataSearchProduct.searchProducts[index];
      await runAddStockOrderItem(item?.orderId, item?.orderItemId);
    }
  };

  async function runAddStockOrderItem(orderId?: string | null, orderItemId?: string | null) {
    try {
      const resp = await addStockFromOrderItemMutation({
        variables: {
          input: {
            orderId: orderId || '',
            orderItemId: orderItemId || '',
            quantity: 1,
            locale: updateSelectLanguage,
          },
        },
        refetchQueries: [
          {
            query: GetOrderItemsDocument,
            variables: getVarOrderItems(updateSelectLanguage),
          },
        ],
      });
      if (resp.data?.addStockFromOrderItem?.orderData?.orderId) {
        setModeAddStock('withOrder');
        setCodeSearch('searched');
      } else {
        setCodeSearch('nonSearched');
      }
    } catch (err: unknown) {
      handlerError(err);
      setCodeSearch('nonSearched');
    }
  }

  async function runAddStockItem(input: AddStockInput) {
    try {
      const resp = await addStockItemMutation({
        variables: {
          input,
        },
      });
      if (resp.data?.addStock?.stockData) {
        setModeAddStock('withoutOrder');
        setCodeSearch('searched');
      } else {
        setCodeSearch('nonSearched');
      }
    } catch (err: unknown) {
      handlerError(err);
      setCodeSearch('nonSearched');
    }
  }

  return (
    <CustomModal
      title=""
      handleClose={handleClose}
      handleComeBack={codeSearch !== 'inProcess' ? () => setCodeSearch('inProcess') : undefined}
      open={open}
      bgcolor="#F1F3F8"
      width="900px"
      height="650px"
      display="flex"
      flexDirection="column"
    >
      <Stack textAlign="center" width="100%" height="100%" overflow="hidden">
        {codeSearch === 'inProcess' ? (
          <ScanComponent
            isOpen={codeSearch === 'inProcess'}
            onChange={changeMode}
            loading={loadGetProduct || loadAddStock}
          />
        ) : null}
        {codeSearch === 'selectProvider' ? (
          <SelectProviderComponent
            handlerSelectedProvider={addStockOrderItems}
            isOpen={codeSearch === 'selectProvider'}
            searchProducts={dataSearchProduct?.searchProducts}
          />
        ) : null}
        {codeSearch === 'searched' ? (
          <SearchedContent
            isOpen={codeSearch === 'searched'}
            dataProduct={
              modeAddStock === 'withOrder'
                ? dataItemOrder?.addStockFromOrderItem
                : dataItemStock?.addStock
            }
            handleClose={handleClose}
          />
        ) : null}
        {codeSearch === 'nonSearched' ? (
          <NonSearched
            isOpen={codeSearch === 'nonSearched'}
            handleClose={handleClose}
            handleClickButton={changeMode}
          />
        ) : null}
        {codeSearch === 'searchedNotOrder' ? (
          <SearchedContentNotOrder
            isTitle
            isOpen={codeSearch === 'searchedNotOrder'}
            addInStockWithoutOrder={runAddStockItem}
            dataProduct={dataSearchProduct?.searchProducts as ExtendedSearchProductsResult[]}
            eanNumber={currentEanNumber}
          />
        ) : null}
        {codeSearch === 'assignBarcode' ? (
          <AssignBarCodeComponent
            isOpen={codeSearch === 'assignBarcode'}
            eanNumber={currentEanNumber}
            onChange={() => setCodeSearch('inProcess')}
          />
        ) : null}
      </Stack>
    </CustomModal>
  );
};
