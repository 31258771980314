import React, { useState } from 'react';

import { useLocalization } from '../../localization';
import ProductCatalog from './components/ProductCatalog';
import { TabsContainer } from './components/TabsContainer';
import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { AddEditProductForm } from './components/AddEditProductForm';

export const ManagerPanelPage = () => {
  const { translateLang } = useLocalization();

  const [isAddProductOpen, setIsAddProductOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<string | null>('productCatalog');

  const [productId, setProductId] = useState<string | null>(null);

  const getActiveTab = () => {
    switch (activeTab) {
      case 'productCatalog':
        return <ProductCatalog handleProductId={handleProductId} />;

      default:
        return <ProductCatalog handleProductId={handleProductId} />;
    }
  };

  const handleTabChange = (val?: string) => {
    if (val) {
      setActiveTab(val);
    }
  };

  const handleOpenAddProduct = () => {
    setIsAddProductOpen(true);
  };
  const handleCloseAddProduct = () => {
    setIsAddProductOpen(false);
    setProductId(null);
  };

  const handleProductId = (id: string) => {
    setProductId(id);
  };

  return (
    <>
      {isAddProductOpen || productId ? (
        <AddEditProductForm handleCloseAddProduct={handleCloseAddProduct} productId={productId} />
      ) : (
        <WrapperPages isButton={false} titleHeader={translateLang('managerPanel')}>
          <TabsContainer
            activeTab={activeTab}
            handleTabChange={handleTabChange}
            handleOpenAddProduct={handleOpenAddProduct}
          />
          {getActiveTab()}
        </WrapperPages>
      )}
    </>
  );
};
