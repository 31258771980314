import React from 'react';
import { Stack } from '@mui/material';
import { useGetIsMobileQuery } from '../../../graphql/cacheQuery/__generated__/getIsMobile';
import { InProgressOrderDetailBlockInfoDesktop } from './OrderHistory/components/InProgressOrderDetailBlockInfoDesktop';
import { ComletedOrderDetailBlockInfoDesktop } from './OrderHistory/components/ComletedOrderDetailBlockInfoDesktop';
import { InProgressOrderDetailBlockInfoPhone } from './OrderHistory/components/InProgressOrderDetailBlockInfoPhone';

export const OrdersHistory = () => {
  const { data } = useGetIsMobileQuery();
  const isMobile = data?.isMobile ?? false;

  return (
    <>
      <Stack width="100%" marginBottom="50px">
        {isMobile ? (
          <InProgressOrderDetailBlockInfoPhone isMobile={isMobile} />
        ) : (
          <>
            <InProgressOrderDetailBlockInfoDesktop isMobile={isMobile} />
            <ComletedOrderDetailBlockInfoDesktop />
          </>
        )}
      </Stack>
    </>
  );
};
