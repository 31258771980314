import { useEffect } from 'react';
import { useGetCarBrandsQuery } from '../__generated__/getCarBrends';
import { useLocalization } from '../../../localization';
import { useGetCarModelsGroupedLazyQuery } from '../__generated__/getCarModelsGrouped';
import { useGetCarsLazyQuery } from '../__generated__/getCars';

export interface CarCustomerType {
  id: string | null;
  name?: string;
  type?: string;
  tecdoc_modelId?: string;
  year?: number;
}
interface Props {
  brandId?: string;
  modalId?: string;
  tecdoc_modelId?: string;
}
interface ReturnHook {
  brands?: CarCustomerType[];
  models?: CarCustomerType[];
  modificationsCar: CarCustomerType[];
  yearsManufacture: CarCustomerType[];
  loadingBrands?: boolean;
  loadingModelsGrouped?: boolean;
  loadingDataCars?: boolean;
}

export const useGetCustomerCar = ({ brandId, modalId, tecdoc_modelId }: Props): ReturnHook => {
  const { updateSelectLanguage } = useLocalization();
  const locale = updateSelectLanguage as string;

  const { data: dataBrands, loading: loadingBrands } = useGetCarBrandsQuery({
    variables: {
      pagination: { limit: -1 },
      sort: ['car_brand_locales.name'],
      filters: { tecdoc_linkingTargetTypes: { eq: 'P' } },
      locale,
    },
  });
  const [runGetDataModelsGrouped, { data: dataModelsGrouped, loading: loadingModelsGrouped }] =
    useGetCarModelsGroupedLazyQuery();
  const [runGetCars, { data: dataCars, loading: loadingDataCars }] = useGetCarsLazyQuery();

  const getBrands = (): CarCustomerType[] => {
    if (dataBrands?.carBrands?.data?.length) {
      return dataBrands.carBrands.data.map(item => {
        return {
          id: item?.attributes?.tecdoc_manuId || '',
          name: item?.attributes?.car_brand_locales?.data[0].attributes?.name || '',
          type: item?.attributes?.tecdoc_linkingTargetTypes || '',
        };
      });
    }
    return [];
  };
  const getModelsGrouped = (): CarCustomerType[] => {
    if (dataModelsGrouped?.carModelsGrouped?.length) {
      return dataModelsGrouped?.carModelsGrouped.map(item => {
        return {
          id: item?.shortName || '',
          name: item?.shortName || '',
        };
      });
    }
    return [];
  };
  const getYearsManufacture = (): CarCustomerType[] => {
    if (dataModelsGrouped?.carModelsGrouped?.length && modalId) {
      const year = new Date().getFullYear();
      const currentCarModels = dataModelsGrouped.carModelsGrouped.find(
        item => item?.shortName === modalId
      );
      if (!currentCarModels?.yearTo && !currentCarModels?.yearFrom) {
        // console.log(currentCarModels?.models);
        if (currentCarModels?.models?.length) {
          return [
            {
              id: '0',
              name: 'Full years',
              tecdoc_modelId: currentCarModels.models[0]?.tecdoc_modelId || '',
            },
          ];
        }
        return [];
      }
      if (currentCarModels.models?.length) {
        // console.log(currentCarModels.models);
        const years: CarCustomerType[] = [];
        currentCarModels.models.map(item => {
          const from = item?.yearFrom || year;
          const to = item?.yearTo || year;
          for (let i = from; i <= to; i++) {
            years.push({
              id: `${i} (${item?.name})`,
              name: `${i} (${item?.name})`,
              tecdoc_modelId: item?.tecdoc_modelId || '',
              year: i,
            });
          }
        });
        years.sort((a, b) => +(b?.year || 0) - +(a.year || 0));
        return years;
      }
    }
    return [];
  };
  const getModificationsCars = (): CarCustomerType[] => {
    if (dataCars?.cars?.data.length) {
      return dataCars.cars.data
        .map(item => {
          const constructionType =
            item?.attributes?.car_locales?.data[0].attributes?.constructionType || '';
          const name = item?.attributes?.car_locales?.data[0].attributes?.name || '';
          const fuelType = item?.attributes?.car_locales?.data[0].attributes?.fuelType || '';
          // console.log(fuelType);
          let type = 5;
          if (fuelType.toLowerCase() === 'diesel') {
            type = 1;
          } else if (fuelType.toLowerCase().includes('diesel/')) {
            type = 2;
          } else if (fuelType.toLowerCase() === 'petrol') {
            type = 3;
          } else if (fuelType.toLowerCase().includes('petrol/')) {
            type = 4;
          }
          return {
            id: item.id || '',
            name: `${constructionType} ${name} (${fuelType} ${(
              (item.attributes?.cylinderCapacityLiter || 0) / 100
            ).toFixed(2)}L ${
              item.attributes?.powerHpTo
                ? `${(+item.attributes?.powerHpTo / 1.36).toFixed(0)}kW)`
                : ''
            }`,
            type: type.toString(),
          };
        })
        .sort((a, b) => {
          return +a.type - +b.type;
        });
    }
    return [];
  };

  useEffect(() => {
    if (brandId) {
      runGetDataModelsGrouped({
        variables: {
          locale: locale,
          brandId: brandId,
        },
      }).then();
    }
  }, [brandId]);
  useEffect(() => {
    if (tecdoc_modelId) {
      runGetCars({
        variables: {
          locale: locale,
          filters: {
            tecdoc_modelId: {
              eq: tecdoc_modelId,
            },
          },
        },
      }).then();
    }
  }, [tecdoc_modelId]);

  return {
    brands: getBrands(),
    models: getModelsGrouped(),
    modificationsCar: getModificationsCars(),
    yearsManufacture: getYearsManufacture(),
    loadingBrands,
    loadingModelsGrouped,
    loadingDataCars,
  };
};
