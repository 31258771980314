import React, { ReactNode } from 'react';
import { Stack, TableCellProps } from '@mui/material';

import { useLocalization } from '../../../../../localization';
import { SupplierBalanceRowItem } from '../SupplierBalanceRowItem';
import { HeaderCell } from '../../../../../components/TableProduct/TableProduct';
import { SupplierTransactionEntity } from '../../../../../__generated__/types';

interface HookProps {
  transactions?: SupplierTransactionEntity[];
  action: (transaction: SupplierTransactionEntity) => void;
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): Array<ReactNode>;
}

export const useGetSupplierBalanceTable = ({ transactions, action }: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();
  const getHeadersTable = (): Array<HeaderCell> => {
    const style: TableCellProps = { color: '#F1F3F8', align: 'left' };
    return [
      {
        title: translateLang('documentNumber'),
        containerProps: { ...style, align: 'center', width: '40px' },
      },
      {
        title: translateLang('date'),
        containerProps: { ...style, align: 'center', width: '140px' },
      },
      {
        title: (
          <Stack>
            <div>{translateLang('sum')}</div>
            <div>({translateLang('debt/remainingAmount')})</div>
          </Stack>
        ),
        containerProps: { ...style, align: 'center', width: '240px' },
      },

      {
        title: translateLang('type'),
        containerProps: { ...style, align: 'center', width: '140px' },
      },
      {
        title: translateLang('comment'),
        containerProps: { ...style, align: 'center', width: '240px' },
      },
      {
        title: translateLang('action'),
        containerProps: { ...style, align: 'center', width: '40px' },
      },
    ];
  };

  const getRowsTableActive = (): ReactNode[] => {
    if (transactions && transactions?.length) {
      return transactions?.map(item => {
        return (
          <SupplierBalanceRowItem key={`client-${item.docId}`} transaction={item} action={action} />
        );
      });
    }
    return [];
  };

  return {
    getHeadersTable,
    getRowsTableActive,
  };
};
