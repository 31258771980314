/* eslint-disable no-console */
import React, { FC } from 'react';
import { Box } from '@mui/material';
import { OrderItemWarehouseType } from '../../../../graphql/queries/hook/useGetOrdersWarehouse';
import { useGetWarehouseOrdersTable } from './hooks/useGetWarehouseOrdersTable';
import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';
interface Props {
  totalPage: number;
  orderItems?: OrderItemWarehouseType[];
  currentPage?: number;
  changePage(page: number): void;
  loading?: boolean;
}

export const CompletedOrderTabTable: FC<Props> = ({
  orderItems,
  totalPage,
  currentPage,
  changePage,
  loading,
}) => {
  const { getHeadersTable, getRowsTableActive } = useGetWarehouseOrdersTable({
    orderItems,
  });

  return (
    <>
      <Box boxSizing="border-box" width="100%">
        <TableProductActive
          startPage={currentPage}
          totalPages={totalPage}
          itemsRows={getRowsTableActive()}
          itemsHeader={getHeadersTable()}
          changeCurrentPage={changePage}
          loading={loading}
        />
      </Box>
    </>
  );
};
