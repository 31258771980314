import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Box } from '@mui/material';
import { HeaderCell } from '../../../../../components/TableProductActive/TableProductActive';
import { BoxCell, TableCellsValue } from '../../../../../components/TableProduct/TableProduct';
import { useGetOrderItems } from '../../../../../graphql/queries/hook/useGetOrderItems';
import { useLocalization } from '../../../../../localization';
import { usePreDeliveryStateQuery } from '../../../../../graphql/cacheQuery/__generated__/preDeliveryState';
import { GetOrderItemsQueryVariables } from '../../../../../graphql/queries/__generated__/getOrderItems';
import { Enum_Mode_Delivery_State } from '../../../../../__generated__/types';
import { OrderItemType } from '../../../../../helpers/types';
import { PrinterButton } from '../../PrinterButton';
import { SelectItemProductInfo } from '../../../types';
import { ToolTypeComment } from '../../../../../components/ToolTypeComment';

interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTable(): Array<TableCellsValue>;
  loading: boolean;
  refetchOrderItems?: () => void;
  variablesQuery?: GetOrderItemsQueryVariables;
  isComplectedToDelivery: boolean;
  getOrderItemByBarcode(barcode: string): OrderItemType | undefined;
}
type SelectProductInfoType = Dispatch<SetStateAction<SelectItemProductInfo | null>>;

export const useGetPreDeliveryOrderItems = (
  setSelectProductInfo: SelectProductInfoType
): ReturnHook => {
  const { data: dataPreDeliveryState } = usePreDeliveryStateQuery();
  const { translateLang } = useLocalization();
  const {
    orderItems,
    loading,
    variablesQuery,
    refetch: refetchOrderItems,
  } = useGetOrderItems(dataPreDeliveryState?.preDeliveryState.idOrder || '');

  const isWarehouseParent =
    dataPreDeliveryState?.preDeliveryState.callParent === Enum_Mode_Delivery_State.Warehouse;
  const quantityEquals: keyof OrderItemType = isWarehouseParent
    ? 'outgoingQuantity'
    : 'deliveryQuantity';

  const getLastField = (item: OrderItemType) => {
    if (dataPreDeliveryState?.preDeliveryState.isInfoAndPrint) {
      return (
        <Box>
          <PrinterButton
            isLabel={true}
            disabled={!item?.barcode}
            handleClick={() => {
              setSelectProductInfo({
                barcode: item?.barcode ?? '',
                titleProduct: item?.productName ?? '',
                articleNumber: item?.article ?? '',
              });
            }}
          />
        </Box>
      );
    }
    return `${translateLang('formed')} ${item.pickedUpQuantity}/${item[quantityEquals]}`;
  };

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      { title: translateLang('status'), containerProps: { width: '40px', align: 'center' } },
      { title: '№', containerProps: { width: '20px', align: 'center' } },
      { title: translateLang('article'), containerProps: { align: 'center' } },
      { title: translateLang('productName'), containerProps: { width: '160px', align: 'center' } },
      { title: translateLang('provider'), containerProps: { align: 'center' } },
      { title: translateLang('client'), containerProps: { width: '60px', align: 'center' } },
      { title: translateLang('orders'), containerProps: { width: '60px', align: 'center' } },
      {
        title: translateLang('productComment'),
        containerProps: { width: '160px', align: 'center' },
      },
      { title: translateLang(''), containerProps: { width: '130px', align: 'center' } },
    ];
  };
  const getRowsTable = (): Array<TableCellsValue> => {
    const heightRow = dataPreDeliveryState?.preDeliveryState.isInfoAndPrint ? 'auto' : '30px';
    if (orderItems && orderItems.length) {
      return orderItems.map((item, index) => {
        const isFormed = item.pickedUpQuantity === item[quantityEquals];
        const values: Array<BoxCell> = [];
        values.push({
          val: (
            <Box color={isFormed ? '#97A408' : '#8D8D8D'}>
              <CheckBoxIcon />
            </Box>
          ),
          containerProps: { height: heightRow, borderLeft: 'none' },
        });
        values.push({ val: index + 1, containerProps: { height: heightRow } });
        values.push({ val: item.article, containerProps: { height: heightRow } });
        values.push({ val: item.productName, containerProps: { height: heightRow } });
        values.push({ val: item.provider, containerProps: { height: heightRow } });
        values.push({
          val: `#${dataPreDeliveryState?.preDeliveryState.idUser}`,
          containerProps: { height: heightRow },
        });
        values.push({
          val: `#${dataPreDeliveryState?.preDeliveryState.orderNumber}`,
          containerProps: { height: heightRow },
        });
        values.push({
          val: <ToolTypeComment comment={item?.comment} width="160px" />,
        });
        values.push({
          val: getLastField(item),
          containerProps: { height: heightRow, color: isFormed ? '#97A408' : '#F44336' },
        });
        return { cellsValues: values };
      });
    }
    return [];
  };
  const isComplectedToDelivery = useMemo(() => {
    if (orderItems && orderItems.length) {
      let result = true;
      for (let i = 0; i < orderItems.length; i++) {
        if (orderItems[i].pickedUpQuantity !== orderItems[i][quantityEquals]) {
          result = false;
          break;
        }
      }
      return result;
    }
    return false;
  }, [orderItems]);

  const getOrderItemByBarcode = useCallback(
    (barcode: string) => {
      const item = orderItems?.find(item => item?.barcode === barcode);
      return item;
    },
    [orderItems]
  );

  return {
    isComplectedToDelivery,
    variablesQuery,
    loading,
    refetchOrderItems,
    getRowsTable,
    getHeadersTable,
    getOrderItemByBarcode,
  };
};
