/* eslint-disable no-underscore-dangle */
import { InMemoryCache } from '@apollo/client';
export const apolloCache = new InMemoryCache({
  /*typePolicies: {
    BannerEntityResponseCollection: {
      fields: {
        data: {
          /!*merge(existing = [], incoming: any[]) {
            return [...existing, ...incoming];
          },*!/
        },
      },
    },
    BannerEntity: {
      merge: true,
    },
  },*/
});
