import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import {
  BoxCell,
  HeaderCell,
  TableCellsValue,
  TableProduct,
} from '../../../components/TableProduct/TableProduct';
import { useNavigate } from 'react-router-dom';

import { useLocalization } from '../../../localization';
import { RemoveRedEyeOutlined } from '@mui/icons-material/';
import { DeleteModal } from '../../ClientInfo/components/Modals/DeleteModal';
import { OrdersType, PaginationType } from '../../../graphql/queries/hook/useGetOrders';
import { PatchesName } from '../../../types/types';

interface Props {
  pageCount: number;
  orders?: OrdersType[];
  pagination: PaginationType | undefined;
  setPage?: Dispatch<SetStateAction<number>>;
  changePage?: (val: number) => void;
  loadingOrders?: boolean;
}

export const TableOrders: FC<Props> = ({
  orders,
  pagination,
  setPage,
  changePage,
  pageCount,
  loadingOrders,
}) => {
  const { translateLang } = useLocalization();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);

  const closeModal = () => {
    setOpenModal(false);
  };

  const formatDate = (item: string | number | Date) => {
    const date = new Date(item);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  };

  const changePageNumber = (val: number) => {
    if (setPage) {
      setPage(val);
    }
    if (changePage) {
      changePage(val);
    }
  };

  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      {
        title: translateLang('numberOrder'),
        containerProps: { width: '80px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('client'),
        containerProps: { width: '200px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('clientId'),
        containerProps: { width: '60px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('orderDate'),
        containerProps: { width: '160px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('manager'),
        containerProps: { width: '250px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('total'),
        containerProps: { width: '80px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang('status'),
        containerProps: { width: '200px', color: '#f1f3f8', align: 'center' },
      },
      {
        title: translateLang(''),
        containerProps: { color: '#f1f3f8', align: 'center' },
      },
      { title: '', containerProps: { width: '20px', color: '#f1f3f8', align: 'center' } },
    ];
  };

  const getRowsTable = (): Array<TableCellsValue> => {
    if (orders && orders?.length) {
      return orders?.map(item => {
        const managerCredential = item.managerCredential
          ?.filter(item => !!item.firstName && !!item.lastName)
          .map(i => {
            const lastName = i?.lastName || '';
            const firstName = i?.firstName || '';
            const userName = i?.userName || '';
            let customerCredential = `${firstName} ${lastName}`;
            if (!lastName && !firstName) {
              customerCredential = userName;
            }
            return customerCredential;
          });

        const values: Array<BoxCell> = [];
        values.push({
          val: `#${item.orderNumber}`,
          containerProps: { height: '20px', borderLeft: 'none' },
        });
        values.push({ val: item?.customerCredential });
        values.push({ val: item.customerId });
        values.push({ val: formatDate(item.date as string) });
        values.push({
          val: managerCredential?.join(', '),
        });
        values.push({
          val: `${item.total} \u20AC`,
          // containerProps: { justifyContent: 'flex-start' },
        });
        values.push({ val: translateLang(item.status as string) });
        values.push({ val: '', containerProps: { borderLeft: 'none' } });
        values.push({
          val: (
            <Box bgcolor="#e7eef4" borderRadius={2}>
              <IconButton
                onClick={() => navigate(`/${PatchesName.Orders}/${item.customerId}/${item.id}`)}
              >
                <RemoveRedEyeOutlined fontSize="small" />
              </IconButton>
            </Box>
          ),
          containerProps: { borderLeft: 'none' },
        });
        return { cellsValues: values };
      });
    }
    return [];
  };

  return (
    <>
      <Box boxSizing="border-box">
        <TableProduct
          startPage={pagination?.page || 0}
          totalPages={pagination?.total ? Math.ceil(pagination.total / pageCount) : 0}
          itemsRows={getRowsTable()}
          itemsHeader={getHeadersTable()}
          changeCurrentPage={changePageNumber}
          loading={loadingOrders}
        />
      </Box>
      <DeleteModal openModal={openModal} closeModal={closeModal} title={translateLang('del')} />
    </>
  );
};
