import React from 'react';
import { useLazyGetAllOrdersDelivery } from '../../../graphql/queries/hook/useGetAllOrdersDelivery';
import { Enum_Order_Status } from '../../../__generated__/types';
import { useGetOrdersCountQuery } from '../../../graphql/queries/__generated__/getOrdersCount';
import { isToday, parseISO } from 'date-fns';
interface ReturnHook {
  tabs: Array<{
    id: number;
    value: 'deliverToday' | 'allOrders' | 'history';
    notification?: number;
  }>;
}
export const useGetTabs = (isMobile: boolean): ReturnHook => {
  const { data } = useGetOrdersCountQuery({
    variables: {
      filters: {
        or: [
          {
            status: {
              eq: Enum_Order_Status.PickedUp,
            },
          },
          {
            status: {
              eq: Enum_Order_Status.Delivery,
            },
          },
        ],
      },
    },
  });

  const countDeliveryToday =
    data?.orders?.data?.reduce((total, item) => {
      console.log(
        'debug > parseISO(item.attributes?.deliveryDate) ==== ',
        parseISO(item.attributes?.deliveryDate)
      );
      return (
        total +
        (item.attributes?.deliveryDate && isToday(parseISO(item.attributes?.deliveryDate)) ? 1 : 0)
      );
    }, 0) ?? 0;

  if (isMobile) {
    return {
      tabs: [
        { id: 2, value: 'deliverToday', notification: countDeliveryToday },
        { id: 3, value: 'history' },
      ],
    };
  }
  return {
    tabs: [
      {
        id: 1,
        value: 'allOrders',
        notification: data?.orders?.meta?.pagination?.total ?? 0,
      },
      {
        id: 2,
        value: 'deliverToday',
        notification: countDeliveryToday,
      },
      { id: 3, value: 'history' },
    ],
  };
};
