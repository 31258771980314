import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import { useGetOrderById } from '../../../graphql/queries/hook/useGetOrderById';
import { CustomerTypography } from './CustomerTypography';
import { useLocalization } from '../../../localization';
import { NoRowsImage } from '../../../components/NoRowsImage/NoRowsImage';
import { AddVehicleModal } from '../../ClientInfo/components/AddVehicleModal';
import { useGetClientById } from '../../../graphql/queries/hook/useGetClientById';

interface Type {
  orderId: string;
  userId: string;
}
export const ClientInfo: FC<Type> = ({ orderId, userId }) => {
  const { translateLang } = useLocalization();
  const { vehicles } = useGetClientById(userId);
  const {
    firstName,
    lastName,
    phoneNumbers,
    deliveryAddress,
    typeClient,
    typePriceClient,
    customerId,
    selectCar,
  } = useGetOrderById(orderId);

  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');

  const [openModal, setOpenModal] = useState<boolean>(false);

  const closeModal = () => {
    setOpenModal(false);
  };
  const handleChange = (event: SelectChangeEvent) => {
    setPhone(event.target.value);
  };
  const handleChangeAddress = (event: SelectChangeEvent) => {
    setAddress(event.target.value);
  };

  const geCurrentCar = () => {
    if (selectCar && vehicles && vehicles.length > 0) {
      const selAuto = vehicles.find(item => item.carID === selectCar?.data?.id);
      if (selAuto) {
        return (
          <Stack>
            <CustomerTypography label={`${translateLang('vinCode')}: `} value={selAuto.vinCode} />
            <CustomerTypography label={`${translateLang('id')}: `} value={selAuto.id} />
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} alignItems="center">
              <CustomerTypography
                label={`${translateLang('car_brand')}: `}
                value={selAuto.carBrand}
              />
              <CustomerTypography
                label={`${translateLang('car_model')}: `}
                value={selAuto.carModel}
              />
              <CustomerTypography
                label={`${translateLang('yearOfManufacture')}: `}
                value={`${selAuto.yearOfManufacture || ''}`}
              />
            </Stack>
            <Divider />
            <CustomerTypography
              label={`${translateLang('car_body_type')}: `}
              value={selAuto.carBodyType}
            />
            {/* <CustomerTypography
              label={`${translateLang('car_engine')}: `}
              value={vehicles[0].carEngine}
            />*/}
          </Stack>
        );
      }
    }
    return (
      <Stack>
        <NoRowsImage text={translateLang('noVehicleSelected')} />
        {/*<Button style={{ color: 'green' }} onClick={() => setOpenModal(true)}>
          {`${translateLang('addVehicle')}: `}
        </Button>*/}
      </Stack>
    );
  };

  useEffect(() => {
    if (phoneNumbers) {
      phoneNumbers.map(i => setPhone(i?.number as string));
    }
  }, [phoneNumbers]);
  useEffect(() => {
    if (deliveryAddress) {
      deliveryAddress.map(addresses =>
        setAddress(`${addresses?.city} ${addresses?.street} ${addresses?.number}`)
      );
    }
  }, [phoneNumbers]);

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      width="100%"
      spacing={4}
      bgcolor="#FFFFFF"
      sx={{
        marginTop: '-5px',
        borderBottomLeftRadius: '10px',
        borderBottomRightRadius: '10px',
      }}
    >
      <Box
        width={{ xs: '80%', lg: '50%' }}
        sx={{
          padding: '40px',
        }}
      >
        <Typography
          sx={{ lineHeight: '37.65px', fontSize: '30px', fontWeight: '400', color: '#2A3453' }}
        >
          {translateLang('client')}
        </Typography>
        <Stack>
          {firstName && (
            <CustomerTypography
              label={`${translateLang('clientShortCred')}:`}
              value={`${firstName} ${lastName}`}
            />
          )}

          <CustomerTypography label={`${translateLang('id')}:`} value={customerId} />
          {typeClient ? (
            <CustomerTypography
              label={`${translateLang('customerType')}:`}
              value={translateLang(typePriceClient || '')}
            />
          ) : null}
          {phoneNumbers?.length ? (
            <>
              <Stack direction={{ xs: 'column', sm: 'row' }}>
                <CustomerTypography label={`${translateLang('phone')}:`} />

                <Select
                  disableUnderline
                  variant="standard"
                  value={phone}
                  onChange={handleChange}
                  fullWidth
                >
                  {phoneNumbers?.map(number => (
                    <MenuItem key={number?.id} value={`${number?.number}`}>
                      {number?.number}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Divider />
            </>
          ) : null}
          {deliveryAddress?.length ? (
            <>
              <Stack direction={{ xs: 'column', sm: 'row' }}>
                <CustomerTypography label={`${translateLang('address')}: `} width="130px" />
                <Select
                  disableUnderline
                  variant="standard"
                  value={address}
                  onChange={handleChangeAddress}
                  fullWidth
                >
                  {deliveryAddress?.map(addresses => (
                    <MenuItem
                      key={addresses?.id}
                      value={`${addresses?.city} ${addresses?.street} ${addresses?.number}`}
                    >
                      {`${addresses?.city} ${addresses?.street} ${addresses?.number}`}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <Divider />
            </>
          ) : null}
        </Stack>
      </Box>
      <Box
        width={{ xs: '80%', lg: '50%' }}
        sx={{
          padding: '40px',
        }}
      >
        <Typography
          sx={{ lineHeight: '37.65px', fontSize: '30px', fontWeight: '400', color: '#2A3453' }}
        >
          {translateLang('vehicle')}
        </Typography>
        {geCurrentCar()}
        <AddVehicleModal
          customerId={customerId as string}
          handleClose={closeModal}
          open={openModal}
        />
      </Box>
    </Stack>
  );
};
