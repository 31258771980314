import React, { FC } from 'react';
import { Button, Fade, Modal, Stack } from '@mui/material';

import { TranslatedField } from '../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { theme } from '../../../../../helpers';
import { useFormikContext } from 'formik';
import { useCreateSpareMutation } from '../hooks/useCreateSpareMutation';
import { GeneralSpareInputType } from '../types';
import { useHandlerNotificationApp } from '../../../../../hooks/useHandlerNotificationApp';
import { useLocalization } from '../../../../../localization';
import { useUpdateSpareMutation } from '../hooks/useUpdateSpareMutation';

interface ConfirmModalFormProps {
  isExitModalOpen: boolean;
  toggleConfirmModal: () => void;
  handleCloseAddProduct: () => void;
  productId: string | null;
}

export const ConfirmModalForm: FC<ConfirmModalFormProps> = ({
  productId,
  isExitModalOpen,
  toggleConfirmModal,
  handleCloseAddProduct,
}) => {
  const { values } = useFormikContext();
  const { addNotification } = useHandlerNotificationApp();
  const { translateLang } = useLocalization();
  const { createGeneralSpare } = useCreateSpareMutation();
  const { updateGeneralSpare } = useUpdateSpareMutation();

  const handleFormikSubmit = () => {
    if (productId) {
      updateGeneralSpare(values as GeneralSpareInputType, productId)
        .then(() => {
          handleCloseAddProduct();
          addNotification({
            messageError: translateLang('productSuccessfullyUpdated'),
            typeMessage: 'success',
          });
        })
        .catch(error => {
          addNotification({
            messageError: error.message,
            typeMessage: 'error',
          });
        });
    } else {
      createGeneralSpare(values as GeneralSpareInputType)
        .then(() => {
          handleCloseAddProduct();
          addNotification({
            messageError: translateLang('productSuccessfullyCreated'),
            typeMessage: 'success',
          });
        })
        .catch(error => {
          addNotification({
            messageError: error.message,
            typeMessage: 'error',
          });
        });
    }
    toggleConfirmModal();
  };

  return (
    <Modal open={isExitModalOpen} onClose={toggleConfirmModal} closeAfterTransition>
      <Fade in={isExitModalOpen}>
        <Stack
          borderTop={`8px solid ${theme.palette.secondary.main}`}
          bgcolor="background.paper"
          sx={{
            p: 4,
            top: '50%',
            width: 400,
            left: '50%',
            boxShadow: 24,
            borderRadius: 2,
            position: 'absolute' as const,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <TranslatedField
            isTranslate
            variant="h5"
            color={theme.palette.secondary.main}
            marginBottom="10px"
            originText={'publishProduct'}
          />
          <TranslatedField
            isTranslate
            variant="body1"
            color="#191516"
            marginBottom="40px"
            originText={'actionIsIrreversible'}
          />
          <Stack flexDirection="row" gap={5}>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                height: 50,
                p: 2,
                textTransform: 'none',
                borderRadius: '10px',
              }}
              onClick={toggleConfirmModal}
            >
              <TranslatedField originText={'cancel'} fontSize={16} isTranslate noWrap />
            </Button>
            <Button
              variant="contained"
              fullWidth
              sx={{
                height: 50,
                p: 2,
                textTransform: 'none',
                backgroundColor: '#5269A3',
                borderRadius: '10px',
              }}
              onClick={handleFormikSubmit}
            >
              <TranslatedField originText={'yesPublish'} fontSize={16} isTranslate noWrap />
            </Button>
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
};
