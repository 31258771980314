import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { addDays, format, subDays } from 'date-fns';

import { PatchesName } from '../../types/types';
import { PAGE_COUNT } from '../../helpers/constants';
import { useLocalization } from '../../localization';
import { TableOrders } from './components/TableOrders';
import { WrapperSearch } from './components/WrapperSearch';
import { OrderFiltersInput } from '../../__generated__/types';
import { getOrdersSearchFilter } from '../../helpers/functions';
import { WrapperPages } from '../../components/Wrapper/WrapperPages';
import { useGetOrders } from '../../graphql/queries/hook/useGetOrders';
import { ToolBarWithSearchAndSelect } from './components/ToolBarWithSearchAndSelect';
import { useFiltersSearchParamsForOrders } from '../../hooks/useFiltersSearchParamsForOrders';

export const OrderList = () => {
  const { translateLang } = useLocalization();
  const navigate = useNavigate();

  const {
    page,
    changePage,
    selManager,
    selStatus,
    searchText,
    selDateTo,
    selDateFrom,
    resetFilters,
  } = useFiltersSearchParamsForOrders({
    dateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
    dateTo: format(new Date(), 'yyyy-MM-dd'),
  });

  const [resetFilterOrders, setResetFilterOrders] = useState(new Date().getTime().toString());

  useEffect(() => {
    resetFilters();
  }, []);

  const handlerButtonWrapper = () => {
    navigate(`/${PatchesName.Orders}/${PatchesName.CreateOrder}`);
  };

  const getFiltersOrders = useCallback((): OrderFiltersInput | undefined => {
    const filters: OrderFiltersInput | undefined = {};
    if (selManager) {
      filters['managers'] = {
        id: {
          eq: selManager,
        },
      };
    }
    if (selStatus) {
      filters['status'] = {
        eq: selStatus,
      };
    }

    if (selDateTo && selDateFrom) {
      filters['date'] = {
        between: [selDateFrom, selDateTo],
      };
    } else {
      filters['date'] = {
        between: [format(subDays(new Date(), 30), 'yyyy-MM-dd'), format(new Date(), 'yyyy-MM-dd')],
      };
    }
    return Object.keys(filters).length > 0 ? filters : undefined;
  }, [selDateFrom, selDateTo, selManager, selStatus]);

  const filters = useMemo(() => {
    return { ...getFiltersOrders(), ...(searchText ? getOrdersSearchFilter(searchText) : {}) };
  }, [getFiltersOrders, searchText]);

  const { orders, pagination, loadingOrders } = useGetOrders({
    pagination: {
      page: page + 1,
      pageSize: PAGE_COUNT,
    },
    filters,
  });

  const handlerReset = () => {
    setResetFilterOrders(new Date().getTime().toString());
  };

  return (
    <WrapperPages
      isButton
      titleButton={translateLang('newOrder')}
      titleHeader={translateLang('orders')}
      handlerButton={handlerButtonWrapper}
    >
      <WrapperSearch placeholder={translateLang('searchOrdersAccountingLabel')} />
      <ToolBarWithSearchAndSelect
        key={resetFilterOrders}
        result={pagination?.total || '0'}
        setResetFilterOrders={handlerReset}
      />
      <TableOrders
        orders={orders}
        pagination={pagination}
        changePage={changePage}
        pageCount={PAGE_COUNT}
        loadingOrders={loadingOrders}
      />
    </WrapperPages>
  );
};
