import React, { FC } from 'react';
import { format } from 'date-fns';

import { CustomerReturnItemEntity } from '../../../../../../__generated__/types';
import { TableProductActive } from '../../../../../../components/TableProductActive/TableProductActive';

interface Props {
  invoiceData: CustomerReturnItemEntity;
}

export const TableClientReturnItemRow: FC<Props> = ({ invoiceData }) => (
  <TableProductActive.RowTable>
    <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none' }}>
      {
        invoiceData.attributes?.stock?.data?.attributes?.car_spare?.data?.attributes
          ?.tecdoc_articleNumber
      }
    </TableProductActive.CellTable>
    <TableProductActive.CellTable>
      {
        invoiceData.attributes?.stock?.data?.attributes?.car_spare?.data?.attributes
          ?.car_spare_locales?.data[0].attributes?.title
      }
    </TableProductActive.CellTable>
    <TableProductActive.CellTable>
      {format(new Date(invoiceData.attributes?.createdAt ?? new Date()), 'yyyy-MM-dd')}
    </TableProductActive.CellTable>
    <TableProductActive.CellTable>
      {invoiceData.attributes?.quantityReturned}
    </TableProductActive.CellTable>
    <TableProductActive.CellTable>
      {`${invoiceData.attributes?.stock?.data?.attributes?.supplierPrice} €`}
    </TableProductActive.CellTable>
  </TableProductActive.RowTable>
);
