import React, { useRef, useState } from 'react';
import {
  Stack,
  Button,
  Popper,
  TextField,
  Typography,
  IconButton,
  ButtonGroup,
  ClickAwayListener,
} from '@mui/material';
import { Icon } from '../Icon';
import { format, isAfter, subDays, isBefore, compareAsc, addDays } from 'date-fns';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PickersDay, StaticDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useSetSelectLanguageUserQuery } from '../../graphql/store/__generated__/setSelectLanguageUser';
import { getCalendarLocalization } from '../../utils/getCalendarLocalization';

interface DefaultRangeDatesItem {
  label: string;
  value: [Date, Date];
}

interface RangeCalendarProps {
  maxDate?: Date;
  minDate?: Date;
  disablePast?: boolean;
  selectedDates: Date[];
  disableFuture?: boolean;
  defaultRangeDates?: DefaultRangeDatesItem[];
  setSelectedDates(dates: Date[]): void;
}

export const RangeCalendar: React.FC<RangeCalendarProps> = ({
  selectedDates,
  setSelectedDates,
  defaultRangeDates,
  ...rest
}) => {
  const isSetDefaultRangeDates = !!defaultRangeDates;
  const rangeCalendarRef = useRef(null);
  const { data: lang } = useSetSelectLanguageUserQuery();

  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [isCustomChange, setIsCustomChange] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [value, setValue] = React.useState<Date | null | undefined>(selectedDates[0]);

  const handleClickAway = () => {
    setAnchorEl(null);
    setIsPopperOpen(false);
  };

  const handleOpenRangeCalendar = () => {
    setAnchorEl(rangeCalendarRef.current);
    setIsPopperOpen(!isPopperOpen);
  };

  const handleSetDate = (date: Date) => {
    setIsCustomChange(true);
    const selectedDay = date;
    const newSelectedDates = [...selectedDates];

    if (newSelectedDates.length < 2) {
      newSelectedDates.push(selectedDay);
    } else {
      newSelectedDates.length = 0;
      newSelectedDates.push(selectedDay);
    }

    setSelectedDates(newSelectedDates.sort(compareAsc));
    setValue(newSelectedDates[0]);
    // if (isPopperOpen) setIsPopperOpen(false);
  };

  const handleSetRangeDate = (value: Date[]) => {
    setIsCustomChange(false);
    setSelectedDates(value);
    setValue(value[0]);
  };

  return (
    <>
      <Stack
        border={1}
        borderRadius={1}
        height="38px"
        minWidth="200px"
        flexDirection="row"
        alignItems="center"
        ref={rangeCalendarRef}
        borderColor="#bdbdbd"
        justifyContent="space-between"
      >
        <Stack flexDirection="row" alignItems="center" paddingRight={1}>
          <IconButton color="primary" onClick={handleOpenRangeCalendar}>
            <Icon icon="calendarMonth" size="small" />
          </IconButton>
          <Typography fontSize={16}>{`${format(selectedDates[0], 'MMM d ', {
            locale: getCalendarLocalization(lang),
          })}${
            selectedDates[1]
              ? ` - ${format(selectedDates[1], 'MMM d', { locale: getCalendarLocalization(lang) })}`
              : ''
          }`}</Typography>
        </Stack>
      </Stack>
      {isPopperOpen && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <Popper open={true} anchorEl={anchorEl} style={{ zIndex: 2000 }}>
            <Stack
              pt={3}
              pr={4}
              gap={1}
              borderRadius={1}
              flexDirection="row"
              bgcolor="background.paper"
              pl={isSetDefaultRangeDates ? 1 : 4}
              border={t => `1px solid ${t.palette.secondary.main}`}
            >
              {isSetDefaultRangeDates && (
                <ButtonGroup orientation="vertical" sx={{ mt: 5 }}>
                  {defaultRangeDates.map(({ label, value }) => (
                    <Button
                      key={label}
                      onClick={() => handleSetRangeDate(value)}
                      variant={selectedDates === value ? 'contained' : 'text'}
                      style={{
                        borderRadius: 0,
                        textTransform: 'none',
                        justifyContent: 'flex-start',
                      }}
                    >
                      {label}
                    </Button>
                  ))}
                </ButtonGroup>
              )}
              <LocalizationProvider
                adapterLocale={getCalendarLocalization(lang)}
                dateAdapter={AdapterDateFns}
              >
                <StaticDatePicker
                  {...rest}
                  openTo="day"
                  value={value}
                  views={['day']}
                  displayStaticWrapperAs="desktop"
                  renderInput={params => <TextField {...params} />}
                  onChange={newValue => handleSetDate(newValue as Date)}
                  renderDay={(day, selectedDays, pickersDayProps) => {
                    const isBetweenDates =
                      isAfter(day, subDays(selectedDates[0], 1)) && isBefore(day, selectedDates[1]);

                    return (
                      <PickersDay
                        className={isBetweenDates ? 'Mui-selected' : ''}
                        {...pickersDayProps}
                      />
                    );
                  }}
                />
              </LocalizationProvider>
            </Stack>
          </Popper>
        </ClickAwayListener>
      )}
    </>
  );
};
