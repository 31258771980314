import React, { FC, useState } from 'react';
import { IconButton, Stack } from '@mui/material';
import { Enum_Receive_For } from '../../../../../../__generated__/types';
import { TableProductActive } from '../../../../../../components/TableProductActive/TableProductActive';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
import { add, isBefore, parseISO, startOfToday } from 'date-fns';
import { ReturnItemModal } from '../ReturnItemModal';
import { setReturnedItemInfo } from '../../../../../../graphql/store/functionsWitingCache';
import { ReturnedType } from '../../../../../../graphql/queries/hook/useGetOrderItemsReturnedFromCustomers';
import { Icon } from '../../../../../../legos';

interface Props {
  itemRow?: ReturnedType;
}

export const TableReturnedItemsRow: FC<Props> = ({ itemRow }) => {
  const [returnModal, setReturnModal] = useState<{
    isOpen: boolean;
    id: string;
  }>({
    isOpen: false,
    id: '',
  });

  const closeModal = () => {
    setReturnModal({
      isOpen: false,
      id: '',
    });
  };

  const returnToSupplierHandle = () => {
    setReturnedItemInfo({
      dataWrite: {
        returnedItemId: itemRow?.id || '',
      },
    });

    setReturnModal({
      isOpen: true,
      id: itemRow?.id || '',
    });
  };
  return (
    <>
      <TableProductActive.RowTable>
        <TableProductActive.CellTable ceilStyle={{ width: '20px', borderLeft: 'none' }}>
          {itemRow?.id}
        </TableProductActive.CellTable>
        <TableProductActive.CellTable>{itemRow?.article}</TableProductActive.CellTable>
        <TableProductActive.CellTable>{itemRow?.productName}</TableProductActive.CellTable>
        <TableProductActive.CellTable>{itemRow?.provider}</TableProductActive.CellTable>
        <TableProductActive.CellTable>{itemRow?.returnReason}</TableProductActive.CellTable>

        <TableProductActive.CellTable>{itemRow?.dateIncoming}</TableProductActive.CellTable>
        <TableProductActive.CellTable>{itemRow?.dateReturned} </TableProductActive.CellTable>
        <TableProductActive.CellTable
          ceilStyle={{
            borderRight: 'none',
            color: isBefore(
              add(parseISO(itemRow?.dateIncoming || ''), { days: 14 }),
              startOfToday()
            )
              ? 'red'
              : 'black',
          }}
        >
          {itemRow?.returnTo}
        </TableProductActive.CellTable>

        <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none', alignItems: 'end' }}>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <IconButton sx={{ bgcolor: '#F1F3F8', borderRadius: 10 }}>
              <SmsOutlinedIcon sx={{ color: '#5269A3' }} />
            </IconButton>
            <IconButton sx={{ bgcolor: '#F1F3F8' }} onClick={() => returnToSupplierHandle()}>
              <Icon
                icon="cropFree"
                color="inherit"
                sx={{
                  color: '#5269A3',
                  borderColor: '#5269A3',
                }}
              />
            </IconButton>
          </Stack>
        </TableProductActive.CellTable>
      </TableProductActive.RowTable>
      {returnModal.isOpen && (
        <ReturnItemModal open={true} id={returnModal.id} handleClose={closeModal} />
      )}
    </>
  );
};
