import { DocumentNode } from 'graphql/language';
import { customWriteQuery } from './customWriteQuery';
import {
  SetSelectLanguageUserDocument,
  SetSelectLanguageUserQuery,
  SetSelectLanguageUserQueryVariables,
} from './__generated__/setSelectLanguageUser';
import { LocalStorageKey } from '../../helpers/types';
import { Enum_Language_Type } from '../../__generated__/types';
import {
  LocalDataSpecialProductDocument,
  LocalDataSpecialProductQuery,
  LocalDataSpecialProductQueryVariables,
} from '../cacheQuery/__generated__/localDataSpecialProduct';
import {
  SetMessageDocument,
  SetMessageQuery,
  SetMessageQueryVariables,
} from '../cacheQuery/__generated__/setMessageQraphQL';
import {
  GetSelectVehicleDocument,
  GetSelectVehicleQuery,
  GetSelectVehicleQueryVariables,
} from '../cacheQuery/__generated__/getSelectIdVehicle';
import {
  GetCurrentClientDocument,
  GetCurrentClientQuery,
  GetCurrentClientQueryVariables,
} from '../cacheQuery/__generated__/getClient';
import {
  PreDeliveryStateDocument,
  PreDeliveryStateQuery,
  PreDeliveryStateQueryVariables,
} from '../cacheQuery/__generated__/preDeliveryState';
import {
  DataDeviderStateDocument,
  DataDeviderStateQuery,
  DataDeviderStateQueryVariables,
} from '../cacheQuery/__generated__/getDevidedData';
import {
  GetIsMobileQuery,
  GetIsMobileQueryVariables,
  GetIsMobileDocument,
} from '../cacheQuery/__generated__/getIsMobile';
import {
  GetRoleUserDocument,
  GetRoleUserQuery,
  GetRoleUserQueryVariables,
} from '../cacheQuery/__generated__/getRoleUser';
import {
  GetReturnedItemInfoDocument,
  GetReturnedItemInfoQuery,
  GetReturnedItemInfoQueryVariables,
} from '../cacheQuery/__generated__/getReturnedItemInfo';

interface PropsWriteQuery<T, U> {
  dataWrite: T;
  _variables?: U;
}
interface Props extends PropsWriteQuery<object, object> {
  node: DocumentNode;
}

function writeQueryCache(props: Props): void {
  customWriteQuery({ ...props });
}

export function setSelectLanguageUserQuery(
  props: PropsWriteQuery<SetSelectLanguageUserQuery, SetSelectLanguageUserQueryVariables>,
  languageUser: Enum_Language_Type,
  isWrite = true
): void {
  if (isWrite) {
    window.localStorage.setItem(LocalStorageKey.LANG, JSON.stringify(languageUser));
  }
  writeQueryCache({ ...props, node: SetSelectLanguageUserDocument });
}
export function setLocalDataSpecialProduct(
  props: PropsWriteQuery<LocalDataSpecialProductQuery, LocalDataSpecialProductQueryVariables>
): void {
  writeQueryCache({ ...props, node: LocalDataSpecialProductDocument });
}

export function setMessageError(
  props: PropsWriteQuery<SetMessageQuery, SetMessageQueryVariables>
): void {
  writeQueryCache({ ...props, node: SetMessageDocument });
}

export function setSelectVehicleUser(
  props: PropsWriteQuery<GetSelectVehicleQuery, GetSelectVehicleQueryVariables>
): void {
  writeQueryCache({ ...props, node: GetSelectVehicleDocument });
}
export function setCurrentClientCred(
  props: PropsWriteQuery<GetCurrentClientQuery, GetCurrentClientQueryVariables>
): void {
  writeQueryCache({ ...props, node: GetCurrentClientDocument });
}

export function setPreDeliveryStateData(
  props: PropsWriteQuery<PreDeliveryStateQuery, PreDeliveryStateQueryVariables>
): void {
  writeQueryCache({ ...props, node: PreDeliveryStateDocument });
}

export function setDataDeviderState(
  props: PropsWriteQuery<DataDeviderStateQuery, DataDeviderStateQueryVariables>
): void {
  writeQueryCache({ ...props, node: DataDeviderStateDocument });
}

export function setIsMobile(
  props: PropsWriteQuery<GetIsMobileQuery, GetIsMobileQueryVariables>
): void {
  writeQueryCache({ ...props, node: GetIsMobileDocument });
}
export function setReturnedItemInfo(
  props: PropsWriteQuery<GetReturnedItemInfoQuery, GetReturnedItemInfoQueryVariables>
): void {
  writeQueryCache({ ...props, node: GetReturnedItemInfoDocument });
}
export function setRoleUser(
  props: PropsWriteQuery<GetRoleUserQuery, GetRoleUserQueryVariables>
): void {
  writeQueryCache({ ...props, node: GetRoleUserDocument });
}
