import React, { ReactNode } from 'react';
import { HeaderCell } from '../../../../../components/TableProduct/TableProduct';
import { useLocalization } from '../../../../../localization';
import { useGetSuppliersQuery } from '../../../../../graphql/queries/__generated__/getSuppliers';
import { TableSupplierRowItem } from '../components/TableSupplierRowItem';
import { DiscountInput, GeneralSparesEntity } from '../../../../../__generated__/types';

interface HookProps {
  spareItem?: GeneralSparesEntity | null;
  changeDiscount(props: DiscountInput): void;
}
interface ReturnHook {
  getHeadersTable(): Array<HeaderCell>;
  getRowsTableActive(): ReactNode[];
  loading: boolean;
}
type ReturnDiscount = {
  id?: string;
  discount: number;
};
export const useGetTableSupplierData = ({ spareItem, changeDiscount }: HookProps): ReturnHook => {
  const { translateLang } = useLocalization();
  const { data, loading } = useGetSuppliersQuery({
    variables: {
      filters: { or: [{ blocked: { eq: false } }, { blocked: { eq: null } }] },
    },
  });

  const getDiscountSuppliers = (idSupplier?: string | null): ReturnDiscount => {
    if (idSupplier && data && spareItem) {
      const supp = spareItem.attributes?.discounts?.find(item => item?.supplier?.id === idSupplier);
      if (supp && supp.discount) {
        return { discount: supp.discount ?? 0, id: supp?.id || '' };
      }
    }
    return { discount: 0 };
  };
  const getPricesSuppliers = (idSupplier?: string | null) => {
    if (idSupplier && data && spareItem) {
      const supp = spareItem.attributes?.suppliersSpare?.find(
        item => item?.supplier?.id === idSupplier
      );
      if (supp && supp.supplierPrice) {
        return supp.supplierPrice;
      }
    }
    return 0;
  };
  const getHeadersTable = (): Array<HeaderCell> => {
    return [
      { title: translateLang('provider'), containerProps: { width: '80px', align: 'center' } },
      { title: translateLang('price'), containerProps: { width: '60px', align: 'center' } },
      { title: translateLang('discount'), containerProps: { align: 'center' } },
      {
        title: `${translateLang('price')} (100%)`,
        containerProps: { width: '70px', align: 'center' },
      },
      {
        title: `${translateLang('price')} (75%)`,
        containerProps: { width: '70px', align: 'center' },
      },
      {
        title: `${translateLang('price')} (50%)`,
        containerProps: { width: '70px', align: 'center' },
      },
      {
        title: `${translateLang('price')} (40%)`,
        containerProps: { width: '70px', align: 'center' },
      },
      {
        title: `${translateLang('price')} (30%)`,
        containerProps: { width: '70px', align: 'center' },
      },
      {
        title: `${translateLang('price')} (25%)`,
        containerProps: { width: '70px', align: 'center' },
      },
    ];
  };
  const getRowsTableActive = (): ReactNode[] => {
    if (data && data.suppliers?.data.length) {
      return data.suppliers.data.map((supplier, index) => {
        const providerPrice = getPricesSuppliers(supplier?.id);
        const discountObj = getDiscountSuppliers(supplier?.id);
        return (
          <TableSupplierRowItem
            key={supplier?.id ?? index.toString()}
            providerID={supplier.id}
            providerName={supplier.attributes?.name}
            providerPrice={providerPrice}
            providerDiscount={discountObj.discount}
            discountID={discountObj.id}
            changeDiscount={changeDiscount}
          />
        );
      });
    }
    return [];
  };

  return {
    loading,
    getHeadersTable,
    getRowsTableActive,
  };
};
