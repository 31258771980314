import * as yup from 'yup';
import { useLocalization } from '../../../../../localization';
import { IncomingPrintFields } from '../types';

export const useValidation = () => {
  const { translateLang } = useLocalization();

  const stringRequiredValidation = yup.string().required(translateLang('emptyInputError'));

  const validationSchema = yup.object({
    [IncomingPrintFields.CompanyName]: stringRequiredValidation,
    [IncomingPrintFields.StreetHouse]: stringRequiredValidation,
    [IncomingPrintFields.CityIndex]: stringRequiredValidation,
    [IncomingPrintFields.VATId]: stringRequiredValidation,
  });

  return {
    validationSchema,
  };
};
