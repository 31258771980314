import { useState } from 'react';
import {
  Enum_Componentorderstatuseditinghistory_Status,
  Enum_Order_Status,
  OrderFiltersInput,
} from '../../../__generated__/types';
import { PAGE_COUNT } from '../../../helpers/constants';
import { useGetOrdersWarehouseQuery } from '../__generated__/getOrdersWarehouse';
import { PaginationType } from './useGetOrders';

export interface OrderItemWarehouseType {
  orderId: string;
  orderNumber: string;
  customerFirstName: string;
  customerLastName: string;
  customerId: string;
  stockAddressOrder: { id: string; address: string; stockId: string }[];
  orderQuantity: number;
  balanceQuantity: number;
  deliveryQuantity: number;
  status: Enum_Order_Status;
}
interface ReturnHook {
  orderItemsWarehouse: Array<OrderItemWarehouseType>;
  loading: boolean;
  page: number;
  totalPage: number;
  pageSize: number;
  changePage(page: number): void;
}

interface PropsHook {
  filters?: OrderFiltersInput;
  pagination?: PaginationType;
}
export const useGetOrdersWarehouse = ({ filters }: PropsHook): ReturnHook => {
  const [page, setPage] = useState(1);

  const { data, loading } = useGetOrdersWarehouseQuery({
    variables: {
      pagination: {
        page: page,
        pageSize: PAGE_COUNT,
      },
      filters: filters
        ? filters
        : {
            or: [
              {
                status: {
                  containsi: Enum_Componentorderstatuseditinghistory_Status.InStock,
                },
              },
              {
                status: {
                  containsi: Enum_Componentorderstatuseditinghistory_Status.Awaiting,
                },
              },
              {
                status: {
                  containsi: Enum_Componentorderstatuseditinghistory_Status.PickedUp,
                },
              },
            ],
          },
      sort: ['date:desc'],
    },
  });

  const changePage = (_page: number): void => {
    setPage(_page + 1);
  };
  const orderItemsWarehouse: Array<OrderItemWarehouseType> = [];
  if (data?.orders?.data.length) {
    data.orders.data.forEach(item => {
      let orderNumber = item?.attributes?.orderNumber || '';
      if (item?.attributes?.orderNumberAdd) {
        orderNumber += `-${item.attributes.orderNumberAdd}`;
      }

      const arrSet = new Set();
      const stockAddressOrder: { id: string; address: string; stockId: string }[] = [];
      if (item.attributes?.order_items?.data.length) {
        item.attributes.order_items.data.forEach(i => {
          const addressId = i?.attributes?.stock?.data?.attributes?.stock_cell_address?.data?.id;
          const cellAddresses =
            i?.attributes?.stock?.data?.attributes?.stock_cell_address?.data?.attributes;
          const a = cellAddresses?.shelf || '';
          const b = cellAddresses?.cell || '';
          if (a && b) {
            arrSet.add({
              id: addressId,
              address: `${a}/${b}`,
              stockId: i.attributes?.stock?.data?.id,
            });
          }
        });
      }
      stockAddressOrder.push(
        ...(Array.from(arrSet) as { id: string; address: string; stockId: string }[])
      );

      const balanceQuantity = item.attributes?.order_items?.data.reduce((acc, i) => {
        if (i.attributes?.quantity?.outgoingQuantity) {
          return acc + i.attributes.quantity.outgoingQuantity;
        }
        return acc;
      }, 0);
      const orderQuantity = item.attributes?.order_items?.data.reduce((acc, i) => {
        if (i.attributes?.quantity?.orderedQuantity) {
          return acc + i.attributes.quantity.orderedQuantity;
        }
        return acc;
      }, 0);
      const deliveryQuantity = item.attributes?.order_items?.data.reduce((acc, i) => {
        if (i.attributes?.quantity?.deliveryQuantity) {
          return acc + i.attributes.quantity.deliveryQuantity;
        }
        return acc;
      }, 0);
      orderItemsWarehouse.push({
        orderId: item.id || '',
        orderNumber,
        customerId: item.attributes?.customer?.data?.id || '',
        customerFirstName:
          item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
            ?.firstName || '',
        customerLastName:
          item.attributes?.customer?.data?.attributes?.customer_contact_info?.data?.attributes
            ?.lastName || '',
        balanceQuantity: balanceQuantity || 0,
        orderQuantity: orderQuantity || 0,
        deliveryQuantity: deliveryQuantity || 0,
        status: item?.attributes?.status || Enum_Order_Status.Created,
        stockAddressOrder,
      });
    });
  }

  return {
    orderItemsWarehouse,
    loading,
    page,
    pageSize: data?.orders?.meta.pagination.total || 0,
    totalPage: data?.orders?.meta.pagination.total
      ? Math.ceil(data?.orders?.meta.pagination.total / PAGE_COUNT)
      : 0,
    changePage,
  };
};
