import React, { FC, useState } from 'react';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { Button, CircularProgress, Fade, IconButton, Modal, Stack } from '@mui/material';

import {
  Enum_Supplierpayment_Paymentdirection,
  Enum_Supplierpayment_Paymentmethod,
} from '../../../../../../__generated__/types';
import { SupplierPaymentFields } from './types';
import { theme } from '../../../../../../helpers';
import { useLocalization } from '../../../../../../localization';
import { CalendarMUI, Icon, Input } from '../../../../../../legos';
import { useTransactionValidation } from './hooks/useTransactionValidation';
import { useHandlerNotificationApp } from '../../../../../../hooks/useHandlerNotificationApp';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useCreateSupplierPaymentMutation } from '../../../../../../graphql/mutations/__generated__/createSupplierPayment';
import { ArrowBack } from '@mui/icons-material';
import { formatCurrencyAmount } from '../../../../../../helpers/functions';

interface Props {
  supplierId?: string;
  isModalOpen: boolean;
  handleClose: () => void;
  supplierTransactionRefetch: () => void;
  refetchSupplierBalance: () => void;
}

const initialValues = {
  [SupplierPaymentFields.Amount]: '',
  [SupplierPaymentFields.Date]: new Date(),
  [SupplierPaymentFields.Description]: '',
};

export const SupplierPaymentForm: FC<Props> = ({
  handleClose,
  supplierId,
  supplierTransactionRefetch,
  isModalOpen,
  refetchSupplierBalance,
}) => {
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();
  const [paymentDirection, setPaymentDirection] =
    useState<Enum_Supplierpayment_Paymentdirection | null>(null);

  const [createSupplierPaymentMutation, { loading }] = useCreateSupplierPaymentMutation();
  const { validationSchema } = useTransactionValidation();

  const [payMethod, setPayMethod] = useState<null | Enum_Supplierpayment_Paymentmethod>(null);

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, setFieldError } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: ({ amount, date, description }) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        createSupplierPaymentMutation({
          variables: {
            data: {
              comment: description,
              paymentDate: formattedDate,
              total: +amount,
              paymentDirection,
              paymentMethod: payMethod as Enum_Supplierpayment_Paymentmethod,
              supplier: supplierId as string,
            },
          },
        })
          .then(() => {
            addNotification({
              messageError: translateLang('paymentWasSuccessful'),
              typeMessage: 'success',
            });
            supplierTransactionRefetch();
            refetchSupplierBalance();
            handleClose();
          })
          .catch(err => {
            addNotification({ messageError: err.message, typeMessage: 'error' });
            setFieldError(SupplierPaymentFields.Amount, err.message);
          });
      },
    });

  const handleCashPay = () => {
    setPayMethod(Enum_Supplierpayment_Paymentmethod.Cash);
  };

  const handleCashlessPay = () => {
    setPayMethod(Enum_Supplierpayment_Paymentmethod.NonCash);
  };

  const handleDateChange = (newValue: Date | null) => {
    setFieldValue(SupplierPaymentFields.Date, newValue);
  };

  const handlePaymentDirection = (direction: Enum_Supplierpayment_Paymentdirection | null) =>
    setPaymentDirection(direction);

  return (
    <Modal open={true} onClose={handleClose} closeAfterTransition>
      <Fade in={isModalOpen}>
        <Stack
          bgcolor={theme.palette.common.lightGrey}
          sx={{
            p: 4,
            top: '50%',
            width: 400,
            left: '50%',
            boxShadow: 24,
            borderRadius: 2,
            position: 'absolute' as const,
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Stack alignItems="center">
            <Stack width="100%" flexDirection="row" justifyContent="space-between" mb={2}>
              {paymentDirection ? (
                <IconButton onClick={() => handlePaymentDirection(null)} sx={{ p: 0 }}>
                  <ArrowBack />
                </IconButton>
              ) : (
                <div />
              )}
              <IconButton onClick={handleClose} sx={{ p: 0 }}>
                <Icon icon="close" sx={{ color: theme.palette.primary.main }} />
              </IconButton>
            </Stack>
            <Stack marginBottom={2.5} alignItems="center">
              <TranslatedField
                isTranslate
                variant="h4"
                color={theme.palette.common.darkBlue}
                fontSize={30}
                originText={'newTransaction'}
              />
              {paymentDirection ? (
                <TranslatedField
                  originText={
                    paymentDirection === 'incoming' ? 'incomingPayment' : 'outgoingPayment'
                  }
                  fontSize={16}
                  isTranslate
                  noWrap
                />
              ) : null}
            </Stack>
            {!paymentDirection ? (
              <Stack flexDirection="row" width="100%" gap={1.25}>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    height: 40,
                    p: 2,
                    textTransform: 'none',
                    backgroundColor: theme.palette.common.white,
                    borderRadius: '5px',
                    color: theme.palette.common.black,
                    boxShadow: 'none',
                    '&:hover': { color: theme.palette.common.white },
                  }}
                  onClick={() => {
                    handlePaymentDirection(Enum_Supplierpayment_Paymentdirection.Incoming);
                  }}
                >
                  <TranslatedField
                    originText={'incomingPayment'}
                    fontSize={16}
                    isTranslate
                    noWrap
                  />
                </Button>
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    height: 40,
                    p: 2,
                    textTransform: 'none',
                    backgroundColor: theme.palette.common.white,
                    borderRadius: '5px',
                    color: theme.palette.common.black,
                    boxShadow: 'none',
                    '&:hover': { color: theme.palette.common.white },
                  }}
                  onClick={() => {
                    handlePaymentDirection(Enum_Supplierpayment_Paymentdirection.Outgoing);
                  }}
                >
                  <TranslatedField
                    originText={'outgoingPayment'}
                    fontSize={16}
                    isTranslate
                    noWrap
                  />
                </Button>
              </Stack>
            ) : (
              <>
                <Stack flexDirection="row" width="100%" gap={2} mb={2}>
                  <Button
                    variant="contained"
                    fullWidth
                    startIcon={
                      payMethod === 'cash' && (
                        <Icon icon="done" sx={{ fontSize: '14px !important' }} />
                      )
                    }
                    sx={{
                      height: 40,
                      width: 210,
                      p: 2,
                      textTransform: 'none',
                      backgroundColor:
                        payMethod === 'cash'
                          ? theme.palette.secondary.main
                          : theme.palette.common.white,
                      borderRadius: '5px',
                      color:
                        payMethod === 'cash'
                          ? theme.palette.common.white
                          : theme.palette.common.black,
                      boxShadow: 'none',
                      '&:hover': { color: theme.palette.common.white },
                    }}
                    onClick={handleCashPay}
                  >
                    <TranslatedField
                      originText={'cash'}
                      fontSize={16}
                      isTranslate
                      noWrap
                      overflow="initial"
                    />
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    startIcon={
                      payMethod === Enum_Supplierpayment_Paymentmethod.NonCash && (
                        <Icon icon="done" sx={{ fontSize: '14px !important' }} />
                      )
                    }
                    sx={{
                      height: 40,
                      width: 210,

                      p: 2,
                      textTransform: 'none',
                      backgroundColor:
                        payMethod === Enum_Supplierpayment_Paymentmethod.NonCash
                          ? theme.palette.secondary.main
                          : theme.palette.common.white,

                      borderRadius: '5px',
                      color:
                        payMethod === Enum_Supplierpayment_Paymentmethod.NonCash
                          ? theme.palette.common.white
                          : theme.palette.common.black,
                      boxShadow: 'none',
                      '&:hover': { color: theme.palette.common.white },
                    }}
                    onClick={handleCashlessPay}
                  >
                    <TranslatedField
                      originText={'bankTransfer'}
                      fontSize={16}
                      isTranslate
                      noWrap
                      overflow="initial"
                    />
                  </Button>
                </Stack>

                <Stack component="form" width="100%" mb={2} onSubmit={handleSubmit}>
                  <Stack
                    width="77.5%"
                    height={75}
                    justifyContent="space-between"
                    flexDirection="row"
                    alignItems="baseline"
                    gap={3}
                  >
                    <TranslatedField
                      originText={'amount'}
                      fontSize={16}
                      isTranslate
                      noWrap
                      overflow="initial"
                    />
                    <Input
                      name={SupplierPaymentFields.Amount}
                      value={`${values[SupplierPaymentFields.Amount]}€`}
                      onChange={event => {
                        const value = formatCurrencyAmount(event.target.value);

                        setFieldValue(SupplierPaymentFields.Amount, value);
                      }}
                      error={
                        touched[SupplierPaymentFields.Amount] &&
                        !!errors[SupplierPaymentFields.Amount]
                      }
                      helperText={
                        touched[SupplierPaymentFields.Amount] &&
                        errors[SupplierPaymentFields.Amount]
                      }
                      inputProps={{
                        style: {
                          textAlign: 'center',
                          paddingLeft: 0,
                          color: theme.palette.common.black,
                          boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                          height: 12,
                        },
                      }}
                      bgcolor={theme.palette.common.white}
                      sx={{
                        ml: 'auto',
                        maxWidth: '180px',
                      }}
                    />
                  </Stack>
                  <Stack
                    width="77.5%"
                    height={75}
                    justifyContent="space-between"
                    flexDirection="row"
                    alignItems="baseline"
                    gap={3}
                  >
                    <TranslatedField
                      originText={'date'}
                      fontSize={16}
                      isTranslate
                      noWrap
                      overflow="initial"
                    />

                    <CalendarMUI
                      disableFuture
                      value={values[SupplierPaymentFields.Date]}
                      isLabel={false}
                      setValue={handleDateChange}
                      inputStyle={{
                        bgcolor: theme.palette.common.white,
                        height: 40,
                        width: 180,
                        boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                        p: 0,
                        '& .MuiInputBase-root': {
                          p: '5px 15px',
                        },
                      }}
                    />
                  </Stack>

                  <Stack
                    width="100%"
                    height={75}
                    justifyContent="space-between"
                    flexDirection="row"
                    alignItems="baseline"
                    gap={3}
                  >
                    <TranslatedField
                      originText={'comment'}
                      fontSize={16}
                      isTranslate
                      noWrap
                      overflow="initial"
                    />
                    <Input
                      name={SupplierPaymentFields.Description}
                      value={values[SupplierPaymentFields.Description]}
                      onChange={handleChange}
                      error={
                        touched[SupplierPaymentFields.Description] &&
                        !!errors[SupplierPaymentFields.Description]
                      }
                      helperText={
                        touched[SupplierPaymentFields.Description] &&
                        errors[SupplierPaymentFields.Description]
                      }
                      inputProps={{
                        style: {
                          color: theme.palette.common.black,
                          boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
                          height: 12,
                        },
                      }}
                      bgcolor={theme.palette.common.white}
                      sx={{
                        ml: 'auto',
                        maxWidth: '271px',
                      }}
                    />
                  </Stack>

                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={!payMethod || loading}
                    startIcon={loading ? <CircularProgress size={20} /> : undefined}
                    sx={{
                      height: 50,
                      p: 2,
                      textTransform: 'none',
                      backgroundColor: '#5269A3',
                      borderRadius: '10px',
                      '&:disabled': {
                        color: theme.palette.common.white,
                      },
                    }}
                  >
                    <TranslatedField originText={'pay'} fontSize={16} isTranslate noWrap />
                  </Button>
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
      </Fade>
    </Modal>
  );
};
