import { gql } from '@apollo/client';

export const LOCAL_SCHEME = gql`
  directive @client on FIELD

  enum ENUM_LANGUAGE_TYPE {
    en
    uk
    de
  }
  enum ENUM_MODE_DELIVERY_STATE {
    warehouse
    delivery
  }

  type ErrorMessageType {
    message: String!
    code: String!
    time: String!
  }
  type DataDeviderState {
    idOrder: String!
    orderNumber: String!
    isOpenModal: Boolean!
  }
  type PreDeliveryState {
    isInfoAndPrint: Boolean!
    isOpenModal: Boolean!
    idOrder: String!
    orderNumber: String!
    idUser: String!
    status: ENUM_ORDER_STATUS!
    callParent: ENUM_MODE_DELIVERY_STATE!
  }
  type SpecialProducts {
    idCategory: String!
    productsIDs: [String!]!
    systemName: ENUM_SPECIALPROPOSAL_SYSTEM_NAME!
    titleTab: String!
  }
  type CurrentUser {
    firstName: String!
    lastName: String!
  }

  extend type Query {
    client: CurrentUser!
    selectVehicleUser: String
    messageError: ErrorMessageType
    selectLanguage: ENUM_LANGUAGE_TYPE!
    preDeliveryState: PreDeliveryState!
    dataDeviderState: DataDeviderState!
    selectedSpecialProduct: SpecialProducts!
    isMobile: Boolean!
    userRole: String!
    returnedItemId: String!
    reviewId: String!
  }
`;
