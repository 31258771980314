export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** A string used to identify an i18n locale */
  I18NLocaleCode: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  Long: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AddReviewInput = {
  generalSpareId: Scalars['ID'];
  rating?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
};

export type AddStockFromOrderItemInput = {
  incomingInvoiceId?: InputMaybe<Scalars['ID']>;
  locale: Scalars['I18NLocaleCode'];
  orderId: Scalars['ID'];
  orderItemId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type AddStockFromOrderItemResult = {
  __typename?: 'AddStockFromOrderItemResult';
  orderData?: Maybe<OrderData>;
  stockData?: Maybe<StockData>;
};

export type AddStockInput = {
  brandId: Scalars['ID'];
  incomingInvoiceId?: InputMaybe<Scalars['ID']>;
  locale: Scalars['I18NLocaleCode'];
  quantity: Scalars['Int'];
  supplierId: Scalars['ID'];
  supplierPrice: Scalars['Float'];
  tecdoc_articleNumber: Scalars['String'];
};

export type AddStockResult = {
  __typename?: 'AddStockResult';
  stockData?: Maybe<StockData>;
};

export type ArticleCriteriaInput = {
  criteriaDescription?: InputMaybe<Scalars['String']>;
  criteriaUnitDescription?: InputMaybe<Scalars['String']>;
  formattedValue?: InputMaybe<Scalars['String']>;
};

export type BackupSetting = {
  __typename?: 'BackupSetting';
  allowBackup?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  slackIncomingWebhook?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BackupSettingEntity = {
  __typename?: 'BackupSettingEntity';
  attributes?: Maybe<BackupSetting>;
  id?: Maybe<Scalars['ID']>;
};

export type BackupSettingEntityResponse = {
  __typename?: 'BackupSettingEntityResponse';
  data?: Maybe<BackupSettingEntity>;
};

export type BackupSettingInput = {
  allowBackup?: InputMaybe<Scalars['Boolean']>;
  slackIncomingWebhook?: InputMaybe<Scalars['String']>;
};

export type Banner = {
  __typename?: 'Banner';
  createdAt?: Maybe<Scalars['DateTime']>;
  image: UploadFileEntityResponse;
  link?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<BannerRelationResponseCollection>;
  size?: Maybe<Enum_Banner_Size>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type BannerLocalizationsArgs = {
  filters?: InputMaybe<BannerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BannerEntity = {
  __typename?: 'BannerEntity';
  attributes?: Maybe<Banner>;
  id?: Maybe<Scalars['ID']>;
};

export type BannerEntityResponse = {
  __typename?: 'BannerEntityResponse';
  data?: Maybe<BannerEntity>;
};

export type BannerEntityResponseCollection = {
  __typename?: 'BannerEntityResponseCollection';
  data: Array<BannerEntity>;
  meta: ResponseCollectionMeta;
};

export type BannerFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<BannerFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  link?: InputMaybe<StringFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<BannerFiltersInput>;
  not?: InputMaybe<BannerFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<BannerFiltersInput>>>;
  size?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type BannerInput = {
  image?: InputMaybe<Scalars['ID']>;
  link?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Enum_Banner_Size>;
};

export type BannerRelationResponseCollection = {
  __typename?: 'BannerRelationResponseCollection';
  data: Array<BannerEntity>;
};

export type BlockSupplierInput = {
  block: Scalars['Boolean'];
  supplierId: Scalars['ID'];
};

export type BooleanFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  contains?: InputMaybe<Scalars['Boolean']>;
  containsi?: InputMaybe<Scalars['Boolean']>;
  endsWith?: InputMaybe<Scalars['Boolean']>;
  eq?: InputMaybe<Scalars['Boolean']>;
  eqi?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Boolean']>;
  gte?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  lt?: InputMaybe<Scalars['Boolean']>;
  lte?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
  nei?: InputMaybe<Scalars['Boolean']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']>;
  notContainsi?: InputMaybe<Scalars['Boolean']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']>;
};

export type Brand = {
  __typename?: 'Brand';
  count?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CanAddReviewResponse = {
  __typename?: 'CanAddReviewResponse';
  canAddReview: Scalars['Boolean'];
  generalSpareId: Scalars['ID'];
};

export type Car = {
  __typename?: 'Car';
  car_brand?: Maybe<CarBrandEntityResponse>;
  car_locales?: Maybe<CarLocaleRelationResponseCollection>;
  car_model?: Maybe<CarModelEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  cylinderCapacityLiter?: Maybe<Scalars['Int']>;
  engines?: Maybe<Scalars['JSON']>;
  orders?: Maybe<OrderRelationResponseCollection>;
  powerHpFrom?: Maybe<Scalars['Int']>;
  powerHpTo?: Maybe<Scalars['Int']>;
  tecdoc_carId?: Maybe<Scalars['String']>;
  tecdoc_carType?: Maybe<Scalars['String']>;
  tecdoc_firstCountry?: Maybe<Scalars['String']>;
  tecdoc_manuId?: Maybe<Scalars['String']>;
  tecdoc_modelId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vehicles?: Maybe<VehicleRelationResponseCollection>;
  yearOfConstrFrom?: Maybe<Scalars['Int']>;
  yearOfConstrTo?: Maybe<Scalars['Int']>;
};


export type CarCar_LocalesArgs = {
  filters?: InputMaybe<CarLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CarOrdersArgs = {
  filters?: InputMaybe<OrderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CarVehiclesArgs = {
  filters?: InputMaybe<VehicleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CarBrand = {
  __typename?: 'CarBrand';
  car_brand_locales?: Maybe<CarBrandLocaleRelationResponseCollection>;
  car_models?: Maybe<CarModelRelationResponseCollection>;
  cars?: Maybe<CarRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  isPopular?: Maybe<Scalars['Boolean']>;
  tecdoc_linkingTargetTypes?: Maybe<Scalars['String']>;
  tecdoc_manuId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vehicles?: Maybe<VehicleRelationResponseCollection>;
};


export type CarBrandCar_Brand_LocalesArgs = {
  filters?: InputMaybe<CarBrandLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CarBrandCar_ModelsArgs = {
  filters?: InputMaybe<CarModelFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CarBrandCarsArgs = {
  filters?: InputMaybe<CarFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CarBrandVehiclesArgs = {
  filters?: InputMaybe<VehicleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CarBrandEntity = {
  __typename?: 'CarBrandEntity';
  attributes?: Maybe<CarBrand>;
  id?: Maybe<Scalars['ID']>;
};

export type CarBrandEntityResponse = {
  __typename?: 'CarBrandEntityResponse';
  data?: Maybe<CarBrandEntity>;
};

export type CarBrandEntityResponseCollection = {
  __typename?: 'CarBrandEntityResponseCollection';
  data: Array<CarBrandEntity>;
  meta: ResponseCollectionMeta;
};

export type CarBrandFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CarBrandFiltersInput>>>;
  car_brand_locales?: InputMaybe<CarBrandLocaleFiltersInput>;
  car_models?: InputMaybe<CarModelFiltersInput>;
  cars?: InputMaybe<CarFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isPopular?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<CarBrandFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CarBrandFiltersInput>>>;
  tecdoc_linkingTargetTypes?: InputMaybe<StringFilterInput>;
  tecdoc_manuId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  vehicles?: InputMaybe<VehicleFiltersInput>;
};

export type CarBrandLocale = {
  __typename?: 'CarBrandLocale';
  createdAt?: Maybe<Scalars['DateTime']>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<CarBrandLocaleRelationResponseCollection>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CarBrandLocaleLocalizationsArgs = {
  filters?: InputMaybe<CarBrandLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CarBrandLocaleEntity = {
  __typename?: 'CarBrandLocaleEntity';
  attributes?: Maybe<CarBrandLocale>;
  id?: Maybe<Scalars['ID']>;
};

export type CarBrandLocaleEntityResponse = {
  __typename?: 'CarBrandLocaleEntityResponse';
  data?: Maybe<CarBrandLocaleEntity>;
};

export type CarBrandLocaleEntityResponseCollection = {
  __typename?: 'CarBrandLocaleEntityResponseCollection';
  data: Array<CarBrandLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type CarBrandLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CarBrandLocaleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<CarBrandLocaleFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CarBrandLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CarBrandLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CarBrandLocaleInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type CarBrandLocaleRelationResponseCollection = {
  __typename?: 'CarBrandLocaleRelationResponseCollection';
  data: Array<CarBrandLocaleEntity>;
};

export type CarCategory = {
  __typename?: 'CarCategory';
  car_category_childrens?: Maybe<CarCategoryRelationResponseCollection>;
  car_category_parent?: Maybe<CarCategoryEntityResponse>;
  category?: Maybe<CategoryEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  hasChildren: Scalars['Boolean'];
  hasRoot?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<CarCategoryRelationResponseCollection>;
  name?: Maybe<Scalars['String']>;
  popular_category?: Maybe<CategoryEntityResponse>;
  tecdoc_assemblyGroupNodeId?: Maybe<Scalars['String']>;
  tecdoc_linkingTargetType?: Maybe<Scalars['String']>;
  tecdoc_parentNodeId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CarCategoryCar_Category_ChildrensArgs = {
  filters?: InputMaybe<CarCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CarCategoryLocalizationsArgs = {
  filters?: InputMaybe<CarCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CarCategoryEntity = {
  __typename?: 'CarCategoryEntity';
  attributes?: Maybe<CarCategory>;
  id?: Maybe<Scalars['ID']>;
};

export type CarCategoryEntityResponse = {
  __typename?: 'CarCategoryEntityResponse';
  data?: Maybe<CarCategoryEntity>;
};

export type CarCategoryEntityResponseCollection = {
  __typename?: 'CarCategoryEntityResponseCollection';
  data: Array<CarCategoryEntity>;
  meta: ResponseCollectionMeta;
};

export type CarCategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CarCategoryFiltersInput>>>;
  car_category_childrens?: InputMaybe<CarCategoryFiltersInput>;
  car_category_parent?: InputMaybe<CarCategoryFiltersInput>;
  category?: InputMaybe<CategoryFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  hasChildren?: InputMaybe<BooleanFilterInput>;
  hasRoot?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<CarCategoryFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CarCategoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CarCategoryFiltersInput>>>;
  popular_category?: InputMaybe<CategoryFiltersInput>;
  tecdoc_assemblyGroupNodeId?: InputMaybe<StringFilterInput>;
  tecdoc_linkingTargetType?: InputMaybe<StringFilterInput>;
  tecdoc_parentNodeId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CarCategoryInput = {
  car_category_childrens?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  car_category_parent?: InputMaybe<Scalars['ID']>;
  category?: InputMaybe<Scalars['ID']>;
  hasChildren?: InputMaybe<Scalars['Boolean']>;
  hasRoot?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  popular_category?: InputMaybe<Scalars['ID']>;
  tecdoc_assemblyGroupNodeId?: InputMaybe<Scalars['String']>;
  tecdoc_linkingTargetType?: InputMaybe<Scalars['String']>;
  tecdoc_parentNodeId?: InputMaybe<Scalars['String']>;
};

export type CarCategoryRelationResponseCollection = {
  __typename?: 'CarCategoryRelationResponseCollection';
  data: Array<CarCategoryEntity>;
};

export type CarEntity = {
  __typename?: 'CarEntity';
  attributes?: Maybe<Car>;
  id?: Maybe<Scalars['ID']>;
};

export type CarEntityResponse = {
  __typename?: 'CarEntityResponse';
  data?: Maybe<CarEntity>;
};

export type CarEntityResponseCollection = {
  __typename?: 'CarEntityResponseCollection';
  data: Array<CarEntity>;
  meta: ResponseCollectionMeta;
};

export type CarFilter = {
  tecdoc_carId: Scalars['String'];
  tecdoc_carType: Scalars['String'];
};

export type CarFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CarFiltersInput>>>;
  car_brand?: InputMaybe<CarBrandFiltersInput>;
  car_locales?: InputMaybe<CarLocaleFiltersInput>;
  car_model?: InputMaybe<CarModelFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  cylinderCapacityLiter?: InputMaybe<IntFilterInput>;
  engines?: InputMaybe<JsonFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<CarFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CarFiltersInput>>>;
  orders?: InputMaybe<OrderFiltersInput>;
  powerHpFrom?: InputMaybe<IntFilterInput>;
  powerHpTo?: InputMaybe<IntFilterInput>;
  tecdoc_carId?: InputMaybe<StringFilterInput>;
  tecdoc_carType?: InputMaybe<StringFilterInput>;
  tecdoc_firstCountry?: InputMaybe<StringFilterInput>;
  tecdoc_manuId?: InputMaybe<StringFilterInput>;
  tecdoc_modelId?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  vehicles?: InputMaybe<VehicleFiltersInput>;
  yearOfConstrFrom?: InputMaybe<IntFilterInput>;
  yearOfConstrTo?: InputMaybe<IntFilterInput>;
};

export type CarLocale = {
  __typename?: 'CarLocale';
  constructionType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  fuelType?: Maybe<Scalars['String']>;
  impulsionType?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<CarLocaleRelationResponseCollection>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CarLocaleLocalizationsArgs = {
  filters?: InputMaybe<CarLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CarLocaleEntity = {
  __typename?: 'CarLocaleEntity';
  attributes?: Maybe<CarLocale>;
  id?: Maybe<Scalars['ID']>;
};

export type CarLocaleEntityResponse = {
  __typename?: 'CarLocaleEntityResponse';
  data?: Maybe<CarLocaleEntity>;
};

export type CarLocaleEntityResponseCollection = {
  __typename?: 'CarLocaleEntityResponseCollection';
  data: Array<CarLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type CarLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CarLocaleFiltersInput>>>;
  constructionType?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  fuelType?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  impulsionType?: InputMaybe<StringFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<CarLocaleFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CarLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CarLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CarLocaleInput = {
  constructionType?: InputMaybe<Scalars['String']>;
  fuelType?: InputMaybe<Scalars['String']>;
  impulsionType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type CarLocaleRelationResponseCollection = {
  __typename?: 'CarLocaleRelationResponseCollection';
  data: Array<CarLocaleEntity>;
};

export type CarModel = {
  __typename?: 'CarModel';
  car_brand?: Maybe<CarBrandEntityResponse>;
  car_model_locales?: Maybe<CarModelLocaleRelationResponseCollection>;
  cars?: Maybe<CarRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  isPopular?: Maybe<Scalars['Boolean']>;
  tecdoc_manuId?: Maybe<Scalars['String']>;
  tecdoc_modelId?: Maybe<Scalars['String']>;
  tecdoc_yearOfConstrFrom?: Maybe<Scalars['String']>;
  tecdoc_yearOfConstrTo?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vehicles?: Maybe<VehicleRelationResponseCollection>;
  yearFrom?: Maybe<Scalars['Int']>;
  yearTo?: Maybe<Scalars['Int']>;
};


export type CarModelCar_Model_LocalesArgs = {
  filters?: InputMaybe<CarModelLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CarModelCarsArgs = {
  filters?: InputMaybe<CarFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CarModelVehiclesArgs = {
  filters?: InputMaybe<VehicleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CarModelEntity = {
  __typename?: 'CarModelEntity';
  attributes?: Maybe<CarModel>;
  id?: Maybe<Scalars['ID']>;
};

export type CarModelEntityResponse = {
  __typename?: 'CarModelEntityResponse';
  data?: Maybe<CarModelEntity>;
};

export type CarModelEntityResponseCollection = {
  __typename?: 'CarModelEntityResponseCollection';
  data: Array<CarModelEntity>;
  meta: ResponseCollectionMeta;
};

export type CarModelFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CarModelFiltersInput>>>;
  car_brand?: InputMaybe<CarBrandFiltersInput>;
  car_model_locales?: InputMaybe<CarModelLocaleFiltersInput>;
  cars?: InputMaybe<CarFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isPopular?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<CarModelFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CarModelFiltersInput>>>;
  tecdoc_manuId?: InputMaybe<StringFilterInput>;
  tecdoc_modelId?: InputMaybe<StringFilterInput>;
  tecdoc_yearOfConstrFrom?: InputMaybe<StringFilterInput>;
  tecdoc_yearOfConstrTo?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  vehicles?: InputMaybe<VehicleFiltersInput>;
  yearFrom?: InputMaybe<IntFilterInput>;
  yearTo?: InputMaybe<IntFilterInput>;
};

export type CarModelGrouped = {
  __typename?: 'CarModelGrouped';
  models?: Maybe<Array<Maybe<CarModelGroupedCarModel>>>;
  shortName?: Maybe<Scalars['String']>;
  yearFrom?: Maybe<Scalars['Int']>;
  yearTo?: Maybe<Scalars['Int']>;
};

export type CarModelGroupedCarModel = {
  __typename?: 'CarModelGroupedCarModel';
  name?: Maybe<Scalars['String']>;
  tecdoc_modelId?: Maybe<Scalars['String']>;
  yearFrom?: Maybe<Scalars['Int']>;
  yearTo?: Maybe<Scalars['Int']>;
};

export type CarModelLocale = {
  __typename?: 'CarModelLocale';
  createdAt?: Maybe<Scalars['DateTime']>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<CarModelLocaleRelationResponseCollection>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CarModelLocaleLocalizationsArgs = {
  filters?: InputMaybe<CarModelLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CarModelLocaleEntity = {
  __typename?: 'CarModelLocaleEntity';
  attributes?: Maybe<CarModelLocale>;
  id?: Maybe<Scalars['ID']>;
};

export type CarModelLocaleEntityResponse = {
  __typename?: 'CarModelLocaleEntityResponse';
  data?: Maybe<CarModelLocaleEntity>;
};

export type CarModelLocaleEntityResponseCollection = {
  __typename?: 'CarModelLocaleEntityResponseCollection';
  data: Array<CarModelLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type CarModelLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CarModelLocaleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<CarModelLocaleFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CarModelLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CarModelLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CarModelLocaleInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type CarModelLocaleRelationResponseCollection = {
  __typename?: 'CarModelLocaleRelationResponseCollection';
  data: Array<CarModelLocaleEntity>;
};

export type CarModelRelationResponseCollection = {
  __typename?: 'CarModelRelationResponseCollection';
  data: Array<CarModelEntity>;
};

export type CarRelationResponseCollection = {
  __typename?: 'CarRelationResponseCollection';
  data: Array<CarEntity>;
};

export type CarSpare = {
  __typename?: 'CarSpare';
  brandId?: Maybe<Scalars['Int']>;
  car_spare_locales?: Maybe<CarSpareLocaleRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  eanNumber?: Maybe<Scalars['JSON']>;
  hasDiscount?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Scalars['JSON']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  maxDiscount?: Maybe<Scalars['Int']>;
  oemNumbers?: Maybe<Scalars['JSON']>;
  order_items?: Maybe<OrderItemRelationResponseCollection>;
  price?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  reviewCount?: Maybe<Scalars['Int']>;
  reviews?: Maybe<ReviewRelationResponseCollection>;
  special_proposal?: Maybe<SpecialProposalEntityResponse>;
  stocks?: Maybe<StockRelationResponseCollection>;
  tecdoc_articleNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CarSpareCar_Spare_LocalesArgs = {
  filters?: InputMaybe<CarSpareLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CarSpareOrder_ItemsArgs = {
  filters?: InputMaybe<OrderItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CarSpareReviewsArgs = {
  filters?: InputMaybe<ReviewFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CarSpareStocksArgs = {
  filters?: InputMaybe<StockFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CarSpareBrand = {
  __typename?: 'CarSpareBrand';
  brandId?: Maybe<Scalars['Int']>;
  brandName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<CarSpareBrandRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CarSpareBrandLocalizationsArgs = {
  filters?: InputMaybe<CarSpareBrandFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CarSpareBrandEntity = {
  __typename?: 'CarSpareBrandEntity';
  attributes?: Maybe<CarSpareBrand>;
  id?: Maybe<Scalars['ID']>;
};

export type CarSpareBrandEntityResponse = {
  __typename?: 'CarSpareBrandEntityResponse';
  data?: Maybe<CarSpareBrandEntity>;
};

export type CarSpareBrandEntityResponseCollection = {
  __typename?: 'CarSpareBrandEntityResponseCollection';
  data: Array<CarSpareBrandEntity>;
  meta: ResponseCollectionMeta;
};

export type CarSpareBrandFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CarSpareBrandFiltersInput>>>;
  brandId?: InputMaybe<IntFilterInput>;
  brandName?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<CarSpareBrandFiltersInput>;
  not?: InputMaybe<CarSpareBrandFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CarSpareBrandFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CarSpareBrandInput = {
  brandId?: InputMaybe<Scalars['Int']>;
  brandName?: InputMaybe<Scalars['String']>;
};

export type CarSpareBrandRelationResponseCollection = {
  __typename?: 'CarSpareBrandRelationResponseCollection';
  data: Array<CarSpareBrandEntity>;
};

export type CarSpareEntity = {
  __typename?: 'CarSpareEntity';
  attributes?: Maybe<CarSpare>;
  id?: Maybe<Scalars['ID']>;
};

export type CarSpareEntityResponse = {
  __typename?: 'CarSpareEntityResponse';
  data?: Maybe<CarSpareEntity>;
};

export type CarSpareFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CarSpareFiltersInput>>>;
  brandId?: InputMaybe<IntFilterInput>;
  car_spare_locales?: InputMaybe<CarSpareLocaleFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  discounts?: InputMaybe<ComponentDiscountDiscountFiltersInput>;
  eanNumber?: InputMaybe<JsonFilterInput>;
  forSearch?: InputMaybe<StringFilterInput>;
  hasDiscount?: InputMaybe<BooleanFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  images?: InputMaybe<JsonFilterInput>;
  isAvailable?: InputMaybe<BooleanFilterInput>;
  isDraft?: InputMaybe<BooleanFilterInput>;
  maxDiscount?: InputMaybe<IntFilterInput>;
  not?: InputMaybe<CarSpareFiltersInput>;
  oemNumbers?: InputMaybe<JsonFilterInput>;
  or?: InputMaybe<Array<InputMaybe<CarSpareFiltersInput>>>;
  order_items?: InputMaybe<OrderItemFiltersInput>;
  rating?: InputMaybe<FloatFilterInput>;
  reviewCount?: InputMaybe<IntFilterInput>;
  reviews?: InputMaybe<ReviewFiltersInput>;
  source?: InputMaybe<IntFilterInput>;
  special_proposal?: InputMaybe<SpecialProposalFiltersInput>;
  stocks?: InputMaybe<StockFiltersInput>;
  tecdoc_articleNumber?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  virtualSupplierPrice?: InputMaybe<FloatFilterInput>;
};

export type CarSpareLocale = {
  __typename?: 'CarSpareLocale';
  articleCriteria?: Maybe<Scalars['JSON']>;
  brandName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  descriptionsInfo?: Maybe<Scalars['JSON']>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<CarSpareLocaleRelationResponseCollection>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CarSpareLocaleLocalizationsArgs = {
  filters?: InputMaybe<CarSpareLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CarSpareLocaleEntity = {
  __typename?: 'CarSpareLocaleEntity';
  attributes?: Maybe<CarSpareLocale>;
  id?: Maybe<Scalars['ID']>;
};

export type CarSpareLocaleEntityResponse = {
  __typename?: 'CarSpareLocaleEntityResponse';
  data?: Maybe<CarSpareLocaleEntity>;
};

export type CarSpareLocaleEntityResponseCollection = {
  __typename?: 'CarSpareLocaleEntityResponseCollection';
  data: Array<CarSpareLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type CarSpareLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CarSpareLocaleFiltersInput>>>;
  articleCriteria?: InputMaybe<JsonFilterInput>;
  brandName?: InputMaybe<StringFilterInput>;
  carSpareId?: InputMaybe<LongFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  descriptionsInfo?: InputMaybe<JsonFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<CarSpareLocaleFiltersInput>;
  not?: InputMaybe<CarSpareLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CarSpareLocaleFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CarSpareLocaleInput = {
  articleCriteria?: InputMaybe<Scalars['JSON']>;
  brandName?: InputMaybe<Scalars['String']>;
  carSpareId?: InputMaybe<Scalars['Long']>;
  descriptionsInfo?: InputMaybe<Scalars['JSON']>;
  title?: InputMaybe<Scalars['String']>;
};

export type CarSpareLocaleRelationResponseCollection = {
  __typename?: 'CarSpareLocaleRelationResponseCollection';
  data: Array<CarSpareLocaleEntity>;
};

export type CarSpareRelationResponseCollection = {
  __typename?: 'CarSpareRelationResponseCollection';
  data: Array<CarSpareEntity>;
};

export type Category = {
  __typename?: 'Category';
  car_popular_categories?: Maybe<CarCategoryRelationResponseCollection>;
  car_root_categories?: Maybe<CarCategoryRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<CategoryRelationResponseCollection>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CategoryCar_Popular_CategoriesArgs = {
  filters?: InputMaybe<CarCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CategoryCar_Root_CategoriesArgs = {
  filters?: InputMaybe<CarCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CategoryLocalizationsArgs = {
  filters?: InputMaybe<CategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CategoryEntity = {
  __typename?: 'CategoryEntity';
  attributes?: Maybe<Category>;
  id?: Maybe<Scalars['ID']>;
};

export type CategoryEntityResponse = {
  __typename?: 'CategoryEntityResponse';
  data?: Maybe<CategoryEntity>;
};

export type CategoryEntityResponseCollection = {
  __typename?: 'CategoryEntityResponseCollection';
  data: Array<CategoryEntity>;
  meta: ResponseCollectionMeta;
};

export type CategoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CategoryFiltersInput>>>;
  car_popular_categories?: InputMaybe<CarCategoryFiltersInput>;
  car_root_categories?: InputMaybe<CarCategoryFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<CategoryFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CategoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CategoryFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CategoryInput = {
  car_popular_categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  car_root_categories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
};

export type CategoryRelationResponseCollection = {
  __typename?: 'CategoryRelationResponseCollection';
  data: Array<CategoryEntity>;
};

export type CategoryTree = {
  __typename?: 'CategoryTree';
  id?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
};

export type CategoryTreeInput = {
  levelFrom?: InputMaybe<Scalars['Int']>;
  levelTo?: InputMaybe<Scalars['Int']>;
  locale: Scalars['I18NLocaleCode'];
};

export type CategoryTreeResult = {
  __typename?: 'CategoryTreeResult';
  categoryTree?: Maybe<Array<Maybe<CategoryTree>>>;
  popularCategory?: Maybe<Array<Maybe<PopularCategory>>>;
};

export type CollectionMeta = {
  __typename?: 'CollectionMeta';
  pagination: Pagination;
};

export type ComponentClientDeliveryAddress = {
  __typename?: 'ComponentClientDeliveryAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  main?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
};

export type ComponentClientDeliveryAddressFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentClientDeliveryAddressFiltersInput>>>;
  city?: InputMaybe<StringFilterInput>;
  country?: InputMaybe<StringFilterInput>;
  district?: InputMaybe<StringFilterInput>;
  main?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<ComponentClientDeliveryAddressFiltersInput>;
  number?: InputMaybe<StringFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentClientDeliveryAddressFiltersInput>>>;
  street?: InputMaybe<StringFilterInput>;
  zipCode?: InputMaybe<StringFilterInput>;
};

export type ComponentClientDeliveryAddressInput = {
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  district?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  main?: InputMaybe<Scalars['Boolean']>;
  number?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  zipCode?: InputMaybe<Scalars['String']>;
};

export type ComponentClientEmail = {
  __typename?: 'ComponentClientEmail';
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type ComponentClientEmailFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentClientEmailFiltersInput>>>;
  email?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentClientEmailFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentClientEmailFiltersInput>>>;
};

export type ComponentClientEmailInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type ComponentClientPhoneNumbers = {
  __typename?: 'ComponentClientPhoneNumbers';
  id: Scalars['ID'];
  main?: Maybe<Scalars['Boolean']>;
  number?: Maybe<Scalars['String']>;
};

export type ComponentClientPhoneNumbersFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentClientPhoneNumbersFiltersInput>>>;
  main?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<ComponentClientPhoneNumbersFiltersInput>;
  number?: InputMaybe<StringFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentClientPhoneNumbersFiltersInput>>>;
};

export type ComponentClientPhoneNumbersInput = {
  id?: InputMaybe<Scalars['ID']>;
  main?: InputMaybe<Scalars['Boolean']>;
  number?: InputMaybe<Scalars['String']>;
};

export type ComponentDiscountDiscount = {
  __typename?: 'ComponentDiscountDiscount';
  discount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  supplier?: Maybe<SupplierEntityResponse>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type ComponentDiscountDiscountFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentDiscountDiscountFiltersInput>>>;
  discount?: InputMaybe<IntFilterInput>;
  not?: InputMaybe<ComponentDiscountDiscountFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentDiscountDiscountFiltersInput>>>;
  supplier?: InputMaybe<SupplierFiltersInput>;
  updated?: InputMaybe<DateTimeFilterInput>;
};

export type ComponentOrderStatusEditingHistory = {
  __typename?: 'ComponentOrderStatusEditingHistory';
  date: Scalars['DateTime'];
  id: Scalars['ID'];
  manager?: Maybe<UsersPermissionsUserEntityResponse>;
  status: Enum_Componentorderstatuseditinghistory_Status;
  status_note?: Maybe<Scalars['String']>;
};

export type ComponentOrderStatusEditingHistoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentOrderStatusEditingHistoryFiltersInput>>>;
  date?: InputMaybe<DateTimeFilterInput>;
  manager?: InputMaybe<UsersPermissionsUserFiltersInput>;
  not?: InputMaybe<ComponentOrderStatusEditingHistoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentOrderStatusEditingHistoryFiltersInput>>>;
  status?: InputMaybe<StringFilterInput>;
  status_note?: InputMaybe<StringFilterInput>;
};

export type ComponentOrderStatusEditingHistoryInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  manager?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<Enum_Componentorderstatuseditinghistory_Status>;
  status_note?: InputMaybe<Scalars['String']>;
};

export type ComponentReviewReview = {
  __typename?: 'ComponentReviewReview';
  date?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};

export type ComponentReviewReviewFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ComponentReviewReviewFiltersInput>>>;
  date?: InputMaybe<DateTimeFilterInput>;
  not?: InputMaybe<ComponentReviewReviewFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentReviewReviewFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type ComponentReviewReviewInput = {
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  text?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type ConfirmPhoneNumberInput = {
  code: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ContactNotification = {
  __typename?: 'ContactNotification';
  VINcode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<ContactNotificationRelationResponseCollection>;
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  status?: Maybe<Enum_Contactnotification_Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ContactNotificationLocalizationsArgs = {
  filters?: InputMaybe<ContactNotificationFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContactNotificationEntity = {
  __typename?: 'ContactNotificationEntity';
  attributes?: Maybe<ContactNotification>;
  id?: Maybe<Scalars['ID']>;
};

export type ContactNotificationEntityResponse = {
  __typename?: 'ContactNotificationEntityResponse';
  data?: Maybe<ContactNotificationEntity>;
};

export type ContactNotificationEntityResponseCollection = {
  __typename?: 'ContactNotificationEntityResponseCollection';
  data: Array<ContactNotificationEntity>;
  meta: ResponseCollectionMeta;
};

export type ContactNotificationFiltersInput = {
  VINcode?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ContactNotificationFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<ContactNotificationFiltersInput>;
  message?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ContactNotificationFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ContactNotificationFiltersInput>>>;
  phoneNumber?: InputMaybe<StringFilterInput>;
  status?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ContactNotificationInput = {
  VINcode?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Enum_Contactnotification_Status>;
};

export type ContactNotificationRelationResponseCollection = {
  __typename?: 'ContactNotificationRelationResponseCollection';
  data: Array<ContactNotificationEntity>;
};

export type ContentReleasesRelease = {
  __typename?: 'ContentReleasesRelease';
  actions?: Maybe<ContentReleasesReleaseActionRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  releasedAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type ContentReleasesReleaseActionsArgs = {
  filters?: InputMaybe<ContentReleasesReleaseActionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ContentReleasesReleaseAction = {
  __typename?: 'ContentReleasesReleaseAction';
  contentType: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  entry?: Maybe<GenericMorph>;
  release?: Maybe<ContentReleasesReleaseEntityResponse>;
  type: Enum_Contentreleasesreleaseaction_Type;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ContentReleasesReleaseActionEntity = {
  __typename?: 'ContentReleasesReleaseActionEntity';
  attributes?: Maybe<ContentReleasesReleaseAction>;
  id?: Maybe<Scalars['ID']>;
};

export type ContentReleasesReleaseActionEntityResponse = {
  __typename?: 'ContentReleasesReleaseActionEntityResponse';
  data?: Maybe<ContentReleasesReleaseActionEntity>;
};

export type ContentReleasesReleaseActionEntityResponseCollection = {
  __typename?: 'ContentReleasesReleaseActionEntityResponseCollection';
  data: Array<ContentReleasesReleaseActionEntity>;
  meta: ResponseCollectionMeta;
};

export type ContentReleasesReleaseActionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ContentReleasesReleaseActionFiltersInput>>>;
  contentType?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<ContentReleasesReleaseActionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ContentReleasesReleaseActionFiltersInput>>>;
  release?: InputMaybe<ContentReleasesReleaseFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ContentReleasesReleaseActionInput = {
  contentType?: InputMaybe<Scalars['String']>;
  release?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<Enum_Contentreleasesreleaseaction_Type>;
};

export type ContentReleasesReleaseActionRelationResponseCollection = {
  __typename?: 'ContentReleasesReleaseActionRelationResponseCollection';
  data: Array<ContentReleasesReleaseActionEntity>;
};

export type ContentReleasesReleaseEntity = {
  __typename?: 'ContentReleasesReleaseEntity';
  attributes?: Maybe<ContentReleasesRelease>;
  id?: Maybe<Scalars['ID']>;
};

export type ContentReleasesReleaseEntityResponse = {
  __typename?: 'ContentReleasesReleaseEntityResponse';
  data?: Maybe<ContentReleasesReleaseEntity>;
};

export type ContentReleasesReleaseEntityResponseCollection = {
  __typename?: 'ContentReleasesReleaseEntityResponseCollection';
  data: Array<ContentReleasesReleaseEntity>;
  meta: ResponseCollectionMeta;
};

export type ContentReleasesReleaseFiltersInput = {
  actions?: InputMaybe<ContentReleasesReleaseActionFiltersInput>;
  and?: InputMaybe<Array<InputMaybe<ContentReleasesReleaseFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ContentReleasesReleaseFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ContentReleasesReleaseFiltersInput>>>;
  releasedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ContentReleasesReleaseInput = {
  actions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  releasedAt?: InputMaybe<Scalars['DateTime']>;
};

export type CreateGeneralSpareBrandInput = {
  brandNameDE: Scalars['String'];
  brandNameEN: Scalars['String'];
};

export type CreateGeneralSpareInput = {
  articleCriteriaDE?: InputMaybe<Array<InputMaybe<ArticleCriteriaInput>>>;
  articleCriteriaEN?: InputMaybe<Array<InputMaybe<ArticleCriteriaInput>>>;
  brandId: Scalars['Int'];
  descriptionsInfoDE?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  descriptionsInfoEN?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eanNumber?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  images?: InputMaybe<Array<InputMaybe<ImageInput>>>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  tecdoc_articleNumber: Scalars['String'];
  titleDE: Scalars['String'];
  titleEN: Scalars['String'];
};

export type CreateOrderDeliveryAddressInput = {
  city: Scalars['String'];
  country: Scalars['String'];
  district?: InputMaybe<Scalars['String']>;
  house: Scalars['String'];
  street: Scalars['String'];
  zipCode: Scalars['String'];
};

export type CreateOrderShopInput = {
  car?: InputMaybe<CarFilter>;
  comment?: InputMaybe<Scalars['String']>;
  deliveryAddress: CreateOrderDeliveryAddressInput;
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  orderItems: Array<OrderShopItemInput>;
  paymentMethodId: Scalars['ID'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  totalPrice: Scalars['Float'];
};

export type CreateOrderShopResult = {
  __typename?: 'CreateOrderShopResult';
  id?: Maybe<Scalars['String']>;
};

export type CurrentUser = {
  __typename?: 'CurrentUser';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type CustomerAccount = {
  __typename?: 'CustomerAccount';
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['Long'];
  total?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerAccountEntity = {
  __typename?: 'CustomerAccountEntity';
  attributes?: Maybe<CustomerAccount>;
  id?: Maybe<Scalars['ID']>;
};

export type CustomerAccountEntityResponse = {
  __typename?: 'CustomerAccountEntityResponse';
  data?: Maybe<CustomerAccountEntity>;
};

export type CustomerAccountEntityResponseCollection = {
  __typename?: 'CustomerAccountEntityResponseCollection';
  data: Array<CustomerAccountEntity>;
  meta: ResponseCollectionMeta;
};

export type CustomerAccountFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CustomerAccountFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customerId?: InputMaybe<LongFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<CustomerAccountFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CustomerAccountFiltersInput>>>;
  total?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CustomerAccountTransaction = {
  __typename?: 'CustomerAccountTransaction';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customerId: Scalars['Long'];
  documentId?: Maybe<Scalars['Int']>;
  documentNumber?: Maybe<Scalars['String']>;
  documentType?: Maybe<Enum_Customeraccounttransaction_Documenttype>;
  total?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerAccountTransactionEntity = {
  __typename?: 'CustomerAccountTransactionEntity';
  attributes?: Maybe<CustomerAccountTransaction>;
  id?: Maybe<Scalars['ID']>;
};

export type CustomerAccountTransactionEntityResponse = {
  __typename?: 'CustomerAccountTransactionEntityResponse';
  data?: Maybe<CustomerAccountTransactionEntity>;
};

export type CustomerAccountTransactionEntityResponseCollection = {
  __typename?: 'CustomerAccountTransactionEntityResponseCollection';
  data: Array<CustomerAccountTransactionEntity>;
  meta: ResponseCollectionMeta;
};

export type CustomerAccountTransactionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CustomerAccountTransactionFiltersInput>>>;
  comment?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customerId?: InputMaybe<LongFilterInput>;
  documentId?: InputMaybe<IntFilterInput>;
  documentNumber?: InputMaybe<StringFilterInput>;
  documentType?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<CustomerAccountTransactionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CustomerAccountTransactionFiltersInput>>>;
  total?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CustomerBalanceInput = {
  customerId: Scalars['ID'];
};

export type CustomerBill = {
  __typename?: 'CustomerBill';
  billDate?: Maybe<Scalars['Date']>;
  billNumber?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<UsersPermissionsUserEntityResponse>;
  orders?: Maybe<OrderRelationResponseCollection>;
  total?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CustomerBillOrdersArgs = {
  filters?: InputMaybe<OrderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CustomerBillEntity = {
  __typename?: 'CustomerBillEntity';
  attributes?: Maybe<CustomerBill>;
  id?: Maybe<Scalars['ID']>;
};

export type CustomerBillEntityResponse = {
  __typename?: 'CustomerBillEntityResponse';
  data?: Maybe<CustomerBillEntity>;
};

export type CustomerBillEntityResponseCollection = {
  __typename?: 'CustomerBillEntityResponseCollection';
  data: Array<CustomerBillEntity>;
  meta: ResponseCollectionMeta;
};

export type CustomerBillFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CustomerBillFiltersInput>>>;
  billDate?: InputMaybe<DateFilterInput>;
  billNumber?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer?: InputMaybe<UsersPermissionsUserFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<CustomerBillFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CustomerBillFiltersInput>>>;
  orders?: InputMaybe<OrderFiltersInput>;
  total?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CustomerBillInput = {
  billDate?: InputMaybe<Scalars['Date']>;
  orders?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type CustomerBillRelationResponseCollection = {
  __typename?: 'CustomerBillRelationResponseCollection';
  data: Array<CustomerBillEntity>;
};

export type CustomerContactInfo = {
  __typename?: 'CustomerContactInfo';
  VATId?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryAddress?: Maybe<Array<Maybe<ComponentClientDeliveryAddress>>>;
  dob?: Maybe<Scalars['Date']>;
  emails?: Maybe<Array<Maybe<ComponentClientEmail>>>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumbers?: Maybe<Array<Maybe<ComponentClientPhoneNumbers>>>;
  serviceName?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};


export type CustomerContactInfoDeliveryAddressArgs = {
  filters?: InputMaybe<ComponentClientDeliveryAddressFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CustomerContactInfoEmailsArgs = {
  filters?: InputMaybe<ComponentClientEmailFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type CustomerContactInfoPhoneNumbersArgs = {
  filters?: InputMaybe<ComponentClientPhoneNumbersFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CustomerContactInfoEntity = {
  __typename?: 'CustomerContactInfoEntity';
  attributes?: Maybe<CustomerContactInfo>;
  id?: Maybe<Scalars['ID']>;
};

export type CustomerContactInfoEntityResponse = {
  __typename?: 'CustomerContactInfoEntityResponse';
  data?: Maybe<CustomerContactInfoEntity>;
};

export type CustomerContactInfoEntityResponseCollection = {
  __typename?: 'CustomerContactInfoEntityResponseCollection';
  data: Array<CustomerContactInfoEntity>;
  meta: ResponseCollectionMeta;
};

export type CustomerContactInfoFiltersInput = {
  VATId?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<CustomerContactInfoFiltersInput>>>;
  companyName?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  deliveryAddress?: InputMaybe<ComponentClientDeliveryAddressFiltersInput>;
  dob?: InputMaybe<DateFilterInput>;
  emails?: InputMaybe<ComponentClientEmailFiltersInput>;
  firstName?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lastName?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<CustomerContactInfoFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CustomerContactInfoFiltersInput>>>;
  phoneNumbers?: InputMaybe<ComponentClientPhoneNumbersFiltersInput>;
  serviceName?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type CustomerContactInfoInput = {
  VATId?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  deliveryAddress?: InputMaybe<Array<InputMaybe<ComponentClientDeliveryAddressInput>>>;
  dob?: InputMaybe<Scalars['Date']>;
  emails?: InputMaybe<Array<InputMaybe<ComponentClientEmailInput>>>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumbers?: InputMaybe<Array<InputMaybe<ComponentClientPhoneNumbersInput>>>;
  serviceName?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['ID']>;
};

export type CustomerPayment = {
  __typename?: 'CustomerPayment';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<UsersPermissionsUserEntityResponse>;
  paymentDate?: Maybe<Scalars['Date']>;
  paymentDirection: Enum_Customerpayment_Paymentdirection;
  paymentMethod: Enum_Customerpayment_Paymentmethod;
  paymentNumber?: Maybe<Scalars['String']>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerPaymentEntity = {
  __typename?: 'CustomerPaymentEntity';
  attributes?: Maybe<CustomerPayment>;
  id?: Maybe<Scalars['ID']>;
};

export type CustomerPaymentEntityResponse = {
  __typename?: 'CustomerPaymentEntityResponse';
  data?: Maybe<CustomerPaymentEntity>;
};

export type CustomerPaymentEntityResponseCollection = {
  __typename?: 'CustomerPaymentEntityResponseCollection';
  data: Array<CustomerPaymentEntity>;
  meta: ResponseCollectionMeta;
};

export type CustomerPaymentFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CustomerPaymentFiltersInput>>>;
  comment?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer?: InputMaybe<UsersPermissionsUserFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<CustomerPaymentFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CustomerPaymentFiltersInput>>>;
  paymentDate?: InputMaybe<DateFilterInput>;
  paymentDirection?: InputMaybe<StringFilterInput>;
  paymentMethod?: InputMaybe<StringFilterInput>;
  paymentNumber?: InputMaybe<StringFilterInput>;
  total?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CustomerPaymentInput = {
  comment?: InputMaybe<Scalars['String']>;
  customer: Scalars['ID'];
  orderId?: InputMaybe<Scalars['ID']>;
  paymentDate: Scalars['Date'];
  paymentDirection: Enum_Customerpayment_Paymentdirection;
  paymentMethod: Enum_Customerpayment_Paymentmethod;
  paymentNumber?: InputMaybe<Scalars['String']>;
  total: Scalars['Float'];
};

export type CustomerPaymentRelationResponseCollection = {
  __typename?: 'CustomerPaymentRelationResponseCollection';
  data: Array<CustomerPaymentEntity>;
};

export type CustomerReturn = {
  __typename?: 'CustomerReturn';
  correctiveInvoiceDate?: Maybe<Scalars['Date']>;
  correctiveInvoiceNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<UsersPermissionsUserEntityResponse>;
  customer_return_items?: Maybe<CustomerReturnItemRelationResponseCollection>;
  dateReturned?: Maybe<Scalars['Date']>;
  status: Enum_Customerreturn_Status;
  total?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type CustomerReturnCustomer_Return_ItemsArgs = {
  filters?: InputMaybe<CustomerReturnItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type CustomerReturnEntity = {
  __typename?: 'CustomerReturnEntity';
  attributes?: Maybe<CustomerReturn>;
  id?: Maybe<Scalars['ID']>;
};

export type CustomerReturnEntityResponse = {
  __typename?: 'CustomerReturnEntityResponse';
  data?: Maybe<CustomerReturnEntity>;
};

export type CustomerReturnEntityResponseCollection = {
  __typename?: 'CustomerReturnEntityResponseCollection';
  data: Array<CustomerReturnEntity>;
  meta: ResponseCollectionMeta;
};

export type CustomerReturnFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CustomerReturnFiltersInput>>>;
  correctiveInvoiceDate?: InputMaybe<DateFilterInput>;
  correctiveInvoiceNumber?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer?: InputMaybe<UsersPermissionsUserFiltersInput>;
  customer_return_items?: InputMaybe<CustomerReturnItemFiltersInput>;
  dateReturned?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<CustomerReturnFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CustomerReturnFiltersInput>>>;
  status?: InputMaybe<StringFilterInput>;
  total?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CustomerReturnInput = {
  correctiveInvoiceDate?: InputMaybe<Scalars['Date']>;
  correctiveInvoiceNumber?: InputMaybe<Scalars['String']>;
  customer?: InputMaybe<Scalars['ID']>;
  dateReturned?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Enum_Customerreturn_Status>;
};

export type CustomerReturnItem = {
  __typename?: 'CustomerReturnItem';
  createdAt?: Maybe<Scalars['DateTime']>;
  customerPrice: Scalars['Float'];
  customer_return?: Maybe<CustomerReturnEntityResponse>;
  order_item?: Maybe<OrderItemEntityResponse>;
  quantityReturned: Scalars['Int'];
  returnComment?: Maybe<Scalars['String']>;
  returnReason?: Maybe<Scalars['String']>;
  stock?: Maybe<StockEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CustomerReturnItemEntity = {
  __typename?: 'CustomerReturnItemEntity';
  attributes?: Maybe<CustomerReturnItem>;
  id?: Maybe<Scalars['ID']>;
};

export type CustomerReturnItemEntityResponse = {
  __typename?: 'CustomerReturnItemEntityResponse';
  data?: Maybe<CustomerReturnItemEntity>;
};

export type CustomerReturnItemEntityResponseCollection = {
  __typename?: 'CustomerReturnItemEntityResponseCollection';
  data: Array<CustomerReturnItemEntity>;
  meta: ResponseCollectionMeta;
};

export type CustomerReturnItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<CustomerReturnItemFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customerPrice?: InputMaybe<FloatFilterInput>;
  customer_return?: InputMaybe<CustomerReturnFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<CustomerReturnItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<CustomerReturnItemFiltersInput>>>;
  order_item?: InputMaybe<OrderItemFiltersInput>;
  quantityReturned?: InputMaybe<IntFilterInput>;
  returnComment?: InputMaybe<StringFilterInput>;
  returnReason?: InputMaybe<StringFilterInput>;
  stock?: InputMaybe<StockFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type CustomerReturnItemRelationResponseCollection = {
  __typename?: 'CustomerReturnItemRelationResponseCollection';
  data: Array<CustomerReturnItemEntity>;
};

export type CustomerReturnRelationResponseCollection = {
  __typename?: 'CustomerReturnRelationResponseCollection';
  data: Array<CustomerReturnEntity>;
};

export type CustomerTransactionEntity = {
  __typename?: 'CustomerTransactionEntity';
  comment?: Maybe<Scalars['String']>;
  docAmount?: Maybe<Scalars['Float']>;
  docDate: Scalars['Date'];
  docId: Scalars['ID'];
  docNumber?: Maybe<Scalars['String']>;
  docType?: Maybe<DocType>;
};

export type CustomerTransactionEntityCollections = {
  __typename?: 'CustomerTransactionEntityCollections';
  data: Array<CustomerTransactionEntity>;
  meta: CollectionMeta;
};

export type CustomerTransactionsFiltersInput = {
  customerId: Scalars['ID'];
  dateEnd: Scalars['Date'];
  dateStart: Scalars['Date'];
  /** filter by number of document (contains, case insensitive) */
  docNumber?: InputMaybe<Scalars['String']>;
  /** filter by types of document */
  docType?: InputMaybe<Array<InputMaybe<DocType>>>;
};

export type DataDeviderState = {
  __typename?: 'DataDeviderState';
  idOrder: Scalars['String'];
  isOpenModal: Scalars['Boolean'];
  orderNumber: Scalars['String'];
};

export type DateFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  contains?: InputMaybe<Scalars['Date']>;
  containsi?: InputMaybe<Scalars['Date']>;
  endsWith?: InputMaybe<Scalars['Date']>;
  eq?: InputMaybe<Scalars['Date']>;
  eqi?: InputMaybe<Scalars['Date']>;
  gt?: InputMaybe<Scalars['Date']>;
  gte?: InputMaybe<Scalars['Date']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  lt?: InputMaybe<Scalars['Date']>;
  lte?: InputMaybe<Scalars['Date']>;
  ne?: InputMaybe<Scalars['Date']>;
  nei?: InputMaybe<Scalars['Date']>;
  not?: InputMaybe<DateFilterInput>;
  notContains?: InputMaybe<Scalars['Date']>;
  notContainsi?: InputMaybe<Scalars['Date']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Date']>>>;
  startsWith?: InputMaybe<Scalars['Date']>;
};

export type DateTimeFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  contains?: InputMaybe<Scalars['DateTime']>;
  containsi?: InputMaybe<Scalars['DateTime']>;
  endsWith?: InputMaybe<Scalars['DateTime']>;
  eq?: InputMaybe<Scalars['DateTime']>;
  eqi?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  ne?: InputMaybe<Scalars['DateTime']>;
  nei?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']>;
  notContainsi?: InputMaybe<Scalars['DateTime']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']>;
};

export type DiscountInput = {
  deleteDiscount?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  supplierId?: InputMaybe<Scalars['ID']>;
};

export enum DocType {
  IncomingPaymentCash = 'incoming_payment_cash',
  IncomingPaymentNonCash = 'incoming_payment_non_cash',
  Order = 'order',
  OutgoingPaymentCash = 'outgoing_payment_cash',
  OutgoingPaymentNonCash = 'outgoing_payment_non_cash',
  Return = 'return'
}

export enum Enum_Banner_Size {
  Desktop = 'desktop',
  Mobile = 'mobile'
}

export enum Enum_Componentorderstatuseditinghistory_Status {
  Archived = 'archived',
  Awaiting = 'awaiting',
  Completed = 'completed',
  Coordination = 'coordination',
  Created = 'created',
  Delivery = 'delivery',
  Editing = 'editing',
  InStock = 'in_stock',
  Ordered = 'ordered',
  PickedUp = 'picked_up'
}

export enum Enum_Contactnotification_Status {
  Completed = 'completed',
  InProcess = 'inProcess',
  New = 'new'
}

export enum Enum_Contentreleasesreleaseaction_Type {
  Publish = 'publish',
  Unpublish = 'unpublish'
}

export enum Enum_Customeraccounttransaction_Documenttype {
  Order = 'order',
  PaymentIncomingCash = 'payment_incoming_cash',
  PaymentIncomingNonCash = 'payment_incoming_non_cash',
  PaymentOutgoingCash = 'payment_outgoing_cash',
  PaymentOutgoingNonCash = 'payment_outgoing_non_cash',
  Return = 'return'
}

export enum Enum_Customerpayment_Paymentdirection {
  Incoming = 'incoming',
  Outgoing = 'outgoing'
}

export enum Enum_Customerpayment_Paymentmethod {
  Cash = 'cash',
  NonCash = 'non_cash'
}

export enum Enum_Customerreturn_Status {
  Completed = 'completed',
  Created = 'created'
}

export enum Enum_Incominginvoice_Status {
  Completed = 'completed',
  Created = 'created'
}

export enum Enum_Joblog_Status {
  Error = 'Error',
  InProgress = 'In_progress',
  Success = 'Success'
}

export enum Enum_Language_Type {
  De = 'de',
  En = 'en',
  Uk = 'uk'
}

export enum Enum_Method_To_Return {
  AddToCustomerReturn = 'AddToCustomerReturn',
  RemoveFromOrder = 'RemoveFromOrder'
}

export enum Enum_Mode_Delivery_State {
  Delivery = 'delivery',
  Warehouse = 'warehouse'
}

export enum Enum_Move_To {
  Delivery = 'Delivery',
  PickUp = 'PickUp'
}

export enum Enum_Newslettersubscription_Source {
  Footer = 'footer',
  HomePage = 'home_page'
}

export enum Enum_Newslettersubscription_Status {
  Archived = 'archived',
  Processed = 'processed',
  Unprocessed = 'unprocessed'
}

export enum Enum_Nosparepart_Source {
  ProductList = 'product_list'
}

export enum Enum_Nosparepart_Status {
  Archived = 'archived',
  Processed = 'processed',
  Unprocessed = 'unprocessed'
}

export enum Enum_Order_Action_Type {
  SetIsOrderConfirmed = 'SetIsOrderConfirmed',
  SetIsOrdered = 'SetIsOrdered',
  UnsetIsOrdered = 'UnsetIsOrdered'
}

export enum Enum_Order_Status {
  Archived = 'archived',
  Awaiting = 'awaiting',
  Completed = 'completed',
  Coordination = 'coordination',
  Created = 'created',
  Delivery = 'delivery',
  Editing = 'editing',
  InStock = 'in_stock',
  Ordered = 'ordered',
  PickedUp = 'picked_up'
}

export enum Enum_Receive_For {
  Stock = 'Stock',
  Supplier = 'Supplier'
}

export enum Enum_Search_By {
  ArticleNumber = 'ArticleNumber',
  BrandName = 'BrandName',
  ComparableNumbers = 'ComparableNumbers',
  OemNumbers = 'OEMNumbers',
  Title = 'Title'
}

export enum Enum_Shop_Order_Status {
  Completed = 'completed',
  Created = 'created',
  Processing = 'processing'
}

export enum Enum_Specialproposal_System_Name {
  BiggestDiscounts = 'biggest_discounts',
  TopOffers = 'top_offers',
  TopSales = 'top_sales',
  Wishlist = 'wishlist'
}

export enum Enum_Stockhistory_Changebalancequantity {
  Minus = 'minus',
  NoChange = 'noChange',
  Plus = 'plus'
}

export enum Enum_Supplierpaymentdetail_Paymenttype {
  Payment = 'payment',
  Return = 'return'
}

export enum Enum_Supplierpayment_Paymentdirection {
  Incoming = 'incoming',
  Outgoing = 'outgoing'
}

export enum Enum_Supplierpayment_Paymentmethod {
  Cash = 'cash',
  NonCash = 'non_cash'
}

export enum Enum_Supplierreturn_Status {
  Completed = 'completed',
  Created = 'created'
}

export type ErrorMessageType = {
  __typename?: 'ErrorMessageType';
  code: Scalars['String'];
  message: Scalars['String'];
  time: Scalars['String'];
};

export type FileInfoInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type FilterRangeInput = {
  car?: InputMaybe<CarFilter>;
  locale: Scalars['I18NLocaleCode'];
  maxPrice?: InputMaybe<Scalars['Float']>;
  minPrice?: InputMaybe<Scalars['Float']>;
  searchBy?: InputMaybe<Array<InputMaybe<Enum_Search_By>>>;
  /**  search by title, articleNumber and brandName  */
  searchText?: InputMaybe<Scalars['String']>;
  tecdoc_categoryId?: InputMaybe<Scalars['String']>;
};

export type FilterRangeResult = {
  __typename?: 'FilterRangeResult';
  brands?: Maybe<Array<Maybe<Brand>>>;
  hasDiscount?: Maybe<Array<Maybe<Scalars['Boolean']>>>;
  maxPrice?: Maybe<Scalars['Float']>;
  minPrice?: Maybe<Scalars['Float']>;
};

export type FloatFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  contains?: InputMaybe<Scalars['Float']>;
  containsi?: InputMaybe<Scalars['Float']>;
  endsWith?: InputMaybe<Scalars['Float']>;
  eq?: InputMaybe<Scalars['Float']>;
  eqi?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
  nei?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']>;
  notContainsi?: InputMaybe<Scalars['Float']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  startsWith?: InputMaybe<Scalars['Float']>;
};

export type FloatGteLteInput = {
  gte?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
};

export type ForgotPasswordByPhoneNumberInput = {
  phoneNumber: Scalars['String'];
  token: Scalars['String'];
};

export type GeneralComponentReviewReview = {
  __typename?: 'GeneralComponentReviewReview';
  date?: Maybe<Scalars['DateTime']>;
  text?: Maybe<Scalars['String']>;
  userFirstName?: Maybe<Scalars['String']>;
  userLastName?: Maybe<Scalars['String']>;
};

export type GeneralDiscounts = {
  __typename?: 'GeneralDiscounts';
  discount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  supplier?: Maybe<GeneralSupplier>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type GeneralReview = {
  __typename?: 'GeneralReview';
  rating?: Maybe<Scalars['Int']>;
  reply?: Maybe<GeneralComponentReviewReview>;
  review?: Maybe<GeneralComponentReviewReview>;
};

export type GeneralReviewEntity = {
  __typename?: 'GeneralReviewEntity';
  attributes?: Maybe<GeneralReview>;
  id?: Maybe<Scalars['ID']>;
};

export type GeneralReviewEntityResponseCollection = {
  __typename?: 'GeneralReviewEntityResponseCollection';
  data?: Maybe<Array<Maybe<GeneralReviewEntity>>>;
  meta: CollectionMeta;
};

export type GeneralSpare = {
  __typename?: 'GeneralSpare';
  articleCriteria?: Maybe<Scalars['JSON']>;
  brandId?: Maybe<Scalars['Int']>;
  brandName?: Maybe<Scalars['String']>;
  descriptionsInfo?: Maybe<Scalars['JSON']>;
  discounts?: Maybe<Array<Maybe<GeneralDiscounts>>>;
  eanNumber?: Maybe<Scalars['JSON']>;
  hasDiscount?: Maybe<Scalars['Boolean']>;
  images?: Maybe<Scalars['JSON']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  isDraft?: Maybe<Scalars['Boolean']>;
  maxDiscount?: Maybe<Scalars['Int']>;
  oemNumbers?: Maybe<Scalars['JSON']>;
  price?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
  reviewCount?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['Int']>;
  suppliersSpare?: Maybe<Array<Maybe<GeneralSuppliersSpare>>>;
  tecdoc_articleNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type GeneralSparesCarFilter = {
  tecdoc_carId: Scalars['String'];
  tecdoc_carType: Scalars['String'];
};

export type GeneralSparesEntity = {
  __typename?: 'GeneralSparesEntity';
  attributes?: Maybe<GeneralSpare>;
  id: Scalars['ID'];
};

export type GeneralSparesEntityResponse = {
  __typename?: 'GeneralSparesEntityResponse';
  data?: Maybe<GeneralSparesEntity>;
};

export type GeneralSparesEntityResponseCollection = {
  __typename?: 'GeneralSparesEntityResponseCollection';
  data: Array<GeneralSparesEntity>;
  meta?: Maybe<CollectionMeta>;
};

export type GeneralSparesFiltersInput = {
  brandIds?: InputMaybe<Array<Scalars['Int']>>;
  car?: InputMaybe<GeneralSparesCarFilter>;
  hasDiscount?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  price?: InputMaybe<FloatGteLteInput>;
  searchBy?: InputMaybe<Array<InputMaybe<Enum_Search_By>>>;
  /**  search by title, articleNumber, brandName, oem numbers and comparable numbers  */
  searchText?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['Boolean']>;
  tecdoc_categoryId?: InputMaybe<Scalars['String']>;
};

export type GeneralSparesRelationSpecialProposalCollection = {
  __typename?: 'GeneralSparesRelationSpecialProposalCollection';
  data: Array<GeneralSparesEntity>;
};

export type GeneralSupplier = {
  __typename?: 'GeneralSupplier';
  deliveryDay?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  priceCountRow?: Maybe<Scalars['Int']>;
  priceLastUpdate?: Maybe<Scalars['DateTime']>;
};

export type GeneralSuppliersSpare = {
  __typename?: 'GeneralSuppliersSpare';
  amount?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['Int']>;
  discount?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  minOrderQuantity?: Maybe<Scalars['Int']>;
  prevPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  priceByRole?: Maybe<PriceByRole>;
  rowVersion?: Maybe<Scalars['Float']>;
  supplier?: Maybe<GeneralSupplier>;
  supplierPrice?: Maybe<Scalars['Float']>;
  tecdoc_articleNumber?: Maybe<Scalars['String']>;
  warehouse?: Maybe<Scalars['JSON']>;
};

export type GeneralSuppliersSpareCreateInput = {
  amount: Scalars['Int'];
  brandId?: InputMaybe<Scalars['Int']>;
  supplierId: Scalars['ID'];
  supplierPrice: Scalars['Float'];
  tecdoc_articleNumber: Scalars['String'];
};

export type GeneralSuppliersSpareUpdateInput = {
  amount?: InputMaybe<Scalars['String']>;
  supplierPrice?: InputMaybe<Scalars['Float']>;
};

export type GenericMorph = BackupSetting | Banner | Car | CarBrand | CarBrandLocale | CarCategory | CarLocale | CarModel | CarModelLocale | CarSpare | CarSpareBrand | CarSpareLocale | Category | ComponentClientDeliveryAddress | ComponentClientEmail | ComponentClientPhoneNumbers | ComponentDiscountDiscount | ComponentOrderStatusEditingHistory | ComponentReviewReview | ContactNotification | ContentReleasesRelease | ContentReleasesReleaseAction | CustomerAccount | CustomerAccountTransaction | CustomerBill | CustomerContactInfo | CustomerPayment | CustomerReturn | CustomerReturnItem | I18NLocale | IncomingInvoice | JobLog | Markup | NewsletterSubscription | NoSparePart | Order | OrderItem | PaymentMethod | Review | Spare | SpareArchive | SpecialProposal | Stock | StockCellAddress | StockHistory | StockReturnedFromCustomer | Supplier | SupplierAccount | SupplierPayment | SupplierPaymentDetail | SupplierReturn | SupplierReturnItem | UploadFile | UploadFolder | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser | Vehicle;

export type I18NLocale = {
  __typename?: 'I18NLocale';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type I18NLocaleEntity = {
  __typename?: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']>;
};

export type I18NLocaleEntityResponse = {
  __typename?: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
};

export type I18NLocaleEntityResponseCollection = {
  __typename?: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
};

export type I18NLocaleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IdFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  containsi?: InputMaybe<Scalars['ID']>;
  endsWith?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  eqi?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  gte?: InputMaybe<Scalars['ID']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  lt?: InputMaybe<Scalars['ID']>;
  lte?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  nei?: InputMaybe<Scalars['ID']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']>;
  notContainsi?: InputMaybe<Scalars['ID']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  startsWith?: InputMaybe<Scalars['ID']>;
};

export type ImageInput = {
  imageID200?: InputMaybe<Scalars['ID']>;
  imageID400?: InputMaybe<Scalars['ID']>;
  imageID1600?: InputMaybe<Scalars['ID']>;
  imageURL200?: InputMaybe<Scalars['String']>;
  imageURL400?: InputMaybe<Scalars['String']>;
  imageURL1600?: InputMaybe<Scalars['String']>;
};

export type IncomingInvoice = {
  __typename?: 'IncomingInvoice';
  createdAt?: Maybe<Scalars['DateTime']>;
  date: Scalars['Date'];
  debtTotal?: Maybe<Scalars['Float']>;
  status?: Maybe<Enum_Incominginvoice_Status>;
  stocks?: Maybe<StockRelationResponseCollection>;
  supplier?: Maybe<SupplierEntityResponse>;
  supplierInvoiceDate?: Maybe<Scalars['Date']>;
  supplierInvoiceNumber?: Maybe<Scalars['String']>;
  supplierInvoiceTotal?: Maybe<Scalars['Float']>;
  supplier_payment_details?: Maybe<SupplierPaymentDetailRelationResponseCollection>;
  total?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type IncomingInvoiceStocksArgs = {
  filters?: InputMaybe<StockFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type IncomingInvoiceSupplier_Payment_DetailsArgs = {
  filters?: InputMaybe<SupplierPaymentDetailFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type IncomingInvoiceEntity = {
  __typename?: 'IncomingInvoiceEntity';
  attributes?: Maybe<IncomingInvoice>;
  id?: Maybe<Scalars['ID']>;
};

export type IncomingInvoiceEntityResponse = {
  __typename?: 'IncomingInvoiceEntityResponse';
  data?: Maybe<IncomingInvoiceEntity>;
};

export type IncomingInvoiceEntityResponseCollection = {
  __typename?: 'IncomingInvoiceEntityResponseCollection';
  data: Array<IncomingInvoiceEntity>;
  meta: ResponseCollectionMeta;
};

export type IncomingInvoiceFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<IncomingInvoiceFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  date?: InputMaybe<DateFilterInput>;
  debtTotal?: InputMaybe<FloatFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<IncomingInvoiceFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<IncomingInvoiceFiltersInput>>>;
  status?: InputMaybe<StringFilterInput>;
  stocks?: InputMaybe<StockFiltersInput>;
  supplier?: InputMaybe<SupplierFiltersInput>;
  supplierInvoiceDate?: InputMaybe<DateFilterInput>;
  supplierInvoiceNumber?: InputMaybe<StringFilterInput>;
  supplierInvoiceTotal?: InputMaybe<FloatFilterInput>;
  supplier_payment_details?: InputMaybe<SupplierPaymentDetailFiltersInput>;
  total?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type IncomingInvoiceInput = {
  date?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<Enum_Incominginvoice_Status>;
  stocks?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  supplier?: InputMaybe<Scalars['ID']>;
  supplierInvoiceDate?: InputMaybe<Scalars['Date']>;
  supplierInvoiceNumber?: InputMaybe<Scalars['String']>;
  supplierInvoiceTotal?: InputMaybe<Scalars['Float']>;
};

export type IncomingInvoicePaymentInput = {
  amount: Scalars['Float'];
  id: Scalars['ID'];
};

export type IncomingInvoiceRelationResponseCollection = {
  __typename?: 'IncomingInvoiceRelationResponseCollection';
  data: Array<IncomingInvoiceEntity>;
};

export type IncomingInvoiceReturnInput = {
  amount: Scalars['Float'];
  id: Scalars['ID'];
};

export type IntFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contains?: InputMaybe<Scalars['Int']>;
  containsi?: InputMaybe<Scalars['Int']>;
  endsWith?: InputMaybe<Scalars['Int']>;
  eq?: InputMaybe<Scalars['Int']>;
  eqi?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  nei?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']>;
  notContainsi?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startsWith?: InputMaybe<Scalars['Int']>;
};

export type JsonFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  contains?: InputMaybe<Scalars['JSON']>;
  containsi?: InputMaybe<Scalars['JSON']>;
  endsWith?: InputMaybe<Scalars['JSON']>;
  eq?: InputMaybe<Scalars['JSON']>;
  eqi?: InputMaybe<Scalars['JSON']>;
  gt?: InputMaybe<Scalars['JSON']>;
  gte?: InputMaybe<Scalars['JSON']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  lt?: InputMaybe<Scalars['JSON']>;
  lte?: InputMaybe<Scalars['JSON']>;
  ne?: InputMaybe<Scalars['JSON']>;
  nei?: InputMaybe<Scalars['JSON']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']>;
  notContainsi?: InputMaybe<Scalars['JSON']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']>>>;
  startsWith?: InputMaybe<Scalars['JSON']>;
};

export type JobLog = {
  __typename?: 'JobLog';
  createdAt?: Maybe<Scalars['DateTime']>;
  finish?: Maybe<Scalars['DateTime']>;
  log?: Maybe<Scalars['JSON']>;
  name?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Enum_Joblog_Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type JobLogEntity = {
  __typename?: 'JobLogEntity';
  attributes?: Maybe<JobLog>;
  id?: Maybe<Scalars['ID']>;
};

export type JobLogEntityResponse = {
  __typename?: 'JobLogEntityResponse';
  data?: Maybe<JobLogEntity>;
};

export type JobLogEntityResponseCollection = {
  __typename?: 'JobLogEntityResponseCollection';
  data: Array<JobLogEntity>;
  meta: ResponseCollectionMeta;
};

export type JobLogFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<JobLogFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  finish?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  log?: InputMaybe<JsonFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<JobLogFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<JobLogFiltersInput>>>;
  start?: InputMaybe<DateTimeFilterInput>;
  status?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type JobLogInput = {
  finish?: InputMaybe<Scalars['DateTime']>;
  log?: InputMaybe<Scalars['JSON']>;
  name?: InputMaybe<Scalars['String']>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Enum_Joblog_Status>;
};

export type LoginByPhoneNumberInput = {
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type LongFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  contains?: InputMaybe<Scalars['Long']>;
  containsi?: InputMaybe<Scalars['Long']>;
  endsWith?: InputMaybe<Scalars['Long']>;
  eq?: InputMaybe<Scalars['Long']>;
  eqi?: InputMaybe<Scalars['Long']>;
  gt?: InputMaybe<Scalars['Long']>;
  gte?: InputMaybe<Scalars['Long']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  lt?: InputMaybe<Scalars['Long']>;
  lte?: InputMaybe<Scalars['Long']>;
  ne?: InputMaybe<Scalars['Long']>;
  nei?: InputMaybe<Scalars['Long']>;
  not?: InputMaybe<LongFilterInput>;
  notContains?: InputMaybe<Scalars['Long']>;
  notContainsi?: InputMaybe<Scalars['Long']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Long']>>>;
  startsWith?: InputMaybe<Scalars['Long']>;
};

export type MakePayload = {
  __typename?: 'MakePayload';
  ok?: Maybe<Scalars['Boolean']>;
};

export type Markup = {
  __typename?: 'Markup';
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<Scalars['Int']>;
  customer30?: Maybe<Scalars['Int']>;
  customer40?: Maybe<Scalars['Int']>;
  employee?: Maybe<Scalars['Int']>;
  permanentWholesaleCustomer?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  wholesaleCustomer?: Maybe<Scalars['Int']>;
};

export type MarkupEntity = {
  __typename?: 'MarkupEntity';
  attributes?: Maybe<Markup>;
  id?: Maybe<Scalars['ID']>;
};

export type MarkupEntityResponse = {
  __typename?: 'MarkupEntityResponse';
  data?: Maybe<MarkupEntity>;
};

export type MarkupInput = {
  customer?: InputMaybe<Scalars['Int']>;
  customer30?: InputMaybe<Scalars['Int']>;
  customer40?: InputMaybe<Scalars['Int']>;
  employee?: InputMaybe<Scalars['Int']>;
  permanentWholesaleCustomer?: InputMaybe<Scalars['Int']>;
  wholesaleCustomer?: InputMaybe<Scalars['Int']>;
};

export type MoveOrderItemToInput = {
  moveTo: Enum_Move_To;
  orderId: Scalars['ID'];
  quantity: Scalars['Int'];
  stockBarcode: Scalars['String'];
};

export type MoveOrderItemToResult = {
  __typename?: 'MoveOrderItemToResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  accessTecDoc?: Maybe<MakePayload>;
  addBarcodeToCarSpare?: Maybe<GeneralSparesEntityResponse>;
  addReview?: Maybe<ReviewEntityResponse>;
  addStock?: Maybe<AddStockResult>;
  addStockFromOrderItem?: Maybe<AddStockFromOrderItemResult>;
  autoOrdering?: Maybe<Array<Maybe<OrderItemEntity>>>;
  blockSupplier?: Maybe<Scalars['Boolean']>;
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  completeOrder?: Maybe<OrderEntityResponse>;
  confirmPhoneNumber?: Maybe<UsersPermissionsLoginPayload>;
  createBanner?: Maybe<BannerEntityResponse>;
  createBannerLocalization?: Maybe<BannerEntityResponse>;
  createCarBrandLocale?: Maybe<CarBrandLocaleEntityResponse>;
  createCarBrandLocaleLocalization?: Maybe<CarBrandLocaleEntityResponse>;
  createCarCategory?: Maybe<CarCategoryEntityResponse>;
  createCarCategoryLocalization?: Maybe<CarCategoryEntityResponse>;
  createCarLocale?: Maybe<CarLocaleEntityResponse>;
  createCarLocaleLocalization?: Maybe<CarLocaleEntityResponse>;
  createCarModelLocale?: Maybe<CarModelLocaleEntityResponse>;
  createCarModelLocaleLocalization?: Maybe<CarModelLocaleEntityResponse>;
  createCarSpareBrand?: Maybe<CarSpareBrandEntityResponse>;
  createCarSpareBrandLocalization?: Maybe<CarSpareBrandEntityResponse>;
  createCarSpareLocaleLocalization?: Maybe<CarSpareLocaleEntityResponse>;
  createCategory?: Maybe<CategoryEntityResponse>;
  createCategoryLocalization?: Maybe<CategoryEntityResponse>;
  createContactNotification?: Maybe<ContactNotificationEntityResponse>;
  createContactNotificationLocalization?: Maybe<ContactNotificationEntityResponse>;
  createContentReleasesRelease?: Maybe<ContentReleasesReleaseEntityResponse>;
  createContentReleasesReleaseAction?: Maybe<ContentReleasesReleaseActionEntityResponse>;
  createCustomerBill?: Maybe<CustomerBillEntityResponse>;
  createCustomerContactInfo?: Maybe<CustomerContactInfoEntityResponse>;
  createCustomerPayment?: Maybe<CustomerPaymentEntityResponse>;
  createCustomerReturn?: Maybe<CustomerReturnEntityResponse>;
  createGeneralSpare?: Maybe<GeneralSparesEntityResponse>;
  createGeneralSpareBrand?: Maybe<CarSpareBrandEntityResponse>;
  createGeneralSuppliersSpare?: Maybe<GeneralSuppliersSpare>;
  createIncomingInvoice?: Maybe<IncomingInvoiceEntityResponse>;
  createJobLog?: Maybe<JobLogEntityResponse>;
  createNewsletterSubscription?: Maybe<NewsletterSubscriptionEntityResponse>;
  createNoSparePart?: Maybe<NoSparePartEntityResponse>;
  createOrder?: Maybe<OrderEntityResponse>;
  createOrderItem?: Maybe<OrderItemEntityResponse>;
  createOrderShop?: Maybe<CreateOrderShopResult>;
  createPaymentMethod?: Maybe<PaymentMethodEntityResponse>;
  createPaymentMethodLocalization?: Maybe<PaymentMethodEntityResponse>;
  createReview?: Maybe<ReviewEntityResponse>;
  createSpecialProposal?: Maybe<SpecialProposalEntityResponse>;
  createSpecialProposalLocalization?: Maybe<SpecialProposalEntityResponse>;
  createStockCellAddress?: Maybe<StockCellAddressEntityResponse>;
  createSupplier?: Maybe<SupplierEntityResponse>;
  createSupplierAccount?: Maybe<SupplierAccountEntityResponse>;
  createSupplierPayment?: Maybe<SupplierPaymentEntityResponse>;
  createSupplierReturn?: Maybe<SupplierReturnEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  createVehicle?: Maybe<VehicleEntityResponse>;
  deleteBackupSetting?: Maybe<BackupSettingEntityResponse>;
  deleteBanner?: Maybe<BannerEntityResponse>;
  deleteCarBrandLocale?: Maybe<CarBrandLocaleEntityResponse>;
  deleteCarCategory?: Maybe<CarCategoryEntityResponse>;
  deleteCarLocale?: Maybe<CarLocaleEntityResponse>;
  deleteCarModelLocale?: Maybe<CarModelLocaleEntityResponse>;
  deleteCarSpareBrand?: Maybe<CarSpareBrandEntityResponse>;
  deleteCategory?: Maybe<CategoryEntityResponse>;
  deleteContactNotification?: Maybe<ContactNotificationEntityResponse>;
  deleteContentReleasesRelease?: Maybe<ContentReleasesReleaseEntityResponse>;
  deleteContentReleasesReleaseAction?: Maybe<ContentReleasesReleaseActionEntityResponse>;
  deleteCustomerContactInfo?: Maybe<CustomerContactInfoEntityResponse>;
  deleteCustomerReturn?: Maybe<CustomerReturnEntityResponse>;
  deleteIncomingInvoice?: Maybe<IncomingInvoiceEntityResponse>;
  deleteJobLog?: Maybe<JobLogEntityResponse>;
  deleteNewsletterSubscription?: Maybe<NewsletterSubscriptionEntityResponse>;
  deleteNoSparePart?: Maybe<NoSparePartEntityResponse>;
  deleteOrder?: Maybe<OrderEntityResponse>;
  deleteOrderItem?: Maybe<OrderItemEntityResponse>;
  deletePaymentMethod?: Maybe<PaymentMethodEntityResponse>;
  deleteReview?: Maybe<ReviewEntityResponse>;
  deleteSpecialProposal?: Maybe<SpecialProposalEntityResponse>;
  deleteSupplier?: Maybe<SupplierEntityResponse>;
  deleteSupplierAccount?: Maybe<SupplierAccountEntityResponse>;
  deleteSupplierPayment?: Maybe<SupplierPaymentEntityResponse>;
  deleteSupplierReturn?: Maybe<SupplierReturnEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteVehicle?: Maybe<VehicleEntityResponse>;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  forgotPasswordByPhoneNumber?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  loginByPhoneNumber?: Maybe<UsersPermissionsLoginPayload>;
  makeCar?: Maybe<MakePayload>;
  makeCarBrand?: Maybe<MakePayload>;
  makeCarDetails?: Maybe<MakePayload>;
  makeCarSpare?: Maybe<MakePayload>;
  makeCarSpareBrands?: Maybe<MakePayload>;
  makeGeneralSpareFromSupplier?: Maybe<MakePayload>;
  makePrices?: Maybe<MakePayload>;
  moveOrderItemTo?: Maybe<MoveOrderItemToResult>;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  orderItemAction?: Maybe<Array<Maybe<OrderItemActionResponse>>>;
  orderPayment?: Maybe<OrderEntityResponse>;
  receiveOrderItemFromDelivery?: Maybe<StockReturnedFromCustomerEntity>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  registerByPhoneNumber?: Maybe<UsersPermissionsPasswordPayload>;
  removeFile?: Maybe<UploadFileEntityResponse>;
  requestConfirmPhoneNumber?: Maybe<UsersPermissionsPasswordPayload>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  resetPasswordByPhoneNumber?: Maybe<UsersPermissionsLoginPayload>;
  returnOrderItemFromCustomer?: Maybe<ReturnOrderItemFromCustomerResult>;
  splitOrder?: Maybe<SplitOrderResponse>;
  updateBackupSetting?: Maybe<BackupSettingEntityResponse>;
  updateBanner?: Maybe<BannerEntityResponse>;
  updateCarBrandLocale?: Maybe<CarBrandLocaleEntityResponse>;
  updateCarCategory?: Maybe<CarCategoryEntityResponse>;
  updateCarLocale?: Maybe<CarLocaleEntityResponse>;
  updateCarModelLocale?: Maybe<CarModelLocaleEntityResponse>;
  updateCarSpareBrand?: Maybe<CarSpareBrandEntityResponse>;
  updateCategory?: Maybe<CategoryEntityResponse>;
  updateContactNotification?: Maybe<ContactNotificationEntityResponse>;
  updateContentReleasesRelease?: Maybe<ContentReleasesReleaseEntityResponse>;
  updateContentReleasesReleaseAction?: Maybe<ContentReleasesReleaseActionEntityResponse>;
  updateCustomerBill?: Maybe<CustomerBillEntityResponse>;
  updateCustomerContactInfo?: Maybe<CustomerContactInfoEntityResponse>;
  updateCustomerReturn?: Maybe<CustomerReturnEntityResponse>;
  updateDiscounts?: Maybe<GeneralSparesEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateGeneralSpare?: Maybe<GeneralSparesEntityResponse>;
  updateGeneralSuppliersSpare?: Maybe<GeneralSuppliersSpare>;
  updateIncomingInvoice?: Maybe<IncomingInvoiceEntityResponse>;
  updateJobLog?: Maybe<JobLogEntityResponse>;
  updateMarkup?: Maybe<MarkupEntityResponse>;
  updateNewsletterSubscription?: Maybe<NewsletterSubscriptionEntityResponse>;
  updateNoSparePart?: Maybe<NoSparePartEntityResponse>;
  updateOrder?: Maybe<OrderEntityResponse>;
  updateOrderItem?: Maybe<OrderItemEntityResponse>;
  updatePaymentMethod?: Maybe<PaymentMethodEntityResponse>;
  updateReview?: Maybe<ReviewEntityResponse>;
  updateSpecialProposal?: Maybe<SpecialProposalEntityResponse>;
  updateStock?: Maybe<StockEntityResponse>;
  updateStockCellAddress?: Maybe<StockCellAddressEntityResponse>;
  updateSupplier?: Maybe<SupplierEntityResponse>;
  updateSupplierAccount?: Maybe<SupplierAccountEntityResponse>;
  updateSupplierPayment?: Maybe<SupplierPaymentEntityResponse>;
  updateSupplierReturn?: Maybe<SupplierReturnEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  updateVehicle?: Maybe<VehicleEntityResponse>;
  upload: UploadFileEntityResponse;
};


export type MutationAccessTecDocArgs = {
  key: Scalars['String'];
};


export type MutationAddBarcodeToCarSpareArgs = {
  barcode: Scalars['String'];
  carSpareId: Scalars['ID'];
  locale: Scalars['I18NLocaleCode'];
};


export type MutationAddReviewArgs = {
  input: AddReviewInput;
};


export type MutationAddStockArgs = {
  input: AddStockInput;
};


export type MutationAddStockFromOrderItemArgs = {
  input: AddStockFromOrderItemInput;
};


export type MutationAutoOrderingArgs = {
  orderId: Scalars['ID'];
  supplierId?: InputMaybe<Scalars['ID']>;
};


export type MutationBlockSupplierArgs = {
  input: BlockSupplierInput;
};


export type MutationChangePasswordArgs = {
  currentPassword: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationCompleteOrderArgs = {
  barcode: Scalars['String'];
};


export type MutationConfirmPhoneNumberArgs = {
  input: ConfirmPhoneNumberInput;
};


export type MutationCreateBannerArgs = {
  data: BannerInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateBannerLocalizationArgs = {
  data?: InputMaybe<BannerInput>;
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateCarBrandLocaleArgs = {
  data: CarBrandLocaleInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateCarBrandLocaleLocalizationArgs = {
  data?: InputMaybe<CarBrandLocaleInput>;
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateCarCategoryArgs = {
  data: CarCategoryInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateCarCategoryLocalizationArgs = {
  data?: InputMaybe<CarCategoryInput>;
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateCarLocaleArgs = {
  data: CarLocaleInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateCarLocaleLocalizationArgs = {
  data?: InputMaybe<CarLocaleInput>;
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateCarModelLocaleArgs = {
  data: CarModelLocaleInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateCarModelLocaleLocalizationArgs = {
  data?: InputMaybe<CarModelLocaleInput>;
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateCarSpareBrandArgs = {
  data: CarSpareBrandInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateCarSpareBrandLocalizationArgs = {
  data?: InputMaybe<CarSpareBrandInput>;
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateCarSpareLocaleLocalizationArgs = {
  data?: InputMaybe<CarSpareLocaleInput>;
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateCategoryArgs = {
  data: CategoryInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateCategoryLocalizationArgs = {
  data?: InputMaybe<CategoryInput>;
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateContactNotificationArgs = {
  data: ContactNotificationInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateContactNotificationLocalizationArgs = {
  data?: InputMaybe<ContactNotificationInput>;
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateContentReleasesReleaseArgs = {
  data: ContentReleasesReleaseInput;
};


export type MutationCreateContentReleasesReleaseActionArgs = {
  data: ContentReleasesReleaseActionInput;
};


export type MutationCreateCustomerBillArgs = {
  data: CustomerBillInput;
};


export type MutationCreateCustomerContactInfoArgs = {
  data: CustomerContactInfoInput;
};


export type MutationCreateCustomerPaymentArgs = {
  data: CustomerPaymentInput;
};


export type MutationCreateCustomerReturnArgs = {
  data: CustomerReturnInput;
};


export type MutationCreateGeneralSpareArgs = {
  input: CreateGeneralSpareInput;
  locale: Scalars['I18NLocaleCode'];
};


export type MutationCreateGeneralSpareBrandArgs = {
  input: CreateGeneralSpareBrandInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateGeneralSuppliersSpareArgs = {
  data: GeneralSuppliersSpareCreateInput;
};


export type MutationCreateIncomingInvoiceArgs = {
  data: IncomingInvoiceInput;
};


export type MutationCreateJobLogArgs = {
  data: JobLogInput;
};


export type MutationCreateNewsletterSubscriptionArgs = {
  data: NewsletterSubscriptionInput;
};


export type MutationCreateNoSparePartArgs = {
  data: NoSparePartInput;
};


export type MutationCreateOrderArgs = {
  data: OrderInput;
};


export type MutationCreateOrderItemArgs = {
  data: OrderStaffItemInput;
};


export type MutationCreateOrderShopArgs = {
  data: CreateOrderShopInput;
  locale: Scalars['I18NLocaleCode'];
};


export type MutationCreatePaymentMethodArgs = {
  data: PaymentMethodInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreatePaymentMethodLocalizationArgs = {
  data?: InputMaybe<PaymentMethodInput>;
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateReviewArgs = {
  data: ReviewInput;
};


export type MutationCreateSpecialProposalArgs = {
  data: SpecialProposalInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateSpecialProposalLocalizationArgs = {
  data?: InputMaybe<SpecialProposalInput>;
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationCreateStockCellAddressArgs = {
  data: StockCellAddressInput;
};


export type MutationCreateSupplierArgs = {
  data: SupplierInput;
};


export type MutationCreateSupplierAccountArgs = {
  data: SupplierAccountInput;
};


export type MutationCreateSupplierPaymentArgs = {
  data: SupplierPaymentInput;
};


export type MutationCreateSupplierReturnArgs = {
  data: SupplierReturnInput;
};


export type MutationCreateUploadFileArgs = {
  data: UploadFileInput;
};


export type MutationCreateUploadFolderArgs = {
  data: UploadFolderInput;
};


export type MutationCreateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
};


export type MutationCreateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
};


export type MutationCreateVehicleArgs = {
  data: VehicleInput;
};


export type MutationDeleteBannerArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteCarBrandLocaleArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteCarCategoryArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteCarLocaleArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteCarModelLocaleArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteCarSpareBrandArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteContactNotificationArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteContentReleasesReleaseArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteContentReleasesReleaseActionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomerContactInfoArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCustomerReturnArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteIncomingInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteJobLogArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNewsletterSubscriptionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNoSparePartArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrderArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrderItemArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePaymentMethodArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteReviewArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSpecialProposalArgs = {
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationDeleteSupplierArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSupplierAccountArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSupplierPaymentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSupplierReturnArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUploadFileArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUploadFolderArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsRoleArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUsersPermissionsUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteVehicleArgs = {
  id: Scalars['ID'];
};


export type MutationEmailConfirmationArgs = {
  confirmation: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
  staff?: InputMaybe<Scalars['Boolean']>;
};


export type MutationForgotPasswordByPhoneNumberArgs = {
  input: ForgotPasswordByPhoneNumberInput;
};


export type MutationLoginArgs = {
  input: UsersPermissionsLoginInput;
};


export type MutationLoginByPhoneNumberArgs = {
  input: LoginByPhoneNumberInput;
};


export type MutationMakeGeneralSpareFromSupplierArgs = {
  supplierId?: InputMaybe<Scalars['ID']>;
};


export type MutationMakePricesArgs = {
  supplierId?: InputMaybe<Scalars['ID']>;
};


export type MutationMoveOrderItemToArgs = {
  input: MoveOrderItemToInput;
};


export type MutationMultipleUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  files: Array<InputMaybe<Scalars['Upload']>>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
};


export type MutationOrderItemActionArgs = {
  input: OrderItemActionInput;
};


export type MutationOrderPaymentArgs = {
  orderId: Scalars['ID'];
  paymentAmount: Scalars['Float'];
};


export type MutationReceiveOrderItemFromDeliveryArgs = {
  input: ReceiveOrderItemFromDeliveryInput;
};


export type MutationRegisterArgs = {
  input: UsersPermissionsRegisterInput;
};


export type MutationRegisterByPhoneNumberArgs = {
  input: RegisterByPhoneNumberInput;
};


export type MutationRemoveFileArgs = {
  id: Scalars['ID'];
};


export type MutationRequestConfirmPhoneNumberArgs = {
  phoneNumber: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  code: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
};


export type MutationResetPasswordByPhoneNumberArgs = {
  input: ResetPasswordByPhoneNumberInput;
};


export type MutationReturnOrderItemFromCustomerArgs = {
  input: ReturnOrderItemFromCustomerInput;
};


export type MutationSplitOrderArgs = {
  orderId: Scalars['ID'];
  separateOrderItemIds: Array<Scalars['ID']>;
};


export type MutationUpdateBackupSettingArgs = {
  data: BackupSettingInput;
};


export type MutationUpdateBannerArgs = {
  data: BannerInput;
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdateCarBrandLocaleArgs = {
  data: CarBrandLocaleInput;
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdateCarCategoryArgs = {
  data: CarCategoryInput;
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdateCarLocaleArgs = {
  data: CarLocaleInput;
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdateCarModelLocaleArgs = {
  data: CarModelLocaleInput;
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdateCarSpareBrandArgs = {
  data: CarSpareBrandInput;
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdateCategoryArgs = {
  data: CategoryInput;
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdateContactNotificationArgs = {
  data: ContactNotificationInput;
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdateContentReleasesReleaseArgs = {
  data: ContentReleasesReleaseInput;
  id: Scalars['ID'];
};


export type MutationUpdateContentReleasesReleaseActionArgs = {
  data: ContentReleasesReleaseActionInput;
  id: Scalars['ID'];
};


export type MutationUpdateCustomerBillArgs = {
  data: CustomerBillInput;
  id: Scalars['ID'];
};


export type MutationUpdateCustomerContactInfoArgs = {
  data: CustomerContactInfoInput;
  id: Scalars['ID'];
};


export type MutationUpdateCustomerReturnArgs = {
  data: CustomerReturnInput;
  id: Scalars['ID'];
};


export type MutationUpdateDiscountsArgs = {
  discounts: Array<DiscountInput>;
  generalSpareId: Scalars['ID'];
  locale: Scalars['I18NLocaleCode'];
};


export type MutationUpdateFileInfoArgs = {
  id: Scalars['ID'];
  info?: InputMaybe<FileInfoInput>;
};


export type MutationUpdateGeneralSpareArgs = {
  id: Scalars['ID'];
  input: UpdateGeneralSpareInput;
  locale: Scalars['I18NLocaleCode'];
};


export type MutationUpdateGeneralSuppliersSpareArgs = {
  data: GeneralSuppliersSpareUpdateInput;
  id: Scalars['ID'];
};


export type MutationUpdateIncomingInvoiceArgs = {
  data: IncomingInvoiceInput;
  id: Scalars['ID'];
};


export type MutationUpdateJobLogArgs = {
  data: JobLogInput;
  id: Scalars['ID'];
};


export type MutationUpdateMarkupArgs = {
  data: MarkupInput;
};


export type MutationUpdateNewsletterSubscriptionArgs = {
  data: NewsletterSubscriptionInput;
  id: Scalars['ID'];
};


export type MutationUpdateNoSparePartArgs = {
  data: NoSparePartInput;
  id: Scalars['ID'];
};


export type MutationUpdateOrderArgs = {
  data: UpdateOrderInput;
  id: Scalars['ID'];
};


export type MutationUpdateOrderItemArgs = {
  data: UpdateOrderItemInput;
  id: Scalars['ID'];
};


export type MutationUpdatePaymentMethodArgs = {
  data: PaymentMethodInput;
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdateReviewArgs = {
  data: ReviewInput;
  id: Scalars['ID'];
};


export type MutationUpdateSpecialProposalArgs = {
  data: SpecialProposalInput;
  id: Scalars['ID'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type MutationUpdateStockArgs = {
  data: StockInput;
  id: Scalars['ID'];
};


export type MutationUpdateStockCellAddressArgs = {
  data: StockCellAddressInput;
  id: Scalars['ID'];
};


export type MutationUpdateSupplierArgs = {
  data: SupplierInput;
  id: Scalars['ID'];
};


export type MutationUpdateSupplierAccountArgs = {
  data: SupplierAccountInput;
  id: Scalars['ID'];
};


export type MutationUpdateSupplierPaymentArgs = {
  data: SupplierPaymentInput;
  id: Scalars['ID'];
};


export type MutationUpdateSupplierReturnArgs = {
  data: SupplierReturnInput;
  id: Scalars['ID'];
};


export type MutationUpdateUploadFileArgs = {
  data: UploadFileInput;
  id: Scalars['ID'];
};


export type MutationUpdateUploadFolderArgs = {
  data: UploadFolderInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsRoleArgs = {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID'];
};


export type MutationUpdateUsersPermissionsUserArgs = {
  data: UsersPermissionsUserInput;
  id: Scalars['ID'];
};


export type MutationUpdateVehicleArgs = {
  data: VehicleInput;
  id: Scalars['ID'];
};


export type MutationUploadArgs = {
  field?: InputMaybe<Scalars['String']>;
  file: Scalars['Upload'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']>;
  refId?: InputMaybe<Scalars['ID']>;
};

export type MyOrderItemEntity = {
  __typename?: 'MyOrderItemEntity';
  car?: Maybe<CarEntity>;
  car_spare?: Maybe<CarSpareEntity>;
  customer_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['ID']>;
  quantity?: Maybe<Scalars['Int']>;
  returnedQuantity?: Maybe<Scalars['Int']>;
  status: Enum_Shop_Order_Status;
};

export type NewsletterSubscription = {
  __typename?: 'NewsletterSubscription';
  createdAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  phone: Scalars['String'];
  source: Enum_Newslettersubscription_Source;
  status: Enum_Newslettersubscription_Status;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UsersPermissionsUserEntityResponse>;
};

export type NewsletterSubscriptionEntity = {
  __typename?: 'NewsletterSubscriptionEntity';
  attributes?: Maybe<NewsletterSubscription>;
  id?: Maybe<Scalars['ID']>;
};

export type NewsletterSubscriptionEntityResponse = {
  __typename?: 'NewsletterSubscriptionEntityResponse';
  data?: Maybe<NewsletterSubscriptionEntity>;
};

export type NewsletterSubscriptionEntityResponseCollection = {
  __typename?: 'NewsletterSubscriptionEntityResponseCollection';
  data: Array<NewsletterSubscriptionEntity>;
  meta: ResponseCollectionMeta;
};

export type NewsletterSubscriptionFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<NewsletterSubscriptionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<NewsletterSubscriptionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<NewsletterSubscriptionFiltersInput>>>;
  phone?: InputMaybe<StringFilterInput>;
  source?: InputMaybe<StringFilterInput>;
  status?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  user?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type NewsletterSubscriptionInput = {
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Enum_Newslettersubscription_Source>;
  status?: InputMaybe<Enum_Newslettersubscription_Status>;
  user?: InputMaybe<Scalars['ID']>;
};

export type NoSparePart = {
  __typename?: 'NoSparePart';
  createdAt?: Maybe<Scalars['DateTime']>;
  message?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  source?: Maybe<Enum_Nosparepart_Source>;
  status?: Maybe<Enum_Nosparepart_Status>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vin_code?: Maybe<Scalars['String']>;
};

export type NoSparePartEntity = {
  __typename?: 'NoSparePartEntity';
  attributes?: Maybe<NoSparePart>;
  id?: Maybe<Scalars['ID']>;
};

export type NoSparePartEntityResponse = {
  __typename?: 'NoSparePartEntityResponse';
  data?: Maybe<NoSparePartEntity>;
};

export type NoSparePartEntityResponseCollection = {
  __typename?: 'NoSparePartEntityResponseCollection';
  data: Array<NoSparePartEntity>;
  meta: ResponseCollectionMeta;
};

export type NoSparePartFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<NoSparePartFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  message?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<NoSparePartFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<NoSparePartFiltersInput>>>;
  phone?: InputMaybe<StringFilterInput>;
  source?: InputMaybe<StringFilterInput>;
  status?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  vin_code?: InputMaybe<StringFilterInput>;
};

export type NoSparePartInput = {
  message?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Enum_Nosparepart_Source>;
  status?: InputMaybe<Enum_Nosparepart_Status>;
  vin_code?: InputMaybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  car?: Maybe<CarEntityResponse>;
  comment?: Maybe<Scalars['String']>;
  courier?: Maybe<UsersPermissionsUserEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<UsersPermissionsUserEntityResponse>;
  customer_bill?: Maybe<CustomerBillEntityResponse>;
  date: Scalars['Date'];
  deliveryAddress?: Maybe<ComponentClientDeliveryAddress>;
  deliveryDate?: Maybe<Scalars['DateTime']>;
  fullPaidDate?: Maybe<Scalars['Date']>;
  isFullPaid?: Maybe<Scalars['Boolean']>;
  isShopOrder?: Maybe<Scalars['Boolean']>;
  managers?: Maybe<UsersPermissionsUserRelationResponseCollection>;
  my_order_items: Array<MyOrderItemEntity>;
  orderNumber?: Maybe<Scalars['String']>;
  orderNumberAdd?: Maybe<Scalars['String']>;
  order_items?: Maybe<OrderItemRelationResponseCollection>;
  paymentTotal?: Maybe<Scalars['Float']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status: Enum_Order_Status;
  status_editing_history: Array<Maybe<ComponentOrderStatusEditingHistory>>;
  total?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type OrderManagersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type OrderOrder_ItemsArgs = {
  filters?: InputMaybe<OrderItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type OrderStatus_Editing_HistoryArgs = {
  filters?: InputMaybe<ComponentOrderStatusEditingHistoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OrderData = {
  __typename?: 'OrderData';
  customerFirstNameOrServiceName?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['ID']>;
  customerLastNameOrNameRepresentative?: Maybe<Scalars['String']>;
  customerPhoneNumber?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['Date']>;
  orderId?: Maybe<Scalars['ID']>;
  orderItemId?: Maybe<Scalars['ID']>;
  orderNumber?: Maybe<Scalars['String']>;
  orderNumberAdd?: Maybe<Scalars['String']>;
  orderedQuantity?: Maybe<Scalars['Int']>;
  outgoingQuantity?: Maybe<Scalars['Int']>;
};

export type OrderEntity = {
  __typename?: 'OrderEntity';
  attributes?: Maybe<Order>;
  id?: Maybe<Scalars['ID']>;
};

export type OrderEntityResponse = {
  __typename?: 'OrderEntityResponse';
  data?: Maybe<OrderEntity>;
};

export type OrderEntityResponseCollection = {
  __typename?: 'OrderEntityResponseCollection';
  data: Array<OrderEntity>;
  meta: ResponseCollectionMeta;
};

export type OrderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<OrderFiltersInput>>>;
  car?: InputMaybe<CarFiltersInput>;
  comment?: InputMaybe<StringFilterInput>;
  courier?: InputMaybe<UsersPermissionsUserFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer?: InputMaybe<UsersPermissionsUserFiltersInput>;
  customer_bill?: InputMaybe<CustomerBillFiltersInput>;
  date?: InputMaybe<DateFilterInput>;
  deliveryAddress?: InputMaybe<ComponentClientDeliveryAddressFiltersInput>;
  deliveryDate?: InputMaybe<DateTimeFilterInput>;
  fullPaidDate?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isFullPaid?: InputMaybe<BooleanFilterInput>;
  isShopOrder?: InputMaybe<BooleanFilterInput>;
  managers?: InputMaybe<UsersPermissionsUserFiltersInput>;
  not?: InputMaybe<OrderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<OrderFiltersInput>>>;
  orderNumber?: InputMaybe<StringFilterInput>;
  orderNumberAdd?: InputMaybe<StringFilterInput>;
  order_items?: InputMaybe<OrderItemFiltersInput>;
  paymentTotal?: InputMaybe<FloatFilterInput>;
  phoneNumber?: InputMaybe<StringFilterInput>;
  status?: InputMaybe<StringFilterInput>;
  status_editing_history?: InputMaybe<ComponentOrderStatusEditingHistoryFiltersInput>;
  total?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type OrderInput = {
  car?: InputMaybe<Scalars['ID']>;
  comment?: InputMaybe<Scalars['String']>;
  courier?: InputMaybe<Scalars['ID']>;
  customer?: InputMaybe<Scalars['ID']>;
  customer_bill?: InputMaybe<Scalars['ID']>;
  date?: InputMaybe<Scalars['Date']>;
  deliveryAddress?: InputMaybe<ComponentClientDeliveryAddressInput>;
  deliveryDate?: InputMaybe<Scalars['DateTime']>;
  fullPaidDate?: InputMaybe<Scalars['Date']>;
  isFullPaid?: InputMaybe<Scalars['Boolean']>;
  isShopOrder?: InputMaybe<Scalars['Boolean']>;
  managers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orderNumberAdd?: InputMaybe<Scalars['String']>;
  paymentTotal?: InputMaybe<Scalars['Float']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Enum_Order_Status>;
  status_editing_history?: InputMaybe<Array<InputMaybe<ComponentOrderStatusEditingHistoryInput>>>;
  total?: InputMaybe<Scalars['Float']>;
};

export type OrderItem = {
  __typename?: 'OrderItem';
  autoOrderLastError?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  car_spare?: Maybe<CarSpareEntityResponse>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer_price?: Maybe<Scalars['Float']>;
  customer_return_items?: Maybe<CustomerReturnItemRelationResponseCollection>;
  delivery_date?: Maybe<Scalars['Date']>;
  discount?: Maybe<Scalars['Float']>;
  isOrderConfirmed?: Maybe<Scalars['Boolean']>;
  isOrdered?: Maybe<Scalars['Boolean']>;
  order?: Maybe<OrderEntityResponse>;
  quantity?: Maybe<OrderItemQuantity>;
  spare_archive?: Maybe<SpareArchiveEntityResponse>;
  stock?: Maybe<StockEntityResponse>;
  stock_returned_from_customers?: Maybe<StockReturnedFromCustomerRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type OrderItemCustomer_Return_ItemsArgs = {
  filters?: InputMaybe<CustomerReturnItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type OrderItemStock_Returned_From_CustomersArgs = {
  filters?: InputMaybe<StockReturnedFromCustomerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OrderItemActionData = {
  orderId: Scalars['ID'];
  orderItemIds: Array<Scalars['ID']>;
};

export type OrderItemActionInput = {
  actionData: OrderItemActionData;
  actionType: Enum_Order_Action_Type;
  locale: Scalars['I18NLocaleCode'];
};

export type OrderItemActionResponse = {
  __typename?: 'OrderItemActionResponse';
  errorMessage?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  success: Scalars['Boolean'];
};

export type OrderItemEntity = {
  __typename?: 'OrderItemEntity';
  attributes?: Maybe<OrderItem>;
  id?: Maybe<Scalars['ID']>;
};

export type OrderItemEntityResponse = {
  __typename?: 'OrderItemEntityResponse';
  data?: Maybe<OrderItemEntity>;
};

export type OrderItemEntityResponseCollection = {
  __typename?: 'OrderItemEntityResponseCollection';
  data: Array<OrderItemEntity>;
  meta: ResponseCollectionMeta;
};

export type OrderItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<OrderItemFiltersInput>>>;
  autoOrderLastError?: InputMaybe<StringFilterInput>;
  car_spare?: InputMaybe<CarSpareFiltersInput>;
  comment?: InputMaybe<StringFilterInput>;
  confirmedQuantity?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer_price?: InputMaybe<FloatFilterInput>;
  customer_return_items?: InputMaybe<CustomerReturnItemFiltersInput>;
  deliveryQuantity?: InputMaybe<IntFilterInput>;
  delivery_date?: InputMaybe<DateFilterInput>;
  discount?: InputMaybe<FloatFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isOrderConfirmed?: InputMaybe<BooleanFilterInput>;
  isOrdered?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<OrderItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<OrderItemFiltersInput>>>;
  order?: InputMaybe<OrderFiltersInput>;
  outgoingQuantity?: InputMaybe<IntFilterInput>;
  pickedUpQuantity?: InputMaybe<IntFilterInput>;
  quantity?: InputMaybe<IntFilterInput>;
  returnedQuantity?: InputMaybe<IntFilterInput>;
  spare_archive?: InputMaybe<SpareArchiveFiltersInput>;
  stock?: InputMaybe<StockFiltersInput>;
  stock_returned_from_customers?: InputMaybe<StockReturnedFromCustomerFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type OrderItemQuantity = {
  __typename?: 'OrderItemQuantity';
  confirmedQuantity?: Maybe<Scalars['Int']>;
  deliveryQuantity?: Maybe<Scalars['Int']>;
  orderedQuantity?: Maybe<Scalars['Int']>;
  outgoingQuantity?: Maybe<Scalars['Int']>;
  pickedUpQuantity?: Maybe<Scalars['Int']>;
  returnedQuantity?: Maybe<Scalars['Int']>;
};

export type OrderItemRelationResponseCollection = {
  __typename?: 'OrderItemRelationResponseCollection';
  data: Array<OrderItemEntity>;
};

export type OrderRelationResponseCollection = {
  __typename?: 'OrderRelationResponseCollection';
  data: Array<OrderEntity>;
};

export type OrderShopItemInput = {
  brandId: Scalars['Int'];
  discount?: InputMaybe<Scalars['Int']>;
  external_id: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  /**  'id' from type GeneralSuppliersSpare  */
  spareId: Scalars['ID'];
  tecdoc_articleNumber: Scalars['String'];
};

export type OrderStaffItemInput = {
  brandId: Scalars['Int'];
  deliveryDate: Scalars['Date'];
  discount: Scalars['Int'];
  external_id: Scalars['String'];
  orderId: Scalars['ID'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  /**  'id' from type GeneralSuppliersSpare  */
  spareId: Scalars['ID'];
  tecdoc_articleNumber: Scalars['String'];
};

export type Pagination = {
  __typename?: 'Pagination';
  page: Scalars['Int'];
  pageCount: Scalars['Int'];
  pageSize: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginationArg = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  start?: InputMaybe<Scalars['Int']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  isAvailable?: Maybe<Scalars['Boolean']>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<PaymentMethodRelationResponseCollection>;
  name?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type PaymentMethodLocalizationsArgs = {
  filters?: InputMaybe<PaymentMethodFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PaymentMethodEntity = {
  __typename?: 'PaymentMethodEntity';
  attributes?: Maybe<PaymentMethod>;
  id?: Maybe<Scalars['ID']>;
};

export type PaymentMethodEntityResponse = {
  __typename?: 'PaymentMethodEntityResponse';
  data?: Maybe<PaymentMethodEntity>;
};

export type PaymentMethodEntityResponseCollection = {
  __typename?: 'PaymentMethodEntityResponseCollection';
  data: Array<PaymentMethodEntity>;
  meta: ResponseCollectionMeta;
};

export type PaymentMethodFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<PaymentMethodFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  isAvailable?: InputMaybe<BooleanFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<PaymentMethodFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<PaymentMethodFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<PaymentMethodFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type PaymentMethodInput = {
  description?: InputMaybe<Scalars['String']>;
  isAvailable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};

export type PaymentMethodRelationResponseCollection = {
  __typename?: 'PaymentMethodRelationResponseCollection';
  data: Array<PaymentMethodEntity>;
};

export type PopularCategory = {
  __typename?: 'PopularCategory';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
};

export type PreDeliveryState = {
  __typename?: 'PreDeliveryState';
  callParent: Enum_Mode_Delivery_State;
  idOrder: Scalars['String'];
  idUser: Scalars['String'];
  isInfoAndPrint: Scalars['Boolean'];
  isOpenModal: Scalars['Boolean'];
  orderNumber: Scalars['String'];
  status: Enum_Order_Status;
};

export type PriceByRole = {
  __typename?: 'PriceByRole';
  customer?: Maybe<Scalars['Float']>;
  customer30?: Maybe<Scalars['Float']>;
  customer40?: Maybe<Scalars['Float']>;
  employee?: Maybe<Scalars['Float']>;
  permanentWholesaleCustomer?: Maybe<Scalars['Float']>;
  wholesaleCustomer?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  backupSetting?: Maybe<BackupSettingEntityResponse>;
  banner?: Maybe<BannerEntityResponse>;
  banners?: Maybe<BannerEntityResponseCollection>;
  canAddReview: Array<CanAddReviewResponse>;
  car?: Maybe<CarEntityResponse>;
  carBrand?: Maybe<CarBrandEntityResponse>;
  carBrandLocale?: Maybe<CarBrandLocaleEntityResponse>;
  carBrandLocales?: Maybe<CarBrandLocaleEntityResponseCollection>;
  carBrands?: Maybe<CarBrandEntityResponseCollection>;
  carCategories?: Maybe<CarCategoryEntityResponseCollection>;
  carCategory?: Maybe<CarCategoryEntityResponse>;
  carLocale?: Maybe<CarLocaleEntityResponse>;
  carLocales?: Maybe<CarLocaleEntityResponseCollection>;
  carModel?: Maybe<CarModelEntityResponse>;
  carModelLocale?: Maybe<CarModelLocaleEntityResponse>;
  carModelLocales?: Maybe<CarModelLocaleEntityResponseCollection>;
  carModels?: Maybe<CarModelEntityResponseCollection>;
  carModelsGrouped?: Maybe<Array<Maybe<CarModelGrouped>>>;
  carSpareBrand?: Maybe<CarSpareBrandEntityResponse>;
  carSpareBrands?: Maybe<CarSpareBrandEntityResponseCollection>;
  carSpareLocale?: Maybe<CarSpareLocaleEntityResponse>;
  carSpareLocales?: Maybe<CarSpareLocaleEntityResponseCollection>;
  cars?: Maybe<CarEntityResponseCollection>;
  categories?: Maybe<CategoryEntityResponseCollection>;
  category?: Maybe<CategoryEntityResponse>;
  client: CurrentUser;
  contactNotification?: Maybe<ContactNotificationEntityResponse>;
  contactNotifications?: Maybe<ContactNotificationEntityResponseCollection>;
  contentReleasesRelease?: Maybe<ContentReleasesReleaseEntityResponse>;
  contentReleasesReleaseAction?: Maybe<ContentReleasesReleaseActionEntityResponse>;
  contentReleasesReleaseActions?: Maybe<ContentReleasesReleaseActionEntityResponseCollection>;
  contentReleasesReleases?: Maybe<ContentReleasesReleaseEntityResponseCollection>;
  customerAccount?: Maybe<CustomerAccountEntityResponse>;
  customerAccountTransaction?: Maybe<CustomerAccountTransactionEntityResponse>;
  customerAccountTransactions?: Maybe<CustomerAccountTransactionEntityResponseCollection>;
  customerAccounts?: Maybe<CustomerAccountEntityResponseCollection>;
  customerBalance?: Maybe<Scalars['Float']>;
  customerBill?: Maybe<CustomerBillEntityResponse>;
  customerBills?: Maybe<CustomerBillEntityResponseCollection>;
  customerContactInfo?: Maybe<CustomerContactInfoEntityResponse>;
  customerContactInfos?: Maybe<CustomerContactInfoEntityResponseCollection>;
  customerPayment?: Maybe<CustomerPaymentEntityResponse>;
  customerPayments?: Maybe<CustomerPaymentEntityResponseCollection>;
  customerReturn?: Maybe<CustomerReturnEntityResponse>;
  customerReturnItem?: Maybe<CustomerReturnItemEntityResponse>;
  customerReturnItems?: Maybe<CustomerReturnItemEntityResponseCollection>;
  customerReturns?: Maybe<CustomerReturnEntityResponseCollection>;
  customerTransactions?: Maybe<CustomerTransactionEntityCollections>;
  dataDeviderState: DataDeviderState;
  generalReviews?: Maybe<GeneralReviewEntityResponseCollection>;
  generalSpare?: Maybe<GeneralSparesEntityResponse>;
  generalSpares?: Maybe<GeneralSparesEntityResponseCollection>;
  getCategoryTree?: Maybe<CategoryTreeResult>;
  getFilterRange?: Maybe<FilterRangeResult>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  incomingInvoice?: Maybe<IncomingInvoiceEntityResponse>;
  incomingInvoices?: Maybe<IncomingInvoiceEntityResponseCollection>;
  isMobile: Scalars['Boolean'];
  jobLog?: Maybe<JobLogEntityResponse>;
  jobLogs?: Maybe<JobLogEntityResponseCollection>;
  markup?: Maybe<MarkupEntityResponse>;
  me?: Maybe<UsersPermissionsMe>;
  messageError?: Maybe<ErrorMessageType>;
  myOrder?: Maybe<OrderEntityResponse>;
  myOrders?: Maybe<OrderEntityResponseCollection>;
  newsletterSubscription?: Maybe<NewsletterSubscriptionEntityResponse>;
  newsletterSubscriptions?: Maybe<NewsletterSubscriptionEntityResponseCollection>;
  noSparePart?: Maybe<NoSparePartEntityResponse>;
  noSpareParts?: Maybe<NoSparePartEntityResponseCollection>;
  order?: Maybe<OrderEntityResponse>;
  orderItem?: Maybe<OrderItemEntityResponse>;
  orderItems?: Maybe<OrderItemEntityResponseCollection>;
  orders?: Maybe<OrderEntityResponseCollection>;
  paymentMethod?: Maybe<PaymentMethodEntityResponse>;
  paymentMethods?: Maybe<PaymentMethodEntityResponseCollection>;
  preDeliveryState: PreDeliveryState;
  returnedItemId: Scalars['String'];
  review?: Maybe<ReviewEntityResponse>;
  reviewId: Scalars['String'];
  reviews?: Maybe<ReviewEntityResponseCollection>;
  searchOrderItemForReturn?: Maybe<OrderItemEntity>;
  searchProducts?: Maybe<Array<Maybe<SearchProductsResult>>>;
  selectLanguage: Enum_Language_Type;
  selectVehicleUser?: Maybe<Scalars['String']>;
  selectedSpecialProduct: SpecialProducts;
  spare?: Maybe<SpareEntityResponse>;
  spareArchive?: Maybe<SpareArchiveEntityResponse>;
  spares?: Maybe<SpareEntityResponseCollection>;
  sparesArchive?: Maybe<SpareArchiveEntityResponseCollection>;
  specialProposal?: Maybe<SpecialProposalEntityResponse>;
  specialProposals?: Maybe<SpecialProposalEntityResponseCollection>;
  stock?: Maybe<StockEntityResponse>;
  stockCellAddresses?: Maybe<Array<Maybe<StockCellAddressItem>>>;
  stockHistories?: Maybe<StockHistoryEntityResponseCollection>;
  stockHistory?: Maybe<StockHistoryEntityResponse>;
  stockReturnedFromCustomer?: Maybe<StockReturnedFromCustomerEntityResponse>;
  stockReturnedFromCustomers?: Maybe<StockReturnedFromCustomerEntityResponseCollection>;
  stocks?: Maybe<StockEntityResponseCollection>;
  supplier?: Maybe<SupplierEntityResponse>;
  supplierAccount?: Maybe<SupplierAccountEntityResponse>;
  supplierAccounts?: Maybe<SupplierAccountEntityResponseCollection>;
  supplierBalance?: Maybe<SupplierBalance>;
  supplierPayment?: Maybe<SupplierPaymentEntityResponse>;
  supplierPaymentDetail?: Maybe<SupplierPaymentDetailEntityResponse>;
  supplierPaymentDetails?: Maybe<SupplierPaymentDetailEntityResponseCollection>;
  supplierPayments?: Maybe<SupplierPaymentEntityResponseCollection>;
  supplierReturn?: Maybe<SupplierReturnEntityResponse>;
  supplierReturnItem?: Maybe<SupplierReturnItemEntityResponse>;
  supplierReturnItems?: Maybe<SupplierReturnItemEntityResponseCollection>;
  supplierReturns?: Maybe<SupplierReturnEntityResponseCollection>;
  supplierSupportAutoOrdering?: Maybe<Array<Maybe<SupplierSupportAutoOrdering>>>;
  supplierTransactions?: Maybe<SupplierTransactionEntityCollections>;
  suppliers?: Maybe<SupplierEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  userRole: Scalars['String'];
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
  vehicle?: Maybe<VehicleEntityResponse>;
  vehicles?: Maybe<VehicleEntityResponseCollection>;
};


export type QueryBannerArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryBannersArgs = {
  filters?: InputMaybe<BannerFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCanAddReviewArgs = {
  generalSpareIds: Array<Scalars['ID']>;
};


export type QueryCarArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCarBrandArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCarBrandLocaleArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryCarBrandLocalesArgs = {
  filters?: InputMaybe<CarBrandLocaleFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCarBrandsArgs = {
  filters?: InputMaybe<CarBrandFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCarCategoriesArgs = {
  filters?: InputMaybe<CarCategoryFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCarCategoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryCarLocaleArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryCarLocalesArgs = {
  filters?: InputMaybe<CarLocaleFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCarModelArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCarModelLocaleArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryCarModelLocalesArgs = {
  filters?: InputMaybe<CarModelLocaleFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCarModelsArgs = {
  filters?: InputMaybe<CarModelFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCarModelsGroupedArgs = {
  brandId: Scalars['String'];
  locale: Scalars['I18NLocaleCode'];
};


export type QueryCarSpareBrandArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryCarSpareBrandsArgs = {
  filters?: InputMaybe<CarSpareBrandFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCarSpareLocaleArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryCarSpareLocalesArgs = {
  filters?: InputMaybe<CarSpareLocaleFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCarsArgs = {
  filters?: InputMaybe<CarFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCategoriesArgs = {
  filters?: InputMaybe<CategoryFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCategoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryContactNotificationArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryContactNotificationsArgs = {
  filters?: InputMaybe<ContactNotificationFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryContentReleasesReleaseArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryContentReleasesReleaseActionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryContentReleasesReleaseActionsArgs = {
  filters?: InputMaybe<ContentReleasesReleaseActionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryContentReleasesReleasesArgs = {
  filters?: InputMaybe<ContentReleasesReleaseFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCustomerAccountArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomerAccountTransactionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomerAccountTransactionsArgs = {
  filters?: InputMaybe<CustomerAccountTransactionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCustomerAccountsArgs = {
  filters?: InputMaybe<CustomerAccountFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCustomerBalanceArgs = {
  input: CustomerBalanceInput;
};


export type QueryCustomerBillArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomerBillsArgs = {
  filters?: InputMaybe<CustomerBillFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCustomerContactInfoArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomerContactInfosArgs = {
  filters?: InputMaybe<CustomerContactInfoFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCustomerPaymentArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomerPaymentsArgs = {
  filters?: InputMaybe<CustomerPaymentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCustomerReturnArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomerReturnItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryCustomerReturnItemsArgs = {
  filters?: InputMaybe<CustomerReturnItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCustomerReturnsArgs = {
  filters?: InputMaybe<CustomerReturnFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryCustomerTransactionsArgs = {
  filters: CustomerTransactionsFiltersInput;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryGeneralReviewsArgs = {
  generalSpareId: Scalars['ID'];
  pagination?: InputMaybe<PaginationArg>;
};


export type QueryGeneralSpareArgs = {
  id: Scalars['ID'];
  locale: Scalars['I18NLocaleCode'];
};


export type QueryGeneralSparesArgs = {
  filters?: InputMaybe<GeneralSparesFiltersInput>;
  locale: Scalars['I18NLocaleCode'];
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryGetCategoryTreeArgs = {
  input: CategoryTreeInput;
};


export type QueryGetFilterRangeArgs = {
  input: FilterRangeInput;
};


export type QueryI18NLocaleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryI18NLocalesArgs = {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryIncomingInvoiceArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryIncomingInvoicesArgs = {
  filters?: InputMaybe<IncomingInvoiceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryJobLogArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryJobLogsArgs = {
  filters?: InputMaybe<JobLogFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryMyOrderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryMyOrdersArgs = {
  filters?: InputMaybe<OrderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryNewsletterSubscriptionArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryNewsletterSubscriptionsArgs = {
  filters?: InputMaybe<NewsletterSubscriptionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryNoSparePartArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryNoSparePartsArgs = {
  filters?: InputMaybe<NoSparePartFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryOrderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryOrderItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryOrderItemsArgs = {
  filters?: InputMaybe<OrderItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryOrdersArgs = {
  filters?: InputMaybe<OrderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryPaymentMethodArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QueryPaymentMethodsArgs = {
  filters?: InputMaybe<PaymentMethodFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryReviewArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryReviewsArgs = {
  filters?: InputMaybe<ReviewFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySearchOrderItemForReturnArgs = {
  input: SearchOrderItemForReturnInput;
};


export type QuerySearchProductsArgs = {
  input: SearchProductsInput;
};


export type QuerySpareArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySpareArchiveArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySparesArgs = {
  filters?: InputMaybe<SpareFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySparesArchiveArgs = {
  filters?: InputMaybe<SpareArchiveFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySpecialProposalArgs = {
  id?: InputMaybe<Scalars['ID']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
};


export type QuerySpecialProposalsArgs = {
  filters?: InputMaybe<SpecialProposalFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryStockArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryStockHistoriesArgs = {
  filters?: InputMaybe<StockHistoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryStockHistoryArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryStockReturnedFromCustomerArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryStockReturnedFromCustomersArgs = {
  filters?: InputMaybe<StockReturnedFromCustomerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryStocksArgs = {
  filters?: InputMaybe<StockFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySupplierArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySupplierAccountArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySupplierAccountsArgs = {
  filters?: InputMaybe<SupplierAccountFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySupplierBalanceArgs = {
  input: SupplierBalanceInput;
};


export type QuerySupplierPaymentArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySupplierPaymentDetailArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySupplierPaymentDetailsArgs = {
  filters?: InputMaybe<SupplierPaymentDetailFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySupplierPaymentsArgs = {
  filters?: InputMaybe<SupplierPaymentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySupplierReturnArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySupplierReturnItemArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QuerySupplierReturnItemsArgs = {
  filters?: InputMaybe<SupplierReturnItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySupplierReturnsArgs = {
  filters?: InputMaybe<SupplierReturnFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySupplierSupportAutoOrderingArgs = {
  orderId: Scalars['ID'];
};


export type QuerySupplierTransactionsArgs = {
  filters: SupplierTransactionsFiltersInput;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QuerySuppliersArgs = {
  filters?: InputMaybe<SupplierFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUploadFileArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUploadFolderArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUploadFoldersArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsRoleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsRolesArgs = {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryUsersPermissionsUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryUsersPermissionsUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type QueryVehicleArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryVehiclesArgs = {
  filters?: InputMaybe<VehicleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type ReceiveOrderItemFromDeliveryInput = {
  barcode: Scalars['String'];
  customerReturnId?: InputMaybe<Scalars['ID']>;
  methodToReturn: Enum_Method_To_Return;
  receiveFor: Enum_Receive_For;
  stockReturnedFromCustomerId: Scalars['ID'];
  supplierReturnId?: InputMaybe<Scalars['ID']>;
};

export type RegisterByPhoneNumberInput = {
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  token: Scalars['String'];
};

export type ResetPasswordByPhoneNumberInput = {
  code: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type ResponseCollectionMeta = {
  __typename?: 'ResponseCollectionMeta';
  pagination: Pagination;
};

export type ReturnOrderItemFromCustomerInput = {
  orderItemId: Scalars['ID'];
  returnComment?: InputMaybe<Scalars['String']>;
  returnReason: Scalars['String'];
  returnedQuantity: Scalars['Int'];
};

export type ReturnOrderItemFromCustomerResult = {
  __typename?: 'ReturnOrderItemFromCustomerResult';
  success?: Maybe<Scalars['Boolean']>;
};

export type Review = {
  __typename?: 'Review';
  car_spare?: Maybe<CarSpareEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  publication?: Maybe<Scalars['Boolean']>;
  rating?: Maybe<Scalars['Int']>;
  reply?: Maybe<ComponentReviewReview>;
  review?: Maybe<ComponentReviewReview>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ReviewEntity = {
  __typename?: 'ReviewEntity';
  attributes?: Maybe<Review>;
  id?: Maybe<Scalars['ID']>;
};

export type ReviewEntityResponse = {
  __typename?: 'ReviewEntityResponse';
  data?: Maybe<ReviewEntity>;
};

export type ReviewEntityResponseCollection = {
  __typename?: 'ReviewEntityResponseCollection';
  data: Array<ReviewEntity>;
  meta: ResponseCollectionMeta;
};

export type ReviewFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<ReviewFiltersInput>>>;
  car_spare?: InputMaybe<CarSpareFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<ReviewFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ReviewFiltersInput>>>;
  publication?: InputMaybe<BooleanFilterInput>;
  rating?: InputMaybe<IntFilterInput>;
  reply?: InputMaybe<ComponentReviewReviewFiltersInput>;
  review?: InputMaybe<ComponentReviewReviewFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type ReviewInput = {
  car_spare?: InputMaybe<Scalars['ID']>;
  publication?: InputMaybe<Scalars['Boolean']>;
  rating?: InputMaybe<Scalars['Int']>;
  reply?: InputMaybe<ComponentReviewReviewInput>;
  review?: InputMaybe<ComponentReviewReviewInput>;
};

export type ReviewRelationResponseCollection = {
  __typename?: 'ReviewRelationResponseCollection';
  data: Array<ReviewEntity>;
};

export type SearchOrderItemForReturnInput = {
  stockBarcode: Scalars['String'];
};

export type SearchProductsInput = {
  eanNumber: Scalars['String'];
  locale: Scalars['I18NLocaleCode'];
};

export type SearchProductsResult = {
  __typename?: 'SearchProductsResult';
  articleCriteria?: Maybe<Scalars['JSON']>;
  brandId?: Maybe<Scalars['ID']>;
  brandName?: Maybe<Scalars['String']>;
  carSpareId?: Maybe<Scalars['ID']>;
  images?: Maybe<Scalars['JSON']>;
  orderDate?: Maybe<Scalars['Date']>;
  orderId?: Maybe<Scalars['ID']>;
  orderItemId?: Maybe<Scalars['ID']>;
  orderItemQuantity?: Maybe<Scalars['Int']>;
  supplierId?: Maybe<Scalars['ID']>;
  supplierName?: Maybe<Scalars['String']>;
  tecdoc_articleNumber?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type Spare = {
  __typename?: 'Spare';
  amount?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['String']>;
  lockForReturn?: Maybe<Scalars['Boolean']>;
  minOrderQuantity?: Maybe<Scalars['Int']>;
  prevPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntityResponse>;
  supplierPrice?: Maybe<Scalars['Float']>;
  tecdoc_articleNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  warehouse?: Maybe<Scalars['JSON']>;
};

export type SpareArchive = {
  __typename?: 'SpareArchive';
  amount?: Maybe<Scalars['String']>;
  brandId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['String']>;
  lockForReturn?: Maybe<Scalars['Boolean']>;
  minOrderQuantity?: Maybe<Scalars['Int']>;
  order_items?: Maybe<OrderItemRelationResponseCollection>;
  supplier?: Maybe<SupplierEntityResponse>;
  supplierPrice?: Maybe<Scalars['Float']>;
  tecdoc_articleNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  warehouse?: Maybe<Scalars['JSON']>;
};


export type SpareArchiveOrder_ItemsArgs = {
  filters?: InputMaybe<OrderItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SpareArchiveEntity = {
  __typename?: 'SpareArchiveEntity';
  attributes?: Maybe<SpareArchive>;
  id?: Maybe<Scalars['ID']>;
};

export type SpareArchiveEntityResponse = {
  __typename?: 'SpareArchiveEntityResponse';
  data?: Maybe<SpareArchiveEntity>;
};

export type SpareArchiveEntityResponseCollection = {
  __typename?: 'SpareArchiveEntityResponseCollection';
  data: Array<SpareArchiveEntity>;
  meta: ResponseCollectionMeta;
};

export type SpareArchiveFiltersInput = {
  amount?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SpareArchiveFiltersInput>>>;
  brandId?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  discount?: InputMaybe<FloatFilterInput>;
  external_id?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lockForReturn?: InputMaybe<BooleanFilterInput>;
  minOrderQuantity?: InputMaybe<IntFilterInput>;
  not?: InputMaybe<SpareArchiveFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SpareArchiveFiltersInput>>>;
  order_items?: InputMaybe<OrderItemFiltersInput>;
  rowVersion?: InputMaybe<LongFilterInput>;
  supplier?: InputMaybe<SupplierFiltersInput>;
  supplierPrice?: InputMaybe<FloatFilterInput>;
  tecdoc_articleNumber?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  warehouse?: InputMaybe<JsonFilterInput>;
};

export type SpareArchiveRelationResponseCollection = {
  __typename?: 'SpareArchiveRelationResponseCollection';
  data: Array<SpareArchiveEntity>;
};

export type SpareEntity = {
  __typename?: 'SpareEntity';
  attributes?: Maybe<Spare>;
  id?: Maybe<Scalars['ID']>;
};

export type SpareEntityResponse = {
  __typename?: 'SpareEntityResponse';
  data?: Maybe<SpareEntity>;
};

export type SpareEntityResponseCollection = {
  __typename?: 'SpareEntityResponseCollection';
  data: Array<SpareEntity>;
  meta: ResponseCollectionMeta;
};

export type SpareFiltersInput = {
  amount?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SpareFiltersInput>>>;
  brandId?: InputMaybe<IntFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  discount?: InputMaybe<FloatFilterInput>;
  external_id?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  lockForReturn?: InputMaybe<BooleanFilterInput>;
  minOrderQuantity?: InputMaybe<IntFilterInput>;
  not?: InputMaybe<SpareFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SpareFiltersInput>>>;
  price?: InputMaybe<FloatFilterInput>;
  rowVersion?: InputMaybe<LongFilterInput>;
  supplier?: InputMaybe<SupplierFiltersInput>;
  supplierPrice?: InputMaybe<FloatFilterInput>;
  tecdoc_articleNumber?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  warehouse?: InputMaybe<JsonFilterInput>;
};

export type SpareRelationResponseCollection = {
  __typename?: 'SpareRelationResponseCollection';
  data: Array<SpareEntity>;
};

export type SpecialProducts = {
  __typename?: 'SpecialProducts';
  idCategory: Scalars['String'];
  productsIDs: Array<Scalars['String']>;
  systemName: Enum_Specialproposal_System_Name;
  titleTab: Scalars['String'];
};

export type SpecialProposal = {
  __typename?: 'SpecialProposal';
  createdAt?: Maybe<Scalars['DateTime']>;
  generalSpares?: Maybe<GeneralSparesRelationSpecialProposalCollection>;
  locale?: Maybe<Scalars['String']>;
  localizations?: Maybe<SpecialProposalRelationResponseCollection>;
  name?: Maybe<Scalars['String']>;
  products?: Maybe<CarSpareRelationResponseCollection>;
  system_name?: Maybe<Enum_Specialproposal_System_Name>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type SpecialProposalGeneralSparesArgs = {
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SpecialProposalLocalizationsArgs = {
  filters?: InputMaybe<SpecialProposalFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SpecialProposalProductsArgs = {
  filters?: InputMaybe<CarSpareFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SpecialProposalEntity = {
  __typename?: 'SpecialProposalEntity';
  attributes?: Maybe<SpecialProposal>;
  id?: Maybe<Scalars['ID']>;
};

export type SpecialProposalEntityResponse = {
  __typename?: 'SpecialProposalEntityResponse';
  data?: Maybe<SpecialProposalEntity>;
};

export type SpecialProposalEntityResponseCollection = {
  __typename?: 'SpecialProposalEntityResponseCollection';
  data: Array<SpecialProposalEntity>;
  meta: ResponseCollectionMeta;
};

export type SpecialProposalFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SpecialProposalFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<SpecialProposalFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SpecialProposalFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SpecialProposalFiltersInput>>>;
  products?: InputMaybe<CarSpareFiltersInput>;
  system_name?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SpecialProposalInput = {
  name?: InputMaybe<Scalars['String']>;
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  system_name?: InputMaybe<Enum_Specialproposal_System_Name>;
};

export type SpecialProposalRelationResponseCollection = {
  __typename?: 'SpecialProposalRelationResponseCollection';
  data: Array<SpecialProposalEntity>;
};

export type SplitOrderResponse = {
  __typename?: 'SplitOrderResponse';
  newOrder?: Maybe<OrderEntityResponse>;
  oldOrder?: Maybe<OrderEntityResponse>;
};

export type Stock = {
  __typename?: 'Stock';
  balanceQuantity: Scalars['Int'];
  car_spare?: Maybe<CarSpareEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer_return_items?: Maybe<CustomerReturnItemRelationResponseCollection>;
  incomingQuantity?: Maybe<Scalars['Int']>;
  incoming_invoice?: Maybe<IncomingInvoiceEntityResponse>;
  isAcceptedFromOrder?: Maybe<Scalars['Boolean']>;
  lockForReturn?: Maybe<Scalars['Boolean']>;
  order_items?: Maybe<OrderItemRelationResponseCollection>;
  reservedQuantity?: Maybe<Scalars['Int']>;
  stock_cell_address?: Maybe<StockCellAddressEntityResponse>;
  stock_histories?: Maybe<StockHistoryRelationResponseCollection>;
  stock_returned_from_customers?: Maybe<StockReturnedFromCustomerRelationResponseCollection>;
  supplier?: Maybe<SupplierEntityResponse>;
  supplierPrice?: Maybe<Scalars['Float']>;
  supplier_return_items?: Maybe<SupplierReturnItemRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type StockCustomer_Return_ItemsArgs = {
  filters?: InputMaybe<CustomerReturnItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type StockOrder_ItemsArgs = {
  filters?: InputMaybe<OrderItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type StockStock_HistoriesArgs = {
  filters?: InputMaybe<StockHistoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type StockStock_Returned_From_CustomersArgs = {
  filters?: InputMaybe<StockReturnedFromCustomerFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type StockSupplier_Return_ItemsArgs = {
  filters?: InputMaybe<SupplierReturnItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type StockCellAddress = {
  __typename?: 'StockCellAddress';
  cell?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  priority?: Maybe<Scalars['Int']>;
  shelf?: Maybe<Scalars['String']>;
  stocks?: Maybe<StockRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type StockCellAddressStocksArgs = {
  filters?: InputMaybe<StockFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type StockCellAddressEntity = {
  __typename?: 'StockCellAddressEntity';
  attributes?: Maybe<StockCellAddress>;
  id?: Maybe<Scalars['ID']>;
};

export type StockCellAddressEntityResponse = {
  __typename?: 'StockCellAddressEntityResponse';
  data?: Maybe<StockCellAddressEntity>;
};

export type StockCellAddressFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<StockCellAddressFiltersInput>>>;
  cell?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<StockCellAddressFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<StockCellAddressFiltersInput>>>;
  priority?: InputMaybe<IntFilterInput>;
  shelf?: InputMaybe<StringFilterInput>;
  stocks?: InputMaybe<StockFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type StockCellAddressInput = {
  cell?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
  shelf?: InputMaybe<Scalars['String']>;
  stocks?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type StockCellAddressItem = {
  __typename?: 'StockCellAddressItem';
  cell?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isEmptyCell?: Maybe<Scalars['Boolean']>;
  priority?: Maybe<Scalars['Int']>;
  shelf?: Maybe<Scalars['String']>;
};

export type StockData = {
  __typename?: 'StockData';
  articleNumber?: Maybe<Scalars['String']>;
  brandName?: Maybe<Scalars['String']>;
  quantityIncoming?: Maybe<Scalars['Int']>;
  stockBarcode?: Maybe<Scalars['String']>;
  stockCellAddress?: Maybe<StockCellAddressItem>;
  stockId?: Maybe<Scalars['ID']>;
  supplierId?: Maybe<Scalars['ID']>;
  supplierName?: Maybe<Scalars['String']>;
  supplierPrice?: Maybe<Scalars['Float']>;
  title?: Maybe<Scalars['String']>;
};

export type StockEntity = {
  __typename?: 'StockEntity';
  attributes?: Maybe<Stock>;
  id?: Maybe<Scalars['ID']>;
};

export type StockEntityResponse = {
  __typename?: 'StockEntityResponse';
  data?: Maybe<StockEntity>;
};

export type StockEntityResponseCollection = {
  __typename?: 'StockEntityResponseCollection';
  data: Array<StockEntity>;
  meta: ResponseCollectionMeta;
};

export type StockFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<StockFiltersInput>>>;
  balanceQuantity?: InputMaybe<IntFilterInput>;
  car_spare?: InputMaybe<CarSpareFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer_return_items?: InputMaybe<CustomerReturnItemFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  incomingQuantity?: InputMaybe<IntFilterInput>;
  incoming_invoice?: InputMaybe<IncomingInvoiceFiltersInput>;
  isAcceptedFromOrder?: InputMaybe<BooleanFilterInput>;
  lockForReturn?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<StockFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<StockFiltersInput>>>;
  order_items?: InputMaybe<OrderItemFiltersInput>;
  reservedQuantity?: InputMaybe<IntFilterInput>;
  stock_cell_address?: InputMaybe<StockCellAddressFiltersInput>;
  stock_histories?: InputMaybe<StockHistoryFiltersInput>;
  stock_returned_from_customers?: InputMaybe<StockReturnedFromCustomerFiltersInput>;
  supplier?: InputMaybe<SupplierFiltersInput>;
  supplierPrice?: InputMaybe<FloatFilterInput>;
  supplier_return_items?: InputMaybe<SupplierReturnItemFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type StockHistory = {
  __typename?: 'StockHistory';
  changeBalanceQuantity?: Maybe<Enum_Stockhistory_Changebalancequantity>;
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateOperation?: Maybe<Scalars['DateTime']>;
  operationName?: Maybe<Scalars['String']>;
  orderInfo?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  stock?: Maybe<StockEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StockHistoryEntity = {
  __typename?: 'StockHistoryEntity';
  attributes?: Maybe<StockHistory>;
  id?: Maybe<Scalars['ID']>;
};

export type StockHistoryEntityResponse = {
  __typename?: 'StockHistoryEntityResponse';
  data?: Maybe<StockHistoryEntity>;
};

export type StockHistoryEntityResponseCollection = {
  __typename?: 'StockHistoryEntityResponseCollection';
  data: Array<StockHistoryEntity>;
  meta: ResponseCollectionMeta;
};

export type StockHistoryFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<StockHistoryFiltersInput>>>;
  changeBalanceQuantity?: InputMaybe<StringFilterInput>;
  comment?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  dateOperation?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<StockHistoryFiltersInput>;
  operationName?: InputMaybe<StringFilterInput>;
  or?: InputMaybe<Array<InputMaybe<StockHistoryFiltersInput>>>;
  orderInfo?: InputMaybe<StringFilterInput>;
  quantity?: InputMaybe<IntFilterInput>;
  stock?: InputMaybe<StockFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type StockHistoryRelationResponseCollection = {
  __typename?: 'StockHistoryRelationResponseCollection';
  data: Array<StockHistoryEntity>;
};

export type StockInput = {
  customer_return_items?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  incoming_invoice?: InputMaybe<Scalars['ID']>;
  lockForReturn?: InputMaybe<Scalars['Boolean']>;
  stock_cell_address?: InputMaybe<Scalars['ID']>;
  stock_histories?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  stock_returned_from_customers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  supplierPrice?: InputMaybe<Scalars['Float']>;
  supplier_return_items?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type StockRelationResponseCollection = {
  __typename?: 'StockRelationResponseCollection';
  data: Array<StockEntity>;
};

export type StockReturnedFromCustomer = {
  __typename?: 'StockReturnedFromCustomer';
  createdAt?: Maybe<Scalars['DateTime']>;
  dateIncoming?: Maybe<Scalars['DateTime']>;
  dateReturned?: Maybe<Scalars['DateTime']>;
  order_item?: Maybe<OrderItemEntityResponse>;
  parent_stock?: Maybe<StockEntityResponse>;
  returnComment?: Maybe<Scalars['String']>;
  returnReason?: Maybe<Scalars['String']>;
  returnedQuantity?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type StockReturnedFromCustomerEntity = {
  __typename?: 'StockReturnedFromCustomerEntity';
  attributes?: Maybe<StockReturnedFromCustomer>;
  id?: Maybe<Scalars['ID']>;
};

export type StockReturnedFromCustomerEntityResponse = {
  __typename?: 'StockReturnedFromCustomerEntityResponse';
  data?: Maybe<StockReturnedFromCustomerEntity>;
};

export type StockReturnedFromCustomerEntityResponseCollection = {
  __typename?: 'StockReturnedFromCustomerEntityResponseCollection';
  data: Array<StockReturnedFromCustomerEntity>;
  meta: ResponseCollectionMeta;
};

export type StockReturnedFromCustomerFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<StockReturnedFromCustomerFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  dateIncoming?: InputMaybe<DateTimeFilterInput>;
  dateReturned?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<StockReturnedFromCustomerFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<StockReturnedFromCustomerFiltersInput>>>;
  order_item?: InputMaybe<OrderItemFiltersInput>;
  parent_stock?: InputMaybe<StockFiltersInput>;
  returnComment?: InputMaybe<StringFilterInput>;
  returnReason?: InputMaybe<StringFilterInput>;
  returnedQuantity?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type StockReturnedFromCustomerRelationResponseCollection = {
  __typename?: 'StockReturnedFromCustomerRelationResponseCollection';
  data: Array<StockReturnedFromCustomerEntity>;
};

export type StringFilterInput = {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  containsi?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  eqi?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  nei?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']>;
  notContainsi?: InputMaybe<Scalars['String']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  notNull?: InputMaybe<Scalars['Boolean']>;
  null?: InputMaybe<Scalars['Boolean']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  blocked?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deliveryDay?: Maybe<Scalars['Int']>;
  incoming_invoices?: Maybe<IncomingInvoiceRelationResponseCollection>;
  name?: Maybe<Scalars['String']>;
  ownCompany: Scalars['Boolean'];
  priceCountRow?: Maybe<Scalars['Int']>;
  priceLastUpdate?: Maybe<Scalars['DateTime']>;
  spares?: Maybe<SpareRelationResponseCollection>;
  spares_archive?: Maybe<SpareArchiveRelationResponseCollection>;
  stocks?: Maybe<StockRelationResponseCollection>;
  supplier_payments?: Maybe<SupplierPaymentRelationResponseCollection>;
  supplier_returns?: Maybe<SupplierReturnRelationResponseCollection>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type SupplierIncoming_InvoicesArgs = {
  filters?: InputMaybe<IncomingInvoiceFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SupplierSparesArgs = {
  filters?: InputMaybe<SpareFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SupplierSpares_ArchiveArgs = {
  filters?: InputMaybe<SpareArchiveFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SupplierStocksArgs = {
  filters?: InputMaybe<StockFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SupplierSupplier_PaymentsArgs = {
  filters?: InputMaybe<SupplierPaymentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SupplierSupplier_ReturnsArgs = {
  filters?: InputMaybe<SupplierReturnFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SupplierAccount = {
  __typename?: 'SupplierAccount';
  createdAt?: Maybe<Scalars['DateTime']>;
  supplierId: Scalars['Long'];
  total?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SupplierAccountEntity = {
  __typename?: 'SupplierAccountEntity';
  attributes?: Maybe<SupplierAccount>;
  id?: Maybe<Scalars['ID']>;
};

export type SupplierAccountEntityResponse = {
  __typename?: 'SupplierAccountEntityResponse';
  data?: Maybe<SupplierAccountEntity>;
};

export type SupplierAccountEntityResponseCollection = {
  __typename?: 'SupplierAccountEntityResponseCollection';
  data: Array<SupplierAccountEntity>;
  meta: ResponseCollectionMeta;
};

export type SupplierAccountFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SupplierAccountFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<SupplierAccountFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SupplierAccountFiltersInput>>>;
  supplierId?: InputMaybe<LongFilterInput>;
  total?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SupplierAccountInput = {
  supplierId?: InputMaybe<Scalars['Long']>;
  total?: InputMaybe<Scalars['Float']>;
};

export type SupplierBalance = {
  __typename?: 'SupplierBalance';
  balance?: Maybe<Scalars['Float']>;
  remainingPayment?: Maybe<Scalars['Float']>;
  remainingReturn?: Maybe<Scalars['Float']>;
};

export type SupplierBalanceInput = {
  supplierId: Scalars['ID'];
};

export type SupplierEntity = {
  __typename?: 'SupplierEntity';
  attributes?: Maybe<Supplier>;
  id?: Maybe<Scalars['ID']>;
};

export type SupplierEntityResponse = {
  __typename?: 'SupplierEntityResponse';
  data?: Maybe<SupplierEntity>;
};

export type SupplierEntityResponseCollection = {
  __typename?: 'SupplierEntityResponseCollection';
  data: Array<SupplierEntity>;
  meta: ResponseCollectionMeta;
};

export type SupplierFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SupplierFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  cronExecutor?: InputMaybe<JsonFilterInput>;
  deliveryDay?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  incoming_invoices?: InputMaybe<IncomingInvoiceFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SupplierFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SupplierFiltersInput>>>;
  ownCompany?: InputMaybe<BooleanFilterInput>;
  priceCountRow?: InputMaybe<IntFilterInput>;
  priceLastUpdate?: InputMaybe<DateTimeFilterInput>;
  spares?: InputMaybe<SpareFiltersInput>;
  spares_archive?: InputMaybe<SpareArchiveFiltersInput>;
  stocks?: InputMaybe<StockFiltersInput>;
  supplier_payments?: InputMaybe<SupplierPaymentFiltersInput>;
  supplier_returns?: InputMaybe<SupplierReturnFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SupplierInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  cronExecutor?: InputMaybe<Scalars['JSON']>;
  deliveryDay?: InputMaybe<Scalars['Int']>;
  incoming_invoices?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  priceCountRow?: InputMaybe<Scalars['Int']>;
  priceLastUpdate?: InputMaybe<Scalars['DateTime']>;
  spares?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  spares_archive?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  stocks?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  supplier_payments?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  supplier_returns?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type SupplierPayment = {
  __typename?: 'SupplierPayment';
  comment?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  paymentDate: Scalars['Date'];
  paymentDirection: Enum_Supplierpayment_Paymentdirection;
  paymentMethod: Enum_Supplierpayment_Paymentmethod;
  paymentNumber?: Maybe<Scalars['String']>;
  remainingAmount: Scalars['Float'];
  supplier?: Maybe<SupplierEntityResponse>;
  supplier_payment_details?: Maybe<SupplierPaymentDetailRelationResponseCollection>;
  total: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type SupplierPaymentSupplier_Payment_DetailsArgs = {
  filters?: InputMaybe<SupplierPaymentDetailFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SupplierPaymentDetail = {
  __typename?: 'SupplierPaymentDetail';
  amount?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  incoming_invoice?: Maybe<IncomingInvoiceEntityResponse>;
  paymentType?: Maybe<Enum_Supplierpaymentdetail_Paymenttype>;
  supplier_payment?: Maybe<SupplierPaymentEntityResponse>;
  supplier_return?: Maybe<SupplierReturnEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SupplierPaymentDetailEntity = {
  __typename?: 'SupplierPaymentDetailEntity';
  attributes?: Maybe<SupplierPaymentDetail>;
  id?: Maybe<Scalars['ID']>;
};

export type SupplierPaymentDetailEntityResponse = {
  __typename?: 'SupplierPaymentDetailEntityResponse';
  data?: Maybe<SupplierPaymentDetailEntity>;
};

export type SupplierPaymentDetailEntityResponseCollection = {
  __typename?: 'SupplierPaymentDetailEntityResponseCollection';
  data: Array<SupplierPaymentDetailEntity>;
  meta: ResponseCollectionMeta;
};

export type SupplierPaymentDetailFiltersInput = {
  amount?: InputMaybe<FloatFilterInput>;
  and?: InputMaybe<Array<InputMaybe<SupplierPaymentDetailFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  incoming_invoice?: InputMaybe<IncomingInvoiceFiltersInput>;
  not?: InputMaybe<SupplierPaymentDetailFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SupplierPaymentDetailFiltersInput>>>;
  paymentType?: InputMaybe<StringFilterInput>;
  supplier_payment?: InputMaybe<SupplierPaymentFiltersInput>;
  supplier_return?: InputMaybe<SupplierReturnFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SupplierPaymentDetailRelationResponseCollection = {
  __typename?: 'SupplierPaymentDetailRelationResponseCollection';
  data: Array<SupplierPaymentDetailEntity>;
};

export type SupplierPaymentEntity = {
  __typename?: 'SupplierPaymentEntity';
  attributes?: Maybe<SupplierPayment>;
  id?: Maybe<Scalars['ID']>;
};

export type SupplierPaymentEntityResponse = {
  __typename?: 'SupplierPaymentEntityResponse';
  data?: Maybe<SupplierPaymentEntity>;
};

export type SupplierPaymentEntityResponseCollection = {
  __typename?: 'SupplierPaymentEntityResponseCollection';
  data: Array<SupplierPaymentEntity>;
  meta: ResponseCollectionMeta;
};

export type SupplierPaymentFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SupplierPaymentFiltersInput>>>;
  comment?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<SupplierPaymentFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SupplierPaymentFiltersInput>>>;
  paymentDate?: InputMaybe<DateFilterInput>;
  paymentDirection?: InputMaybe<StringFilterInput>;
  paymentMethod?: InputMaybe<StringFilterInput>;
  paymentNumber?: InputMaybe<StringFilterInput>;
  remainingAmount?: InputMaybe<FloatFilterInput>;
  supplier?: InputMaybe<SupplierFiltersInput>;
  supplier_payment_details?: InputMaybe<SupplierPaymentDetailFiltersInput>;
  total?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SupplierPaymentInput = {
  comment?: InputMaybe<Scalars['String']>;
  /** Payments will be linked to invoices in the order they are listed */
  incomingInvoicePayments?: InputMaybe<Array<IncomingInvoicePaymentInput>>;
  paymentDate?: InputMaybe<Scalars['Date']>;
  paymentDirection?: InputMaybe<Enum_Supplierpayment_Paymentdirection>;
  paymentMethod?: InputMaybe<Enum_Supplierpayment_Paymentmethod>;
  paymentNumber?: InputMaybe<Scalars['String']>;
  supplier?: InputMaybe<Scalars['ID']>;
  total?: InputMaybe<Scalars['Float']>;
};

export type SupplierPaymentRelationResponseCollection = {
  __typename?: 'SupplierPaymentRelationResponseCollection';
  data: Array<SupplierPaymentEntity>;
};

export type SupplierReturn = {
  __typename?: 'SupplierReturn';
  correctiveInvoiceDate?: Maybe<Scalars['Date']>;
  correctiveInvoiceNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  dateReturned?: Maybe<Scalars['Date']>;
  remainingAmount: Scalars['Float'];
  status?: Maybe<Enum_Supplierreturn_Status>;
  supplier?: Maybe<SupplierEntityResponse>;
  supplier_payment_details?: Maybe<SupplierPaymentDetailRelationResponseCollection>;
  supplier_return_items?: Maybe<SupplierReturnItemRelationResponseCollection>;
  total?: Maybe<Scalars['Float']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type SupplierReturnSupplier_Payment_DetailsArgs = {
  filters?: InputMaybe<SupplierPaymentDetailFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type SupplierReturnSupplier_Return_ItemsArgs = {
  filters?: InputMaybe<SupplierReturnItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type SupplierReturnEntity = {
  __typename?: 'SupplierReturnEntity';
  attributes?: Maybe<SupplierReturn>;
  id?: Maybe<Scalars['ID']>;
};

export type SupplierReturnEntityResponse = {
  __typename?: 'SupplierReturnEntityResponse';
  data?: Maybe<SupplierReturnEntity>;
};

export type SupplierReturnEntityResponseCollection = {
  __typename?: 'SupplierReturnEntityResponseCollection';
  data: Array<SupplierReturnEntity>;
  meta: ResponseCollectionMeta;
};

export type SupplierReturnFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SupplierReturnFiltersInput>>>;
  correctiveInvoiceDate?: InputMaybe<DateFilterInput>;
  correctiveInvoiceNumber?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  dateReturned?: InputMaybe<DateFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<SupplierReturnFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SupplierReturnFiltersInput>>>;
  remainingAmount?: InputMaybe<FloatFilterInput>;
  status?: InputMaybe<StringFilterInput>;
  supplier?: InputMaybe<SupplierFiltersInput>;
  supplier_payment_details?: InputMaybe<SupplierPaymentDetailFiltersInput>;
  supplier_return_items?: InputMaybe<SupplierReturnItemFiltersInput>;
  total?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SupplierReturnInput = {
  correctiveInvoiceDate?: InputMaybe<Scalars['Date']>;
  correctiveInvoiceNumber?: InputMaybe<Scalars['String']>;
  dateReturned?: InputMaybe<Scalars['Date']>;
  /** Returns will be linked to invoices in the order they are listed */
  incomingInvoiceReturns?: InputMaybe<Array<IncomingInvoiceReturnInput>>;
  status?: InputMaybe<Enum_Supplierreturn_Status>;
  supplier?: InputMaybe<Scalars['ID']>;
};

export type SupplierReturnItem = {
  __typename?: 'SupplierReturnItem';
  createdAt?: Maybe<Scalars['DateTime']>;
  quantityReturned?: Maybe<Scalars['Int']>;
  stock?: Maybe<StockEntityResponse>;
  supplierPrice?: Maybe<Scalars['Float']>;
  supplier_return?: Maybe<SupplierReturnEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SupplierReturnItemEntity = {
  __typename?: 'SupplierReturnItemEntity';
  attributes?: Maybe<SupplierReturnItem>;
  id?: Maybe<Scalars['ID']>;
};

export type SupplierReturnItemEntityResponse = {
  __typename?: 'SupplierReturnItemEntityResponse';
  data?: Maybe<SupplierReturnItemEntity>;
};

export type SupplierReturnItemEntityResponseCollection = {
  __typename?: 'SupplierReturnItemEntityResponseCollection';
  data: Array<SupplierReturnItemEntity>;
  meta: ResponseCollectionMeta;
};

export type SupplierReturnItemFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<SupplierReturnItemFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<SupplierReturnItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SupplierReturnItemFiltersInput>>>;
  quantityReturned?: InputMaybe<IntFilterInput>;
  stock?: InputMaybe<StockFiltersInput>;
  supplierPrice?: InputMaybe<FloatFilterInput>;
  supplier_return?: InputMaybe<SupplierReturnFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type SupplierReturnItemRelationResponseCollection = {
  __typename?: 'SupplierReturnItemRelationResponseCollection';
  data: Array<SupplierReturnItemEntity>;
};

export type SupplierReturnRelationResponseCollection = {
  __typename?: 'SupplierReturnRelationResponseCollection';
  data: Array<SupplierReturnEntity>;
};

export type SupplierSupportAutoOrdering = {
  __typename?: 'SupplierSupportAutoOrdering';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  order_items?: Maybe<Array<Maybe<OrderItemEntity>>>;
};

export enum SupplierTransactionDocType {
  IncomingInvoice = 'incoming_invoice',
  IncomingPaymentCash = 'incoming_payment_cash',
  IncomingPaymentNonCash = 'incoming_payment_non_cash',
  OutgoingPaymentCash = 'outgoing_payment_cash',
  OutgoingPaymentNonCash = 'outgoing_payment_non_cash',
  Return = 'return'
}

export type SupplierTransactionEntity = {
  __typename?: 'SupplierTransactionEntity';
  comment?: Maybe<Scalars['String']>;
  debtAmount?: Maybe<Scalars['Float']>;
  docAmount?: Maybe<Scalars['Float']>;
  docDate: Scalars['Date'];
  docId: Scalars['ID'];
  docNumber?: Maybe<Scalars['String']>;
  docType?: Maybe<SupplierTransactionDocType>;
  remainingAmount?: Maybe<Scalars['Float']>;
};

export type SupplierTransactionEntityCollections = {
  __typename?: 'SupplierTransactionEntityCollections';
  data: Array<SupplierTransactionEntity>;
  meta: CollectionMeta;
};

export type SupplierTransactionsFiltersInput = {
  dateEnd: Scalars['Date'];
  dateStart: Scalars['Date'];
  /** filter by number of document (contains, case insensitive) */
  docNumber?: InputMaybe<Scalars['String']>;
  /** filter by types of document */
  docType?: InputMaybe<Array<InputMaybe<SupplierTransactionDocType>>>;
  supplierId: Scalars['ID'];
};

export type UpdateGeneralSpareInput = {
  articleCriteriaDE?: InputMaybe<Array<InputMaybe<ArticleCriteriaInput>>>;
  articleCriteriaEN?: InputMaybe<Array<InputMaybe<ArticleCriteriaInput>>>;
  brandId?: InputMaybe<Scalars['Int']>;
  descriptionsInfoDE?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  descriptionsInfoEN?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  eanNumber?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  images?: InputMaybe<Array<InputMaybe<ImageInput>>>;
  isDraft?: InputMaybe<Scalars['Boolean']>;
  tecdoc_articleNumber?: InputMaybe<Scalars['String']>;
  titleDE?: InputMaybe<Scalars['String']>;
  titleEN?: InputMaybe<Scalars['String']>;
};

export type UpdateOrderInput = {
  car?: InputMaybe<Scalars['ID']>;
  comment?: InputMaybe<Scalars['String']>;
  courier?: InputMaybe<Scalars['ID']>;
  customer?: InputMaybe<Scalars['ID']>;
  deliveryAddress?: InputMaybe<ComponentClientDeliveryAddressInput>;
  deliveryDate?: InputMaybe<Scalars['DateTime']>;
  managers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Enum_Order_Status>;
  status_editing_history?: InputMaybe<Array<InputMaybe<ComponentOrderStatusEditingHistoryInput>>>;
};

export type UpdateOrderItemInput = {
  comment?: InputMaybe<Scalars['String']>;
  customer_price?: InputMaybe<Scalars['Float']>;
  discount?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

export type UploadFile = {
  __typename?: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']>;
  caption?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  ext?: Maybe<Scalars['String']>;
  formats?: Maybe<Scalars['JSON']>;
  hash: Scalars['String'];
  height?: Maybe<Scalars['Int']>;
  mime: Scalars['String'];
  name: Scalars['String'];
  previewUrl?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
  provider_metadata?: Maybe<Scalars['JSON']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type UploadFileEntity = {
  __typename?: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFileEntityResponse = {
  __typename?: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
};

export type UploadFileEntityResponseCollection = {
  __typename?: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFileFiltersInput = {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
};

export type UploadFileInput = {
  alternativeText?: InputMaybe<Scalars['String']>;
  caption?: InputMaybe<Scalars['String']>;
  ext?: InputMaybe<Scalars['String']>;
  folder?: InputMaybe<Scalars['ID']>;
  folderPath?: InputMaybe<Scalars['String']>;
  formats?: InputMaybe<Scalars['JSON']>;
  hash?: InputMaybe<Scalars['String']>;
  height?: InputMaybe<Scalars['Int']>;
  mime?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  previewUrl?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  provider_metadata?: InputMaybe<Scalars['JSON']>;
  size?: InputMaybe<Scalars['Float']>;
  url?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Int']>;
};

export type UploadFileRelationResponseCollection = {
  __typename?: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
};

export type UploadFolder = {
  __typename?: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String'];
  pathId: Scalars['Int'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UploadFolderChildrenArgs = {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UploadFolderFilesArgs = {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UploadFolderEntity = {
  __typename?: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']>;
};

export type UploadFolderEntityResponse = {
  __typename?: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
};

export type UploadFolderEntityResponseCollection = {
  __typename?: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
};

export type UploadFolderFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UploadFolderInput = {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  name?: InputMaybe<Scalars['String']>;
  parent?: InputMaybe<Scalars['ID']>;
  path?: InputMaybe<Scalars['String']>;
  pathId?: InputMaybe<Scalars['Int']>;
};

export type UploadFolderRelationResponseCollection = {
  __typename?: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
};

export type UsersPermissionsCreateRolePayload = {
  __typename?: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsDeleteRolePayload = {
  __typename?: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsLoginInput = {
  identifier: Scalars['String'];
  password: Scalars['String'];
  provider?: Scalars['String'];
};

export type UsersPermissionsLoginPayload = {
  __typename?: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']>;
  user: UsersPermissionsMe;
};

export type UsersPermissionsMe = {
  __typename?: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  customer_contact_info?: Maybe<CustomerContactInfoEntityResponse>;
  customer_vehicles?: Maybe<VehicleRelationResponseCollection>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  newsletter_subscription?: Maybe<NewsletterSubscriptionEntityResponse>;
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String'];
  wishlist?: Maybe<Scalars['JSON']>;
};


export type UsersPermissionsMeCustomer_VehiclesArgs = {
  filters?: InputMaybe<VehicleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsMeRole = {
  __typename?: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type UsersPermissionsPasswordPayload = {
  __typename?: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsPermission = {
  __typename?: 'UsersPermissionsPermission';
  action: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type UsersPermissionsPermissionEntity = {
  __typename?: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsPermissionFiltersInput = {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
};

export type UsersPermissionsPermissionRelationResponseCollection = {
  __typename?: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
};

export type UsersPermissionsRegisterInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  staff?: InputMaybe<Scalars['Boolean']>;
  username: Scalars['String'];
};

export type UsersPermissionsRole = {
  __typename?: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
};


export type UsersPermissionsRolePermissionsArgs = {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsRoleUsersArgs = {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsRoleEntity = {
  __typename?: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsRoleEntityResponse = {
  __typename?: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
};

export type UsersPermissionsRoleEntityResponseCollection = {
  __typename?: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsRoleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
};

export type UsersPermissionsRoleInput = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  type?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type UsersPermissionsUpdateRolePayload = {
  __typename?: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean'];
};

export type UsersPermissionsUser = {
  __typename?: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  confirmedPhoneNumber?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer_bills?: Maybe<CustomerBillRelationResponseCollection>;
  customer_contact_info?: Maybe<CustomerContactInfoEntityResponse>;
  customer_payments?: Maybe<CustomerPaymentRelationResponseCollection>;
  customer_returns?: Maybe<CustomerReturnRelationResponseCollection>;
  customer_vehicles?: Maybe<VehicleRelationResponseCollection>;
  email?: Maybe<Scalars['String']>;
  manager_orders?: Maybe<OrderRelationResponseCollection>;
  newsletter_subscription?: Maybe<NewsletterSubscriptionEntityResponse>;
  orders?: Maybe<OrderRelationResponseCollection>;
  orders_courier?: Maybe<OrderRelationResponseCollection>;
  phoneNumber?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  wishlist?: Maybe<Scalars['JSON']>;
};


export type UsersPermissionsUserCustomer_BillsArgs = {
  filters?: InputMaybe<CustomerBillFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsUserCustomer_PaymentsArgs = {
  filters?: InputMaybe<CustomerPaymentFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsUserCustomer_ReturnsArgs = {
  filters?: InputMaybe<CustomerReturnFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsUserCustomer_VehiclesArgs = {
  filters?: InputMaybe<VehicleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsUserManager_OrdersArgs = {
  filters?: InputMaybe<OrderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsUserOrdersArgs = {
  filters?: InputMaybe<OrderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type UsersPermissionsUserOrders_CourierArgs = {
  filters?: InputMaybe<OrderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UsersPermissionsUserEntity = {
  __typename?: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']>;
};

export type UsersPermissionsUserEntityResponse = {
  __typename?: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
};

export type UsersPermissionsUserEntityResponseCollection = {
  __typename?: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
};

export type UsersPermissionsUserFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  confirmationOTP?: InputMaybe<StringFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  confirmedPhoneNumber?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer_bills?: InputMaybe<CustomerBillFiltersInput>;
  customer_contact_info?: InputMaybe<CustomerContactInfoFiltersInput>;
  customer_payments?: InputMaybe<CustomerPaymentFiltersInput>;
  customer_returns?: InputMaybe<CustomerReturnFiltersInput>;
  customer_vehicles?: InputMaybe<VehicleFiltersInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  manager_orders?: InputMaybe<OrderFiltersInput>;
  newsletter_subscription?: InputMaybe<NewsletterSubscriptionFiltersInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  orders?: InputMaybe<OrderFiltersInput>;
  orders_courier?: InputMaybe<OrderFiltersInput>;
  password?: InputMaybe<StringFilterInput>;
  phoneNumber?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordOTP?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
  wishlist?: InputMaybe<JsonFilterInput>;
};

export type UsersPermissionsUserInput = {
  blocked?: InputMaybe<Scalars['Boolean']>;
  confirmationOTP?: InputMaybe<Scalars['String']>;
  confirmationToken?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  confirmedPhoneNumber?: InputMaybe<Scalars['Boolean']>;
  customer_bills?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  customer_contact_info?: InputMaybe<Scalars['ID']>;
  customer_payments?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  customer_returns?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  customer_vehicles?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  email?: InputMaybe<Scalars['String']>;
  manager_orders?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  newsletter_subscription?: InputMaybe<Scalars['ID']>;
  orders?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  orders_courier?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  password?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
  resetPasswordOTP?: InputMaybe<Scalars['String']>;
  resetPasswordToken?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['ID']>;
  username?: InputMaybe<Scalars['String']>;
  wishlist?: InputMaybe<Scalars['JSON']>;
};

export type UsersPermissionsUserRelationResponseCollection = {
  __typename?: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  car?: Maybe<CarEntityResponse>;
  car_brand?: Maybe<CarBrandEntityResponse>;
  car_model?: Maybe<CarModelEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']>;
  customer?: Maybe<UsersPermissionsUserEntityResponse>;
  main?: Maybe<Scalars['Boolean']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  vinCode?: Maybe<Scalars['String']>;
  yearOfManufacture?: Maybe<Scalars['Int']>;
};

export type VehicleEntity = {
  __typename?: 'VehicleEntity';
  attributes?: Maybe<Vehicle>;
  id?: Maybe<Scalars['ID']>;
};

export type VehicleEntityResponse = {
  __typename?: 'VehicleEntityResponse';
  data?: Maybe<VehicleEntity>;
};

export type VehicleEntityResponseCollection = {
  __typename?: 'VehicleEntityResponseCollection';
  data: Array<VehicleEntity>;
  meta: ResponseCollectionMeta;
};

export type VehicleFiltersInput = {
  and?: InputMaybe<Array<InputMaybe<VehicleFiltersInput>>>;
  car?: InputMaybe<CarFiltersInput>;
  car_brand?: InputMaybe<CarBrandFiltersInput>;
  car_model?: InputMaybe<CarModelFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  customer?: InputMaybe<UsersPermissionsUserFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  main?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<VehicleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<VehicleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  vinCode?: InputMaybe<StringFilterInput>;
  yearOfManufacture?: InputMaybe<IntFilterInput>;
};

export type VehicleInput = {
  car?: InputMaybe<Scalars['ID']>;
  car_brand?: InputMaybe<Scalars['ID']>;
  car_model?: InputMaybe<Scalars['ID']>;
  customer?: InputMaybe<Scalars['ID']>;
  main?: InputMaybe<Scalars['Boolean']>;
  vinCode?: InputMaybe<Scalars['String']>;
  yearOfManufacture?: InputMaybe<Scalars['Int']>;
};

export type VehicleRelationResponseCollection = {
  __typename?: 'VehicleRelationResponseCollection';
  data: Array<VehicleEntity>;
};
