import { IconButton } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import { SearchField } from '../../../components/SearchField';
import { ListAltOutlined, FilterAltOutlined } from '@mui/icons-material';

interface Props {
  isCollapseCatalog: boolean;
  isCollapseFilter: boolean;
  onCollapseCatalogClick: () => void;
  onCollapseFilterClick: () => void;
  onSearchParts: (text: string) => void;
}
export const WrapperSearchFieldCollapse: FC<Props> = ({
  isCollapseCatalog,
  isCollapseFilter,
  onCollapseCatalogClick,
  onCollapseFilterClick,
  onSearchParts,
}) => {
  return (
    <Box width="100%" position="relative" display="flex" alignItems="center">
      <SearchField executeWithDelay={onSearchParts} />
      <IconButton
        onClick={onCollapseFilterClick}
        sx={{
          marginLeft: '30px',
          '&': {
            borderRadius: '10px',
            backgroundColor: isCollapseFilter ? '#97A408' : '#5269A3',
            color: 'white',
          },
          '&:hover': {
            borderRadius: '10px',
            backgroundColor: isCollapseFilter ? 'rgba(151,164,8,0.56)' : '#2A3453',
            color: 'white',
          },
        }}
        size="large"
      >
        <FilterAltOutlined />
      </IconButton>
      <IconButton
        onClick={onCollapseCatalogClick}
        sx={{
          marginLeft: '10px',
          '&': {
            borderRadius: '10px',
            backgroundColor: isCollapseCatalog ? '#97A408' : '#5269A3',
            color: 'white',
          },
          '&:hover': {
            borderRadius: '10px',
            backgroundColor: isCollapseCatalog ? 'rgba(151,164,8,0.56)' : '#2A3453',
            color: 'white',
          },
        }}
        size="large"
      >
        <ListAltOutlined />
      </IconButton>
    </Box>
  );
};
