import React, { FC, useRef, useState } from 'react';
import { Box, Typography, Zoom } from '@mui/material';
import { useBarcode } from 'next-barcode';

import { useLocalization } from '../../../../../localization';
import { CurrentAddressType } from '../../../types';
import { useUpdateStockOrderItemMutation } from '../../../../../graphql/mutations/__generated__/updateStockOrderitem';

import { ReturnInformComponent } from './ReturnInformComponent';

import { BlockButtons } from '../../PrintModal/BlockButtons';
import { InformAddressStock } from '../../PrintModal/InformAddressStock';
import { EditAddressStock } from '../../PrintModal/EditAddresStock';
import { handlerError } from '../../../../../helpers/functions';
import { ReturnedType } from '../../../../../graphql/queries/hook/useGetOrderItemsReturnedFromCustomers';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  barCode: string;
  returnedItem?: ReturnedType | null;
}
export const SearchedContentForReturn: FC<Props> = ({ isOpen, barCode, returnedItem }) => {
  const { translateLang } = useLocalization();

  const { inputRef } = useBarcode({
    value: barCode || 'no number',
    options: {
      background: 'transparent',
      textPosition: 'top',
      textMargin: 14,
      height: 70,
      fontSize: 24,
    },
  });
  const [runUpdateStockOrderItem] = useUpdateStockOrderItemMutation();
  const [editing, setEditing] = useState(false);
  const [currentAddress, setCurrentAddress] = useState<CurrentAddressType>({
    cell: returnedItem?.cell || '',
    shelf: returnedItem?.shelf || '',
    stockCellAddressId: returnedItem?.stockId || '',
  });
  const [emptyCellID, setEmptyCellID] = useState(currentAddress.stockCellAddressId);
  const divRef = useRef<HTMLDivElement>(null);
  const runStickerPrint = () => {
    const ifram = document.createElement('iframe');
    ifram.style.display = 'none';
    document.body.appendChild(ifram);
    const pri = ifram.contentWindow;
    if (pri && divRef.current) {
      pri.document.open();
      pri.document.write(divRef.current.innerHTML);
      pri.document.close();
      pri.focus();
      pri.print();
    }
    setTimeout(() => {
      document.body.removeChild(ifram);
      // handleClose();
    }, 500);
  };
  const changeAddressOrderItem = async () => {
    if (emptyCellID && emptyCellID !== currentAddress.stockCellAddressId) {
      try {
        if (currentAddress?.stockCellAddressId) {
          const resp = await runUpdateStockOrderItem({
            variables: {
              id: returnedItem?.stockId || '',
              data: {
                stock_cell_address: emptyCellID,
              },
            },
          });
          if (resp.data?.updateStock?.data?.attributes?.stock_cell_address?.data) {
            const cell =
              resp.data.updateStock.data.attributes?.stock_cell_address?.data.attributes?.cell ||
              '';
            const shelf =
              resp.data.updateStock.data.attributes?.stock_cell_address?.data?.attributes?.shelf ||
              '';
            const stockCellAddressId =
              resp.data.updateStock.data.attributes?.stock_cell_address?.data.id || '';
            setCurrentAddress({
              cell,
              shelf,
              stockCellAddressId,
            });
            setEditing(false);
          }
        }
      } catch (err: unknown) {
        handlerError(err);
      }
    }
  };

  return (
    <Zoom
      in={isOpen}
      mountOnEnter
      unmountOnExit
      style={{ transitionDelay: isOpen ? '500ms' : '0ms' }}
    >
      <Box display="flex" width="100%" height="100%" p={1}>
        <Box
          width="360px"
          height="100%"
          borderRight="1px solid #8D93A6"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          pr={2}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '18px',
              lineHeight: '38px',
              color: '#646363',
            }}
          >
            {translateLang('barCodeOrder')}
          </Typography>
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <svg ref={inputRef} />
          </Box>
        </Box>
        <Box
          flex={1}
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          pl={6}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '18px',
              lineHeight: '38px',
              color: '#646363',
            }}
          >
            {translateLang(!editing ? 'productInformation' : 'choosePlace')}
          </Typography>
          {returnedItem && <ReturnInformComponent returnedItem={returnedItem} />}
          {!editing ? (
            <InformAddressStock shelf={currentAddress.shelf} cell={currentAddress.cell} />
          ) : (
            <EditAddressStock emptyCellID={emptyCellID} setEmptyCellID={setEmptyCellID} />
          )}
          <BlockButtons
            saveChangeAddressOrderItems={changeAddressOrderItem}
            editing={editing}
            runStickerPrint={runStickerPrint}
            changeMode={setEditing}
          />
        </Box>
      </Box>
    </Zoom>
  );
};
