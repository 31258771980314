import React, { FC, useState } from 'react';
import { Add } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';

import { theme } from '../../../../../../helpers';
import { useGetTransactions } from './hooks/useGetTransactions';
import { PAGE_COUNT } from '../../../../../../helpers/constants';
import { useLocalization } from '../../../../../../localization';
import { ClientTransactionTable } from './ClientTransactionTable';
import { CustomerPaymentModal } from '../../../CustomerPaymentModal';
import { ClientTransactionToolbar } from './ClientTransactionToolbar';
import { CustomerAccountTransactionFiltersInput } from '../../../../../../__generated__/types';
import { useFiltersSearchParamsForOrders } from '../../../../../../hooks/useFiltersSearchParamsForOrders';
import { useFiltersSearchParamsForClients } from '../../../../../../hooks/useFiltersSearchParamsForClients';
import { TranslatedField } from '../../../../../../components/Layout/components/TranslatedField/TranslatedField';

interface ClientTransactionProps {
  userId?: string;
}

export const ClientTransaction: FC<ClientTransactionProps> = ({ userId }) => {
  const { translateLang } = useLocalization();

  const [transactionModal, setTransactionModal] = useState(false);

  const { selStatus, selDateTo, selDateFrom } = useFiltersSearchParamsForOrders();

  const { page, changePage } = useFiltersSearchParamsForClients();
  const { transactions, pagination, isLoading, customerPaymentsRefetch } = useGetTransactions({
    pagination: {
      page,
      pageSize: PAGE_COUNT,
    },
    filters: getFiltersOrders(),
    customerId: userId,
  });

  const changePageNumber = (value: number) => {
    changePage(value + 1);
  };

  const toggleTransactionModal = () => {
    setTransactionModal(!transactionModal);
  };

  function getFiltersOrders(): CustomerAccountTransactionFiltersInput | undefined {
    const filters: CustomerAccountTransactionFiltersInput | undefined = {};

    if (selStatus) {
      filters['documentType'] = {
        eq: selStatus,
      };
    }

    if (selDateTo && selDateFrom) {
      filters['createdAt'] = {
        between: [selDateFrom, selDateTo],
      };
    }
    return Object.keys(filters).length > 0 ? filters : undefined;
  }

  return (
    <Box width="100%">
      <ClientTransactionToolbar />
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        pt={2.5}
        pl={3.75}
        pr={2}
        pb={2}
        bgcolor={theme.palette.common.white}
        sx={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
      >
        <TranslatedField
          isTranslate
          variant="h5"
          fontSize={26}
          color="black"
          originText={translateLang('transactions')}
        />
        <Button
          variant="contained"
          startIcon={<Add />}
          sx={{
            height: 50,
            p: 2,
            textTransform: 'none',
            backgroundColor: '#5269A3',
            borderRadius: '10px',
          }}
          onClick={toggleTransactionModal}
        >
          <TranslatedField originText={'createTransaction'} fontSize={16} isTranslate noWrap />
        </Button>
      </Stack>

      <ClientTransactionTable
        transactions={transactions}
        totalPage={pagination?.pageCount}
        changePage={changePageNumber}
        currentPage={page}
        loading={isLoading}
      />
      {transactionModal ? (
        <CustomerPaymentModal
          isModalOpen={transactionModal}
          handleClose={toggleTransactionModal}
          userId={userId}
          customerPaymentsRefetch={customerPaymentsRefetch}
        />
      ) : null}
    </Box>
  );
};
