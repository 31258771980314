import { useEffect } from 'react';
import { UpdateOrderMutationVariables } from '../../../graphql/mutations/__generated__/updateOrder';
import { useGetMe } from '../../../graphql/queries/hook/useGetMe';
import { useGetOrderByIdLazyQuery } from '../../../graphql/queries/__generated__/getOrderById';
import { useLocalization } from '../../../localization';

interface ReturnHook {
  managers: NonNullable<UpdateOrderMutationVariables['data']['managers']>;
  isNewManager: boolean;
  orderNumber?: string | null;
}

export const useGetManagers = (idOrder: string): ReturnHook => {
  const { updateSelectLanguage } = useLocalization();
  const [runGetById, { data }] = useGetOrderByIdLazyQuery();
  const { id } = useGetMe();

  const getIsNewManager = (): boolean => {
    if (data?.order?.data?.attributes?.managers?.data && id) {
      const managers = data?.order?.data?.attributes?.managers?.data.map(item => item?.id || '');
      return !managers.includes(id);
    }
    return false;
  };

  const getActualManagers = (): string[] => {
    if (data?.order?.data?.attributes?.managers?.data && id) {
      const managers = data?.order?.data?.attributes?.managers?.data.map(item => item?.id || '');
      if (!managers.includes(id)) {
        return [...managers, id];
      } else {
        return managers;
      }
    }
    return [];
  };
  const getOrderNumber = () => {
    let orderNumber = data?.order?.data?.attributes?.orderNumber || '';
    if (data?.order?.data?.attributes?.orderNumberAdd) {
      orderNumber += `-${data?.order.data.attributes.orderNumberAdd}`
    }
    return orderNumber
  }

  useEffect(() => {
    if (idOrder) {
      runGetById({ variables: { id: idOrder, locale: updateSelectLanguage as string } }).then();
    }
  }, [idOrder]);

  return {
    orderNumber: getOrderNumber(),
    managers: getActualManagers(),
    isNewManager: getIsNewManager(),
  };
};
