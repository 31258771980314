import React from 'react';

import { theme } from '../../../../helpers';
import { useLocalization } from '../../../../localization';
import {
  SupplierTransactionDocType,
  SupplierTransactionEntity,
} from '../../../../__generated__/types';
import { TableProductActive } from '../../../../components/TableProductActive/TableProductActive';
import { IconButton, Stack, Typography } from '@mui/material';
import { LinkOutlined, RemoveRedEye, RemoveRedEyeOutlined } from '@mui/icons-material';
import { formatCurrency } from '../../../../utils/currencyUtils';
import { formatDateTime } from '../../../../utils/dateUtils';

export const SupplierBalanceRowItem = ({
  transaction,
  action,
}: {
  transaction: SupplierTransactionEntity;
  action: (transaction: SupplierTransactionEntity) => void;
}) => {
  const { translateLang } = useLocalization();
  const { docId, docDate, docAmount, docType, comment, remainingAmount, debtAmount } = transaction;

  const sum = formatCurrency(docAmount, {
    error: '0',
    prefix:
      docType === SupplierTransactionDocType.OutgoingPaymentCash ||
      docType === SupplierTransactionDocType.OutgoingPaymentNonCash ||
      docType === SupplierTransactionDocType.Return
        ? '+ '
        : docType === SupplierTransactionDocType.IncomingInvoice ||
          docType === SupplierTransactionDocType.IncomingPaymentCash ||
          docType === SupplierTransactionDocType.IncomingPaymentNonCash
        ? '- '
        : '',
  });

  const description =
    docType === SupplierTransactionDocType.IncomingPaymentCash
      ? `${translateLang('paymentReceived')} (${translateLang('cash')})`
      : docType === SupplierTransactionDocType.IncomingPaymentNonCash
      ? `${translateLang('paymentReceived')} (${translateLang('nonCash')})`
      : docType === SupplierTransactionDocType.OutgoingPaymentCash
      ? `${translateLang('payment')} (${translateLang('cash')})`
      : docType === SupplierTransactionDocType.OutgoingPaymentNonCash
      ? `${translateLang('payment')} (${translateLang('nonCash')})`
      : docType === SupplierTransactionDocType.IncomingInvoice
      ? translateLang('incomingInvoice')
      : docType === SupplierTransactionDocType.Return
      ? translateLang('turnoverDocument')
      : '';

  return (
    <TableProductActive.RowTable>
      <TableProductActive.CellTable ceilStyle={{ width: '40px', borderLeft: 'none' }}>
        {docId}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ width: '100px' }}>
        {formatDateTime(docDate, { format: 'P' })}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable
        ceilStyle={{
          width: '60px',
          alignItems: 'center',
          color:
            docType === SupplierTransactionDocType.OutgoingPaymentCash ||
            docType === SupplierTransactionDocType.OutgoingPaymentNonCash ||
            docType === SupplierTransactionDocType.Return
              ? theme.palette.common.appleGreen
              : docType === SupplierTransactionDocType.IncomingInvoice ||
                docType === SupplierTransactionDocType.IncomingPaymentCash ||
                docType === SupplierTransactionDocType.IncomingPaymentNonCash
              ? theme.palette.common.red
              : undefined,
        }}
      >
        {sum}
        {' ('}

        <Typography fontWeight={700} fontSize={13}>
          {docType === SupplierTransactionDocType.IncomingInvoice
            ? formatCurrency(debtAmount)
            : formatCurrency(remainingAmount)}
        </Typography>
        {')'}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ width: '100px' }}>
        {description}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ width: '100px' }}>
        {comment}
      </TableProductActive.CellTable>
      <TableProductActive.CellTable ceilStyle={{ width: '60px', alignItems: 'end' }}>
        <Stack flexDirection="row" gap={1.25}>
          {
            <IconButton
              onClick={() => {
                action(transaction);
              }}
            >
              {docType === SupplierTransactionDocType.OutgoingPaymentCash ||
              docType === SupplierTransactionDocType.OutgoingPaymentNonCash ||
              docType === SupplierTransactionDocType.IncomingPaymentCash ||
              docType === SupplierTransactionDocType.IncomingPaymentNonCash ||
              docType === SupplierTransactionDocType.Return ? (
                <LinkOutlined />
              ) : (
                <RemoveRedEyeOutlined />
              )}
            </IconButton>
          }
        </Stack>
      </TableProductActive.CellTable>
    </TableProductActive.RowTable>
  );
};
