import React, { FC, useEffect, useRef, useState } from 'react';
import { Add } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { addDays, endOfToday, format, startOfDay, subDays } from 'date-fns';

import { theme } from '../../../../helpers';
import { PAGE_COUNT } from '../../../../helpers/constants';
import { useLocalization } from '../../../../localization';
import { SupplierBalanceTable } from './SupplierBalanceTable';
import { SupplierBalanceToolbar } from './SupplierBalanceToolbar';
import { useGetSupplierBalance } from './hooks/useGetSupplierBalance';
import { SupplierPaymentForm } from './component/SupplierPaymentModal';
import {
  SupplierTransactionDocType,
  SupplierTransactionEntity,
  SupplierTransactionsFiltersInput,
} from '../../../../__generated__/types';
import { useFiltersSearchParamsForOrders } from '../../../../hooks/useFiltersSearchParamsForOrders';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { LinkingPaymentModal } from './component/LinkingPaymentModal/LinkingPaymentModal';
import { IncomingInvoicePaymentDetailsModal } from './component/IncomingInvoicePaymentDetailsModal';

interface Props {
  idSupplier: string;
  refetchSupplierBalance: () => void;
}

export const SupplierBalance: FC<Props> = ({ idSupplier, refetchSupplierBalance }) => {
  const documentInfo = useRef<{
    id: string;
    type: SupplierTransactionDocType;
    total: number;
    debt: number;
  } | null>(null);
  const { translateLang } = useLocalization();
  const { page, changePage, selDateTo, selDateFrom, changeDate, resetFilters } =
    useFiltersSearchParamsForOrders({
      dateFrom: format(addDays(new Date(), -30), 'yyyy-MM-dd'),
      dateTo: format(new Date(), 'yyyy-MM-dd'),
    });

  const [transactionModal, setTransactionModal] = useState(false);
  const [linkingPaymentModal, setLinkingPaymentModal] = useState(false);
  const [invoicePaymentDetails, setInvoicePaymentDetails] = useState(false);
  const { transactions, pagination, isLoading, supplierTransactionRefetch } = useGetSupplierBalance(
    {
      pagination: {
        page,
        pageSize: PAGE_COUNT,
      },
      filters: getFiltersInvoices(),
    }
  );

  const action = (transaction: SupplierTransactionEntity) => {
    if (
      transaction?.docType === SupplierTransactionDocType.IncomingPaymentCash ||
      transaction?.docType === SupplierTransactionDocType.IncomingPaymentNonCash ||
      transaction?.docType === SupplierTransactionDocType.OutgoingPaymentCash ||
      transaction?.docType === SupplierTransactionDocType.OutgoingPaymentNonCash ||
      transaction?.docType === SupplierTransactionDocType.Return
    ) {
      documentInfo.current = {
        id: transaction.docId,
        type: transaction.docType,
        total: 0,
        debt: 0,
      };
      setLinkingPaymentModal(true);
    }
    if (transaction?.docType === SupplierTransactionDocType.IncomingInvoice) {
      documentInfo.current = {
        id: transaction.docId,
        type: transaction.docType,
        total: transaction.docAmount ?? 0,
        debt: transaction.debtAmount ?? 0,
      };
      setInvoicePaymentDetails(true);
    }
  };

  const changePageNumber = (value: number) => {
    changePage(value + 1);
  };

  useEffect(() => {
    resetFilters();
  }, []);

  const refresh = async () => Promise.all([supplierTransactionRefetch(), refetchSupplierBalance()]);

  const toggleTransactionModal = () => {
    setTransactionModal(!transactionModal);
  };

  const toggleLinkingPaymentModal = async (isStateChanged: boolean) => {
    if (linkingPaymentModal) {
      documentInfo.current = null;
      if (isStateChanged) {
        await refresh();
      }
    }
    setLinkingPaymentModal(!linkingPaymentModal);
  };

  const toggleInvoicePaymentDetails = () => {
    setInvoicePaymentDetails(!invoicePaymentDetails);
  };

  function getFiltersInvoices(): SupplierTransactionsFiltersInput {
    const filters: SupplierTransactionsFiltersInput = {
      supplierId: idSupplier,
      dateStart: format(startOfDay(subDays(new Date(), 30)), 'yyyy-MM-dd'),
      dateEnd: format(endOfToday(), 'yyyy-MM-dd'),
    };

    if (selDateTo && selDateFrom) {
      filters['dateStart'] = selDateFrom;
      filters['dateEnd'] = selDateTo;
    }
    return filters;
  }

  return (
    <Box width="100%">
      <SupplierBalanceToolbar />
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        pt={2.5}
        pl={3.75}
        pr={2}
        pb={2}
        bgcolor={theme.palette.common.white}
        sx={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
      >
        <TranslatedField
          isTranslate
          variant="h5"
          fontSize={26}
          color="black"
          originText={translateLang('transactions')}
        />
        <Button
          variant="contained"
          startIcon={<Add />}
          sx={{
            height: 50,
            p: 2,
            textTransform: 'none',
            backgroundColor: '#5269A3',
            borderRadius: '10px',
          }}
          onClick={toggleTransactionModal}
        >
          <TranslatedField originText={'createPayment'} fontSize={16} isTranslate noWrap />
        </Button>
      </Stack>

      <SupplierBalanceTable
        transactions={transactions}
        totalPage={pagination?.pageCount}
        changePage={changePageNumber}
        currentPage={page}
        loading={isLoading}
        action={action}
      />
      {transactionModal ? (
        <SupplierPaymentForm
          supplierId={idSupplier}
          isModalOpen={transactionModal}
          handleClose={toggleTransactionModal}
          refetchSupplierBalance={refetchSupplierBalance}
          supplierTransactionRefetch={refresh}
        />
      ) : null}
      {linkingPaymentModal && documentInfo.current ? (
        <LinkingPaymentModal
          documentType={documentInfo.current.type}
          documentId={documentInfo.current.id}
          supplierId={idSupplier}
          isModalOpen={linkingPaymentModal}
          handleClose={toggleLinkingPaymentModal}
        />
      ) : null}
      {invoicePaymentDetails && documentInfo.current?.id ? (
        <IncomingInvoicePaymentDetailsModal
          total={documentInfo.current.total}
          debt={documentInfo.current.debt}
          invoiceId={documentInfo.current?.id}
          handleClose={toggleInvoicePaymentDetails}
          isModalOpen
        />
      ) : null}
    </Box>
  );
};
