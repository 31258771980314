import { useLocalization } from '../../../../../localization';
import { GeneralSpareInputType, CriteriaProps } from '../types';
import { useUpdateGeneralSpareMutation } from '../../../../../graphql/mutations/__generated__/updateGeneralSpare';

export const useUpdateSpareMutation = () => {
  const { updateSelectLanguage } = useLocalization();
  const [updateGeneralSpareMutation] = useUpdateGeneralSpareMutation();

  const updateGeneralSpare = (values: GeneralSpareInputType, productId: string) => {
    const articleCriteriaEN = values?.criteria?.map((item: CriteriaProps) => ({
      formattedValue: item.titleEN,
      criteriaDescription: item.valueEN,
      criteriaUnitDescription: item.dimensionEN,
    }));
    const articleCriteriaDE = values?.criteria?.map((item: CriteriaProps) => ({
      formattedValue: item.titleDE,
      criteriaDescription: item.valueDE,
      criteriaUnitDescription: item.dimensionDE,
    }));

    return updateGeneralSpareMutation({
      variables: {
        input: {
          brandId: +values.brandCode.brandId,
          titleDE: values.titleDE,
          titleEN: values.titleEN,
          ...(values?.images ? { images: values?.images } : null),
          ...(values?.descriptionENG ? { descriptionsInfoEN: [values?.descriptionENG] } : null),
          ...(values?.descriptionDE ? { descriptionsInfoDE: [values?.descriptionDE] } : null),
          ...(articleCriteriaDE ? { articleCriteriaDE: articleCriteriaDE } : null),
          ...(articleCriteriaEN ? { articleCriteriaEN: articleCriteriaEN } : null),
          tecdoc_articleNumber: values?.productCode,
          eanNumber: values?.barcodes,
          isDraft: values?.isDraft,
        },
        locale: updateSelectLanguage,
        id: productId,
      },
    });
  };

  return { updateGeneralSpare };
};
