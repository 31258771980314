import React, { forwardRef } from 'react';
import { usePreDeliveryStateQuery } from '../../../../../../graphql/cacheQuery/__generated__/preDeliveryState';
import { useGetOrderItems } from '../../../../../../graphql/queries/hook/useGetOrderItems';
import { useBarcode } from 'next-barcode';
import { useGetOrderById } from '../../../../../../graphql/queries/hook/useGetOrderById';

export const PrintReceipt = forwardRef<HTMLDivElement>((props, ref) => {
  const { data: dataPreDeliveryState } = usePreDeliveryStateQuery();
  const { orderItems } = useGetOrderItems(dataPreDeliveryState?.preDeliveryState.idOrder || '');
  const { firstName, lastName, userName, total, selectCar, deliveryAddress } = useGetOrderById(
    dataPreDeliveryState?.preDeliveryState.idOrder || ''
  );

  const getBarcode = () => {
    if (dataPreDeliveryState?.preDeliveryState.orderNumber) {
      const length = 10 - dataPreDeliveryState.preDeliveryState.orderNumber.length;
      let str = '';
      for (let i = 0; i < length; i++) {
        str += '0';
      }
      return `02-${str}${dataPreDeliveryState.preDeliveryState.idOrder}`;
    }
    return 'no number';
  };

  const { inputRef } = useBarcode({
    value: getBarcode(),
    options: {
      background: 'transparent',
      textPosition: 'bottom',
      textAlign: 'center',
      textMargin: 2,
      height: 60,
      fontSize: 16,
      margin: 0,
    },
  });

  const getOrderItems = () => {
    if (orderItems && orderItems.length) {
      return orderItems.map((item, index) => (
        <div key={index.toString()}>
          <span style={{ fontWeight: '600' }}>{`${index + 1}. `}</span>
          <span
            style={{ textAlign: 'left', fontSize: '14px' }}
          >{` ${item.article} ${item.productName} ${item.brandName}`}</span>
          <div style={{ display: 'flex' }}>
            <span style={{ flex: 1 }}></span>
            <span style={{ flex: 1 }}></span>
            <span style={{ flex: 1, textAlign: 'left' }}>{`${item.balanceQuantity} x`}</span>
            <span style={{ flex: 1, textAlign: 'left' }}>{item.customerPrice}</span>
            <span style={{ flex: 1, textAlign: 'right' }}>
              {((item?.customerPrice || 0) * (item?.balanceQuantity || 0)).toFixed(2)}
            </span>
          </div>
          <Line />
        </div>
      ));
    }
    return null;
  };
  const getTotalQuantity = () => {
    if (orderItems && orderItems.length) {
      return orderItems.reduce((akk, item) => {
        if (item?.balanceQuantity) {
          return akk + item.balanceQuantity;
        }
        return akk;
      }, 0);
    }
    return 0;
  };
  const getUserName = () => {
    const _firstName = firstName || '';
    const _lastName = lastName || '';
    const _userName = userName || '';
    if (_firstName || _lastName) {
      return `${_firstName} ${_lastName}`;
    } else if (_userName) {
      return _userName;
    }
    return 'No Name';
  };
  const getBrandAutoUser = () => {
    if (selectCar && selectCar.data?.attributes?.car_brand) {
      return (
        <span style={{ display: 'block', textAlign: 'center' }}>
          {
            selectCar.data.attributes.car_brand.data?.attributes?.car_brand_locales?.data[0]
              .attributes?.name
          }
        </span>
      );
    }
    return null;
  };
  const getModelAutoUser = () => {
    if (selectCar && selectCar.data?.attributes?.car_locales?.data[0].attributes?.name) {
      return (
        <span style={{ display: 'block', textAlign: 'center' }}>
          {selectCar.data.attributes.car_locales.data[0].attributes.name}
        </span>
      );
    }
    return null;
  };
  const getDeliveryDate = () => {
    if (orderItems.length && orderItems[0].deliveryDate) {
      const dateStr = new Date(orderItems[0].deliveryDate);
      const date = dateStr.getDate().toString().padStart(2, '0');
      const month = (dateStr.getMonth() + 1).toString().padStart(2, '0');
      const year = dateStr.getFullYear();
      return `${date}.${month}.${year}`;
    }
    return 'no date';
  };

  return (
    <div
      ref={ref}
      style={{
        display: 'none',
        width: '300px',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        border: 'solid 1px',
        backgroundColor: '#fff',
        fontFamily: 'Arial',
      }}
    >
      <p style={{ textAlign: 'center', fontSize: '24px' }}>
        {`Lieferschein #${dataPreDeliveryState?.preDeliveryState.orderNumber}`}
      </p>
      <span style={{ display: 'block', textAlign: 'center' }}>{getUserName()}</span>
      <span style={{ display: 'block', textAlign: 'center' }}>{getDeliveryDate()}</span>
      {getBrandAutoUser()}
      {getModelAutoUser()}
      <span style={{ display: 'block', textAlign: 'left', margin: '10px 0 10px 10px' }}>
        Lieferzone:
        <span style={{ fontSize: '16px', fontWeight: 600 }}>{` ${
          deliveryAddress?.[0]?.city ? `${deliveryAddress?.[0]?.city},` : ''
        } ${deliveryAddress?.[0]?.district ? `Bezirk: ${deliveryAddress?.[0]?.district},` : ''} ${
          deliveryAddress?.[0]?.street ? `Straße: ${deliveryAddress?.[0]?.street}` : ''
        }  ${deliveryAddress?.[0]?.number ? `${deliveryAddress?.[0]?.number},` : ''} ${
          deliveryAddress?.[0]?.zipCode ? `VIN-Code: ${deliveryAddress?.[0]?.zipCode},` : ''
        }`}</span>
      </span>
      <div style={{ width: '100%', height: '10px', borderBottom: 'solid 1px' }} />
      {getOrderItems()}

      <span style={{ display: 'block', textAlign: 'left', margin: '10px 0 10px 10px' }}>
        Stk. gesamt:
        <span style={{ fontSize: '18px', fontWeight: 600 }}>{`  ${getTotalQuantity()}`}</span>
      </span>
      <Line />
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 0 0 10px' }}>
        <span>Gesamt:</span>
        <span style={{ fontWeight: 600 }}>{total}</span>
      </div>
      <div style={{ padding: '20px 0' }}>
        <svg ref={inputRef} />
      </div>
      <span style={{ display: 'block', textAlign: 'left' }}>© autobro.at</span>
    </div>
  );
});
PrintReceipt.displayName = 'PrintReceipt';

const Line = () => {
  return (
    <svg viewBox="0 0 100 1" xmlns="http://www.w3.org/2000/svg">
      <line x1="0" y1="0" x2="100" y2="0" strokeWidth="0.5" stroke="black" />
    </svg>
  );
};
