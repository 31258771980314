import React, { FC, useState } from 'react';
import { CalendarMUI, Input } from '../../legos';
import { useParams } from 'react-router-dom';
import { Box, Grid, Stack } from '@mui/material';
import { useLocalization } from '../../localization';
import { useGetMeQuery } from '../../graphql/queries/__generated__/getMe';
import { VehicleItemChip } from '../CheckoutPage/components/VehicleItemChip';
import { useGetClientById } from '../../graphql/queries/hook/useGetClientById';
import { TranslatedField } from '../../components/Layout/components/TranslatedField/TranslatedField';
import { UrlParamsObjectType } from '../../types/types';
import SearchParts from '../CheckoutPage/components/SearchParts';
import { CartDetailBox } from '../CheckoutPage/components/CartDetailBox';
import { useGetOrderById } from '../../graphql/queries/hook/useGetOrderById';

const spaceUnicode = '\u00A0';

export const RegistrationOrderStep: FC = () => {
  const { translateLang } = useLocalization();
  const { idUser = '', idOrder = '' } = useParams<UrlParamsObjectType>();
  const { data } = useGetMeQuery();
  const { phoneNumbers, vehicles } = useGetClientById(idUser);
  const { selectCar, comment, total } = useGetOrderById(idOrder);
  const [value, setValue] = useState<Date | null>(new Date());
  const managerName = data?.me?.username || '';

  const getSelectVehicle = () => {
    if (selectCar && vehicles && vehicles.length) {
      const selCar = vehicles.find(car => car.carID === selectCar.data?.id);
      if (selCar) {
        return selCar;
      }
    }
    return null;
  };
  const selectVehicle = getSelectVehicle();

  return (
    <Box paddingBottom={15}>
      <Box
        sx={{
          m: '10px',
          padding: '40px',
          borderRadius: '10px',
          backgroundColor: '#FFFFFF',
        }}
      >
        <TranslatedField
          noWrap
          isTranslate
          variant="h5"
          color="black"
          marginBottom="50px"
          originText={translateLang('newOrderRegistration')}
        />
        <Grid item container spacing={3} alignItems="flex-end">
          <Grid item xs={3}>
            <CalendarMUI value={value} setValue={setValue} />
          </Grid>
          <Grid item xs={3}>
            <Input value={managerName} label={translateLang('manager')} />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          m: '10px',
          mt: '25px',
          borderRadius: '10px',
          backgroundColor: '#FFFFFF',
        }}
      >
        <CartDetailBox
          orderId={idOrder}
          comment={comment}
          orderTotal={total}
          vehicle={selectVehicle}
          phoneNumber={phoneNumbers?.[0]?.number ?? ''}
        />
      </Box>
      {selectVehicle ? (
        <Box
          sx={{
            m: '10px',
            padding: '40px',
            borderRadius: '10px',
          }}
          marginBottom="50px"
        >
          <Stack
            borderBottom={0.5}
            borderColor="#2A3453"
            direction="row"
            alignItems="center"
            gap={1}
            paddingBottom={5}
          >
            <TranslatedField
              noWrap
              isTranslate
              variant="h5"
              color="#2A3453"
              originText={`${translateLang('autoClient')}:`}
            />
            <VehicleItemChip vehicle={selectVehicle} />
          </Stack>

          <Stack flexDirection="row" alignItems="center" mt={5}>
            <TranslatedField
              noWrap
              variant="h5"
              fontWeight={400}
              color="#2A3453"
              originText={`${translateLang('catalogFor')}:${spaceUnicode}`}
            />
            <VehicleItemChip vehicle={selectVehicle} />
          </Stack>
        </Box>
      ) : null}
      <SearchParts />
    </Box>
  );
};
