import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetOrderById } from '../../../graphql/queries/hook/useGetOrderById';
import { PatchesName, UrlParamsObjectType } from '../../../types/types';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useLocalization } from '../../../localization';
import { OrderInfoTypography } from './OrderHistory/components/OrderInfoTypography';
import { useGetOrderItems } from '../../../graphql/queries/hook/useGetOrderItems';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';
import { MobileTopNavBar } from './MobileTopNavBar';
import { ReturnCheckList } from './OrderHistory/components/ReturnCheckList';
import { ReturnApprovePage } from './OrderHistory/components/ReturnApprowePage';
import { NoRowsImage } from '../../../components/NoRowsImage/NoRowsImage';
import { getAddress, getPhone } from '../../../helpers/functions';

export enum StepReturnComponent {
  Step1,
  Step2,
  Step3,
}

export const MobileDeliveryOrderInfo = () => {
  const { idOrder = '' } = useParams<UrlParamsObjectType>();
  const [currentStep, setCurrentStep] = useState(StepReturnComponent.Step1);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const { firstName, lastName, orderNumber, deliveryAddress, phoneNumbers, customerId, loading } =
    useGetOrderById(idOrder);
  const { translateLang } = useLocalization();
  const navigate = useNavigate();

  const { orderItems } = useGetOrderItems(idOrder);
  const handleBack = () => {
    if (currentStep === StepReturnComponent.Step1) {
      navigate(`/${PatchesName.Delivery}`, {
        replace: true,
      });
    } else {
      setCurrentStep(currentStep => currentStep - 1);
    }
  };

  return (
    <Stack height="100%" bgcolor="#F1F3F8">
      <MobileTopNavBar activeStep={currentStep} onPrev={handleBack} totalStep={2} />
      <Stack p="10px">
        {loading ? (
          <CircularProgress sx={{ alignSelf: 'center' }} size={30} color="secondary" />
        ) : null}

        {currentStep === StepReturnComponent.Step1 &&
          (orderNumber?.length && !loading ? (
            <>
              <Typography
                sx={{
                  lineHeight: '25.1px',
                  fontSize: '20px',
                  fontWeight: '600',
                  color: '#2A3453',
                  textAlign: 'center',
                  mt: '25px',
                }}
              >{`${translateLang('order')} #${orderNumber}`}</Typography>
              <Stack sx={{ borderRadius: '10px' }} padding="10px" marginBottom="10px">
                {deliveryAddress && deliveryAddress.length > 0 && (
                  <OrderInfoTypography
                    label={`${translateLang('address')}:`}
                    value={getAddress(deliveryAddress)}
                    divider={false}
                    width="100%"
                  />
                )}
                {(firstName || lastName) && (
                  <OrderInfoTypography
                    label={`${translateLang('client')}:`}
                    value={`${firstName} ${lastName} #${customerId}`}
                    divider={false}
                  />
                )}
                {phoneNumbers && (
                  <OrderInfoTypography
                    label={`${translateLang('phone')}:`}
                    value={getPhone(phoneNumbers)}
                    divider={false}
                  />
                )}
              </Stack>

              <Typography
                sx={{
                  lineHeight: '20px',
                  fontSize: '16px',
                  fontWeight: 400,
                  color: ' #535252',
                  marginY: '12px',
                  paddingLeft: '10px',
                }}
              >{`${translateLang('products')}: ${orderItems.length}`}</Typography>
              {orderItems?.map((i, index) => (
                <Stack
                  key={i.id}
                  sx={{ backgroundColor: '#FFFFFF', borderRadius: '10px' }}
                  padding="10px"
                  marginBottom="10px"
                >
                  <OrderInfoTypography
                    label={`${translateLang('goods')}:`}
                    value={i.productName}
                    divider={false}
                  />
                  <OrderInfoTypography
                    label={`${translateLang('article')}:`}
                    value={i.article}
                    divider={false}
                  />
                  {i.id && (
                    <Button
                      disabled={index === 2 ? true : false}
                      variant="outlined"
                      onClick={() => {
                        setSelectedItemId(i.id || null);
                        setCurrentStep(StepReturnComponent.Step2);
                      }}
                      sx={{
                        textTransform: 'none',
                        height: '50px',
                        borderRadius: '10px',
                        color: '#97A408',
                        borderColor: '#97A408',
                        borderWidth: '1.5px',
                        marginY: '10px',
                      }}
                    >
                      <TranslatedField noWrap isTranslate fontSize={16} originText="makeAReturn" />
                    </Button>
                  )}
                </Stack>
              ))}
            </>
          ) : (
            <NoRowsImage text="nothingFound" />
          ))}
        {currentStep === StepReturnComponent.Step2 && (
          <ReturnCheckList setCurrentStep={setCurrentStep} />
        )}
        {currentStep === StepReturnComponent.Step3 && (
          <ReturnApprovePage
            address={getAddress(deliveryAddress)}
            client={`${firstName} ${lastName} `}
            phone={getPhone(phoneNumbers)}
            id={selectedItemId}
          />
        )}
      </Stack>
    </Stack>
  );
};
