/* eslint-disable no-console */
import React, { FC, useState } from 'react';

import { useLocalization } from '../../../../localization';
import { CustomModal } from '../../../../components';
import { RegisterPage } from '../../../Authorization/RegisterPage';
import { EnterCodeForm } from '../../../Authorization/components/EnterCodeForm';

interface Props {
  closeModal(): void;
  openModal: boolean;
}

export const AddClientModal: FC<Props> = ({ openModal, closeModal }) => {
  const { translateLang } = useLocalization();
  const [openConfirmForm, setOpenConfirmForm] = useState<boolean>(false);
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const [isResponse, setIsResponse] = useState<boolean>(false);
  if (isResponse) {
    closeModal();
    setIsResponse(false);
  }

  const toggleConfirmForm = () => {
    setOpenConfirmForm(!openConfirmForm);
  };

  const handleCloseForm = () => {
    openConfirmForm && toggleConfirmForm();
    closeModal();
  };

  return (
    <CustomModal
      width={650}
      title={translateLang('addClients')}
      handleClose={handleCloseForm}
      open={openModal}
    >
      {openConfirmForm && phone ? (
        <EnterCodeForm toggleConfirmForm={handleCloseForm} phone={phone} password={password} />
      ) : (
        <RegisterPage
          asModal
          closeModal={handleCloseForm}
          toggleConfirmForm={toggleConfirmForm}
          handleSetPhone={val => setPhone(val)}
          handleSetPassword={val => setPassword(val)}
        />
      )}
    </CustomModal>
  );
};
