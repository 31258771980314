import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { Button, IconButton, Stack, Typography } from '@mui/material';

import { theme } from '../../../helpers';
import { PatchesName } from '../../../types/types';
import { useLocalization } from '../../../localization';
import { ClientInfoBalance } from './ClientInfoBalance';
import { useGetMe } from '../../../graphql/queries/hook/useGetMe';
import { useGetClientById } from '../../../graphql/queries/hook/useGetClientById';
import { TranslatedField } from '../../../components/Layout/components/TranslatedField/TranslatedField';

interface ClientInfoHeaderProps {
  idUser: string;
  activeTable: string | null;
}

export const ClientInfoHeader: FC<ClientInfoHeaderProps> = ({ idUser, activeTable }) => {
  const navigate = useNavigate();
  const { translateLang } = useLocalization();
  const { isAdmin, isSuperAdmin, isAccounter } = useGetMe();

  const { firstName, lastName } = useGetClientById(idUser);

  const handlerButtonWrapper = () => {
    navigate(`/${PatchesName.Orders}/${PatchesName.CreateOrder}/${idUser}`);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const clientName = `${firstName ?? ''} ${lastName ?? ''} ${
    !(firstName && lastName) ? translateLang('client') : ''
  }`;

  return (
    <>
      <Stack flexDirection="row" justifyContent="space-between" alignItems="center" mb={5}>
        <Stack flexDirection="row" alignItems="center" gap={1}>
          <IconButton onClick={handleGoBack}>
            <ArrowBack sx={{ color: theme.palette.common.black }} />
          </IconButton>
          <Typography fontSize={40} fontWeight={400}>
            {clientName} <span style={{ fontWeight: 800 }}>{`#${idUser}`}</span>
          </Typography>
        </Stack>
        {isAdmin || isSuperAdmin || isAccounter ? <ClientInfoBalance idUser={idUser} /> : null}
      </Stack>
      {activeTable === 'vehicle' ? (
        <Stack alignItems="end" mb={5}>
          <Button
            onClick={handlerButtonWrapper}
            variant="contained"
            style={{
              width: 'fit-content',
              minWidth: '220px',
              textTransform: 'none',
              backgroundColor: theme.palette.secondary.main,
              borderRadius: '10px',
              height: '46px',
            }}
          >
            <TranslatedField
              originText={translateLang('addOrder')}
              fontSize={16}
              isTranslate
              noWrap
              color={theme.palette.common.white}
            />
          </Button>
        </Stack>
      ) : null}
    </>
  );
};
