import React, { FC, useState, useMemo, useCallback } from 'react';
import { Box, Button, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Close, Done, EditNote, KeyboardBackspace } from '@mui/icons-material';

import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useLocalization } from '../../../../localization';
import { FormikContext, useFormik } from 'formik';
import { CriteriaProps, GeneralSpareInputType, ProductField, articleCriteriaProps } from './types';
import {
  ConfirmModalForm,
  ExitModalForm,
  ProductCategoryForm,
  ProductCriteriaForm,
  ProductDescriptionForm,
  ProductPhoto,
} from './components';
import { useValidation } from './hooks/useValidation';
import { useCreateSpareMutation } from './hooks/useCreateSpareMutation';
import { useHandlerNotificationApp } from '../../../../hooks/useHandlerNotificationApp';
import { useUpdateSpareMutation } from './hooks/useUpdateSpareMutation';
import { useGetGeneralSpareByIdQuery } from '../../../../graphql/queries/__generated__/getGeneralSpareById';
import { useGetSuppliersQuery } from '../../../../graphql/queries/__generated__/getSuppliers';

interface AddEditProductFormProps {
  handleCloseAddProduct: () => void;
  productId: string | null;
}

export const AddEditProductForm: FC<AddEditProductFormProps> = ({
  handleCloseAddProduct,
  productId,
}) => {
  const mdScreen = useMediaQuery('(max-width:900px)');
  const { translateLang } = useLocalization();
  const { addNotification } = useHandlerNotificationApp();

  const { validationSchema } = useValidation();
  const { createGeneralSpare } = useCreateSpareMutation();
  const { updateGeneralSpare } = useUpdateSpareMutation();

  const [isExitModalOpen, setIsExitModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const { data: suppliersData } = useGetSuppliersQuery({
    variables: {
      filters: { or: [{ blocked: { eq: false } }, { blocked: { eq: null } }] },
    },
  });

  const { data: productData } = useGetGeneralSpareByIdQuery({
    variables: { id: productId || '' },
    skip: !productId,
  });

  const ownSupplier = useMemo(
    () => suppliersData?.suppliers?.data.find(item => item.attributes?.ownCompany === true)?.id,
    [suppliersData]
  );

  const productEN = useMemo(() => productData?.en?.data?.attributes, [productData]);
  const productDE = useMemo(() => productData?.de?.data?.attributes, [productData]);

  const isOwnSupplier = productEN?.source ? productEN?.source?.toString() === ownSupplier : false;
  const isEditable = !!productEN?.isDraft || !!productDE?.isDraft || isOwnSupplier || !productId;
  const criterias = useMemo(() => {
    return productEN?.articleCriteria?.map((item: articleCriteriaProps, index: number) => {
      return {
        dimensionEN: item.criteriaUnitDescription,
        dimensionDE: productDE?.articleCriteria?.[index]?.criteriaUnitDescription || '',
        titleEN: item.criteriaDescription,
        titleDE: productDE?.articleCriteria?.[index]?.criteriaDescription || '',
        valueEN: item.formattedValue,
        valueDE: productDE?.articleCriteria?.[index]?.formattedValue || '',
      };
    });
  }, [productEN, productDE]);

  const initialValues = {
    [ProductField.ProductCode]: productEN?.tecdoc_articleNumber ?? '',
    [ProductField.BrandCode]: {
      brandName: productEN?.brandName ?? '',
      brandId: productEN?.brandId?.toString() ?? '',
      id: productId || '',
    },
    [ProductField.Barcodes]: productEN?.eanNumber ?? [''],
    [ProductField.TitleEN]: productEN?.title ?? '',
    [ProductField.BrandNameENG]: productEN?.brandName ?? '',
    [ProductField.DescriptionENG]: productEN?.descriptionsInfo[0] ?? '',
    [ProductField.TitleDE]: productDE?.title ?? '',
    [ProductField.BrandNameDE]: productDE?.brandName ?? '',
    [ProductField.DescriptionDE]: productDE?.descriptionsInfo[0] ?? '',
    [ProductField.Criteria]: criterias ?? [],
    [ProductField.IsDraft]: productEN?.isDraft ?? true,
    // [ProductField.Category]: null,
    // [ProductField.SubCategory]: null,
    // [ProductField.SecondCategory]: null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async values => {
      if (values[ProductField.IsDraft]) {
        await handleAddEditProduct(values, productId).then(() => {
          handleCloseAddProduct();
        });
      } else {
        toggleConfirmModal();
      }
    },
  });

  const handleAddEditProduct = useCallback(
    async (values: GeneralSpareInputType, productId?: string | null) => {
      if (productId) {
        await updateGeneralSpare(values, productId)
          .then(() => {
            addNotification({
              messageError: translateLang('productSuccessfullyUpdated'),
              typeMessage: 'success',
            });
          })
          .catch(error => {
            addNotification({
              messageError: error.message,
              typeMessage: 'error',
            });
          });
      } else {
        await createGeneralSpare(values)
          .then(() => {
            addNotification({
              messageError: translateLang('productSuccessfullyCreated'),
              typeMessage: 'success',
            });
          })
          .catch(error => {
            addNotification({
              messageError: error.message,
              typeMessage: 'error',
            });
          });
      }
    },
    []
  );

  const handlePublishSubmit = () => {
    formik.setFieldValue('isDraft', false);
    formik.handleSubmit();
  };
  const handleDraftSubmit = () => {
    formik.setFieldValue('isDraft', true);
    formik.handleSubmit();
  };

  const toggleConfirmModal = () => {
    setIsConfirmModalOpen(!isConfirmModalOpen);
  };

  const toggleExitModal = () => {
    setIsExitModalOpen(!isExitModalOpen);
  };

  return (
    <FormikContext.Provider value={formik}>
      <Box component="form" onSubmit={formik.handleSubmit}>
        <Grid container>
          <Grid item xs={12} container justifyContent="space-between" alignItems="center" mb={3.75}>
            <Grid xs item container alignItems="center">
              <Grid item>
                <IconButton onClick={toggleExitModal}>
                  <KeyboardBackspace style={{ color: '#1B2441' }} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="h6" fontSize={mdScreen ? '20px' : '30px'}>
                  {translateLang('product')}{' '}
                  <span style={{ fontWeight: 800 }}>{productEN?.tecdoc_articleNumber}</span>
                </Typography>
              </Grid>
            </Grid>
            <Grid xs="auto" item>
              <Button
                variant="outlined"
                endIcon={<Close />}
                sx={{
                  height: 50,
                  p: '15px 30px',
                  mr: 2,
                  textTransform: 'none',
                  borderRadius: '10px',
                }}
                onClick={toggleExitModal}
              >
                <TranslatedField originText={'cancel'} fontSize={16} isTranslate noWrap />
              </Button>
              {(!productId || isEditable) && (
                <Button
                  variant="contained"
                  endIcon={<EditNote />}
                  sx={{
                    height: 50,
                    p: '15px 30px',
                    mr: 2,
                    textTransform: 'none',
                    backgroundColor: '#5269A3',
                    borderRadius: '10px',
                  }}
                  onClick={handleDraftSubmit}
                >
                  <TranslatedField originText={'saveAsDraft'} fontSize={16} isTranslate noWrap />
                </Button>
              )}
              <Button
                variant="contained"
                endIcon={<Done />}
                sx={{
                  height: 50,
                  p: '15px 30px',
                  textTransform: 'none',
                  backgroundColor: '#5269A3',
                  borderRadius: '10px',
                }}
                onClick={handlePublishSubmit}
              >
                <TranslatedField originText={'save'} fontSize={16} isTranslate noWrap />
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              bgcolor="background.paper"
              borderRadius="10px"
              p={{ xs: 4, md: '40px 70px 40px 40px' }}
              boxShadow="box-shadow: 0px 3px 8px -1px rgba(25, 21, 22, 0.05), 0px 0px 1px 0px rgba(25, 21, 22, 0.25)"
            >
              <Grid item xs={12} lg={4}>
                <Box pr={{ xs: 0, lg: 4 }} borderRight={{ xs: 'none', lg: '0.5px solid #2A3453' }}>
                  <ProductPhoto
                    isEditable={isEditable}
                    imagesSrc={productEN?.images}
                    productId={productId}
                    productCode={formik.values[ProductField.ProductCode]}
                    titleEN={formik.values[ProductField.TitleEN]}
                    titleDE={formik.values[ProductField.TitleDE]}
                    brandCode={formik.values[ProductField.BrandCode]}
                    handleAddEditProduct={handleAddEditProduct}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={8}>
                <ProductDescriptionForm isEditable={isEditable} />
                {/* <ProductCategoryForm /> */}
                <ProductCriteriaForm />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ExitModalForm
          isExitModalOpen={isExitModalOpen}
          toggleExitModal={toggleExitModal}
          handleCloseAddProduct={handleCloseAddProduct}
        />
        <ConfirmModalForm
          productId={productId}
          isExitModalOpen={isConfirmModalOpen}
          toggleConfirmModal={toggleConfirmModal}
          handleCloseAddProduct={handleCloseAddProduct}
        />
      </Box>
    </FormikContext.Provider>
  );
};
