/* eslint-disable no-console */
import React, { FC, useState } from 'react';
import { Box, Button, Stack, Typography, Zoom } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Input } from '../../../../legos';
import { ItemProductType } from '../../types';
import { SelectComponent } from './SelectComponent';
import { useLocalization } from '../../../../localization';
import { AddStockInput, SearchProductsResult } from '../../../../__generated__/types';
import { useGetSuppliersQuery } from '../../../../graphql/queries/__generated__/getSuppliers';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';

export interface ExtendedSearchProductsResult extends SearchProductsResult {
  price?: number | null;
}

interface Props {
  isTitle?: boolean;
  isOpen: boolean;
  eanNumber: string;
  dataProduct?: ExtendedSearchProductsResult[] | null;
  addInStockWithoutOrder(input: AddStockInput): void;
}

enum AddInStockFields {
  price = 'price',
  quantity = 'quantity',
}

export const SearchedContentNotOrder: FC<Props> = ({
  eanNumber,
  isOpen,
  isTitle,
  dataProduct,
  addInStockWithoutOrder,
}) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const { data: dataSuppliers } = useGetSuppliersQuery({
    variables: {
      filters: { or: [{ blocked: { eq: false } }, { blocked: { eq: null } }] },
    },
  });
  const [selectBrand, setSelectBrand] = useState(dataProduct?.[0]?.brandId || '');
  const [selectSupplier, setSelectSupplier] = useState('');

  const addInStock = ({ price, quantity }: { price: number; quantity: number }) => {
    // handleClose();
    addInStockWithoutOrder({
      brandId: selectBrand,
      quantity: quantity,
      supplierId: selectSupplier,
      supplierPrice: price,
      tecdoc_articleNumber: getArticleNumber(),
      locale: updateSelectLanguage,
    });
  };

  const getSelectProduct = (): Array<ItemProductType> => {
    if (dataProduct?.length) {
      return dataProduct.map(item => {
        return {
          value: item?.brandId || '',
          label: `${item?.title} (${item?.brandName})`,
        };
      });
    }

    return [];
  };

  const getSuppliers = (): Array<ItemProductType> => {
    if (dataSuppliers?.suppliers?.data.length) {
      return dataSuppliers.suppliers.data.map(item => {
        return {
          value: item?.id || '',
          label: item?.attributes?.name || '',
        };
      });
    }
    return [];
  };

  function getArticleNumber(): string {
    if (dataProduct?.length && dataProduct[0]?.tecdoc_articleNumber) {
      return dataProduct[0]?.tecdoc_articleNumber;
    }
    return '';
  }

  const initialValues = {
    [AddInStockFields.price]: dataProduct?.[0]?.price || 0,
    [AddInStockFields.quantity]: 0,
  };

  const validationSchema = yup.object({
    [AddInStockFields.price]: yup
      .number()
      .positive(translateLang('positiv_number'))
      .required(translateLang('recuired_field')),
    [AddInStockFields.quantity]: yup
      .number()
      .positive(translateLang('positiv_number'))
      .required(translateLang('recuired_field')),
  });
  const { values, errors, handleChange, handleSubmit, isValid, dirty } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      addInStock({
        price: values[AddInStockFields.price],
        quantity: values[AddInStockFields.quantity],
      });
    },
  });

  return (
    <Zoom
      in={isOpen}
      mountOnEnter
      unmountOnExit
      style={{ transitionDelay: isOpen ? '500ms' : '0ms' }}
    >
      <Stack
        component="form"
        onSubmit={handleSubmit}
        flexDirection="row"
        width="100%"
        height="100%"
        p={1}
      >
        <Box
          width="250px"
          borderRight="1px solid #8D93A6"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '18px',
              lineHeight: '38px',
              color: '#646363',
            }}
          >
            {translateLang('barCodeOrder')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '24px',
              lineHeight: '30px',
            }}
          >
            {eanNumber}
          </Typography>
          <Typography
            mt={2}
            sx={{
              fontWeight: 400,
              fontSize: '18px',
              lineHeight: '38px',
              color: '#646363',
            }}
          >
            {translateLang('article')}
          </Typography>
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '24px',
              lineHeight: '30px',
            }}
          >
            {dataProduct?.length ? dataProduct[0]?.tecdoc_articleNumber : ''}
          </Typography>
        </Box>
        <Box
          flex={1}
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          pl={6}
        >
          {isTitle ? (
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '26px',
                lineHeight: '33px',
                alignSelf: 'center',
                marginBottom: '16px',
              }}
            >
              {translateLang('itemNotFoundInOrders')}
            </Typography>
          ) : null}
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: isTitle ? '18px' : '26px',
              lineHeight: '23px',
              alignSelf: 'center',
            }}
          >
            {translateLang('goodsInWarehouse')}
          </Typography>
          <Box textAlign="left" mt={3}>
            <SelectComponent
              dataSelect={getSelectProduct()}
              selectValue={selectBrand}
              changeSelect={setSelectBrand}
              label={translateLang('chooseProduct')}
            />
            <SelectComponent
              label={translateLang('selectProvider')}
              dataSelect={getSuppliers()}
              selectValue={selectSupplier}
              changeSelect={setSelectSupplier}
            />
            <Stack gap={2}>
              <Input
                type="number"
                variant={'outlined'}
                placeholder={translateLang('purchasePrice')}
                label={`${translateLang('purchasePrice')}, €`}
                name={AddInStockFields.price}
                value={values[AddInStockFields.price]}
                onChange={handleChange}
                error={!!errors[AddInStockFields.price]}
                helperText={errors[AddInStockFields.price]}
              />
              <Input
                type="number"
                variant={'outlined'}
                placeholder={translateLang('quantity')}
                label={`${translateLang('quantity')}`}
                name={AddInStockFields.quantity}
                value={values[AddInStockFields.quantity]}
                onChange={handleChange}
                error={!!errors[AddInStockFields.quantity]}
                helperText={errors[AddInStockFields.quantity]}
              />
            </Stack>
          </Box>
          <Stack display="flex" mt={5} spacing={4} width="100%" alignItems="center">
            <Button
              type="submit"
              disabled={!selectSupplier || !selectBrand || !isValid || !dirty}
              variant="contained"
              color="secondary"
              style={{
                width: '50%',
                height: '50px',
                borderRadius: '10px',
              }}
            >
              <TranslatedField
                originText={translateLang('putInStorage')}
                fontSize={16}
                isTranslate
                noWrap
                color="white"
                textTransform="none"
              />
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Zoom>
  );
};
