import React, { Dispatch, FC } from 'react';
import { Edit } from '@mui/icons-material';
import { SxProps, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { useLocalization } from '../../../../localization';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getCalendarLocalization } from '../../../../utils/getCalendarLocalization';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useSetSelectLanguageUserQuery } from '../../../../graphql/store/__generated__/setSelectLanguageUser';

type Props = {
  value: Date | null;
  setValue: Dispatch<React.SetStateAction<Date | null>> | ((newValue: Date | null) => void);
  isLabel?: boolean;
  inputStyle?: SxProps;
  disableFuture?: boolean;
};

export const CalendarInvoicePrint: FC<Props> = ({
  value,
  setValue,
  isLabel = true,
  inputStyle,
  disableFuture,
}) => {
  const { translateLang } = useLocalization();
  const { data: lang } = useSetSelectLanguageUserQuery();

  const onKeyDown = (e: any) => {
    e.preventDefault();
  };

  return (
    <>
      {isLabel ? (
        <TranslatedField
          fontSize={11}
          noWrap
          isTranslate
          variant="h5"
          color="#2A3453"
          marginBottom="2px"
          originText={translateLang('dateCreateNewOrder')}
        />
      ) : null}

      <LocalizationProvider
        adapterLocale={getCalendarLocalization(lang)}
        dateAdapter={AdapterDateFns}
      >
        <DatePicker
          disableFuture={disableFuture}
          InputProps={{
            disableUnderline: true,
          }}
          value={value}
          onChange={newValue => {
            setValue(newValue);
          }}
          components={{
            OpenPickerIcon: props => <Edit {...props} fontSize="small" />,
          }}
          renderInput={params => (
            <TextField
              variant="standard"
              disabled
              focused={false}
              sx={{
                border: 'none',
                height: '35px',
                paddingTop: '10px',
                paddingLeft: '16px',
                borderRadius: '6px',
                paddingRight: '16px',
                paddingBottom: '5px',
                background: '#F1F3F8',
                ...inputStyle,
              }}
              {...params}
              onKeyDown={onKeyDown}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};
