import React, { FC } from 'react';
import { Box, Checkbox, IconButton, Tooltip, Typography } from '@mui/material';
import { Enum_Order_Action_Type, Enum_Order_Status } from '../../__generated__/types';
import { OrderItemType } from '../../helpers/types';
import { theme } from '../../helpers';
import { InfoOutlined, WarningOutlined } from '@mui/icons-material';

type OrderItemStatusesCellProps = {
  action?: (orderItem: string, actionType: Enum_Order_Action_Type) => Promise<void>;
  orderItem: Pick<
    OrderItemType,
    'id' | 'isOrderConfirmed' | 'isOrdered' | 'statusOrder' | 'autoOrderLastError'
  >;
};

export const OrderItemStatusesCell: FC<OrderItemStatusesCellProps> = ({ orderItem, action }) => {
  const autoOrderLastError = orderItem.autoOrderLastError ?? '';
  const isOrdered = orderItem?.isOrdered || false;
  const isOrderConfirmed = orderItem?.isOrderConfirmed || false;
  const disable =
    !action ||
    !(
      orderItem.statusOrder === Enum_Order_Status.Coordination ||
      orderItem.statusOrder === Enum_Order_Status.Ordered
    ) ||
    (isOrdered && isOrderConfirmed);

  const disabledIsOrdered = disable || isOrderConfirmed;
  const disabledIsConfirmedOrder = disable || !isOrdered;

  return (
    <Box
      display="flex"
      width="100%"
      height="100%"
      alignItems="center"
      justifyContent="space-evenly"
    >
      <Checkbox
        checked={isOrdered}
        sx={{
          color: theme.palette.grey[600],
          '&.Mui-checked': {
            color: disabledIsOrdered ? 'rgba(30,200,60,0.35)' : '#1EC83C',
          },
        }}
        disabled={disabledIsOrdered}
        onChange={(e, checked) => {
          if (action) {
            action(
              orderItem?.id || '',
              checked ? Enum_Order_Action_Type.SetIsOrdered : Enum_Order_Action_Type.UnsetIsOrdered
            );
          }
        }}
      />
      <Checkbox
        checked={isOrderConfirmed}
        sx={{
          '&.Mui-checked': {
            color: disabledIsConfirmedOrder ? 'rgba(30,200,60,0.35)' : '#1EC83C',
          },
        }}
        disabled={disabledIsConfirmedOrder}
        onChange={(e, checked) => {
          if (action && checked) {
            action(orderItem?.id || '', Enum_Order_Action_Type.SetIsOrderConfirmed);
          }
        }}
      />
      {autoOrderLastError ? (
        <Tooltip title={<Typography>{autoOrderLastError}</Typography>} arrow>
          <InfoOutlined fontSize="small" sx={{ color: theme.palette.error.light }} />
        </Tooltip>
      ) : (
        <InfoOutlined fontSize="small" sx={{ color: theme.palette.grey[300] }} />
      )}
    </Box>
  );
};
