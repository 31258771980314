/* eslint-disable no-console */
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';

import { GRAPHQL_API } from '../helpers/constants';
import { LocalStorageKey } from '../helpers/types';
import { setMessageError } from '../graphql/store/functionsWitingCache';
import { apolloClient } from './apolloClient';

const httpLink = createUploadLink({ uri: GRAPHQL_API });

const authLink = setContext((_, { headers }) => {
  const token = window.localStorage.getItem(LocalStorageKey.JWT);

  if (token) {
    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${JSON.parse(token)}`,
      },
    };
  }
  return {
    headers,
  };
});
// const color = '#e37b03'
const errorLink = onError(({ graphQLErrors, operation, networkError }) => {
  if (graphQLErrors?.length) {
    graphQLErrors.map(itemErr => {
      const { message, extensions } = itemErr;
      if (operation.operationName === 'getMe' && extensions?.code === 'FORBIDDEN') {
        return;
      }
      console.log(`%c[ERROR_GRAPHQL]: ${message}`, 'color: #e37b03');
      console.log(`%c[ERROR_GRAPHQL_CODE]: `, 'color: #e37b03', extensions?.code);
      console.log(
        `%c[ERROR_GRAPHQL_OPERATION]: `,
        'color: #e37b03',
        operation.operationName,
        operation.variables
      );

      setMessageError({
        dataWrite: {
          messageError: {
            message,
            code: (extensions?.code as string) || '',
            time: new Date().getTime().toString(),
          },
        },
      });
      setTimeout(() => {
        setMessageError({
          dataWrite: {
            messageError: null,
          },
        });
      }, 1000);
    });
  } //
  else {
    if (networkError && networkError?.message && networkError.message.includes('401')) {
      console.log(networkError?.message);
      window.localStorage.removeItem(LocalStorageKey.JWT);
      window.location.assign('/');
      apolloClient.clearStore().then();
    }
  }
  // return new Observable((): void => {
  //   console.log('Errors Auth');
  //   // window.localStorage.removeItem(LocalStorageKey.JWT);
  //   // window.location.assign('/');
  //   // apolloClient.clearStore().then();
  // });
});

const link = authLink.concat(httpLink as unknown as ApolloLink);

export const apolloLink = ApolloLink.from([errorLink, link]);
