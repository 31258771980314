/* eslint-disable no-console */
import React, { FC, useRef, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useBarcode } from 'next-barcode';
import { Box, Button, CircularProgress, IconButton, Stack, Typography, Zoom } from '@mui/material';

import { useLocalization } from '../../../../localization';
import { AddStockFromOrderItemResult, AddStockResult } from '../../../../__generated__/types';
import { InformAddressStock } from './InformAddressStock';
import { InformComponent } from './InformComponent';
import { BlockButtons } from './BlockButtons';
import { EditAddressStock } from './EditAddresStock';
import { CurrentAddressType } from '../../types';
import { useUpdateStockOrderItemMutation } from '../../../../graphql/mutations/__generated__/updateStockOrderitem';
import { handlerError } from '../../../../helpers/functions';
import { PrintSticker } from '../PrintSticker';
import { Icon, Input } from '../../../../legos';
import { TranslatedField } from '../../../../components/Layout/components/TranslatedField/TranslatedField';
import { useAddStockFromOrderItemMutation } from '../../../../graphql/mutations/__generated__/addStockFromOrderItem';
import { GetOrderItemsDocument } from '../../../../graphql/queries/__generated__/getOrderItems';
import { getVarOrderItems } from '../../../../graphql/queries/hook/useGetOrderItems';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  dataProduct?: AddStockFromOrderItemResult | AddStockResult | null;
}

enum AddInStockFields {
  quantity = 'quantity',
}

export const SearchedContent: FC<Props> = ({ isOpen, dataProduct }) => {
  const { translateLang, updateSelectLanguage } = useLocalization();
  const [showInput, setShowInput] = useState(false);
  const { inputRef } = useBarcode({
    value: dataProduct?.stockData?.stockBarcode || 'no number',
    options: {
      background: 'transparent',
      textPosition: 'top',
      textMargin: 14,
      height: 70,
      fontSize: 24,
    },
  });
  const [runUpdateStockOrderItem] = useUpdateStockOrderItemMutation();
  const [addStockFromOrderItemMutation, { data: updatedOrderData, loading }] =
    useAddStockFromOrderItemMutation();
  const [editing, setEditing] = useState(false);
  const [currentAddress, setCurrentAddress] = useState<CurrentAddressType>(getStockCellAddress());
  const [emptyCellID, setEmptyCellID] = useState(currentAddress.stockCellAddressId);
  const divRef = useRef<HTMLDivElement>(null);

  const runStickerPrint = () => {
    const ifram = document.createElement('iframe');
    ifram.style.display = 'none';
    document.body.appendChild(ifram);
    const pri = ifram.contentWindow;
    if (pri && divRef.current) {
      pri.document.open();
      pri.document.write(divRef.current.innerHTML);
      pri.document.close();
      pri.focus();
      pri.print();
    }
    setTimeout(() => {
      document.body.removeChild(ifram);
      // handleClose();
    }, 500);
  };
  const changeAddressOrderItem = async () => {
    if (emptyCellID && emptyCellID !== currentAddress.stockCellAddressId) {
      try {
        if (currentAddress?.stockCellAddressId) {
          const resp = await runUpdateStockOrderItem({
            variables: {
              id: dataProduct?.stockData?.stockId || '',
              data: {
                stock_cell_address: emptyCellID,
              },
            },
          });
          if (resp.data?.updateStock?.data?.attributes?.stock_cell_address?.data) {
            const cell =
              resp.data.updateStock.data.attributes?.stock_cell_address?.data.attributes?.cell ||
              '';
            const shelf =
              resp.data.updateStock.data.attributes?.stock_cell_address?.data?.attributes?.shelf ||
              '';
            const stockCellAddressId =
              resp.data.updateStock.data.attributes?.stock_cell_address?.data.id || '';
            setCurrentAddress({
              cell,
              shelf,
              stockCellAddressId,
            });
            setEditing(false);
          }
        }
      } catch (err: unknown) {
        handlerError(err);
      }
    }
  };
  function getStockCellAddress(): CurrentAddressType {
    if (dataProduct?.stockData?.stockCellAddress) {
      return {
        cell: dataProduct?.stockData.stockCellAddress.cell || '?',
        shelf: dataProduct?.stockData.stockCellAddress?.shelf || '?',
        stockCellAddressId: dataProduct?.stockData?.stockCellAddress?.id || '',
      };
    }
    return { cell: '?', shelf: '?', stockCellAddressId: '' };
  }

  const initialValues = {
    [AddInStockFields.quantity]: 1,
  };

  const orderedQuantity =
    updatedOrderData && updatedOrderData.addStockFromOrderItem?.orderData?.orderedQuantity
      ? updatedOrderData.addStockFromOrderItem?.orderData?.orderedQuantity
      : dataProduct?.__typename === 'AddStockFromOrderItemResult' &&
        !isNaN(Number(dataProduct?.orderData?.orderedQuantity))
      ? Number(dataProduct?.orderData?.orderedQuantity)
      : 0;

  const outqoingQuantity =
    updatedOrderData && updatedOrderData.addStockFromOrderItem?.orderData?.outgoingQuantity
      ? updatedOrderData.addStockFromOrderItem?.orderData?.outgoingQuantity
      : dataProduct?.__typename === 'AddStockFromOrderItemResult' &&
        !isNaN(Number(dataProduct?.orderData?.outgoingQuantity))
      ? Number(dataProduct?.orderData?.outgoingQuantity)
      : 0;

  const maxQuantity = orderedQuantity - outqoingQuantity;

  const validationSchema = yup.object({
    [AddInStockFields.quantity]: yup
      .number()
      .positive(translateLang('positiv_number'))
      .max(maxQuantity, `${translateLang('maximal')} ${maxQuantity}`),
  });
  const { values, errors, handleChange, handleSubmit, isValid } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      if (dataProduct?.__typename === 'AddStockFromOrderItemResult') {
        runAddStockOrderItem(
          values[AddInStockFields.quantity],
          dataProduct?.orderData?.orderId,
          dataProduct?.orderData?.orderItemId
        ).finally(() => {
          setShowInput(false);
        });
      }
    },
  });

  async function runAddStockOrderItem(
    quantity: number,
    orderId?: string | null,
    orderItemId?: string | null
  ) {
    try {
      await addStockFromOrderItemMutation({
        variables: {
          input: {
            orderId: orderId || '',
            orderItemId: orderItemId || '',
            quantity: quantity,
            locale: updateSelectLanguage,
          },
        },
        refetchQueries: [
          {
            query: GetOrderItemsDocument,
            variables: getVarOrderItems(updateSelectLanguage),
          },
        ],
      });
    } catch (err: unknown) {
      handlerError(err);
    }
  }

  return (
    <Zoom
      in={isOpen}
      mountOnEnter
      unmountOnExit
      style={{ transitionDelay: isOpen ? '500ms' : '0ms' }}
    >
      <Box display="flex" width="100%" height="100%" p={1}>
        <Box
          width="360px"
          height="100%"
          borderRight="1px solid #8D93A6"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          pr={2}
        >
          <Typography
            sx={{
              fontWeight: 400,
              fontSize: '18px',
              lineHeight: '38px',
              color: '#646363',
            }}
          >
            {translateLang('barCodeOrder')}
          </Typography>
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <svg ref={inputRef} />
          </Box>
        </Box>
        <Stack width="100%">
          <Box
            flex={1}
            height="100%"
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            pl={6}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '18px',
                lineHeight: '38px',
                color: '#646363',
              }}
            >
              {translateLang(!editing ? 'productInformation' : 'choosePlace')}
            </Typography>
            {!editing ? (
              <InformAddressStock shelf={currentAddress.shelf} cell={currentAddress.cell} />
            ) : (
              <EditAddressStock emptyCellID={emptyCellID} setEmptyCellID={setEmptyCellID} />
            )}
            <InformComponent dataProduct={dataProduct} />
            {
              <>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '20px',
                    lineHeight: '25.1px',
                    marginTop: 2,
                    marginBottom: '10px',
                  }}
                >
                  {translateLang('quantity')}
                  {': '}
                  {outqoingQuantity}
                  {` (${translateLang('ordered')}: ${orderedQuantity})`}
                </Typography>

                {!!maxQuantity &&
                  (!showInput ? (
                    <Button
                      onClick={() => {
                        setShowInput(true);
                      }}
                      variant="contained"
                      color="secondary"
                      style={{
                        height: '50px',
                        borderRadius: '10px',
                      }}
                    >
                      <TranslatedField
                        originText={translateLang('addQuantity')}
                        fontSize={16}
                        isTranslate
                        noWrap
                        color="white"
                        textTransform="none"
                      />
                    </Button>
                  ) : (
                    <Stack component="form" onSubmit={handleSubmit} flexDirection="row" gap={2}>
                      <Input
                        width="150px"
                        type="number"
                        disabled={loading}
                        variant={'outlined'}
                        placeholder={translateLang('quantity')}
                        name={AddInStockFields.quantity}
                        value={values[AddInStockFields.quantity]}
                        onChange={handleChange}
                        error={!!errors[AddInStockFields.quantity]}
                        helperText={errors[AddInStockFields.quantity]}
                      />
                      <IconButton
                        disabled={loading || !isValid}
                        type="submit"
                        style={{ height: 40, marginTop: 5 }}
                      >
                        {loading ? (
                          <CircularProgress size={20} />
                        ) : (
                          <Icon icon="check" color="inherit" />
                        )}
                      </IconButton>
                      <IconButton
                        disabled={loading}
                        onClick={() => {
                          setShowInput(false);
                        }}
                        style={{ height: 40, marginTop: 5 }}
                      >
                        <Icon icon="close" color="inherit" />
                      </IconButton>
                    </Stack>
                  ))}
              </>
            }
          </Box>
          <BlockButtons
            saveChangeAddressOrderItems={changeAddressOrderItem}
            editing={editing}
            runStickerPrint={runStickerPrint}
            changeMode={setEditing}
          />
        </Stack>
        <PrintSticker
          ref={divRef}
          barcode={dataProduct?.stockData?.stockBarcode}
          articleNumber={dataProduct?.stockData?.articleNumber}
          customerPhoneNumber={
            dataProduct?.__typename === 'AddStockFromOrderItemResult'
              ? dataProduct?.orderData?.customerPhoneNumber
              : null
          }
          orderId={
            dataProduct?.__typename === 'AddStockFromOrderItemResult'
              ? `${dataProduct.orderData?.orderNumber}${
                  dataProduct.orderData?.orderNumberAdd
                    ? '-' + dataProduct?.orderData?.orderNumberAdd
                    : ''
                }`
              : null
          }
          orderDate={
            dataProduct?.__typename === 'AddStockFromOrderItemResult'
              ? dataProduct.orderData?.orderDate
              : null
          }
          titleProduct={dataProduct?.stockData?.title}
        />
      </Box>
    </Zoom>
  );
};
