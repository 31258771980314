import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

import { TabsBar } from './components/TabsBar';
import { SupplierInfoHeader } from './components/SupplierInfoHeader';
import { SupplierBalance } from './components/SupplierBalance/SupplierBalance';
import { useGetSupplierBalanceQuery } from '../../graphql/queries/__generated__/getSupplierBalance';
import { IncomingInvoicesTab } from '../Warehouse/components/IncomingInvoicesTab/IncomingInvoicesTab';

export const SupplierInfo = () => {
  const { idSupplier = '' } = useParams();

  const [activeTable, setActiveTable] = useState<string | null>('invoices');

  const { data: supplierBalanceData, refetch: refetchSupplierBalance } = useGetSupplierBalanceQuery(
    {
      variables: {
        input: {
          supplierId: idSupplier,
        },
      },
    }
  );

  const supplierBalance = supplierBalanceData?.supplierBalance?.balance || 0;
  const remainingPayment = supplierBalanceData?.supplierBalance?.remainingPayment || 0;
  const remainingReturn = supplierBalanceData?.supplierBalance?.remainingReturn || 0;

  const handleChange = (val: string | undefined) => {
    if (val) {
      setActiveTable(val);
    }
  };

  const getActiveTable = () => {
    switch (activeTable) {
      case 'invoices':
        return (
          <IncomingInvoicesTab
            idSupplier={idSupplier}
            refetchSupplierBalance={refetchSupplierBalance}
          />
        );
      case 'balance':
        return (
          <SupplierBalance
            idSupplier={idSupplier}
            refetchSupplierBalance={refetchSupplierBalance}
          />
        );
      default:
        return (
          <IncomingInvoicesTab
            idSupplier={idSupplier}
            refetchSupplierBalance={refetchSupplierBalance}
          />
        );
    }
  };

  return (
    <Stack width="100%" height="100%">
      <SupplierInfoHeader
        idSupplier={idSupplier}
        supplierBalance={supplierBalance}
        remainingPayment={remainingPayment}
        remainingReturn={remainingReturn}
      />

      <Stack>
        <Box mb={3.75}>
          <TabsBar activeTable={activeTable} handleChange={handleChange} />
        </Box>

        {getActiveTable()}
      </Stack>
    </Stack>
  );
};
