import React, { FC } from 'react';

import { SupplierReturnItemEntity } from '../../../../../../__generated__/types';
import { TableProductActive } from '../../../../../../components/TableProductActive/TableProductActive';
import { formatDateTime } from '../../../../../../utils/dateUtils';

interface Props {
  invoiceData: SupplierReturnItemEntity;
}

export const TableReturnInvoiceRow: FC<Props> = ({ invoiceData }) => (
  <TableProductActive.RowTable>
    <TableProductActive.CellTable ceilStyle={{ borderLeft: 'none' }}>
      {
        invoiceData.attributes?.stock?.data?.attributes?.car_spare?.data?.attributes
          ?.tecdoc_articleNumber
      }
    </TableProductActive.CellTable>
    <TableProductActive.CellTable>
      {
        invoiceData.attributes?.stock?.data?.attributes?.car_spare?.data?.attributes
          ?.car_spare_locales?.data[0].attributes?.title
      }
    </TableProductActive.CellTable>
    <TableProductActive.CellTable>
      {formatDateTime(invoiceData.attributes?.createdAt, { isISO: true, format: 'P' })}
    </TableProductActive.CellTable>
    <TableProductActive.CellTable>
      {invoiceData.attributes?.quantityReturned}
    </TableProductActive.CellTable>
    <TableProductActive.CellTable>
      {`${invoiceData.attributes?.supplierPrice} €`}
    </TableProductActive.CellTable>
  </TableProductActive.RowTable>
);
