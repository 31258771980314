type FormatCurrencyOptions = {
  digit?: number;
  error?: string;
  prefix?: string;
  suffix?: string;
};
export const formatCurrency = (
  amount?: number | string | null,
  options?: FormatCurrencyOptions
): string => {
  options = options ?? {};
  options.digit = options.digit ?? 2;
  options.error = options.error ?? '';
  options.prefix = options.prefix ?? '';
  options.suffix = options.suffix ?? '';
  if (typeof amount === 'number') {
    return options.prefix + amount.toFixed(options.digit) + options.suffix;
  }

  if (typeof amount === 'string') {
    const value = parseFloat(amount);
    if (Number.isNaN(value) || !Number.isFinite(value)) {
      return options.error;
    }
    return options.prefix + value.toFixed(options.digit) + options.suffix;
  }

  return options.error;
};
