import React from 'react';
import { format } from 'date-fns';
import { useFormik } from 'formik';
import { Button, CircularProgress, IconButton, SelectChangeEvent, Stack } from '@mui/material';

import { theme } from '../../helpers';
import { IncomingInvoicesFields } from './type';
import { useLocalization } from '../../localization';
import { CalendarMUI, Icon, Input, SelectCustom } from '../../legos';
import { useHandlerNotificationApp } from '../../hooks/useHandlerNotificationApp';
import { TranslatedField } from '../Layout/components/TranslatedField/TranslatedField';
import { useGetSuppliersQuery } from '../../graphql/queries/__generated__/getSuppliers';
import { Enum_Incominginvoice_Status, IncomingInvoiceEntity } from '../../__generated__/types';
import { useUpdateIncomingInvoiceMutation } from '../../graphql/mutations/__generated__/updateIncomingInvoice';
import { useCreateIncomingInvoiceMutation } from '../../graphql/mutations/__generated__/createIncomingInvoice';
import { useIncomingInvoiceStatuses } from '../../pages/Warehouse/components/IncomingInvoicesTab/hooks/useIncomingInvoiceStatuses';
import { useIncomingInvoicesValidation } from '../../pages/Warehouse/components/IncomingInvoicesTab/hooks/useIncomingInvoicesValidation';
import { formatCurrencyAmount } from '../../helpers/functions';
import { formatDateTime } from '../../utils/dateUtils';

interface IncomingInvoiceFormProps {
  editInvoice?: boolean;
  invoiceData?: IncomingInvoiceEntity;
  supplier?: string | null;
  isModal?: boolean;
  closeModal: () => void;
  loading?: boolean;
  refetchIncomingInvoices?: () => void;
  handleAddIncomingInvoice?: (id: string) => void;
}

export const IncomingInvoiceForm = ({
  editInvoice,
  invoiceData,
  supplier,
  isModal,
  loading,
  closeModal,
  refetchIncomingInvoices,
  handleAddIncomingInvoice,
}: IncomingInvoiceFormProps) => {
  const { translateLang } = useLocalization();

  const { addNotification } = useHandlerNotificationApp();
  const { incomingInvoiceStatuses } = useIncomingInvoiceStatuses();
  const { validationSchema } = useIncomingInvoicesValidation();
  const { data: dataSuppliers } = useGetSuppliersQuery({
    variables: {
      filters: { or: [{ blocked: { eq: false } }, { blocked: { eq: null } }] },
    },
  });
  const [createIncomingInvoiceMutation, { loading: createLoading }] =
    useCreateIncomingInvoiceMutation();
  const [updateIncomingInvoiceMutation, { loading: editLoading }] =
    useUpdateIncomingInvoiceMutation();

  const initialValues = {
    [IncomingInvoicesFields.Number]: editInvoice
      ? (invoiceData?.attributes?.supplierInvoiceNumber as string)
      : '',
    [IncomingInvoicesFields.Date]: editInvoice
      ? new Date(invoiceData?.attributes?.supplierInvoiceDate as string)
      : new Date(),
    [IncomingInvoicesFields.Supplier]:
      supplier ?? (invoiceData?.attributes?.supplier?.data?.attributes?.name as string),
    [IncomingInvoicesFields.ActualAmount]: editInvoice
      ? (invoiceData?.attributes?.supplierInvoiceTotal as number)
      : '',
    [IncomingInvoicesFields.Status]: editInvoice
      ? invoiceData?.attributes?.status === Enum_Incominginvoice_Status.Completed
        ? translateLang('formed')
        : translateLang('forming')
      : translateLang('forming'),
  };

  const suppliers = dataSuppliers?.suppliers?.data?.map(item => item.attributes?.name) || [];

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue, dirty, resetForm } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: ({ number, date, supplier, actualAmount, status }) => {
        const supplierId = dataSuppliers?.suppliers?.data.find(
          item => item.attributes?.name === supplier
        )?.id;
        const statusType =
          status === translateLang('formed')
            ? Enum_Incominginvoice_Status.Completed
            : Enum_Incominginvoice_Status.Created;
        const formattedDate = format(date, 'yyyy-MM-dd');

        if (editInvoice) {
          updateIncomingInvoiceMutation({
            variables: {
              data: {
                status: statusType,
                supplier: supplierId,
                supplierInvoiceDate: formattedDate,
                supplierInvoiceNumber: number,
                supplierInvoiceTotal: +actualAmount,
              },
              id: invoiceData?.id as string,
            },
          })
            .then(() => {
              addNotification({
                messageError: translateLang('invoiceSuccessfullyUpdated'),
                typeMessage: 'success',
              });
              closeModal();
              resetForm();
            })
            .catch(err => {
              addNotification({ messageError: err.message, typeMessage: 'error' });
            });
        } else {
          createIncomingInvoiceMutation({
            variables: {
              data: {
                status: statusType,
                supplier: supplierId,
                supplierInvoiceDate: formattedDate,
                supplierInvoiceNumber: number,
                supplierInvoiceTotal: +actualAmount,
                date: formatDateTime(new Date(), { format: 'yyyy-MM-dd' }),
              },
            },
          })
            .then(res => {
              addNotification({
                messageError: translateLang('invoiceSuccessfullyCreated'),
                typeMessage: 'success',
              });
              closeModal();
              resetForm();
              refetchIncomingInvoices?.();
              if (
                handleAddIncomingInvoice &&
                !isModal &&
                res?.data?.createIncomingInvoice?.data?.id
              ) {
                handleAddIncomingInvoice(res?.data?.createIncomingInvoice?.data?.id);
              }
            })
            .catch(err => {
              addNotification({ messageError: err.message, typeMessage: 'error' });
            });
        }
      },
    });

  const handleDateChange = (newValue: Date | null) => {
    setFieldValue(IncomingInvoicesFields.Date, newValue);
  };

  return (
    <Stack
      component="form"
      width="100%"
      flexDirection={isModal ? 'column' : 'row'}
      mb={2}
      mt={1.5}
      gap={isModal ? 0 : 2}
      onSubmit={handleSubmit}
    >
      <Stack
        height={75}
        justifyContent="space-between"
        flexDirection={isModal ? 'row' : 'column'}
        alignItems="baseline"
        gap={3}
      >
        <TranslatedField
          originText={'numberSecond'}
          fontSize={16}
          isTranslate
          noWrap
          overflow="initial"
        />
        <Input
          name={IncomingInvoicesFields.Number}
          value={values[IncomingInvoicesFields.Number]}
          onChange={handleChange}
          error={touched[IncomingInvoicesFields.Number] && !!errors[IncomingInvoicesFields.Number]}
          helperText={
            touched[IncomingInvoicesFields.Number] && errors[IncomingInvoicesFields.Number]
          }
          inputProps={{
            style: {
              textAlign: 'center',
              paddingLeft: 0,
              color: theme.palette.common.black,
              boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
              height: 12,
            },
          }}
          bgcolor={theme.palette.common.white}
          sx={{
            ml: 'auto',
            maxWidth: '160px',
          }}
        />
      </Stack>
      <Stack
        height={75}
        justifyContent="space-between"
        flexDirection={isModal ? 'row' : 'column'}
        alignItems="baseline"
        gap={3}
      >
        <TranslatedField originText={'date'} fontSize={16} isTranslate noWrap overflow="initial" />
        <CalendarMUI
          disableFuture
          value={values[IncomingInvoicesFields.Date]}
          isLabel={false}
          setValue={handleDateChange}
          inputStyle={{
            bgcolor: theme.palette.common.white,
            height: 40,
            width: 160,
            boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
            p: 0,
            '& .MuiInputBase-root': {
              p: '5px 15px',
            },
          }}
        />
      </Stack>
      <Stack
        height={75}
        justifyContent="space-between"
        flexDirection={isModal ? 'row' : 'column'}
        alignItems="baseline"
        gap={3}
      >
        <TranslatedField
          originText={'provider'}
          fontSize={16}
          isTranslate
          noWrap
          overflow="initial"
        />
        <SelectCustom
          items={suppliers as string[]}
          value={values[IncomingInvoicesFields.Supplier]}
          currentValue={values[IncomingInvoicesFields.Supplier]}
          handleChange={(event: SelectChangeEvent) => {
            setFieldValue(IncomingInvoicesFields.Supplier, event.target.value);
          }}
          error={
            touched[IncomingInvoicesFields.Supplier] && !!errors[IncomingInvoicesFields.Supplier]
          }
          helperText={
            touched[IncomingInvoicesFields.Supplier] && errors[IncomingInvoicesFields.Supplier]
          }
          height="36px"
          minWidth={160}
          boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset"
          sx={{
            height: '40px',
            bgcolor: theme.palette.common.white,
            borderRadius: '6px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          }}
        />
      </Stack>

      <Stack
        height={75}
        justifyContent="space-between"
        flexDirection={isModal ? 'row' : 'column'}
        alignItems="baseline"
        gap={3}
      >
        <TranslatedField
          originText={'invoiceAmount'}
          fontSize={16}
          isTranslate
          noWrap
          overflow="initial"
        />
        <Input
          name={IncomingInvoicesFields.ActualAmount}
          value={`${values[IncomingInvoicesFields.ActualAmount]}€`}
          onChange={event => {
            const value = formatCurrencyAmount(event.target.value);
            setFieldValue(IncomingInvoicesFields.ActualAmount, value);
          }}
          error={
            touched[IncomingInvoicesFields.ActualAmount] &&
            !!errors[IncomingInvoicesFields.ActualAmount]
          }
          helperText={
            touched[IncomingInvoicesFields.ActualAmount] &&
            errors[IncomingInvoicesFields.ActualAmount]
          }
          inputProps={{
            style: {
              textAlign: 'center',
              paddingLeft: 0,
              color: theme.palette.common.black,
              boxShadow: ' 0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset',
              height: 12,
            },
          }}
          bgcolor={theme.palette.common.white}
          sx={{
            ml: 'auto',
            maxWidth: '160px',
          }}
        />
      </Stack>
      {editInvoice && (
        <Stack
          height={75}
          justifyContent="space-between"
          flexDirection={isModal ? 'row' : 'column'}
          alignItems="baseline"
          gap={3}
        >
          <TranslatedField
            originText={'status'}
            fontSize={16}
            isTranslate
            noWrap
            overflow="initial"
          />
          <SelectCustom
            items={incomingInvoiceStatuses}
            value={values[IncomingInvoicesFields.Status]}
            currentValue={values[IncomingInvoicesFields.Status]}
            handleChange={(event: SelectChangeEvent) => {
              setFieldValue(IncomingInvoicesFields.Status, event.target.value);
            }}
            error={
              touched[IncomingInvoicesFields.Status] && !!errors[IncomingInvoicesFields.Status]
            }
            helperText={
              touched[IncomingInvoicesFields.Status] && errors[IncomingInvoicesFields.Status]
            }
            height="36px"
            minWidth={160}
            boxShadow="0px 1px 2px 0px rgba(0, 0, 0, 0.25) inset"
            sx={{
              height: '40px',
              borderRadius: '6px',
              bgcolor: theme.palette.common.white,
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
          />
        </Stack>
      )}
      {isModal ? (
        <Button
          type="submit"
          variant="contained"
          fullWidth
          disabled={!dirty || createLoading || editLoading}
          startIcon={createLoading || editLoading ? <CircularProgress size={20} /> : undefined}
          sx={{
            height: 50,
            p: 2,
            textTransform: 'none',
            backgroundColor: theme.palette.secondary.main,
            borderRadius: '10px',
            '&:disabled': {
              color: theme.palette.common.white,
            },
          }}
        >
          <TranslatedField originText={'done'} fontSize={16} isTranslate noWrap />
        </Button>
      ) : (
        <Stack height={40} pt="47px" direction="row" gap={1} alignSelf="flex-end">
          <IconButton
            type="submit"
            sx={{
              width: 40,
              color: theme.palette.primary.main,
            }}
          >
            {loading ? <CircularProgress size={20} /> : <Icon icon="check" color="inherit" />}
          </IconButton>
          <IconButton
            disabled={loading}
            onClick={closeModal}
            sx={{
              width: 40,
              color: theme.palette.primary.main,
            }}
          >
            <Icon icon="close" color="inherit" />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};
